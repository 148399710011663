import { api } from "./BaseApi";
import { SUPPLIER } from "../../../server/constants/AppTypes";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

const ACCOUNT_URL = `${API_ROOT_URL}/account`;
const SUPPLIER_ACCOUNT_URL = `${API_ROOT_URL}/supplier/account`;
const UPDATE_URL = `${API_V2_ROOT_URL}/account`;
const UPDATE_LANG = `${API_V2_ROOT_URL}/account/language`;
const ORDER_LIST_COLUMNS = `${API_V2_ROOT_URL}/account/order_list_columns`;

export type UserRequest = {
  activated: boolean,
  authorities: string[],
  created_date: string,
  email: string,
  first_name: string,
  full_name: string,
  languages: string,
  last_name: string,
  login: string,
  password: string,
  phone: string,
  supplier: {
    description: string,
    id: number,
    name: string,
    phone: string,
  },
  user_id: number,
  valid_phone: boolean,
  version: number,
  marketplace_id: number,
  marketplace_ids: number[],
  language_key: string,
};

export const getUser = appType =>
  api.getStream(appType === SUPPLIER ? SUPPLIER_ACCOUNT_URL : ACCOUNT_URL);
export const getUser2 = () => api.get(ACCOUNT_URL);
export const getOrderListColumns = () => api.get(ORDER_LIST_COLUMNS);
export const updateUser = (body: UserRequest) => api.post(UPDATE_URL, { body });

export const changeLang = key =>
  api.put(UPDATE_LANG, {
    params: {
      language_key: key,
    },
  });
