import sprintf from "sprintf";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "./BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";
import fp from "lodash/fp";
import { fromJS } from "immutable";

const RECEIVING_ORDERS_URL = `${API_ROOT_URL}/recipient/orders`;
const VALIDATE_RECIPIENT_ORDER = `${API_ROOT_URL}/recipient/validate/%s`;
const GET_MASKED_PHONE_NUMBER = `${API_ROOT_URL}/public/order/recipient_masked_phone_with_required_validation/%s`;
const VERIFY_PHONE_NUMBER = `${API_ROOT_URL}/public/order/validate_recipient_phone/%s`;
const GET_DROPOFF_LOCATION = `${API_ROOT_URL}/public/order/recipient_drop_off_location/%s`;
const UPDATE_DROPOFF_LOCATION = `${API_ROOT_URL}/public/recipient/order/update_dropoff_location/%s/`;
const SAVE_CUSTOMER_FEEDBACK = `${API_ROOT_URL}/public/order/%s/save_customer_feedback`;
const GET_TIMESLOTS = `${API_ROOT_URL}/public/time_slots_by_order/%s`;
const UPDATE_TIMESLOT = `${API_ROOT_URL}/public/recipient/order/%s/delivery_timeslot`;

const DYNAMIC_FIELDS = `${API_ROOT_URL}/csv/dynamic_columns`;
const NOTE_REASONS = `${API_ROOT_URL}/enum/note_reasons`;

export const getReceivingOrders = (filter: DataListFilter) =>
  api.getStream(RECEIVING_ORDERS_URL, { params: filter.getDefinedValues() });

export const validateRecipientOrder = publicId =>
  api.getStream(sprintf(VALIDATE_RECIPIENT_ORDER, publicId));

export const getMaskedPhoneNumber = publicId =>
  api.getStream(sprintf(GET_MASKED_PHONE_NUMBER, publicId));

export const verifyPhoneNumber = (publicId, phone) =>
  api.post(sprintf(VERIFY_PHONE_NUMBER, publicId), { params: { phone } });

export const getDropOffLocationByPublicId = (publicId, phone) =>
  api.getStream(sprintf(GET_DROPOFF_LOCATION, publicId), { params: { phone } });

export const updateDropOffLocationByPublicId = (publicId, phone, body) =>
  api.put(sprintf(UPDATE_DROPOFF_LOCATION, publicId), {
    params: { phone },
    body,
  });
export const savePublicCustomerFeedback = (publicId, body) =>
  api.post(sprintf(SAVE_CUSTOMER_FEEDBACK, publicId), {
    body,
  });
export const getDropOffTimeSlotsByPublicId = (
  publicId,
  filter: DataListFilter,
) =>
  api.getStream(sprintf(GET_TIMESLOTS, publicId), {
    params: filter.getDefinedValues(),
  });

export const updateDropOffTimeslotByPublicId = (publicId, phone, body) =>
  api.put(sprintf(UPDATE_TIMESLOT, publicId), {
    params: { phone },
    body,
  });

export const getDefaultDynamicFields = () => api.getStream(DYNAMIC_FIELDS);

const noteReasonsCache = new ObservableCache({
  expireAfterWrite: 1000 * 60 * 60 * 4, // 4 hours
  loader: () => api.getStream(NOTE_REASONS),
});
export const getNoteReasons = () => noteReasonsCache.get();
export const saveDefaultDynamicFields = columns =>
  api.post(DYNAMIC_FIELDS, { params: columns });

const POST_SERVICE_ITEM = `${API_V2_ROOT_URL}/admin/service/additional/%s`;
const POST_SERVICE_LIST = `${API_V2_ROOT_URL}/admin/service/additional`;
const postServicesCache = new ObservableCache({
  loader: id =>
    api
      .get(sprintf(POST_SERVICE_ITEM, id))
      .then(fp.flow(fp.get("data"), fromJS)),
});

export const getCachedPostServices = id => postServicesCache.get(id);
export const getPostServicesPredictions = (request: DataListFilter) =>
  api.getStream(POST_SERVICE_LIST, { params: request.getDefinedValues() });
export const getPostServicesPredictions2 = params =>
  api.get(POST_SERVICE_LIST, { params });

export const getAdditionalService = id =>
  api.get(sprintf(POST_SERVICE_ITEM, id));
export const updateAdditionalService = (id, body) =>
  api.put(sprintf(POST_SERVICE_ITEM, id), { body });
export const createAdditionalService = body =>
  api.post(POST_SERVICE_LIST, { body });
export const getAdditionalServices = (page, size) =>
  api.get(POST_SERVICE_LIST, { params: { page, size } });
export const deleteAdditionalService = id =>
  api.delete(sprintf(POST_SERVICE_ITEM, id));

const BATCH_CATEGORY_ITEM = `${API_ROOT_URL}/admin/batch/categories/%s`;
const BATCH_CATEGORY_LIST = `${API_ROOT_URL}/admin/batch/categories`;

export const getBatchCategories = params =>
  api.get(BATCH_CATEGORY_LIST, { params });
export const updateBatchCategory = (id, body) =>
  api.put(sprintf(BATCH_CATEGORY_ITEM, id), { body });
export const createBatchCategory = body =>
  api.post(BATCH_CATEGORY_LIST, { body });
export const deleteBatchCategory = id =>
  api.delete(sprintf(BATCH_CATEGORY_ITEM, id));
