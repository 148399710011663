import { OrderedSet } from "immutable";

export const ROLE_USER = "ROLE_USER";
export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_ADMIN_VIEWER = "ROLE_ADMIN_VIEWER";
export const ROLE_CUSTOMER = "ROLE_CUSTOMER";
export const ROLE_SUPPLIER_ADMIN = "ROLE_SUPPLIER_ADMIN";
export const ROLE_TOOLS_VIEWER = "ROLE_TOOLS_VIEWER";
export const ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN";
export const ROLE_FINANCE_PAYABLE = "ROLE_FINANCE_PAYABLE";
export const ROLE_CUSTOMER_SUPPORT = "ROLE_CUSTOMER_SUPPORT";
export const ROLE_DISPATCH = "ROLE_DISPATCH";
export const ROLE_FINANCE_RECEIVABLE = "ROLE_FINANCE_RECEIVABLE";
export const ROLE_SALES_REPRESENTATIVE = "ROLE_SALES_REPRESENTATIVE";
export const ROLE_CONTRACT_OWNER = "ROLE_CONTRACT_OWNER";
export const ROLE_PACKAGE_ADMIN = "ROLE_PACKAGE_ADMIN";
export const ROLE_RULE_ADMIN = "ROLE_RULE_ADMIN";
export const ROLE_FINANCE_ADMIN = "ROLE_FINANCE_ADMIN";
export const ROLE_AREA_EDITOR = "ROLE_AREA_EDITOR";
export const ROLE_AREA_ADMIN = "ROLE_AREA_ADMIN";
export const ROLE_MARKETPLACE_OWNER = "ROLE_MARKETPLACE_OWNER";
export const ROLE_MARKETPLACE_ADMIN = "ROLE_MARKETPLACE_ADMIN";
export const ROLE_SUPPLIER_BASIC = "ROLE_SUPPLIER_BASIC";
export const ROUTE_CREATOR = "route_creator";

export default OrderedSet.of(
  ROLE_USER,
  ROLE_TOOLS_VIEWER,
  ROLE_ADMIN,
  ROLE_CUSTOMER,
  ROLE_SUPPLIER_ADMIN,
  ROLE_ADMIN_VIEWER,
);
