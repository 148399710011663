import React from "react";
import { Map } from "immutable";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import {
  AppBar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Grid,
  TextField,
} from "@material-ui/core";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Close, ViewList, Search } from "@material-ui/icons";
import {
  getCurrentLanguage,
  getIsRTL,
  getMessages,
} from "../../reducers/LocalizationReducer";
import { withTheme } from "@material-ui/core/styles";
import useSheet from "react-jss";
import * as JsSearch from "js-search";

// eslint-disable-next-line no-undef
const addrs = require("./assets/address.csv");

const enhancer = compose(
  withTheme,
  useSheet({
    title: {
      color: (props) => props.theme.palette.appBarTextColor,
      backgroundColor: (props) => props.theme.palette.primary.main,
    },
  }),
  connect((state) => ({
    i18n: getMessages(state),
    currentLanguage: getCurrentLanguage(state),
    isRTL: getIsRTL(state),
    // eslint-disable-next-line no-undef,global-require
    address: require("./assets/address.csv"),
  })),
  withState("search", "setSearch", ""),
  withState("district", "setDistrict", ""),
  withState("street", "setStreet", ""),
  withState("index", "setIndex", ""),
  withRouter,
);

CitiesHelpCenterDrawer.propTypes = {
  open: PropTypes.bool,
  onRequestChange: PropTypes.func.isRequired,
  routes: PropTypes.array,
  currentLanguage: PropTypes.string,
  i18n: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
  setSearch: PropTypes.func,
  search: PropTypes.string,
  district: PropTypes.string,
  setDistrict: PropTypes.func,
  index: PropTypes.string,
  setIndex: PropTypes.func,
  street: PropTypes.string,
  setStreet: PropTypes.func,
  address: PropTypes.array,
};

const search = new JsSearch.Search("name");

search.addIndex("district");
search.addIndex("name");
search.addIndex("index");
search.addIndex("street");
search.addIndex("house");

search.addDocuments(addrs);

function CitiesHelpCenterDrawer(props) {
  const { i18n } = props;

  const filtered = props.search ? search.search(props.search || "") : addrs;

  return (
    <Drawer
      docked={false}
      openSecondary={true}
      open={props.open}
      onClose={() => props.onRequestChange(false)}
      anchor="right"
      variant="temporary"
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={() => props.onRequestChange(false)}>
            <Close />
          </IconButton>
          <Typography variant="h6" className={props.classes.title}>
            {i18n.get("help_center", "Help Center")}
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container={true} fullWidth={true} style={{ width: 400 }}>
        <Grid item={true} md={12} style={{ padding: 5, marginTop: 10 }}>
          <TextField
            fullWidth={true}
            variant="outlined"
            label="Поиск"
            placeholder="Поиск"
            InputProps={{
              startAdornment: <Search color="primary" />,
            }}
            onChange={(e) => {
              props.setSearch(e.target.value);
            }}
            size="medium"
          />
        </Grid>

        <Grid item={true} md={12}>
          {props.search ? (
            <div style={{ fontSize: 16, margin: 15 }}>
              Найдено: <b>{filtered.length}</b> шт.
            </div>
          ) : null}

          <List className={props.classes.list}>
            {filtered.length
              ? filtered.map((addr) => (
                  <ListItem style={{ width: "100%" }}>
                    <ListItemIcon>
                      <ViewList color="#df000c" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <div>
                          {i18n.get("index", "Индекс")}: <b>{addr.index}</b>{" "}
                          <br />
                          {i18n.get("district", "Район")}:{" "}
                          <b>{addr.district}</b> <br />
                          {i18n.get("address", "Адрес")}:{" "}
                          <b>
                            {addr.name}, {addr.street}, {addr.house},{" "}
                          </b>
                          <br />
                        </div>
                      }
                    />
                  </ListItem>
                ))
              : false}
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );
}

export default enhancer(CitiesHelpCenterDrawer);
