import React, { useState } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Button,
  CardContent,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import SignInLink from "./SignInLink";
import FormTextField from "../form/FormTextField";
import CenteredCard from "../deprecated/CenteredCard";
import { validatePasswordForLocalisation } from "../../helpers/ValidateUtils";
import { accent1 } from "../../../shared/theme/main-theme";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    changePassword: { width: "100%", backgroundColor: accent1 },
    signIn: {
      display: "block",
      marginTop: "16px",
      textAlign: "center",
      textTransform: "uppercase",
    },
  }),
  reduxForm({
    form: "ChangePasswordForm",
    validate: (values, props) => ({
      key:
        !values.key &&
        props.getLocalisationMessage(
          "please_enter_secret_key",
          "Please enter secret key",
        ),
      password: validatePasswordForLocalisation(
        values.password,
        props.getLocalisationMessage,
      ),
      passwordConfirm:
        values.password !== values.passwordConfirm &&
        props.getLocalisationMessage(
          "password_are_not_equal",
          "Password did not match",
        ),
    }),
  }),
);

ChangePasswordForm.propTypes = {
  classes: PropTypes.object,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function ChangePasswordForm(props) {
  const { classes, getLocalisationMessage } = props;
  const [state, setState] = useState({
    showPassword1: false,
    showPassword2: false,
  });

  return (
    <CenteredCard withLogo={true} isLoading={props.submitting}>
      <CardContent>
        <form autoComplete="off" onSubmit={props.handleSubmit}>
          {!props.initialValues.key && (
            <FormTextField
              margin="normal"
              name="key"
              fullWidth={true}
              label={getLocalisationMessage("secret_key")}
            />
          )}

          <FormTextField
            margin="normal"
            name="password"
            type={state.showPassword1 ? "text" : "password"}
            fullWidth={true}
            label={getLocalisationMessage("new_password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setState({
                        ...state,
                        showPassword1: !state.showPassword1,
                      })
                    }
                  >
                    {state.showPassword1 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <FormTextField
            margin="normal"
            type={state.showPassword2 ? "text" : "password"}
            fullWidth={true}
            name="passwordConfirm"
            label={getLocalisationMessage("confirm_password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setState({
                        ...state,
                        showPassword2: !state.showPassword2,
                      })
                    }
                  >
                    {state.showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button type="submit" className={classes.changePassword}>
            {getLocalisationMessage("change_password", "Change Password")}
          </Button>
        </form>

        <SignInLink className={classes.signIn} />
      </CardContent>
    </CenteredCard>
  );
}

export default enhancer(ChangePasswordForm);
