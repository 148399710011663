import { Observable } from "rxjs";
import React from "react";
import { createEventHandler, componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import LayerProvider from "../portals/LayerProvider";
import Notification from "../notifications/Notification";
import { getConnectionStream } from "../../helpers/ConnectionHelpers";
// import { getAppBuildHash } from "../../services/AppSockets";

const AppStatusNotifier = componentFromStream(() => {
  const {
    handler: closeBuildNotif,
    // eslint-disable-next-line no-unused-vars
    stream: closeBuildNotifStream,
  } = createEventHandler();
  const {
    handler: closeConnectionNotif,
    stream: closeConnectionNotifStream,
  } = createEventHandler();

  const makeToggleable = toggleStream => stream =>
    stream.combineLatest(
      toggleStream
        .switchMap(() =>
          Observable.timer(60000)
            .mapTo(false)
            .startWith(true),
        )
        .startWith(false),
      (x, toggle) => !x && !toggle,
    );

  const isOfflineStream = getConnectionStream().let(
    makeToggleable(closeConnectionNotifStream),
  );

  // const isOutdatedStream = getAppBuildHash()
  //   .map(response => response.clientHash === response.serverHash)
  //   .let(makeToggleable(closeBuildNotifStream));

  return isOfflineStream.combineLatest(
    // isOutdatedStream,
    // (isOffline, isOutdated) => (
    isOffline => (
      <div>
        <Notification
          uid="isOffline"
          type="error"
          open={isOffline}
          autoHideDuration={60000}
          onClick={closeConnectionNotif}
          onRequestClose={closeConnectionNotif}
        >
          Не удается подключиться к серверу! Пожалуйста, проверьте свое
          соединение.
        </Notification>

        <Notification
          uid="isRelevantBuild"
          open={false}
          autoHideDuration={60000}
          onClick={closeBuildNotif}
          onRequestClose={closeBuildNotif}
          action={
            <Button onClick={() => window.location.reload(true)}>
              Повторить
            </Button>
          }
        >
          У нас есть обновления! Пожалуйста, обновите эту страницу.
        </Notification>
      </div>
    ),
  );
});

AppStatusObserver.propTypes = { children: PropTypes.node };

export default function AppStatusObserver(props) {
  return (
    <LayerProvider layer={<AppStatusNotifier />}>
      {props.children}
    </LayerProvider>
  );
}
