import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getIsRTL } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
  useSheet({
    root: {
      maxWidth: "100%",
      marginRight: props => (props.isRTL ? "20px" : ""),
    },
    line: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      marginRight: props => (props.isRTL ? "20px" : ""),
    },
  }),
);

MultiLineCell.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object,
  firstLine: PropTypes.node,
  secondLine: PropTypes.node,
  thirdLine: PropTypes.node,
  firstLineClass: PropTypes.string,
  secondLineClass: PropTypes.string,
  thirdLineClass: PropTypes.string,
  fallBackValue: PropTypes.node,
};

function MultiLineCell(props) {
  return (
    <div style={props.style} className={props.classes.root}>
      {props.firstLine && (
        <div className={cx(props.classes.line, props.firstLineClass)}>
          {props.firstLine}
        </div>
      )}
      {props.secondLine && (
        <div className={cx(props.classes.line, props.secondLineClass)}>
          {props.secondLine}
        </div>
      )}
      {props.thirdLine && (
        <div className={cx(props.classes.line, props.thirdLineClass)}>
          {props.thirdLine}
        </div>
      )}
      {!props.firstLine &&
        !props.secondLine &&
        !props.thirdLine &&
        props.fallBackValue}
    </div>
  );
}

export default enhancer(MultiLineCell);
