import { RuleList } from "./Rule";
import fp from "lodash/fp";
import { List, Map, Set } from "immutable";
import {
  RULE_NAME,
  WAREHOUSE,
  COURIER_TYPE,
  JURISDICTION_TO,
  COUNT,
  TO_POSTCODE,
  TRANSPORTATION_TYPE,
  TO_COUNTRY,
  VALUED,
  TO_REGION,
} from "../constants/OrderSortingField";
import { DELIVERY, RETURNING } from "../constants/FlowTypes";
import {
  INSURED_BAG,
  LETTER_BAG,
} from "../components/orders-core/BatchUpdateOrderDialog2";
import { transportationTypesName } from "../constants/TransportationType";
import { IN_TRANSIT } from "../constants/OrderStatusCodes";
import { CIS, DOMESTIC, GLOBAL } from "../constants/CountriesType";

export const TYPE_SHIPMENT = "shipment";
export const TYPE_BATCH = "batch";

export const FAILED_BIN = "Failed";
export const UNSORTED_BIN = "Unsorted";
export const UNASSIGNED_BIN = "Unassigned";
export const NOTFOUND_BIN = "Not Found";
export const UNPAID_BIN = "Unpaid";

export const PK = "Xat/Xabar";
export const MIXED = "MIXED";
export const LETTER = "LETTER"; // 1 xat
export const BANDEROL = "BANDEROL"; // 2 banderol
export const POSTCARD = "POSTCARD"; // 5 pochta kartasi
export const M_BAG = "M_BAG";
export const SMALL_PACKETS = "SMALL_PACKETS"; // 3 mayda paket
export const PARCEL = "PARCEL"; // 4 posilka
export const SEKOGRAMM = "SEKOGRAMM"; // 6 sekogramma
export const LETTER_VALUED = "LETTER_VALUED";
export const TELEGRAM = "TELEGRAM"; // 7 telegramma
export const EXPRESS_DOCS = "EXPRESS_DOCS";
export const EXPRESS_PARCEL = "EXPRESS_PARCEL";
export const BANDEROL_VALUED = "BANDEROL_VALUED";
export const ONE_STEP = "ONE_STEP";
export const COURIER_SERVICE_WITHOUT_BAG = "COURIER_SERVICE_WITHOUT_BAG";
export const COURIER_SERVICE = "COURIER_SERVICE";
export const ONE_STEP_WITHOUT_BAG = "ONE_STEP_WITHOUT_BAG";

export const POST_PACKET = "post_packet";

export const UZBEKISTAN = 246;
const ARMENIA = 23;
const AZERBAIJAN = 27;
const TURKMENISTAN = 236;
const TAJIKISTAN = 225;
const KAZAKHSTAN = 123;
const KYRGYZSTAN = 127;
const RUSSIA = 190;
const BELARUS = 32;
const UKRAINE = 240;
const MOLDOVA = 153;
const HALQARO_WAREHOUSE = 957;

export const isUzbekistan = countries => countries.includes(UZBEKISTAN);
export const isSNG = countries =>
  countries.includes(ARMENIA) ||
  countries.includes(AZERBAIJAN) ||
  countries.includes(TURKMENISTAN) ||
  countries.includes(TAJIKISTAN) ||
  countries.includes(KAZAKHSTAN) ||
  countries.includes(KYRGYZSTAN) ||
  countries.includes(RUSSIA) ||
  countries.includes(UKRAINE) ||
  countries.includes(BELARUS) ||
  countries.includes(MOLDOVA);

const getJurisdictionHierarchy = (order, key = "to_jurisdiction") => {
  const jurisdictions = [];
  const currentJurisdictionId = order.getIn([key, "id"], null);
  const currentJurisdictionName = order.getIn([key, "name"], null);
  const parentsJurisdiction = order.getIn([key, "hierarchy"], List());

  parentsJurisdiction.forEach(jurisdiction => {
    jurisdictions.push({
      id: jurisdiction.get("id"),
      name: jurisdiction.get("name"),
    });
  });

  if (currentJurisdictionId && currentJurisdictionName) {
    jurisdictions.push({
      id: currentJurisdictionId,
      name: currentJurisdictionName,
    });
  }

  return jurisdictions;
};

export const getDestinationJurisdiction = order => {
  const flow = order.get("flow", DELIVERY);

  if (flow === RETURNING)
    return getJurisdictionHierarchy(order, "from_jurisdiction");

  return getJurisdictionHierarchy(order);
};

export const getSingleDestinationJurisdiction = order => {
  const flow = order.get("flow", DELIVERY);

  if (flow === RETURNING) return order.getIn(["from_jurisdiction", "id"], null);

  return order.getIn(["to_jurisdiction", "id"], null);
};

export const getDestinationPostcode = (orderData, path = null) => {
  const order = path ? orderData.get("info", Map()) : orderData;

  const flow = order.get("flow", DELIVERY);

  if (flow === RETURNING) return order.getIn(["from_postcode", "name"], null);

  return order.getIn(["to_postcode", "name"], null);
};

export const getOrderCountry = (orderData, path = null) => {
  const order = path ? orderData.get("info", Map()) : orderData;
  const key =
    order.get("flow", DELIVERY) === RETURNING
      ? "from_jurisdiction"
      : "to_jurisdiction";

  const isHierarchyExists =
    order.hasIn([key, "hierarchy"]) && order.getIn([key, "hierarchy"]).size > 0;
  return isHierarchyExists
    ? order.getIn([key, "hierarchy", "0", "id"], null)
    : order.getIn([key, "id"], null);
};

export const getOrderRegion = (orderData, path = null) => {
  const order = path ? orderData.get("info", Map()) : orderData;
  const key =
    order.get("flow", DELIVERY) === RETURNING
      ? "from_jurisdiction"
      : "to_jurisdiction";

  const isHierarchyExists =
    order.hasIn([key, "hierarchy"]) && order.getIn([key, "hierarchy"]).size > 0;

  if (isHierarchyExists) {
    return order.getIn([key, "hierarchy"]).size > 1
      ? order.getIn([key, "hierarchy", "1"], null)
      : order.getIn([key, "hierarchy", "0"], null);
  }

  return order.get(key, null);
};

export const estimateBinRegion = (orderData, path = null) => {
  const order = path ? orderData.get("info", Map()) : orderData;

  const region = getOrderRegion(order);

  if (region) return region.get("name", null);

  return null;
};

export const getCountriesType = (orderData, path = null) => {
  const order = path ? orderData.get("info", Map()) : orderData;
  const countryId = getOrderCountry(order);

  if (!countryId) return null;

  if (isUzbekistan([countryId])) {
    return DOMESTIC;
  }

  if (isSNG([countryId])) {
    return CIS;
  }

  return GLOBAL;
};

export const estimateBinCategory = (orderData, path = null) => {
  const order = path ? orderData.get("info", Map()) : orderData;

  const serviceTypePath = ["package", "courier_type"];
  const serviceTypeValuedPath = ["package", "valuable"];
  const serviceType = order.getIn(serviceTypePath, null);
  const isValued = order.getIn(serviceTypeValuedPath, null);

  if (
    serviceType === LETTER ||
    (serviceType === BANDEROL && !isValued) ||
    serviceType === POSTCARD
  ) {
    return LETTER_BAG;
  }

  if (serviceType === LETTER_VALUED || isValued) {
    return INSURED_BAG;
  }

  if (order.has("type")) {
    return order.get("category", null);
  }

  return null;
};

export const groupByServiceType = (orderData, path = null) => {
  const order = path ? orderData.get("info", Map()) : orderData;

  const type = order.get("type", null);

  const serviceTypePath = ["package", "courier_type"];
  const serviceTypeNamePath = ["package", "courier_type_name"];
  const serviceTypeValuedPath = ["package", "valuable"];
  const isValued = order.getIn(serviceTypeValuedPath, false);

  let serviceType = order.getIn(serviceTypePath, null);
  if (type) {
    serviceType = order.get("inner_shipment_type", null);
  }

  if (
    serviceType === LETTER ||
    (serviceType === BANDEROL && !isValued) ||
    serviceType === POSTCARD
  ) {
    return PK;
  }

  return order.getIn(serviceTypeNamePath);
};

export const getWarehouse = order => {
  const type = order.get("type", null);

  if (type) {
    return {
      id: order.getIn(["to_warehouse", "id"], null),
      name: order.getIn(["to_warehouse", "name"], null),
    };
  }

  return {
    id: order.getIn(["warehouse", "id"], null),
    name: order.getIn(["warehouse", "name"], null),
  };
};

const isValuable = order => {
  const category = order.get("category", null);
  const valuable = order.getIn(["package", "valuable"], false);
  const courierType = order.getIn(["package", "courier_type"], null);

  if (category === INSURED_BAG) return true;

  if (courierType === LETTER_VALUED) return true;

  return !!valuable;
};

const createMatcher = order => ({
  courier_type: order.get("type", null)
    ? order.get("inner_shipment_type", null)
    : order.getIn(["package", "courier_type"], null),

  supplier: order.getIn(["supplier", "id"], null)
    ? {
        id: order.getIn(["supplier", "id"], null),
      }
    : null,

  valuable: isValuable(order),
  countries_type: getCountriesType(order),
  to_postcode: getDestinationPostcode(order),
  warehouse: getWarehouse(order),
  to_jurisdiction: getDestinationJurisdiction(order),
  status: order.get("status", null),
  transportation_type: order.get("transportation_type", null),
});

const createBinName = (rule, order) => {
  if (!rule) {
    return UNSORTED_BIN;
  }

  const groupBy = rule.get("group_by");

  if (groupBy) {
    const nameChunks = [];

    if (groupBy.contains(RULE_NAME)) {
      nameChunks.push(rule.get("name"));
    }

    if (groupBy.contains(WAREHOUSE)) {
      const warehouse = order.getIn(["warehouse", "name"]);

      if (warehouse) {
        nameChunks.push(warehouse);
      }
    }

    if (groupBy.contains(TO_POSTCODE)) {
      const warehouse = getDestinationPostcode(order);

      if (warehouse) {
        nameChunks.push(warehouse);
      }
    }

    if (groupBy.contains(TO_REGION)) {
      const region = estimateBinRegion(order);

      if (region) {
        nameChunks.push(region);
      }
    } else if (
      groupBy.contains(JURISDICTION_TO) ||
      groupBy.contains(TO_COUNTRY)
    ) {
      const flow = order.get("flow", DELIVERY);

      let jurisdictionKey = "to_jurisdiction";
      if (flow === RETURNING) jurisdictionKey = "from_jurisdiction";

      const jurisdiction = order.getIn([jurisdictionKey, "name"]);

      if (jurisdiction) {
        nameChunks.push(jurisdiction);
      }
    }

    if (groupBy.contains(COURIER_TYPE)) {
      const service = groupByServiceType(order);

      if (service) {
        nameChunks.push(service);
      }
    }

    if (groupBy.contains(TRANSPORTATION_TYPE)) {
      const transportationType = order.get("transportation_type");

      if (transportationType) {
        nameChunks.push(
          transportationTypesName.get(fp.toUpper(transportationType)),
        );
      }
    }

    if (groupBy.contains(VALUED)) {
      const valuable = isValuable(order);
      if (valuable) {
        nameChunks.push("Qiymatli");
      }
    }

    if (nameChunks.length > 0) {
      return nameChunks.join(" - ");
    }
  }

  const nameChunks = [];
  nameChunks.push(rule.get("name"));

  return nameChunks.join(" - ");
};

export const createOrderSortingBinCreator = rules => {
  const ruleList = new RuleList(rules.toJS());

  return order => {
    const matcher = createMatcher(order);
    const code = ruleList.process(matcher);
    const name = createBinName(rules.get(code), order);
    return { code: code || null, bin: name };
  };
};

const createRegistryBinName = (rule, order) => {
  if (!rule) {
    return UNSORTED_BIN;
  }

  const groupBy = rule.get("group_by");

  if (groupBy) {
    const nameChunks = [];

    if (groupBy.contains(RULE_NAME)) {
      nameChunks.push(rule.get("name"));
    }

    if (groupBy.contains(WAREHOUSE)) {
      const warehouse = order.getIn(["to_warehouse", "name"]);

      if (warehouse) {
        nameChunks.push(warehouse);
      }
    }

    if (groupBy.contains(TO_POSTCODE)) {
      const warehouse = getDestinationPostcode(order);

      if (warehouse) {
        nameChunks.push(warehouse);
      }
    }

    if (groupBy.contains(JURISDICTION_TO)) {
      const flow = order.get("flow", DELIVERY);

      let jurisdictionKey = "to_jurisdiction";
      if (flow === RETURNING) jurisdictionKey = "from_jurisdiction";

      const jurisdiction = order.getIn([jurisdictionKey, "name"]);

      if (jurisdiction) {
        nameChunks.push(jurisdiction);
      }
    }

    // if (groupBy.contains(COURIER_TYPE)) {
    //   const service = order.get("inner_shipment_type", null);
    //
    //   if (service) {
    //     nameChunks.push(service);
    //   }
    // }

    if (groupBy.contains(TRANSPORTATION_TYPE)) {
      const transportationType = order.get("transportation_type");

      if (transportationType) {
        nameChunks.push(
          transportationTypesName.get(fp.toUpper(transportationType)),
        );
      }
    }

    if (nameChunks.length > 0) {
      return nameChunks.join(" - ");
    }
  }

  const nameChunks = [];
  nameChunks.push(rule.get("name"));

  return nameChunks.join(" - ");
};

export const createOrderRegistrySortingBinCreator = rules => {
  const result = {};
  const r = rules.toJS();

  Object.keys(r).forEach(key => {
    if (key !== "undefined") {
      result[key] = r[key];
    }
  });

  const ruleList = new RuleList(result);

  return order => {
    const matcher = createMatcher(order);
    const code = ruleList.process(matcher);
    // console.log("code", code, matcher, order.toJS(), rules.toJS());

    const name = createRegistryBinName(rules.get(code), order);
    const warehouse = rules.getIn([code, "warehouse", "id"]);
    return { code: code || null, bin: name, warehouse };
  };
};

export const isExpiredOrderRecord = x => !x.get("hash") || !x.get("hash_time");
// || Date.now() - 10 * 60 * 1000 > x.get("hash_time");

export const getBinFutureStatus = (bin, rules) => {
  if (rules) {
    const findRuleByName = fp.flow(
      fp.find(item => fp.isEqual(item.name, bin)),
      fp.pick(["group", "count"]),
    );

    return findRuleByName(rules.toJS());
  }

  return null;
};

export const getBinByJurisdiction = ({
  initialBins,
  initialBin,
  order,
  rule,
}) => {
  const flow = order.get("flow", DELIVERY);

  let postcodePath = ["info", "to_postcode", "name"];
  let toJurisdictionPath = ["info", "to_jurisdiction", "name"];
  let toJurisdictionIdPath = ["info", "to_jurisdiction", "id"];
  let toJurisdictionHierarchyPath = ["info", "to_jurisdiction", "hierarchy"];

  if (flow === RETURNING) {
    postcodePath = ["info", "from_postcode", "name"];
    toJurisdictionPath = ["info", "from_jurisdiction", "name"];
    toJurisdictionIdPath = ["info", "from_jurisdiction", "id"];
    toJurisdictionHierarchyPath = ["info", "from_jurisdiction", "hierarchy"];
  }

  const serviceTypePath = ["info", "package", "courier_type"];
  const serviceType = order.getIn(serviceTypePath, null);

  if (
    !rule ||
    !serviceType ||
    serviceType === PARCEL ||
    serviceType === COURIER_SERVICE_WITHOUT_BAG ||
    serviceType === ONE_STEP_WITHOUT_BAG ||
    initialBin === UNASSIGNED_BIN
  )
    return null;

  const bins = initialBins.asMutable();
  const orderNumber = order.get("number");
  const groupBy = rule.get("group_by");
  const divisionCount = rule.get("count", 8);
  const addons = [];

  if (!groupBy || !groupBy.contains(COUNT)) return null;

  const serviceTypeName = groupByServiceType(order.get("info"));
  if (!groupBy.contains(COURIER_TYPE) && serviceTypeName) {
    addons.push(serviceTypeName);
  }

  if (
    !groupBy.contains(TRANSPORTATION_TYPE) &&
    order.getIn(["info", "transportation_type"], null)
  ) {
    const transportationTypeName = transportationTypesName.get(
      fp.toUpper(order.getIn(["info", "transportation_type"])),
    );
    addons.push(transportationTypeName);
  }

  const paths = [];
  const postcode = order.getIn(postcodePath, null);
  const toJurisdiction = order.getIn(toJurisdictionPath, null);
  const toJurisdictionId = order.getIn(toJurisdictionIdPath, null);
  const toJurisdictionHierarchy = order.getIn(
    toJurisdictionHierarchyPath,
    List(),
  );

  if (!postcode && !toJurisdiction && toJurisdictionHierarchy.size === 0)
    return null;

  // Group by Postcode
  if (postcode) {
    const postcodeBin = [initialBin, postcode].concat(addons).join(" - ");
    paths.push(postcodeBin);
    if (!bins.has(postcodeBin)) {
      bins.set(
        postcodeBin,
        Map({
          label: postcodeBin,
          mainLabel: initialBin,
          code: order.get("code"),
          postcode: rule.get("postcode", null),
          bin_postcode: postcode,
          category: estimateBinCategory(order, "info"),
          selected: 0,
          country: false,
          orders: Set(),
          level: 1,
          courierTypes: Set(),
        }),
      );
    }

    bins.updateIn([postcodeBin, "orders"], x => x.add(orderNumber));
    bins.updateIn([postcodeBin, "courierTypes"], x => x.add(serviceType));
  }

  // Group by To Jurisdiction
  let hierarchyLength = 2;
  if (toJurisdiction) {
    const toJurisdictionBin = [initialBin, toJurisdiction]
      .concat(addons)
      .join(" - ");
    paths.push(toJurisdictionBin);
    if (!bins.has(toJurisdictionBin)) {
      bins.set(
        toJurisdictionBin,
        Map({
          label: toJurisdictionBin,
          mainLabel: initialBin,
          code: order.get("code"),
          postcode: order.get("postcode", null),
          bin_postcode: postcode,
          bin_jurisdiction: toJurisdictionId,
          category: estimateBinCategory(order, "info"),
          selected: 0,
          orders: Set(),
          country: toJurisdictionHierarchy.size === 0,
          level: hierarchyLength,
          courierTypes: Set(),
        }),
      );
    }
    bins.updateIn([toJurisdictionBin, "orders"], x => x.add(orderNumber));
    bins.updateIn([toJurisdictionBin, "courierTypes"], x => x.add(serviceType));
    hierarchyLength++;
  }

  // Group by Hierarchy of Jurisdiction
  if (toJurisdictionHierarchy) {
    toJurisdictionHierarchy.reverse().forEach((jurisdiction, index) => {
      const name = jurisdiction.get("name");
      const jurisdictionId = jurisdiction.get("id");
      const toJurisdictionNameBin = [initialBin, name]
        .concat(addons)
        .join(" - ");
      paths.push(toJurisdictionNameBin);
      if (!bins.has(toJurisdictionNameBin)) {
        bins.set(
          toJurisdictionNameBin,
          Map({
            label: toJurisdictionNameBin,
            mainLabel: initialBin,
            code: order.get("code"),
            postcode: order.get("postcode", null),
            bin_postcode: postcode,
            bin_jurisdiction: jurisdictionId,
            category: estimateBinCategory(order, "info"),
            selected: 0,
            orders: Set(),
            country: index === toJurisdictionHierarchy.size - 1,
            level: hierarchyLength,
            courierTypes: Set(),
          }),
        );
      }
      bins.updateIn([toJurisdictionNameBin, "orders"], x => x.add(orderNumber));
      bins.updateIn([toJurisdictionNameBin, "courierTypes"], x =>
        x.add(serviceType),
      );
      hierarchyLength++;
    });
  }

  let removedOrders = Set();
  paths.forEach(path => {
    let orders = bins.getIn([path, "orders"]);
    if (removedOrders.size > 0) {
      orders = orders.subtract(removedOrders);
    }

    if (orders.size >= divisionCount) {
      removedOrders = orders;
      bins.setIn([path, "group"], POST_PACKET);
    }
    bins.setIn([path, "orders"], orders);
  });

  return bins.asImmutable();
};

// export const isCourierServiceBag = (courierTypes, order) => courierTypes.includes(COURIER_SERVICE)

export const isRegistryBag = courierTypes =>
  courierTypes.includes(PARCEL) ||
  courierTypes.includes(COURIER_SERVICE_WITHOUT_BAG) ||
  courierTypes.includes(ONE_STEP_WITHOUT_BAG);

export const getRegistryStatusByShipmentType = (courierTypes, defaultStatus) =>
  courierTypes.includes(PARCEL) ||
  courierTypes.includes(COURIER_SERVICE_WITHOUT_BAG) ||
  courierTypes.includes(ONE_STEP_WITHOUT_BAG)
    ? IN_TRANSIT
    : defaultStatus;

export const estimateRegistryCategory = courierTypes => {
  if (
    courierTypes.includes(LETTER) ||
    courierTypes.includes(POSTCARD) ||
    courierTypes.includes(BANDEROL)
  )
    return LETTER_BAG;

  if (courierTypes.includes(POST_PACKET)) return POST_PACKET;

  if (courierTypes.includes(PARCEL)) return PARCEL;

  if (courierTypes.includes(COURIER_SERVICE_WITHOUT_BAG))
    return COURIER_SERVICE_WITHOUT_BAG;

  if (courierTypes.includes(ONE_STEP_WITHOUT_BAG)) return ONE_STEP_WITHOUT_BAG;

  return INSURED_BAG;
};

const P7_XALQARO = process.env.WING_ENV === "uzpost" ? 19938417 : 19938417; // 3886864;
const P1_RAZDELKA = process.env.WING_ENV === "uzpost" ? 19945140 : 19945140; // 3886864;
const P1_LOCAL_SNG = process.env.WING_ENV === "uzpost" ? 19948356 : 19948356; // 3886866;
const P1_MJD = process.env.WING_ENV === "uzpost" ? 19946707 : 19946707; // 3886865;
const P2_LOCAL_SNG = process.env.WING_ENV === "uzpost" ? 19949708 : 19949708; // 3886866;
const P3_PARCEL = process.env.WING_ENV === "uzpost" ? 19950360 : 19949708; // 3886868;
const P28_SMALL_PACKETS =
  process.env.WING_ENV === "uzpost" ? 20004827 : 20004827; // 3886870;

export const estimateWarehouses = (bin, courierType, countries) => {
  const values = {};
  const nextWarehouse = bin.get("transitWarehouse", null);
  const destinationWarehouse = bin.get("destinationWarehouse", null);
  const orders = bin.getIn(["selectedBin", "orders"], Map());

  values.warehouse = {
    id: nextWarehouse,
  };

  if (bin.get("group", null) !== POST_PACKET) {
    values.to_warehouse = {
      id: destinationWarehouse || nextWarehouse,
    };
  }

  if (nextWarehouse === HALQARO_WAREHOUSE) {
    values.warehouse = {
      id: P7_XALQARO,
    };

    if (bin.get("group", null) !== POST_PACKET) {
      // if (orders.size < 8) {
      //   if (courierType !== PARCEL && courierType !== SMALL_PACKETS) {
      //     if (isUzbekistan(countries) || isSNG(countries)) {
      //       values.to_warehouse = {
      //         id: P1_LOCAL_SNG,
      //       };
      //     } else {
      //       values.to_warehouse = {
      //         id: P1_MJD,
      //       };
      //     }
      //   } else if (courierType !== PARCEL) {
      //     values.to_warehouse = {
      //       id: courierType === SMALL_PACKETS ? P28_SMALL_PACKETS : P1_RAZDELKA,
      //     };
      //   } else if (isUzbekistan(countries) || isSNG(countries)) {
      //     values.to_warehouse = {
      //       id: P2_LOCAL_SNG,
      //     };
      //   } else {
      //     values.to_warehouse = {
      //       id: P3_PARCEL,
      //     };
      //   }
      // }

      if (orders.size < 8) {
        if (
          courierType !== PARCEL &&
          courierType !== COURIER_SERVICE_WITHOUT_BAG &&
          courierType !== ONE_STEP_WITHOUT_BAG
        ) {
          values.to_warehouse = {
            id: P1_RAZDELKA,
          };
        } else if (isUzbekistan(countries) || isSNG(countries)) {
          values.to_warehouse = {
            id: P2_LOCAL_SNG,
          };
        } else {
          values.to_warehouse = {
            id: P3_PARCEL,
          };
        }
      }
    }
  }

  // if (nextWarehouse === HALQARO_WAREHOUSE) {
  //   if (courierType !== PARCEL) {
  //     values.warehouse = {
  //       id: courierType === SMALL_PACKETS ? P28_SMALL_PACKETS : P1_RAZDELKA,
  //     };
  //   } else if (isUzbekistan(countries) || isSNG(countries)) {
  //     values.warehouse = {
  //       id: P2_LOCAL_SNG,
  //     };
  //   } else {
  //     values.warehouse = {
  //       id: P3_PARCEL,
  //     };
  //   }
  //
  //   if (bin.get("group", null) !== POST_PACKET) {
  //     values.to_warehouse = {
  //       id: destinationWarehouse || nextWarehouse,
  //     };
  //
  //     if (orders.size < 8) {
  //       if (courierType !== PARCEL && courierType !== SMALL_PACKETS) {
  //         if (isUzbekistan(countries) || isSNG(countries)) {
  //           values.to_warehouse = {
  //             id: P1_LOCAL_SNG,
  //           };
  //         } else {
  //           values.to_warehouse = {
  //             id: P1_MJD,
  //           };
  //         }
  //       } else {
  //         values.to_warehouse = values.warehouse;
  //       }
  //     }
  //   }
  // }

  if (values.to_warehouse === HALQARO_WAREHOUSE) {
    values.to_warehouse = values.warehouse;
  }

  return values;
};

export const estimateRegistryWarehouses = warehouseId => {
  if (
    warehouseId === P1_RAZDELKA ||
    warehouseId === P1_MJD ||
    warehouseId === P1_LOCAL_SNG ||
    warehouseId === P2_LOCAL_SNG ||
    warehouseId === P3_PARCEL ||
    warehouseId === P28_SMALL_PACKETS ||
    warehouseId === P7_XALQARO
  )
    return P7_XALQARO;

  return warehouseId;
};

export const isConsolidatedBag = type =>
  type === "CONSOLIDATED" || type === "CONTAINER";
