import fp from "lodash/fp";
import {
  branch,
  lifecycle,
  shallowEqual,
  shouldUpdate,
  renderNothing,
} from "recompose";
import { isEqualData } from "./DataUtils";

const createKeysTest = fp.flow(
  fp.castArray,
  fp.map(item => (fp.isPlainObject(item) ? fp.matches(item) : fp.get(item))),
  fp.concat(fp.negate(fp.isEmpty)),
  fp.overEvery,
);

export const renderIf = testOrMatcherOrKeys => {
  const test = fp.isFunction(testOrMatcherOrKeys)
    ? testOrMatcherOrKeys
    : createKeysTest(testOrMatcherOrKeys);

  return branch(test, fp.identity, renderNothing);
};

export const pureComponent = (normalizer = fp.identity) =>
  shouldUpdate(
    (props, nextProps) =>
      !isEqualData(normalizer(props), normalizer(nextProps)),
  );

const createResubscribe = keys => {
  const pickKeys = fp.pick(fp.castArray(keys));

  return (props, nextProps) =>
    !shallowEqual(pickKeys(props), pickKeys(nextProps));
};

export const withPropsSubscription = (
  shouldResubscribeOrKeys,
  subscribe,
  unsubscribe,
) => {
  const shouldResubscribe = fp.isFunction(shouldResubscribeOrKeys)
    ? shouldResubscribeOrKeys
    : createResubscribe(shouldResubscribeOrKeys);

  return lifecycle({
    componentDidMount() {
      subscribe(this.props);
    },
    componentDidUpdate(prevProps) {
      if (shouldResubscribe(prevProps, this.props)) {
        subscribe(this.props);

        if (unsubscribe) {
          unsubscribe(prevProps);
        }
      }
    },
    componentWillUnmount() {
      if (unsubscribe) {
        unsubscribe(this.props);
      }
    },
  });
};
