import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

const USER_ITEM_URL = `${API_ROOT_URL}/admin/user/%s`;
const USER_CHOOSE_URL = `${API_ROOT_URL}/admin/user/choose`;
const OPERATOR_CHOOSE_URL = `${API_ROOT_URL}/admin/operator/users/choose`;
const CREATE_USER_URL = `${API_ROOT_URL}/admin/user`;
const USER_LIST_URL = `${API_ROOT_URL}/admin/users`;
// const USER_ROLES_URL = `${API_ROOT_URL}/admin/roles`;
const USER_ROLES_URL2 = `${API_V2_ROOT_URL}/admin/role/choose`;
const USER_ROLES_ITEM_URL = `${API_ROOT_URL}/admin/roles?target_user_id=%s`;

const OWNER_USER_CHOOSE_URL = `${API_ROOT_URL}/admin/owner_users/choose`;
const SALES_USER_CHOOSE_URL = `${API_ROOT_URL}/admin/sales_users/choose`;
const SALES_PERSONE_CHOOSE_URL = `${API_ROOT_URL}/admin/salesrep/choose`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: (id) => api.getStream(sprintf(USER_ITEM_URL, id)),
});
const itemRoleCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: () => api.getStream(USER_ROLES_URL2),
});

const itemUserRoleCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: (id) => api.getStream(sprintf(USER_ROLES_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: (request) =>
    api.getStream(USER_CHOOSE_URL, { params: request.getDefinedValues() }),
});
const ownerPredictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: (request) =>
    api.getStream(OWNER_USER_CHOOSE_URL, {
      params: request.getDefinedValues(),
    }),
});
const salesPredictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: (request) =>
    api.getStream(SALES_USER_CHOOSE_URL, {
      params: request.getDefinedValues(),
    }),
});
const salesPersonePredictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: (request) =>
    api.getStream(SALES_PERSONE_CHOOSE_URL, {
      params: request.getDefinedValues(),
    }),
});

export const getUser = (id) => itemCache.refresh(id);
export const getCachedUser = (id) =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getUserPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);
export const getSalesPersonePredictions = (request: DataListFilter) =>
  salesPersonePredictionsCache.get(request);

export const getCachedOwnerUser = (id) => itemCache.get(id);
export const getOwnerUserPredictions = (request: DataListFilter) =>
  ownerPredictionsCache.get(request);

export const getCachedSalesUser = (id) =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getSalesUserPredictions = (request: DataListFilter) =>
  salesPredictionsCache.get(request);

export const getCachedUserRoles = () =>
  itemRoleCache
    .get()
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));

// export const getCachedUserItemRoles = id => itemUserRoleCache.refresh(id);
export const getCachedUserItemRoles = (id) => itemUserRoleCache.refresh(id);

export const createUser = (body) => api.post(CREATE_USER_URL, { body });
export const updateUser = (id, body) =>
  api.put(sprintf(USER_ITEM_URL, id), { body });
export const getUserList = (request) =>
  api.getStream(USER_LIST_URL, { params: request.getDefinedValues() });
export const getUserList2 = (params) => api.get(USER_LIST_URL, { params });

const operatorPredictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: (request) =>
    api.getStream(OPERATOR_CHOOSE_URL, { params: request.getDefinedValues() }),
});
export const getOperatorPredictions = (request: DataListFilter) =>
  operatorPredictionsCache.get(request);
export const chooseOperator = ({ search }) =>
  api.get(OPERATOR_CHOOSE_URL, {
    params: {
      page: 0,
      size: 20,
      search,
      status: "active",
    },
  });
export const getOpertatorById = (id) => api.get(sprintf(USER_ITEM_URL, id));
export const operatorChoose = (params) =>
  api.get(OPERATOR_CHOOSE_URL, { params });
