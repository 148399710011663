import React, { useEffect, useState } from "react";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
  JUSTIFY_START,
} from "../ui-core/FlexBox";
import FormTextField from "../form/FormTextField";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Close,
  DeleteSweep,
  SystemUpdateAlt,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import { OrderedSet } from "immutable";
import { FieldArray } from "redux-form";
import _ from "lodash";
import {
  createINNValidator,
  createMXIKCodeValidator,
  createNotFalsyValidator,
  createNotNullValidator,
} from "../../helpers/FormUtils";
import FormSelectField from "../form/FormSelectField";
import CustomButton, { OUTLINED, SECONDARY } from "../ui-core/CustomButton";
import { TransitionUp } from "../dialog/TransitionUp";

const vatPercentage = OrderedSet.of(0, 12);

export const avvalSearchUrl = "http://avval.uz/App/avval_search.php";

export const contentItem = {
  name: "",
  spic: "",
  price: 0,
  barcode: "",
  qty: 1,
  vat_percentage: 0,
  vat_total: 0,
  total: 0,
  commission_tin: "",
  service_id: "",
  vat_active: false,
  package_code: "",
};

const useStyles = makeStyles({
  error: {
    "&>div>div>fieldset": {
      borderColor: "#f44336",
    },
  },
});

const CommissionTINItem = ({
  contentItems,
  fields,
  getLocalisationMessage,
  // eslint-disable-next-line no-shadow
  showErrorMessage,
  change,
  errorShow,
  setErrorShow,
  setValid,
}) => {
  const tempsearchHS = [];
  const tempCommissionError = [];
  const tempMixkError = [];

  contentItems.forEach((item, index) => {
    const commissionTin = _.get(item, "commission_tin");
    const itemQty = Number(_.get(item, "qty"));
    const mxikCode = _.get(item, "spic");
    const itemPrice = Number(_.get(item, "price"));
    const itemVatPercentage = Number(_.get(item, "vat_percentage"));

    if (commissionTin) {
      tempCommissionError[index] = null;
    }

    if (mxikCode) {
      tempMixkError[index] = null;
    }

    tempsearchHS[index] = {
      loading: false,
      value: {
        commissionTin,
        itemQty,
        itemPrice,
        mxikCode,
        itemVatPercentage,
      },
    };
  });

  const [searchHS, setSearchHS] = useState([...tempsearchHS]);
  const [commissionTinError, setCommissionTinError] = useState([
    ...tempCommissionError,
  ]);
  const [mixkError, setMixkError] = useState([...tempMixkError]);
  const [commissionChange, setCommissionChange] = useState(false);
  const [mixkChange, setMixkChange] = useState(false);
  const [priceChange, setPriceChange] = useState(false);
  const [vatChange, setVatChange] = useState(false);
  const [qtyChange, setQtyChange] = useState(false);
  const [vatTotalField, setVatTotalField] = useState("");
  const [totalField, setTotalField] = useState("");
  const [vatPercentageField, setVatPercentageField] = useState("");
  const [serviceIdField, setServiceIdField] = useState("");
  const [packageCodeField, setPackageCodeField] = useState("");
  const [vatActiveField, setVatActiveField] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const findedIndex = searchHS.findIndex(x => x.loading);

      if (findedIndex > -1) {
        const tempSearchHS = [...searchHS];
        const findedElement = searchHS[findedIndex];

        if (commissionChange) {
          if (
            findedElement.value.commissionTin &&
            (findedElement.value.commissionTin.length === 14 ||
              findedElement.value.commissionTin.length === 9)
          ) {
            const options = {
              method: "POST",
              headers: {
                Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                search_inn_comm: {
                  comm_inn: findedElement.value.commissionTin,
                },
              }),
            };

            fetch(avvalSearchUrl, options)
              .then(response => response.json())
              .then(res => {
                if (res && res.mess === "success") {
                  const tempError = [...commissionTinError];
                  tempError[findedIndex] = null;

                  setCommissionTinError(tempError);
                  findedElement.loading = false;
                  findedElement.value.itemVatPercentage = res.data.nds_active
                    ? 12
                    : 0;
                  tempSearchHS[findedIndex] = findedElement;
                  setSearchHS(tempSearchHS);
                  setCommissionChange(false);
                  change(vatPercentageField, res.data.nds_active ? 12 : 0);
                  change(serviceIdField, res.data.service_id);
                  change(vatActiveField, res.data.nds_active);
                } else {
                  const tempError = [...commissionTinError];
                  tempError[findedIndex] = getLocalisationMessage(
                    "inn_is_not_found",
                    "INN is not found",
                  );

                  setCommissionTinError(tempError);
                  findedElement.loading = false;
                  tempSearchHS[findedIndex] = findedElement;
                  setSearchHS(tempSearchHS);
                  setCommissionChange(false);
                }
              })
              .catch(error => {
                showErrorMessage(error);
              });
          } else {
            const tempError = [...commissionTinError];
            tempError[findedIndex] = getLocalisationMessage(
              "inn_is_not_found",
              "INN is not found",
            );

            setCommissionTinError(tempError);
            findedElement.loading = false;
            tempSearchHS[findedIndex] = findedElement;
            setSearchHS(tempSearchHS);
            setCommissionChange(false);
          }
        }

        if (mixkChange) {
          if (
            findedElement.value.mxikCode &&
            findedElement.value.mxikCode.length === 17
          ) {
            const optionsMixkCode = {
              method: "POST",
              headers: {
                Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                search_inn_spic: {
                  spic: findedElement.value.mxikCode,
                },
              }),
            };
            fetch(avvalSearchUrl, optionsMixkCode)
              .then(response => response.json())
              .then(res => {
                if (res && res.mess === "success") {
                  const tempError = [...mixkError];
                  tempError[findedIndex] = null;

                  setMixkError(tempError);
                  findedElement.loading = false;
                  tempSearchHS[findedIndex] = findedElement;
                  setSearchHS(tempSearchHS);
                  setMixkChange(false);
                  change(
                    packageCodeField,
                    res.data.packageNames && res.data.packageNames.length > 0
                      ? res.data.packageNames[0].code
                      : "",
                  );
                } else {
                  const tempError = [...mixkError];
                  tempError[findedIndex] = getLocalisationMessage(
                    "mxik_code_is_not_found",
                    " MXIK code is not found",
                  );

                  setMixkError(tempError);
                  findedElement.loading = false;
                  tempSearchHS[findedIndex] = findedElement;
                  setSearchHS(tempSearchHS);
                  setMixkChange(false);
                }
              })
              .catch(error => {
                showErrorMessage(error);
              });
          } else {
            const tempError = [...mixkError];
            tempError[findedIndex] = getLocalisationMessage(
              "mxik_code_is_not_found",
              " MXIK code is not found",
            );

            setMixkError(tempError);
            findedElement.loading = false;
            tempSearchHS[findedIndex] = findedElement;
            setSearchHS(tempSearchHS);
            setMixkChange(false);
          }
        }

        if (
          !commissionChange &&
          !mixkChange &&
          findedElement.value.itemPrice > 0
        ) {
          if (findedElement.value.itemVatPercentage > 0) {
            change(
              vatTotalField,
              (
                parseFloat(
                  (findedElement.value.itemPrice *
                    findedElement.value.itemQty) /
                    1.12,
                ) * 0.12
              ).toFixed(2),
            );
          } else {
            change(vatTotalField, 0);
          }

          change(
            totalField,
            findedElement.value.itemPrice * findedElement.value.itemQty,
          );
          findedElement.loading = false;
          tempSearchHS[findedIndex] = findedElement;
          setSearchHS(tempSearchHS);
        }
      }
    }, 10);

    return () => clearTimeout(delayDebounceFn);
  }, [
    searchHS,
    commissionChange,
    mixkChange,
    priceChange,
    vatChange,
    qtyChange,
  ]);

  useEffect(() => {
    setValid(
      !commissionTinError.includes(
        getLocalisationMessage("inn_is_not_found", "INN is not found"),
      ) &&
        !mixkError.includes(
          getLocalisationMessage(
            "mxik_code_is_not_found",
            " MXIK code is not found",
          ),
        ),
    );
  }, [mixkError, commissionTinError]);

  const classes = useStyles();
  return (
    <FlexBox flex={true} direction="column">
      <FlexBox flex={true} direction="column" style={{ gap: 13 }}>
        {fields.map((item, index) => (
          <FlexBox key={index} style={{ marginBottom: ".25rem", gap: 13 }}>
            <FlexBox direction="column" style={{ minWidth: 270 }}>
              {index === 0 && (
                <h6>{`${getLocalisationMessage(
                  "enter_company_inn",
                  "Enter Company INN",
                )} *`}</h6>
              )}
              <FlexBox
                flex={true}
                direction="column"
                className={
                  searchHS &&
                  searchHS[index] &&
                  searchHS[index].value &&
                  searchHS[index].value.commissionTin &&
                  commissionTinError &&
                  commissionTinError[index]
                    ? classes.error
                    : ""
                }
              >
                <FormTextField
                  immediatelyShowError={errorShow}
                  name={`${item}.commission_tin`}
                  validate={createINNValidator(
                    getLocalisationMessage("this_field_is_required"),
                  )}
                  fullWidth={true}
                  onChange={(e, value) => {
                    const tempSearchHS = [...searchHS];
                    const values =
                      tempSearchHS[index] && tempSearchHS[index].value
                        ? tempSearchHS[index].value
                        : {};
                    tempSearchHS[index] = {
                      loading: true,
                      value: {
                        ...values,
                        commissionTin: value,
                      },
                    };
                    setSearchHS(tempSearchHS);
                    setCommissionChange(true);
                    setVatPercentageField(`${item}.vat_percentage`);
                    setServiceIdField(`${item}.service_id`);
                    setVatActiveField(`${item}.vat_active`);

                    change(`${item}.service_id`, "");
                  }}
                  onInput={e => {
                    // eslint-disable-next-line radix
                    e.target.value = e.target.value.toString().slice(0, 14);
                  }}
                />

                {searchHS &&
                searchHS[index] &&
                searchHS[index].value &&
                searchHS[index].value.commissionTin &&
                commissionTinError &&
                commissionTinError[index] ? (
                  <p
                    style={{
                      marginLeft: 14,
                      marginRight: 14,
                      color: "#f44336",
                      marginTop: 4,
                      fontSize: "0.75rem",
                      textAlign: "left",
                      fontWeight: "400",
                      lineHeight: "1.66",
                      letterSpacing: "0.03333em",
                    }}
                  >
                    {commissionTinError[index]}
                  </p>
                ) : null}
              </FlexBox>
            </FlexBox>
            <FlexBox direction="column">
              {index === 0 && (
                <h6>{`${getLocalisationMessage(
                  "service_id",
                  "Service ID",
                )} *`}</h6>
              )}
              <FormTextField
                immediatelyShowError={errorShow}
                fullWidth={true}
                disabled={true}
                name={`${item}.service_id`}
                validate={createNotFalsyValidator(
                  getLocalisationMessage("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox direction="column">
              {index === 0 && (
                <h6>{`${getLocalisationMessage("product", "Product")} *`}</h6>
              )}
              <FormTextField
                immediatelyShowError={errorShow}
                fullWidth={true}
                name={`${item}.name`}
                validate={createNotFalsyValidator(
                  getLocalisationMessage("this_field_is_required"),
                )}
                disabled={
                  !(
                    searchHS[index] &&
                    searchHS[index].value &&
                    searchHS[index].value.commissionTin
                  )
                }
                onInput={e => {
                  // eslint-disable-next-line radix
                  e.target.value = e.target.value.toString().slice(0, 255);
                }}
              />
            </FlexBox>
            <FlexBox direction="column">
              <FlexBox direction="column" style={{ minWidth: 200 }}>
                {index === 0 && (
                  <h6>
                    {`${getLocalisationMessage("mxik_code", "MXIK Code")} *`}
                  </h6>
                )}
                <FlexBox
                  flex={true}
                  direction="column"
                  className={
                    searchHS &&
                    searchHS[index] &&
                    searchHS[index].value &&
                    searchHS[index].value.mxikCode &&
                    mixkError &&
                    mixkError[index]
                      ? classes.error
                      : ""
                  }
                >
                  <FormTextField
                    immediatelyShowError={errorShow}
                    fullWidth={true}
                    name={`${item}.spic`}
                    onChange={(e, value) => {
                      const tempSearchHS = [...searchHS];
                      const values =
                        tempSearchHS[index] && tempSearchHS[index].value
                          ? tempSearchHS[index].value
                          : {};
                      tempSearchHS[index] = {
                        loading: true,
                        value: {
                          ...values,
                          mxikCode: value,
                        },
                      };
                      setSearchHS(tempSearchHS);
                      setMixkChange(true);
                      setPackageCodeField(`${item}.package_code`);

                      change(`${item}.package_code`, "");
                    }}
                    validate={createMXIKCodeValidator(
                      getLocalisationMessage("this_field_is_required"),
                    )}
                    disabled={
                      !(
                        searchHS[index] &&
                        searchHS[index].value &&
                        searchHS[index].value.commissionTin
                      )
                    }
                    onInput={e => {
                      // eslint-disable-next-line radix
                      e.target.value = e.target.value.toString().slice(0, 17);
                    }}
                  />

                  {searchHS &&
                  searchHS[index] &&
                  searchHS[index].value &&
                  searchHS[index].value.mxikCode &&
                  mixkError &&
                  mixkError[index] ? (
                    <p
                      style={{
                        marginLeft: 14,
                        marginRight: 14,
                        color: "#f44336",
                        marginTop: 4,
                        fontSize: "0.75rem",
                        textAlign: "left",
                        fontWeight: "400",
                        lineHeight: "1.66",
                        letterSpacing: "0.03333em",
                      }}
                    >
                      {mixkError[index]}
                    </p>
                  ) : null}
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox direction="column">
              {index === 0 && (
                <h6>{`${getLocalisationMessage(
                  "package_code",
                  "Package Code",
                )} *`}</h6>
              )}
              <FormTextField
                immediatelyShowError={errorShow}
                fullWidth={true}
                disabled={true}
                name={`${item}.package_code`}
                validate={createNotFalsyValidator(
                  getLocalisationMessage("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox direction="column">
              {index === 0 && (
                <h6>{`${getLocalisationMessage("Quantity", "Quantity")} *`}</h6>
              )}
              <FormTextField
                fullWidth={true}
                name={`${item}.qty`}
                type="number"
                onFocus={event => {
                  event.target.select();
                }}
                validate={createNotNullValidator(
                  getLocalisationMessage("this_field_is_required"),
                )}
                immediatelyShowError={errorShow}
                disabled={
                  !(
                    searchHS[index] &&
                    searchHS[index].value &&
                    searchHS[index].value.commissionTin
                  )
                }
                parse={value => {
                  if (value <= 0) {
                    return 0;
                  }

                  return String(Math.round(parseFloat(String(value))));
                }}
                onChange={(e, value) => {
                  const tempSearchHS = [...searchHS];
                  const values =
                    tempSearchHS[index] && tempSearchHS[index].value
                      ? tempSearchHS[index].value
                      : {};
                  tempSearchHS[index] = {
                    loading: true,
                    value: {
                      ...values,
                      itemQty: Number(value),
                    },
                  };
                  setSearchHS(tempSearchHS);
                  setQtyChange(true);
                  setTotalField(`${item}.total`);
                  setVatTotalField(`${item}.vat_total`);
                  setMixkChange(false);
                  setCommissionChange(false);
                }}
              />
            </FlexBox>
            <FlexBox direction="column">
              {index === 0 && (
                <h6>{`${getLocalisationMessage("price", "Price")} *`}</h6>
              )}
              <FormTextField
                immediatelyShowError={errorShow}
                fullWidth={true}
                name={`${item}.price`}
                type="number"
                onFocus={event => {
                  event.target.select();
                }}
                disabled={
                  !(
                    searchHS[index] &&
                    searchHS[index].value &&
                    searchHS[index].value.commissionTin
                  )
                }
                parse={value => {
                  if (value <= 0) {
                    return 0;
                  }

                  return String(Math.round(parseFloat(String(value))));
                }}
                validate={createNotNullValidator(
                  getLocalisationMessage("this_field_is_required"),
                )}
                onChange={(e, value) => {
                  const tempSearchHS = [...searchHS];
                  const values =
                    tempSearchHS[index] && tempSearchHS[index].value
                      ? tempSearchHS[index].value
                      : {};
                  tempSearchHS[index] = {
                    loading: true,
                    value: {
                      ...values,
                      itemPrice: Number(value),
                    },
                  };
                  setSearchHS(tempSearchHS);
                  setPriceChange(true);
                  setTotalField(`${item}.total`);
                  setVatTotalField(`${item}.vat_total`);
                  setMixkChange(false);
                  setCommissionChange(false);
                }}
              />
            </FlexBox>
            <FlexBox direction="column">
              {index === 0 && (
                <h6>{getLocalisationMessage("total", "Total")}</h6>
              )}
              <FormTextField
                fullWidth={true}
                name={`${item}.total`}
                type="number"
                readOnly={true}
                onFocus={event => {
                  event.target.select();
                }}
                disabled={true}
              />
            </FlexBox>
            <FlexBox style={{ minWidth: 150 }} direction="column" flex={true}>
              {index === 0 && (
                <h6>{`${getLocalisationMessage(
                  "vat_percentage",
                  "VAT Percentage",
                )} *`}</h6>
              )}
              <FormSelectField
                name={`${item}.vat_percentage`}
                fullWidth={true}
                options={vatPercentage}
                formatOption={option => option}
                onChange={(e, value) => {
                  const tempSearchHS = [...searchHS];
                  const values =
                    tempSearchHS[index] && tempSearchHS[index].value
                      ? tempSearchHS[index].value
                      : {};
                  tempSearchHS[index] = {
                    loading: true,
                    value: {
                      ...values,
                      itemVatPercentage: Number(value),
                    },
                  };
                  setSearchHS(tempSearchHS);
                  setVatChange(true);
                  setTotalField(`${item}.total`);
                  setVatTotalField(`${item}.vat_total`);
                  setMixkChange(false);
                  setCommissionChange(false);
                }}
                disabled={true}
              />
            </FlexBox>
            <FlexBox direction="column">
              {index === 0 && (
                <h6>{getLocalisationMessage("vat_total", "VAT total")}</h6>
              )}
              <FormTextField
                readOnly={true}
                fullWidth={true}
                name={`${item}.vat_total`}
                type="number"
                onFocus={event => {
                  event.target.select();
                }}
                disabled={true}
              />
            </FlexBox>

            <FlexBox flex={true} direction="column" align="center">
              {index === 0 && <FlexBox style={{ height: 30 }} />}
              <FlexBox direction="column" align="center">
                <IconButton size="small" onClick={() => fields.remove(index)}>
                  <Close color="error" />
                </IconButton>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>

      <FlexBox
        style={{ marginTop: ".5rem" }}
        justify={JUSTIFY_START}
        align={ALIGN_CENTER}
      >
        <CustomButton
          variant={OUTLINED}
          onClick={() => {
            fields.push(contentItem);
            const tempSearchHS = [...searchHS];
            tempSearchHS[fields.length] = {
              loading: false,
              value: {
                itemQty: 1,
                itemPrice: 0,
                itemVatPercentage: 0,
              },
            };
            setSearchHS(tempSearchHS);
            setErrorShow(false);
          }}
          startIcon={<AddCircleOutline />}
        >
          {getLocalisationMessage("add_more", "Add More")}
        </CustomButton>
      </FlexBox>
    </FlexBox>
  );
};

CommissionTINItem.propTypes = {
  fields: PropTypes.object,
  contentItems: PropTypes.array,
  change: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  setErrorShow: PropTypes.func,
  setValid: PropTypes.func,
  errorShow: PropTypes.bool,
};

const CommissionTINDialog = props => {
  const {
    classes,
    isOpen,
    getLocalisationMessage,
    change,
    setIsOpen,
    isValidatedContentItems,
  } = props;

  const [errorShow, setErrorShow] = useState(false);
  const [valid, setValid] = useState(false);
  return (
    <Dialog
      TransitionComponent={TransitionUp}
      open={isOpen}
      fullWidth={true}
      className={classes.content}
      maxWidth="xl"
    >
      <DialogTitle className={classes.titleWithBorder2}>
        <FlexBox flex={true}>
          <span>{getLocalisationMessage("cod", "COD")}</span>
        </FlexBox>
      </DialogTitle>
      <DialogContent>
        <FlexBox direction="column" flex={true}>
          <FieldArray
            name="contentItems"
            component={CommissionTINItem}
            getLocalisationMessage={props.getLocalisationMessage}
            classes={props.classes}
            change={props.change}
            showErrorMessage={props.showErrorMessage}
            contentItems={props.contentItems}
            errorShow={errorShow}
            setErrorShow={setErrorShow}
            setValid={setValid}
          />
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <FlexBox
          flex={true}
          justify={JUSTIFY_CENTER}
          style={{ paddingBottom: "1rem" }}
        >
          <FlexBox className={classes.btnWrapper}>
            <CustomButton
              className={classes.btn}
              onClick={() => {
                change("contentItems", []);
                setIsOpen(false);
                setErrorShow(false);
              }}
              startIcon={<DeleteSweep />}
              variant={OUTLINED}
              color={SECONDARY}
            >
              {getLocalisationMessage("clear_and_close")}
            </CustomButton>
            <CustomButton
              className={classes.btn}
              endIcon={<SystemUpdateAlt />}
              variant={OUTLINED}
              color={SECONDARY}
              onClick={() => {
                if (valid && isValidatedContentItems) {
                  setIsOpen(false);
                } else {
                  setErrorShow(true);
                }
              }}
            >
              {getLocalisationMessage("save")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </DialogActions>
    </Dialog>
  );
};

CommissionTINDialog.propTypes = {
  classes: PropTypes.object,
  isOpen: PropTypes.object,
  getLocalisationMessage: PropTypes.object,
  change: PropTypes.object,
  contentItems: PropTypes.object,
  setIsOpen: PropTypes.func,
  showErrorMessage: PropTypes.func,
  isValidatedContentItems: PropTypes.bool,
};

export default CommissionTINDialog;
