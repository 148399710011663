import React from "react";
import fp from "lodash/fp";
import { componentFromStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { InputAdornment, TextField } from "@material-ui/core";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";
import { Done, Error } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
// colors
export const PRIMARY = "primary";
export const SECONDARY = "secondary";
// variants
export const FILLED = "filled";
export const OUTLINED = "outlined";
export const STANDARD = "standard";

const CssTextField = withStyles({
  root: {
    "& .Mui-disabled>fieldset": {
      borderStyle: "dashed",
    },
    "& .MuiFormLabel-root": {
      // fontSize:".9rem"
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "black",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      // transform: "translate(14px, -6px) scale(0.85)",
    },
    "&>div>fieldset> legend": {
      // fontSize: ".85em",
      color: "black",
    },
  },
})(TextField);

export const TextFieldComponent = componentFromStream(propsStream => {
  const { handler: refInput, stream: refStream } = createEventHandler();
  const sideEffectsStream = mergeSideEffectStreams(
    propsStream
      .filter(props => props.focus)
      .combineLatest(
        refStream.map(fp.get("input")).filter(Boolean),
        (props, input) => input,
      )
      .distinctUntilChanged()
      .delay(100)
      .do(input => {
        const length = fp.size(input.value);

        input.focus();
        input.setSelectionRange(length, length);
      }),
  );

  return propsStream

    .merge(sideEffectsStream)
    .combineLatest(
      ({
        input,
        meta,
        parseOnBlur,
        focus,
        ignoreRtl,
        inputStyle,
        variant,
        size,
        multiLine,
        immediatelyShowError,
        ignoreError,
        onKeyDown,
        ...custom
      }) => {
        const hasError = Boolean(!meta.active && meta.touched && meta.error);
        if (custom.onShowError) {
          custom.onShowError(hasError);
        }
        const handleKeyDown = event => {
          if (event.key === "ArrowUp" || event.key === "ArrowDown") {
            event.preventDefault(); // Prevent the default behavior
          }
        };

        return (
          /* eslint-disable */
          <CssTextField
            {...input}
            {...custom}
            onKeyDown={e => {
              handleKeyDown(e);
              if (onKeyDown) {
                onKeyDown(e);
              }
            }}
            autocomplete="new-password"
            size={size || "small"}
            ref={refInput}
            multiline={multiLine}
            variant={variant}
            error={
              ignoreError
                ? immediatelyShowError
                  ? meta.error
                  : Boolean(!meta.active && meta.touched && meta.error)
                : ""
            }
            helperText={
              ignoreError
                ? immediatelyShowError
                  ? meta.error
                  : !meta.active && meta.touched && meta.error
                : ""
            }
            inputProps={
              custom.withOutInputProps
                ? {}
                : {
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                  }
            }
            autoComplete="chrome-off"
            InputLabelProps={custom.InputLabelProps}
            InputProps={{
              endAdornment: ignoreError ? (
                <InputAdornment position="end">
                  {!meta.active && meta.touched && meta.error ? (
                    <Error color="error" />
                  ) : !meta.active && meta.touched && custom.withSuccessIcon ? (
                    <Done style={{ color: "#27AE60" }} />
                  ) : (
                    custom.InputProps && custom.InputProps.endAdornment
                  )}
                </InputAdornment>
              ) : null,
              startAdornment:
                custom.InputProps && custom.InputProps.startAdornment,
              onWheel: e => e.target.blur(),
              onBlur: fp.flow(
                fp.get("target.value"),
                parseOnBlur,
                input.onBlur,
              ),
            }}
          />
          /* eslint-enable */
        );
      },
    );
});

TextFieldComponent.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  parseOnBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
};

TextFieldComponent.defaultProps = {
  parseOnBlur: fp.identity,
  variant: "outlined",
};

FormTextField.propTypes = {
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  parseOnBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  focus: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,

  fullWidth: PropTypes.bool,
  multiLine: PropTypes.bool,
  ignoreRtl: PropTypes.bool,
  floatingLabelStyle: PropTypes.object,
  hintStyle: PropTypes.object,

  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  size: PropTypes.oneOf(["medium", "small"]),
  label: PropTypes.node,
  helperText: PropTypes.node,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  immediatelyShowError: PropTypes.bool,
  ignoreError: PropTypes.bool,
  onShowError: PropTypes.func,
  withOutInputProps: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

FormTextField.defaultProps = {
  variant: "outlined",
  size: "small",
  ignoreError: true,
  withOutInputProps: true,
};

export default function FormTextField(props) {
  return <Field {...props} component={TextFieldComponent} />;
}
