import { OrderedSet } from "immutable";

export const UNASSIGNED = "unassigned";
export const ASSIGNED = "assigned";
export const ASSIGNED_TO_COURIER = "assigned_to_courier";
export const ON_THE_WAY = "on_the_way";
export const ARRIVED = "arrived";
export const COMPLETED = "completed";
export const PARTIALLY_COMPLETED = "partially_completed";
export const FAILED = "failed";
export const CLOSED = "closed";

export default OrderedSet.of(
  UNASSIGNED,
  ASSIGNED,
  ASSIGNED_TO_COURIER,
  ON_THE_WAY,
  ARRIVED,
  COMPLETED,
  PARTIALLY_COMPLETED,
  FAILED,
  CLOSED,
);
