import React, { useEffect } from "react";
import PropTypes from "prop-types";
import JurisdictionTreeViewItem from "./JurisdictionTreeViewItem";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import { compose } from "recompose";
import JurisdictionTreeModel from "./JurisdictionTreeModel";
import { isElement } from "../../helpers/DataUtils";

const useStyles = makeStyles({
  loading: {
    display: "flex",
    justifyContent: "center",
    padding: 10,
  },
});

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

const treeModel = new JurisdictionTreeModel();

/* eslint-enable */
const JurisdictionSelectTreeView = (props) => {
  const [model, setModel] = React.useState();

  React.useEffect(() => {
    treeModel.setOnModelChange((newModel) => {
      setModel(newModel);
    });
    treeModel.setOnSelect((selected) => {
      const selectedItem = selected.length
        ? selected[selected.length - 1]
        : null;
      const hasChildren = selectedItem
        ? treeModel.hasChildren(selectedItem)
        : false;
      if (selected) props.onSelect(selected, hasChildren);
      if (selected && hasChildren && !treeModel.isExpanded(selectedItem)) {
        const children = treeModel.getChildren(selectedItem.id);
        if (!children) {
          treeModel.fetchJurisdictionsList(selectedItem.id, () => {
            treeModel.toggleExpansion(selectedItem);
          });
        } else {
          treeModel.toggleExpansion(selectedItem);
        }
      }
    });
    treeModel.fetchJurisdictionsList();
    return () => {
      treeModel.clearModel();
    };
  }, []);

  const classes = useStyles();

  const renderItems = (list = []) =>
    list.map((child, index) => (
      <JurisdictionTreeViewItem
        key={index}
        withoutInput={props.withoutInput}
        countryCode={props.countryCode && props.countryCode.toUpperCase()}
        currentCode={child.code && child.code.toUpperCase()}
        selectedChildren={
          treeModel.hasSelected(child) ||
          (treeModel.hasChildren(child) && treeModel.hasInSelection(child)) ||
          treeModel.isInRestChildren(child)
        }
        selectedThrough={
          treeModel.isThrough(child) || treeModel.isInRestChildren(child)
        }
        selectedNode={
          treeModel.hasSelected(child) ||
          (treeModel.hasChildren(child) && treeModel.hasInSelection(child)) ||
          treeModel.isInRestChildren(child)
        }
        isLast={Boolean(index === list.length - 1)}
        hasChildren={treeModel.hasChildren(child)}
        showExpand={treeModel.hasChildren(child)}
        title={child.name}
        description={props.getLocalisationMessage(child.level_name)}
        node={child.level_id}
        onExpand={() => {
          const children = treeModel.getChildren(child.id);
          if (!children) {
            treeModel.fetchJurisdictionsList(child.id, () => {
              treeModel.toggleExpansion(child);
            });
          } else {
            treeModel.toggleExpansion(child);
          }
        }}
        expanded={treeModel.isExpanded(child)}
        content={<div>{renderItems(treeModel.getChildren(child.id))}</div>}
        isLoading={model.fetchingId === child.id}
        onClick={() => {
          if (treeModel.hasSelected(child)) {
            treeModel.unselect(child);
          } else {
            treeModel.select(child);
          }
        }}
        showEdit={false}
        showAddButton={false}
        selected={treeModel.hasSelected(child)}
      />
    ));

  useEffect(() => {
    if (props.withoutInput) {
      if (treeModel.getChildren() && treeModel.getChildren().length > 0) {
        const element = document.getElementById(props.countryCode);
        if (element) {
          const prevElement = element.previousSibling;
          if (isElement(prevElement))
            prevElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [treeModel.getChildren(), props.countryCode]);
  if (model && !model.fetchingId) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="secondary" variant="indeterminate" />
      </div>
    );
  }

  return (
    <div>
      {" "}
      {renderItems(
        (treeModel.getChildren() || []).filter((ch) =>
          props.searchText
            ? ch.name.toLowerCase().includes(props.searchText.toLowerCase())
            : true,
        ),
      )}{" "}
    </div>
  );
};

JurisdictionSelectTreeView.propTypes = {
  onSelect: PropTypes.func,
  countryCode: PropTypes.string,
  searchText: PropTypes.string,
  withoutInput: PropTypes.bool,
  getLocalisationMessage: PropTypes.bool,
};

export default enhancer(JurisdictionSelectTreeView);
