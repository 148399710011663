import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Navigate from "../router/Navigate";
import { DASHBOARD_URL } from "../../constants/DashboardPathConstants";
import Preloader from "../../../shared/components/preloader/Preloader";
import { isMobile } from "react-device-detect";
import { ORDER_BIN_VALIDATION_SIMPLE_VIEW_URL } from "../../constants/AdminPathConstants";

const enhancer = compose(withRouter);

NavigateFromSignIn.propTypes = {
  router: PropTypes.object,
  location: PropTypes.object,
};

function NavigateFromSignIn(props) {
  const { next } = props.location.query;

  const link = isMobile ? ORDER_BIN_VALIDATION_SIMPLE_VIEW_URL : DASHBOARD_URL;

  return (
    <Preloader show={true}>
      <Navigate
        push={false}
        to={!next || props.router.isActive(next) ? link : next}
      />
    </Preloader>
  );
}

export default enhancer(NavigateFromSignIn);
