import React from "react";
import fp from "lodash/fp";
import { compose, withContext, withHandlers } from "recompose";
import PropTypes from "prop-types";
import DataListFilter from "../../helpers/DataListFilter";
import {
  updateHash,
  updateQuery,
  combineCleanQuery,
} from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  withHandlers(initialProps => {
    const { router } = initialProps;
    const navigateTo = (to, replace) =>
      replace ? router.replace(to) : router.push(to);

    const updateLocationHash = hash =>
      updateHash(router.getCurrentLocation(), hash);
    const updateLocationQuery = query =>
      updateQuery(router.getCurrentLocation(), query);
    const updateLocationQueryFilter = (filter: DataListFilter) =>
      updateLocationQuery(query =>
        combineCleanQuery(query, filter.getValues()),
      );

    return {
      setLocation: () => (to, replace) => {
        if (
          !fp.isUndefined(replace) &&
          process.env.NODE_ENV === "development"
        ) {
          console.warn(
            "setLocation(to, replace) is deprecated, use replaceLocation(to)",
          );
        }

        return navigateTo(to, replace);
      },
      replaceLocation: () => to => navigateTo(to, true),
      setLocationHash: () => (hash, replace) => {
        if (
          !fp.isUndefined(replace) &&
          process.env.NODE_ENV === "development"
        ) {
          console.warn(
            "setLocationHash(hash, replace) is deprecated, use replaceLocationHash(hash)",
          );
        }

        navigateTo(updateLocationHash(hash), replace);
      },
      replaceLocationHash: () => hash =>
        navigateTo(updateLocationHash(hash), true),
      setLocationQuery: () => (query, replace) => {
        if (
          !fp.isUndefined(replace) &&
          process.env.NODE_ENV === "development"
        ) {
          console.warn(
            "setLocationQuery(query, replace) is deprecated, use replaceLocationQuery(query)",
          );
        }

        navigateTo(updateLocationQuery(query), replace);
      },
      replaceLocationQuery: () => query =>
        navigateTo(updateLocationQuery(query), true),
      setLocationQueryFilter: () => (filter, replace) => {
        if (
          !fp.isUndefined(replace) &&
          process.env.NODE_ENV === "development"
        ) {
          console.warn(
            "setLocationQueryFilter(query, replace) is deprecated, use replaceLocationQueryFilter(query)",
          );
        }

        navigateTo(updateLocationQueryFilter(filter), replace);
      },
      replaceLocationQueryFilter: () => filter =>
        navigateTo(updateLocationQueryFilter(filter), true),
    };
  }),
  withContext(
    {
      setLocation: PropTypes.func,
      setLocationHash: PropTypes.func,
      setLocationQuery: PropTypes.func,
      setLocationQueryFilter: PropTypes.func,
      replaceLocation: PropTypes.func,
      replaceLocationHash: PropTypes.func,
      replaceLocationQuery: PropTypes.func,
      replaceLocationQueryFilter: PropTypes.func,
    },
    props => ({
      setLocation: props.setLocation,
      setLocationHash: props.setLocationHash,
      setLocationQuery: props.setLocationQuery,
      setLocationQueryFilter: props.setLocationQueryFilter,
      replaceLocation: props.replaceLocation,
      replaceLocationHash: props.replaceLocationHash,
      replaceLocationQuery: props.replaceLocationQuery,
      replaceLocationQueryFilter: props.replaceLocationQueryFilter,
    }),
  ),
);

NavigateActionsProvider.propTypes = {
  children: PropTypes.node,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    getCurrentLocation: PropTypes.func.isRequired,
  }).isRequired,
};

function NavigateActionsProvider(props) {
  return React.Children.only(props.children);
}

export default enhancer(NavigateActionsProvider);
