import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import zIndex from "@material-ui/core/styles/zIndex";
import Transitions from "@material-ui/core/styles/transitions";
import withGlobalPortal from "../portals/withGlobalPortal";
import Box from "@material-ui/core/Box";
import UzpostLogo from "../icons/UzpostLogo";

const baseZIndex = zIndex.snackbar - 10;

const portalEnhancer = useSheet({
  overlay: {
    display: "none",
    "& > div": {
      position: "fixed",
      zIndex: baseZIndex + 20,
      top: 0,
      left: "50%",
      transform: "translate3d(-50%, -200%, 0) scale(0.5)",
      transition: Transitions.create("500ms", "transform"),
    },
  },
  overlayShown: {
    display: "block",
    zIndex: 2,
    // zIndex: baseZIndex + 10,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    "& .MuiPaper-root": {
      zIndex: 100,
    },
    "& .MuiSvgIcon-root": {
      width: "8em",
    },
    "& > div": {
      position: "fixed",
      zIndex: 3,
      top: "30%",
      left: "50%",
      transform: "translate3d(-50%, 10%, 0) scale(1)",
      transition: "all .3s ease",
      padding: "3rem",
    },
  },
});

import "./CircularProgress.css"; // Import CSS for the animation

export const CircularProgressWithGradient = ({
  size,
  strokeWidth,
  percentage,
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference - (percentage / 100) * circumference;

  return (
    <div className="circular-progress-container">
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        className="rotating-svg"
      >
        {/* Define the linear gradient */}
        <defs>
          <linearGradient id="gradient" x1="25%" y1="25%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#3f51b5" />
            <stop offset="100%" stopColor="#ff9800" />
          </linearGradient>
        </defs>

        {/* Background Circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke="#e6e6e6"
          strokeWidth={strokeWidth}
        />

        {/* Progress Circle with gradient */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke="url(#gradient)" // Apply the linear gradient here
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={progress}
          strokeLinecap="round"
          transform={`rotate(-90 ${size / 2} ${size / 2})`} // Rotate to start from the top
        />
      </svg>
    </div>
  );
};

CircularProgressWithGradient.propTypes = {
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  percentage: PropTypes.number,
};

const PageLoadingPortal = portalEnhancer(props => (
  <div
    className={props.show ? props.classes.overlayShown : props.classes.overlay}
  >
    <Box position="relative" display="inline-flex">
      <CircularProgressWithGradient
        size={200}
        strokeWidth={25}
        percentage={100}
      />
      <Box
        top={0}
        left={15}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
      >
        <UzpostLogo />
      </Box>
    </Box>
  </div>
));

const enhancer = compose(
  withGlobalPortal(
    fp.flow(fp.find(fp.isMatch({ isLoading: true })), show => (
      <PageLoadingPortal show={show} />
    )),
  ),
);

PageLoading.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
};

function PageLoading(props) {
  return React.Children.count(props.children)
    ? React.Children.only(props.children)
    : null;
}

export default enhancer(PageLoading);
