import sprintf from "sprintf";
import { api } from "./BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";
import { ObservableCache } from "../../helpers/loading-cache";
import fp from "lodash/fp";
import DataListFilter from "../../helpers/DataListFilter";

const PRODUCT_CATEGORY_TYPE_CREATE_URL = `${API_ROOT_URL}/admin/product_category_type`;
const PRODUCT_CATEGORY_TYPE_ITEM_URL = `${API_ROOT_URL}/admin/product_category_type/%s`;
const PRODUCT_CATEGORY_TYPE_LIST_URL = `${API_ROOT_URL}/admin/product_category_type/all`;
const PRODUCT_CATEGORY_TYPE_CHOOSE_URL = `${API_ROOT_URL}/admin/product_category_type/choose`;
const PRODUCT_CATEGORY_SERVICE_TYPES_URL = `${API_ROOT_URL}/admin/service_types/category`;

export const getProductCategoryTypeList = params =>
  api.get(PRODUCT_CATEGORY_TYPE_LIST_URL, {
    params,
  });

export const getProductCategoryTypeItem = id =>
  api.get(sprintf(PRODUCT_CATEGORY_TYPE_ITEM_URL, id));

export const createProductCategoryType = body =>
  api.post(PRODUCT_CATEGORY_TYPE_CREATE_URL, {
    body,
  });

export const getProductCategoryServiceType = () =>
  api.get(PRODUCT_CATEGORY_SERVICE_TYPES_URL);

export const updateProductCategoryServiceType = body =>
  api.put(PRODUCT_CATEGORY_SERVICE_TYPES_URL, {
    body,
  });

export const updateProductCategoryType = (id, body) =>
  api.put(sprintf(PRODUCT_CATEGORY_TYPE_ITEM_URL, id), { body });

export const deleteProductCategoryType = id =>
  api.delete(sprintf(PRODUCT_CATEGORY_TYPE_ITEM_URL, id));

const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(PRODUCT_CATEGORY_TYPE_CHOOSE_URL, {
      params: request.getDefinedValues(),
    }),
});

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(PRODUCT_CATEGORY_TYPE_ITEM_URL, id)),
});

export const getProductCategoryPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const getProductCategoryId = id => itemCache.refresh(id);
