import React, { useEffect, useState } from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { getFormValues } from "redux-form";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Place as MapsPlace } from "@material-ui/icons";
import FormMapPostPinDialog from "../deprecated/FormMapPostPinDialog";
import { getGeoCode } from "../../helpers/YandexMapsHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getMapProvider,
  isMapKeyAvailable,
} from "../../../shared/reducers/AppReducer";
import {
  showErrorMessage,
  showWarningMessage,
} from "../../reducers/NotificationsReducer";
import { formatLocalType } from "./FormYandexGeoAutoComplete";
import { getValue } from "../../helpers/DataUtils";

const getLatLng = fp.pick(["lat", "lng"]);
const selector = getFormValues("FormMapPostPinDialog");
const enhancer = compose(
  useSheet({
    iconButton: {
      marginTop: "-5px",
      height: 50,
    },
    manualFieldsBox: {
      background: "white",
      position: "absolute",
      left: 20,
      bottom: 10,
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 10,
      zIndex: 1000,
    },
  }),
  connect(
    state => ({
      values: selector(state, "location"),
      isMapKeyAvailable: isMapKeyAvailable(state),
      mapProvider: getMapProvider(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showWarningMessage,
      showErrorMessage,
    },
  ),
);

ManualSetPostLocationDialog.propTypes = {
  classes: PropTypes.object,

  fieldName: PropTypes.string.isRequired,

  location: PropTypes.object,

  language: PropTypes.string,
  latLon: PropTypes.object,

  type: PropTypes.string,

  change: PropTypes.func,
  locationEdit: PropTypes.func,
};

function ManualSetPostLocationDialog(props) {
  const { classes, location } = props;
  const [stateLocation, setStateLocation] = useState({});
  const [showPickupMapDialog, setShowPickupMapDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [zoom, setZoom] = useState(18);

  useEffect(() => {
    if (stateLocation && stateLocation.lat && stateLocation.lng) {
      setIsLoading(true);

      getGeoCode({
        lat: stateLocation.lat,
        lng: stateLocation.lng,
        lang: formatLocalType(props.language),
      })
        .then(r => {
          setZoom(18);

          props.change(props.fieldName, {
            lat: stateLocation.lat,
            lng: stateLocation.lng,
            address: getValue(
              r,
              "GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted",
              "",
            ),
          });
        })
        .catch(() => setIsLoading(false))
        .finally(() => setIsLoading(false));
    }
  }, [stateLocation]);

  return (
    <React.Fragment>
      <FormMapPostPinDialog
        language={props.language}
        latLon={props.latLon}
        type={props.type}
        open={showPickupMapDialog}
        initialValues={{ location }}
        mapCenter={getLatLng(location)}
        onRequestClose={() => setShowPickupMapDialog(false)}
        onSubmit={data => {
          setShowPickupMapDialog(false);
          props.change(props.fieldName, data.location);
          props.locationEdit(false);
        }}
        setStateLocation={setStateLocation}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        zoom={zoom}
        setZoom={setZoom}
      />

      <IconButton
        data-cy={`${props.fieldName}_showDialogMap`}
        className={classes.iconButton}
        onClick={() => setShowPickupMapDialog(true)}
      >
        <MapsPlace />
      </IconButton>
    </React.Fragment>
  );
}

export default enhancer(ManualSetPostLocationDialog);
