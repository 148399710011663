import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResponseError from "../../helpers/ResponseError";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { DISPATCHED } from "../../constants/OrderStatusCodes";
import fp from "lodash/fp";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import { INACTIVE } from "../../constants/OverallStatus";
import BatchOrdersAssignToDriverDialog from "../../components/orders-core/BatchOrdersAssignToDriverDialog";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { getPostcodePredictions } from "../../api/admin/AdminPostcodesApi";
import { Map } from "immutable";
import { getMessages } from "../../reducers/LocalizationReducer";
import { renderIf } from "../../helpers/HOCUtils";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

BatchAssignOrdersToDriverFormDialogWrapper.propTypes = {
  open: PropTypes.bool,
  disableBarcodes: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function BatchAssignOrdersToDriverFormDialogWrapper({
  initialValues,
  ...props
}) {
  return (
    <BatchOrdersAssignToDriverDialog
      open={props.open}
      initialValues={{
        ...initialValues,
        batch_type: INACTIVE,
        privacy: PUBLIC,
        orderStatus: DISPATCHED,
        supplierDriverOnly: true,
        supplier: { id: 1 },
      }}
      onRequestClose={props.onRequestClose}
      onSubmit={fp.flow(toSnakeCase, values =>
        batchAsyncUpdateOrder(values).catch(ResponseError.throw),
      )}
      onSubmitFail={response => {
        props.showErrorMessage(response);
      }}
      getCachedSupplier={getCachedSupplier}
      getSupplierPredictions={getSupplierPredictions}
      getCachedDriver={getCachedDriver}
      getDriverPredictions={getDriverPredictions}
      getCachedWarehouse={getCachedWarehouse}
      getWarehousePredictions={getWarehousePredictions}
      getPostcodePredictions={getPostcodePredictions}
      disableBarcodes={props.disableBarcodes}
      onSubmitSuccess={response => {
        props.showSuccessMessage(
          props.i18n.get("successfully_updated", "Successfully Updated"),
        );
        props.onSubmitSuccess(response);
      }}
    />
  );
}

export default enhancer(BatchAssignOrdersToDriverFormDialogWrapper);
