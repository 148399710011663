import { OrderedSet } from "immutable";

export const CITY = "city";
export const TO_NEIGHBORHOOD = "to_neighborhood";
export const TO_POSTCODE = "to_postcode";
export const JURISDICTION_TO = "to_jurisdiction";
export const TO_COUNTRY = "to_country";
export const SIZE = "size";
export const SUPPLIER = "supplier";
export const RULE_NAME = "rule_name";
export const WAREHOUSE = "warehouse";
export const COURIER_TYPE = "courier_type";
export const PROMISE_DATE = "promise_date";
export const ADDRESS_TYPE = "address_type";
export const WEEK_DAY = "week_day";
export const COUNT = "count";
export const TRANSPORTATION_TYPE = "transportation_type";
export const VALUED = "valued";
export const TO_REGION = "to_region";

export default OrderedSet.of(
  CITY,
  TO_NEIGHBORHOOD,
  TO_POSTCODE,
  JURISDICTION_TO,
  SIZE,
  SUPPLIER,
  RULE_NAME,
  WAREHOUSE,
  COURIER_TYPE,
  PROMISE_DATE,
  ADDRESS_TYPE,
  WEEK_DAY,
  COUNT,
  TRANSPORTATION_TYPE,
  TO_COUNTRY,
  VALUED,
  TO_REGION,
);
