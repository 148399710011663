import { api } from "./BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const PASSWORD_URL = `${API_ROOT_URL}/user/password`;
const SIGN_OUT_URL = `${API_ROOT_URL}/logout`;

export type PasswordRequest = {
  current_password: string,
  password: string,
  marketplace_id: number,
  marketplace_ids: number[],
};

export const updatePassword = (body: PasswordRequest) =>
  api.put(PASSWORD_URL, { body });

export const signOut = () => api.getStream(SIGN_OUT_URL);
