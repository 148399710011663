import loadScript from "load-script";

const SCRIPT_LOAD_CALLBACK = "fbAsyncInit";

const API_KEY =
  process.env.WING_ENV === "live" ? "865041106883799" : "1026154434105798";

let script;

export function load() {
  if (!script) {
    script = new Promise(resolve => {
      const url = "//connect.facebook.net/en_US/sdk.js";

      window[SCRIPT_LOAD_CALLBACK] = () => {
        const { FB } = window;

        delete window[SCRIPT_LOAD_CALLBACK];

        FB.init({ cookie: true, version: "v2.6", appId: API_KEY });

        resolve(FB);
      };

      loadScript(url);
    });
  }

  return script;
}

export function me() {
  return load().then(
    FB =>
      new Promise((resolve, reject) => {
        FB.api(
          "/me",
          { fields: ["email", "first_name", "last_name"] },
          response => {
            if (response) {
              resolve(response);
            } else {
              reject(new Error());
            }
          },
        );
      }),
  );
}

export function login() {
  return load().then(
    FB =>
      new Promise((resolve, reject) =>
        FB.login(
          ({ status, authResponse }) => {
            if (authResponse) {
              resolve(authResponse);
            } else {
              reject(new Error(status));
            }
          },
          {
            scope: ["email", "public_profile"].join(","),
            return_scopes: true,
            auth_type: "rerequest",
          },
        ),
      ),
  );
}
