import React from "react";
import LinkWithNext from "../router/LinkWithNext";
import { RECOVER_PASSWORD_URL } from "../../constants/DashboardPathConstants";

RecoverPasswordLink.defaultProps = { children: "Recover Password" };

function RecoverPasswordLink(props) {
  return <LinkWithNext {...props} to={RECOVER_PASSWORD_URL} />;
}

export default RecoverPasswordLink;
