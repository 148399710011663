import React, { useEffect, useState } from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { CardContent, CircularProgress, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import SignInLink from "./SignInLink";
import FormTextField from "../form/FormTextField";
import CenteredCard from "../deprecated/CenteredCard";
import { getMessages } from "../../reducers/LocalizationReducer";
import { accent1 } from "../../../shared/theme/main-theme";
import PageLoading from "../ui-core/PageLoading";
import CustomButton, { OUTLINED, SECONDARY } from "../ui-core/CustomButton";
import { ArrowRight, Refresh } from "@material-ui/icons";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { sendCode } from "../../actions/CustomerAuthActions";
import FlexBox, { ALIGN_CENTER, JUSTIFY_CENTER } from "../ui-core/FlexBox";
import { resetPasswordRequest } from "../../actions/AuthActions";
import { redirectUrl } from "../../containers/shared/RecoverPasswordContainer";

// eslint-disable-next-line no-useless-escape
const mailPhoneRegEx = new RegExp(
  "^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})|^([+])+([0-9]{12})+$",
);
const enhancer = compose(
  connect(
    (state) => ({
      i18n: getMessages(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
      resetPasswordRequest,
    },
  ),
  useSheet({
    title: { marginTop: "16px" },
    resetPasswordRequest: {
      marginTop: "1rem",
      width: "100%",
      backgroundColor: accent1,
    },
    signIn: {
      display: "block",
      textAlign: "center",
      marginBottom: "16px",
      textTransform: "uppercase",
    },
  }),
  reduxForm({
    form: "RecoverPasswordForm",
    validate: (values, props) => ({
      email:
        !mailPhoneRegEx.test(values.email) && props.i18n.get("something_wrong"),
    }),
  }),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

RecoverPasswordForm.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  step: PropTypes.number,
  i18n: PropTypes.instanceOf(Map),
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  resetPasswordRequest: PropTypes.func,
  setLocation: PropTypes.func,
  isMail: PropTypes.bool,
};

function RecoverPasswordForm({
  classes: { resetPasswordRequest: resetPasswordRequest2, signIn, title },
  handleSubmit,
  i18n,
  isLoading,
  isMail,
  resetPasswordRequest: resetPasswordRequest1,
  setLocation,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  step,
  submitting,
}) {
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [timer, setTimer] = useState({
    minutes: 1,
    seconds: 60,
  });
  const [zero, setZero] = useState(false);

  useEffect(
    () => timer.minutes === 0 && timer.seconds === 0 && setZero(true),
    [timer.seconds],
  );
  useEffect(() => {
    if (step === 2) {
      if (timer.seconds > 0)
        setTimeout(
          () => setTimer({ ...timer, seconds: timer.seconds - 1 }),
          1000,
        );
      else if (timer.seconds === 0 && timer.minutes === 1) {
        setTimer({
          minutes: 0,
          seconds: 60,
        });
      }
    }
  });

  const handleChange = (event) => {
    setCode(event.target.value);
  };

  return (
    <CenteredCard
      style={{ minWidth: "500px" }}
      withLogo={true}
      isLoading={submitting}
    >
      <PageLoading isLoading={isLoading} />
      <Helmet>
        <title>{i18n.get("recover_password", "Recover Password")}</title>
      </Helmet>
      {step === 1 ? (
        <CardContent>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <h6 className={title}>
              {i18n.get("forgot_your_password", "Forgot your password?")}
            </h6>

            <FormTextField
              fullWidth={true}
              name="email"
              label={i18n.get(
                "enter_your_email_or_phone",
                "Enter your email or Phone",
              )}
              margin="normal"
              onChange={(_, v) => setPhone(v)}
            />

            <CustomButton type="submit" className={resetPasswordRequest2}>
              {i18n.get("recover_password", "Recover Password")}
            </CustomButton>
          </form>
        </CardContent>
      ) : isMail ? (
        <CardContent style={{ padding: "3rem" }}>
          <FlexBox flex={true} align={ALIGN_CENTER} justify={JUSTIFY_CENTER}>
            <h4>
              {i18n.get(
                "please_check_your_email_to_further_proceed_resetting_your_password",
              )}
            </h4>
          </FlexBox>
        </CardContent>
      ) : (
        <CardContent>
          <TextField
            fullWidth={true}
            label={i18n.get("code")}
            value={code}
            onChange={handleChange}
            variant="outlined"
          />
          <FlexBox
            style={{ marginTop: "1rem" }}
            flex={true}
            align={ALIGN_CENTER}
            justify={JUSTIFY_CENTER}
          >
            <strong style={{ fontSize: "1.5rem", marginRight: "2rem" }}>
              0{timer.minutes}:{timer.seconds < 10 && 0}
              {timer.seconds}
            </strong>
            <CustomButton
              color={zero && SECONDARY}
              variant={OUTLINED}
              disabled={!zero}
              onClick={() => {
                if (zero) {
                  resetPasswordRequest1(phone, redirectUrl)
                    .then(() => {
                      showSuccessMessage1(i18n.get("successfully_submitted"));
                      setTimer({
                        minutes: 1,
                        seconds: 60,
                      });
                      setZero(false);
                    })
                    .catch((error) => showErrorMessage1(error));
                }
              }}
              startIcon={<Refresh />}
            >
              {i18n.get("refresh")}
            </CustomButton>
          </FlexBox>
          <CustomButton
            className={resetPasswordRequest2}
            onClick={() =>
              sendCode(code, phone)
                .then((res) => {
                  setIsSendingCode(false);
                  if (res.status === "success") {
                    setLocation(redirectUrl + res.data);
                    showSuccessMessage1(
                      i18n.get("successfully_created", "Successfully Created"),
                    );
                  } else {
                    showErrorMessage1(res.message);
                  }
                })
                .catch((error) => {
                  setIsSendingCode(false);
                  showErrorMessage1(error);
                })
            }
            endIcon={
              isSendingCode ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <ArrowRight />
              )
            }
          >
            {i18n.get("dispatch")}
          </CustomButton>
        </CardContent>
      )}
      <SignInLink className={signIn}>
        {i18n.get("sign_in", "Sign In")}
      </SignInLink>
    </CenteredCard>
  );
}

export default enhancer(RecoverPasswordForm);
