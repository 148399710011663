import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import {
  Paper,
  AppBar,
  CircularProgress,
  Typography,
  Toolbar,
  withTheme,
} from "@material-ui/core";

import Modal from "react-overlays/lib/Modal";
import FlexBox from "./FlexBox";
import { renderIf } from "../../helpers/HOCUtils";
import { responsive } from "../../../shared/theme/jss-responsive";

const enhancer = compose(
  withTheme,
  useSheet({
    root: {
      top: 0,
      left: 0,
      zIndex: 1100,
      width: "100%",
      height: "100%",
      padding: "6px",
      display: "flex",
      position: "fixed",
    },

    backdrop: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -10,
      position: "absolute",
      backgroundColor: "#000A",
    },

    paper: {
      zIndex: 10,
      margin: "auto",
      outline: "none",
      maxWidth: "1200px",
      minWidth: "1200px",
      maxHeight: "100%",
      minHeight: "300px",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#eaeaea",
      [responsive("$xs")]: { minWidth: "400px", width: "100%" },
    },

    appBar: { minHeight: "64px" },

    scrollWrapper: { overflow: "auto" },

    progressOverlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 5000,
      position: "absolute",
      backgroundColor: "#fff",
    },
    title: {
      color: props => props.theme.palette.alternateTextColor,
      flexGrow: 1,
    },
  }),
  renderIf("open"),
);

ModalPaper.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  title: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  className: PropTypes.string,
  paperClassName: PropTypes.string,
  open: PropTypes.bool.isRequired,
  autoScrollBodyContent: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
};

ModalPaper.defaultProps = {
  leftIcon: <span />,
  autoScrollBodyContent: true,
};

function ModalPaper(props) {
  const { classes } = props;
  return (
    <Modal
      show={props.open}
      enforceFocus={false}
      onHide={props.onRequestClose}
      backdropClassName={classes.backdrop}
      className={cx(classes.root, props.className)}
    >
      <Paper className={cx(classes.paper, props.paperClassName)}>
        {Boolean(props.title) && (
          <AppBar position="static">
            <Toolbar>
              {props.leftIcon}
              <Typography variant="h6" className={classes.title}>
                {props.title}
              </Typography>
              {props.rightIcon}
            </Toolbar>
          </AppBar>
        )}

        <FlexBox
          flex={true}
          direction="column"
          className={cx({
            [classes.scrollWrapper]: props.autoScrollBodyContent,
          })}
        >
          {props.children}
        </FlexBox>

        {props.isLoading && (
          <FlexBox
            align="center"
            justify="center"
            className={classes.progressOverlay}
          >
            <CircularProgress />
          </FlexBox>
        )}
      </Paper>
    </Modal>
  );
}

export default enhancer(ModalPaper);
