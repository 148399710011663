import { Observable } from "rxjs";
import React from "react";
import { fromJS, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { componentFromStream, compose, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { getValue, isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getIsRTL } from "../../reducers/LocalizationReducer";
import { Autocomplete } from "@material-ui/lab";
import { Chip, TextField } from "@material-ui/core";

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
  useSheet({
    chipInputRTL: {
      "& svg": {
        marginRight: "-8px",
        marginLeft: "4px",
      },
      '& div[style*="float"]': {
        float: "right",
      },
    },
  }),
);

const AbstractChips = componentFromStream(propsStream => {
  const {
    handler: onUpdateInput,
    stream: onUpdateInputStream,
  } = createEventHandler();

  const valueStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value"))
    .switchMap(props => {
      const mapValues = fp.flow(
        fp.map(fp.pick(["id", "name"])),
        fp.filter(isValidObjectId),
        fp.toPairs,
        fp.map(([index, value]) =>
          value.name
            ? Observable.of({ index, value, text: value.name })
            : props
                .getValue(value.id)
                .map(item => ({
                  index,
                  value: { id: value.id, name: item.get("name") },
                  text: item.get("name"),
                }))
                .startWith({ index, value, text: "Loading ..." })
                .catch(error =>
                  Observable.of({
                    index,
                    value,
                    text: `Failed to load "${value.id}"`,
                    error,
                  }),
                ),
        ),
      );

      return Observable.from(mapValues(props.input.value))
        .mergeAll()
        .scan((acc: List, item) => acc.set(item.index, fromJS(item)), List())
        .distinctUntilChanged(isEqualData)
        .map(fp.flow(acc => acc.toJS(), fp.toArray))
        .startWith([]);
    });

  const dataSourceStream = onUpdateInputStream
    .withLatestFrom(propsStream)
    .switchMap(([searchText, props]) =>
      props
        .getPredictions(searchText)
        .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
        .catch(() => Observable.of(List())),
    )
    .distinctUntilChanged(isEqualData)
    .map(list =>
      list
        .map(item => ({
          value: {
            id: item.get("id"),
            name: item.get("name") || " ",
          },
          text: item.get("name") || " ",
        }))
        .toArray(),
    )
    .startWith([]);

  return propsStream
    .map(fp.omit(["maxItems", "getValue", "getPredictions"]))
    .combineLatest(
      valueStream,
      dataSourceStream,
      ({ input, margin, ...props }, value, dataSource) => (
        <Autocomplete
          {...props}
          multiple={true}
          value={value}
          onFocus={fp.flow(fp.get("target.value"), onUpdateInput)}
          onChange={(_, newValue) => {
            const arr = fp.flow(
              fp.map(fp.get("value")),
              fp.compact,
              fp.uniqBy(v => (fp.isObject(v) ? fp.get("id", v) : v)),
            );
            input.onChange(arr(newValue));
          }}
          fullWidth={true}
          options={dataSource}
          getOptionLabel={option => getValue(option, "value.name", "")}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip label={option.text} {...getTagProps({ index })} />
            ))
          }
          onInputChange={fp.flow(fp.get("target.value"), onUpdateInput)}
          renderInput={params => (
            <TextField
              {...params}
              variant={props.variant}
              size={props.size}
              label={props.label}
              placeholder={props.hintText}
              margin={margin}
            />
          )}
        />
      ),
    );
});

AbstractChips.displayName = "AbstractChips";

FormAbstractChips.propTypes = {
  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,

  filter: PropTypes.func,
  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,
  canAutoPosition: PropTypes.bool,

  hintText: PropTypes.node,
  label: PropTypes.node,

  name: PropTypes.string.isRequired,
  getValue: PropTypes.func.isRequired,
  getPredictions: PropTypes.func.isRequired,
  style: PropTypes.object,

  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  size: PropTypes.oneOf(["medium", "small"]),
};

FormAbstractChips.defaultProps = {
  maxHeight: 320,
  openOnFocus: true,
  canAutoPosition: true,
  clearOnBlur: false,
  maxItems: Infinity,
  filter: fp.stubTrue,

  variant: "outlined",
  size: "small",
};

function FormAbstractChips(props) {
  return <Field {...props} component={AbstractChips} />;
}

export default enhancer(FormAbstractChips);
