import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const SUPPLIER_LIST_URL = `${API_ROOT_URL}/admin/suppliers`;
const SUPPLIER_ITEM_URL = `${API_ROOT_URL}/admin/supplier/%s`;
const SUPPLIER_CHOOSE_URL = `${API_ROOT_URL}/admin/supplier/choose`;
const SUPPLIER_MARKETPLACE_URL = `${API_ROOT_URL}/admin/supplier/%s/marketplaces`;
const SUPPLIER_BASE_CURRENCY_URL = `${API_ROOT_URL}/admin/supplier/%s/base_currency`;
const SUPPLIER_FINANCE_SETTINGS_URL = `${API_ROOT_URL}/admin/supplier/finance_settings`;
const SUPPLIER_CURRENCY_LIST_URL = `${API_ROOT_URL}/admin/supplier/currency_settings/%s`;
const SUPPLIER_MONTHLY_QUOTA_URL = `${API_ROOT_URL}/admin/supplier/%s/monthly_quota`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(SUPPLIER_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(SUPPLIER_CHOOSE_URL, { params: request.getDefinedValues() }),
});

export const getSupplier = id => itemCache.refresh(id);

export const getCachedSupplier = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));

export const getSupplierPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const updateSupplier = (id, body) =>
  api.put(sprintf(SUPPLIER_ITEM_URL, id), { body });

type CreateSupplierRequest = {
  authorities: [],
  billing_address: {
    address: string,
    address_type: number,
    city: {
      code: string,
      gparent_code: string,
      id: number,
      name: string,
      parent_code: string,
    },
    country: {
      code: string,
      gparent_code: string,
      id: number,
      name: string,
      parent_code: string,
    },
    id: number,
    primary: boolean,
    zip: string,
  },
  code: string,
  contact_email: string,
  contact_first_name: string,
  contact_last_name: string,
  contact_password: string,
  contact_phone: string,
  created_date: string,
  fax: string,
  id: number,
  name: string,
  number_of_drivers: number,
  number_of_fleet: number,
  price_formula: string,
  shipping_address: {
    address: string,
    address_type: number,
    city: {
      code: string,
      gparent_code: string,
      id: number,
      name: string,
      parent_code: string,
    },
    country: {
      code: string,
      gparent_code: string,
      id: number,
      name: string,
      parent_code: string,
    },
    id: number,
    primary: boolean,
    zip: string,
  },
  status: string,
  supplier_email: string,
  supplier_phone: string,
  website: string,
};

export type SaveSupplierCurrencyRequest = {
  items: [],
};

export const createSupplier = (body: CreateSupplierRequest) =>
  api.post(SUPPLIER_LIST_URL, { body });

export const getSupplierCurrencyList = id =>
  api.getStream(sprintf(SUPPLIER_CURRENCY_LIST_URL, id));
export const saveSupplierCurrencyList = (
  id,
  body: SaveSupplierCurrencyRequest,
) => api.post(sprintf(SUPPLIER_CURRENCY_LIST_URL, id), { body });
export const getSupplierBaseCurrency = id =>
  api.getStream(sprintf(SUPPLIER_BASE_CURRENCY_URL, id));

export const getSupplierFinanceSettings = () =>
  api.getStream(SUPPLIER_FINANCE_SETTINGS_URL);

export type SupplierFinanceSettingsRequest = [
  {
    id: number,
    status: string,
    supplier: {
      description: string,
      id: number,
      name: string,
      phone: string,
    },
    time_limit: string,
    force_cod_update: boolean,
    note: string,
  },
];

export const saveSupplierFinanceSettings = (
  body: SupplierFinanceSettingsRequest,
) => api.post(SUPPLIER_FINANCE_SETTINGS_URL, { body });

export const getSupplierVehicleCount = id =>
  api.getStream(sprintf(SUPPLIER_MONTHLY_QUOTA_URL, id));

export const updateSupplierMonthlyQuota = (id, values) =>
  api.post(sprintf(SUPPLIER_MONTHLY_QUOTA_URL, id), { body: values });

export const saveMarketplaces = (id, marketplaceIds) =>
  api.post(sprintf(SUPPLIER_MARKETPLACE_URL, id), {
    body: {
      marketplace_ids: marketplaceIds,
    },
  });
