import { OrderedSet } from "immutable";

export const ALL = null;
export const MERCHANTS = "MERCHANTS";
export const CASH_CLIENTS = "CASH_CLIENTS";

export default OrderedSet.of(MERCHANTS, CASH_CLIENTS);

export const MerchantTypeForFilter = OrderedSet.of(
  ALL,
  MERCHANTS,
  CASH_CLIENTS,
);
