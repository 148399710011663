import { Set } from "immutable";

export const NOT_COLLECTED = "not_collected";
export const COLLECTED = "collected";
export const WITH_WING = "with_wing";
export const CUSTOMER_NOT_PAID = "customer_not_paid";
export const PAID_TO_CUSTOMER = "paid_to_customer";

export default Set([
  NOT_COLLECTED,
  COLLECTED,
  WITH_WING,
  CUSTOMER_NOT_PAID,
  PAID_TO_CUSTOMER,
]);
