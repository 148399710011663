import React from "react";
import fp from "lodash/fp";
import ReactDOM from "react-dom";
import { compose } from "recompose";
import PropTypes from "prop-types";
import withSideEffect from "react-side-effect";
import { obtainElement } from "../../../client/helpers/ElementUtils";
import PageLoading from "../../../client/components/ui-core/PageLoading";

export const MOUNT_NODE_ID = "preloader";

const reducePropsToState = fp.flow(fp.find(fp.get("show")), Boolean);
const handleStateChangeOnClient = show => {
  ReactDOM.render(
    <PageLoading isLoading={show} />,
    obtainElement(MOUNT_NODE_ID),
  );
};

const enhancer = compose(
  withSideEffect(reducePropsToState, handleStateChangeOnClient),
);

Preloader.propTypes = { show: PropTypes.bool, children: PropTypes.node };

function Preloader(props) {
  return props.children ? React.Children.only(props.children) : null;
}

export default enhancer(Preloader);
