import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import sizeMe from "react-sizeme";
import { Map, YMaps } from "react-yandex-maps";
// import YandexRouting from "./yandex/YandexRouting";
import { getYandexMapsStream } from "../../helpers/YandexMapsHelper";
import { getMarketplaceMapCenter } from "../../reducers/MarketplaceReducer";
import { getMapProviderKey } from "../../../shared/reducers/AppReducer";
import { formatLocalType } from "../form/FormYandexGeoAutoComplete";
import { getCurrentLanguage } from "../../reducers/LocalizationReducer";

const hasLocation = fp.flow(
  fp.over([fp.get("lat"), fp.get("lng")]),
  fp.every(Boolean),
);

const enhancer = compose(
  connect(state => ({
    baseCountryMapCenter: getMarketplaceMapCenter(state),
    mapProviderKey: getMapProviderKey(state),
    language: getCurrentLanguage(state),
  })),
  sizeMe({ monitorWidth: true, monitorHeight: true }),
  useSheet({ map: { height: "100%" } }),
  mapPropsStream(propsStream =>
    propsStream.combineLatest(getYandexMapsStream(), (props, maps) => ({
      ...props,
      maps,
    })),
  ),
);

YandexMapWrapper.propTypes = {
  size: PropTypes.object,
  classes: PropTypes.object,

  maps: PropTypes.object,
  children: PropTypes.node,

  style: PropTypes.object,
  className: PropTypes.string,
  mapProviderKey: PropTypes.string,

  zoom: PropTypes.number,
  maxZoom: PropTypes.number,
  minZoom: PropTypes.number,

  height: PropTypes.string,
  width: PropTypes.string,
  isCentered: PropTypes.bool,
  mapCenter: PropTypes.object,
  baseCountryMapCenter: PropTypes.object,

  hasZoomControl: PropTypes.bool,
  clickableIcons: PropTypes.bool,
  disableDoubleClickZoom: PropTypes.bool,

  onClick: PropTypes.func,
  onLoad: PropTypes.func,
  onZoomChanged: PropTypes.func,
  onCenterChanged: PropTypes.func,
  onBoundsChange: PropTypes.func,
  language: PropTypes.string,
  instanceRef: PropTypes.any,
  modules: PropTypes.any,
};

YandexMapWrapper.defaultProps = {
  zoom: 9,
  isCentered: false,
  hasZoomControl: true,
  clickableIcons: false,
  height: "100%",
  width: "100%",
  disableDoubleClickZoom: true,
};

function YandexMapWrapper(props) {
  const { zoom } = props;

  const center = hasLocation(props.mapCenter)
    ? props.mapCenter
    : props.baseCountryMapCenter;

  return (
    <YMaps
      enterprise={true}
      query={{
        apikey: props.mapProviderKey,
        // load: "package.full",
        lang: formatLocalType(props.language),
      }}
    >
      <Map
        state={{
          center: [center.lat, center.lng],
          zoom,
          controls: [],
        }}
        width={props.width}
        height={props.height}
        onLoad={props.onLoad}
        instanceRef={props.instanceRef}
        modules={props.modules}
        onClick={props.onClick}
        onBoundsChange={props.onBoundsChange}
      >
        {props.children}
      </Map>
    </YMaps>
  );
}

export default enhancer(YandexMapWrapper);
