import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import AdminCODCodes from "../../constants/AdminCODCodes";
import OrderStatusCodes from "../../constants/OrderStatusCodes";
import AdminPrivacyTypes from "../../constants/AdminPrivacyTypes";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import BatchUpdateOrderDialog from "../../components/orders-core/BatchUpdateOrderDialog2";
import { getPostcodePredictions } from "../../api/admin/AdminPostcodesApi";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox, { JUSTIFY_CENTER } from "../../components/ui-core/FlexBox";
import CustomButton, { OUTLINED } from "../../components/ui-core/CustomButton";
import { CONTAINER } from "../../constants/OrderType";
import { getUserWarehouse } from "../../reducers/ProfileReducer";

export const DEFAULT_SUPPLIER = { id: 1 };
const enhancer = compose(
  connect((state) => ({
    userWarehouse: getUserWarehouse(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

const AdminBatchUpdateOrderDialogWrapper2 = (props) => {
  if (props.isContainer && !props.isSameWarehouses) {
    return (
      <Dialog open={true} maxWidth="md" onClose={props.onRequestClose}>
        <DialogTitle>
          <h4>{props.getLocalisationMessage("selected_diff_warehouses")}</h4>
        </DialogTitle>
        <DialogActions>
          <FlexBox flex={true} justify={JUSTIFY_CENTER}>
            <CustomButton variant={OUTLINED} onClick={props.onRequestClose}>
              {props.getLocalisationMessage("close")}
            </CustomButton>
          </FlexBox>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <BatchUpdateOrderDialog
      isOpenUnpaidDialog={props.isOpenUnpaidDialog}
      title={props.title}
      isContainer={props.isContainer}
      open={props.open}
      onRequestClose={props.onRequestClose}
      codCodes={AdminCODCodes}
      statusCodes={OrderStatusCodes}
      withInTransitToSupplier={true}
      privacyTypes={AdminPrivacyTypes}
      getCachedSupplier={getCachedSupplier}
      getSupplierPredictions={getSupplierPredictions}
      getCachedDriver={getCachedDriver}
      getDriverPredictions={getDriverPredictions}
      getCachedWarehouse={getCachedWarehouse}
      getWarehousePredictions={getWarehousePredictions}
      getPostcodePredictions={getPostcodePredictions}
      initialValues={props.initialValues}
      list={props.list}
      isSorting={props.isSorting}
      onSubmit={fp.flow(toSnakeCase, (values) => {
        const request = {
          ...values,
          privacy: PUBLIC,
          supplier: DEFAULT_SUPPLIER,
          warehouse: values.warehouse || props.userWarehouse,
          type: props.isContainer ? CONTAINER : null,
        };

        return batchAsyncUpdateOrder(
          fp.omit(["batch_ids", "is_barcode", "only_barcode"], request),
        ).catch(ResponseError.throw);
      })}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
    />
  );
};
AdminBatchUpdateOrderDialogWrapper2.propTypes = {
  open: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  list: PropTypes.any,
  title: PropTypes.string,
  isSorting: PropTypes.bool,
  isContainer: PropTypes.bool,
  isSameWarehouses: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  userWarehouse: PropTypes.object,
  isOpenUnpaidDialog: PropTypes.bool,
};

export default enhancer(AdminBatchUpdateOrderDialogWrapper2);
