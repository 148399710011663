import _ from "lodash";
import { SubmissionError } from "redux-form";
import { toCamelCase } from "./CaseMapper";

export default class ResponseError extends SubmissionError {
  static throw(error: Error): void {
    throw new ResponseError(error);
  }

  constructor(error: Error) {
    const values = toCamelCase(
      _.reduce(error.response, (a, v, p) => _.set(a, p, v), {}),
    );

    super({
      _error: error.reasonMessage || error.message,
      responseCode: error.responseCode,
      ...values,
    });
  }
}
