import React, { useEffect, useState } from "react";
import _ from "lodash";
import Immutable, { fromJS, List, Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AppLayout from "../app-layout/AppLayout";
import AppSidebar from "../app-sidebar/AppSidebar";
import AppSidebarLink from "../app-sidebar/AppSidebarLink";
import AppSidebarBlock from "../app-sidebar/AppSidebarBlock";
import AppSidebarProfileBlock from "../app-sidebar/AppSidebarProfileBlock";
import { toJS } from "../../helpers/DataUtils";
import { layoutIcon } from "../../helpers/MarketplaceHelper";
import {
  getUser,
  getUserInboundKey,
  getUserPermissions,
} from "../../reducers/ProfileReducer";
import { getMarketplace } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import adminMenuList, {
  adminMarketplaceMenuConstantsWithoutFirebase,
} from "../../../client/constants/AdminMarketplaceMenuConstants";
import { hasRole } from "../../helpers/RoleUtils";
import { ROLE_OPERATOR } from "../../constants/AdminRoleTypes";
import { getPushLogs } from "../../api/admin/AdminNotificationApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getPushLog } from "../../reducers/PushNotificationReducer";

const enhancer = compose(
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];
      return {
        userRoles,
        inboundKey: Number(getUserInboundKey(state)),
        permissions: getUserPermissions(state),
        isOperator: hasRole(userRoles, ROLE_OPERATOR),
        marketplace: toJS(getMarketplace(state)),
        isRefresh: getPushLog(state),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

const appSlugView = (slug, item, length, getLocalisationMessage1) => {
  switch (slug) {
    case "act_management":
      return (
        <span>
          {item.get("name") ||
            getLocalisationMessage1(
              item.get("slug"),
              _.startCase(item.slug),
            )}{" "}
          <span
            style={{
              color: "#fff",
              minWidth: "24px",
              fontSize: "12px",
              padding: "3px 4px",
              textAlign: "center",
              marginLeft: "8px",
              marginRight: "8px",
              borderRadius: "50px",
              backgroundColor: "red",
              display: "inline-block",
            }}
          >
            {length}
          </span>
        </span>
      );

    default:
      return (
        item.get("name") ||
        getLocalisationMessage1(item.get("slug"), _.startCase(item.slug))
      );
  }
};

const sidebar = ({
  getLocalisationMessage: getLocalisationMessage1,
  marketplace,
  permissions,
  isRefresh,
  isOperator,
  inboundKey,
}) => {
  const [settings, setSettings] = useState(List());
  useEffect(() => {
    const menuList = _.get(marketplace, "setting.settings.leftMenu");
    const menu = List().asMutable();

    const adminMenu =
      inboundKey < 2500
        ? adminMarketplaceMenuConstantsWithoutFirebase
        : adminMenuList;

    adminMenu.forEach(item => {
      const isPermissionGiven = permissions.includes(item.permission);
      const enabledItem = menuList[item.slug];
      if (enabledItem && enabledItem.active && isPermissionGiven) {
        let menuItem = Map({
          ...item,
          name: enabledItem.name,
        });
        if (item.slug === "order_sorting_firebase" && isOperator) {
          menuItem = Map({
            ...item,
            name: getLocalisationMessage1(
              "order_sorting_firebase_for_operator",
            ),
          });
        }
        if (item.slug === "order_sorting" && isOperator) {
          menuItem = Map({
            ...item,
            name: getLocalisationMessage1("order_sorting_operator"),
          });
        }

        // if (slug && slug === item.slug) {
        //   currentMenu = menuItem;
        // }
        menu.push(menuItem);
      }
    });
    setSettings(
      fromJS(_.groupBy(menu.toJS(), "group")).sortBy(item =>
        item.get(0).get("ind"),
      ),
    );
  }, [marketplace]);

  const [pushLogs, setPushLogs] = useState([]);

  useEffect(() => {
    if (permissions.includes("ADMIN_GET_PUSH_LOGS")) {
      getPushLogs("SENT", "ACT").then(res => {
        if (res && res.data) {
          setPushLogs(res.data);
        }
      });
    }
  }, [isRefresh]);

  return (
    <AppSidebar>
      {settings
        .map((group, key) => {
          const groupName = _.startCase(key);
          return (
            <AppSidebarBlock
              title={getLocalisationMessage1(key, groupName)}
              key={key}
            >
              {group
                .map((item, index) => (
                  <AppSidebarLink
                    pushLogs={pushLogs}
                    key={index}
                    slug={item.get("slug")}
                    leftIcon={layoutIcon(item.get("icon"))}
                    to={
                      item.has("params")
                        ? updateQuery(
                            item.get("url"),
                            item.get("params").toJS(),
                          )
                        : item.get("url")
                    }
                  >
                    {appSlugView(
                      item.get("slug"),
                      item,
                      pushLogs.length,
                      getLocalisationMessage1,
                    )}
                  </AppSidebarLink>
                ))
                .toArray()}
            </AppSidebarBlock>
          );
        })
        .toArray()}
      <AppSidebarProfileBlock />
    </AppSidebar>
  );
};

export const SidebarMenu = enhancer(sidebar);

const AdminAppLayout = ({
  title,
  getLocalisationMessage: getLocalisationMessage1,
  marketplace,
  ...props
}) => {
  if (!marketplace)
    return (
      <AppLayout
        {...props}
        title={title}
        sidebar={
          <AppSidebar>
            <AppSidebarBlock title={`${getLocalisationMessage1("loading")}...`}>
              {`${getLocalisationMessage1("loading")}...`}
            </AppSidebarBlock>
            <AppSidebarProfileBlock />
          </AppSidebar>
        }
      />
    );

  return <AppLayout {...props} title={title} sidebar={<SidebarMenu />} />;
};
AdminAppLayout.propTypes = {
  title: PropTypes.string,
  inboundKey: PropTypes.number,
  marketplace: PropTypes.instanceOf(Immutable.Map),
  getLocalisationMessage: PropTypes.func,
  className: PropTypes.string,
};
export default enhancer(AdminAppLayout);
