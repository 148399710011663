import { OrderedSet } from "immutable";

export const ALL = "all";
export const CASH_CLIENT = "cash_clients";
export const CLIENT_MERCHANT = "merchants";
export const CASH_CLIENT2 = "CASH_CLIENTS";
export const CLIENT_MERCHANT2 = "MERCHANTS";
export const MerchantTypeForPackage = OrderedSet.of(
  ALL,
  CASH_CLIENT,
  CLIENT_MERCHANT,
);
export default OrderedSet.of(ALL, CASH_CLIENT, CLIENT_MERCHANT);
