import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { combineReducers } from "redux-immutable";
import { cleanupStringArray } from "../helpers/FormatUtils";
import {
  createAsyncReducer,
  createActionReducer,
  createAsyncFlagReducer,
} from "../helpers/ReducerFactory";
import { injectReducer } from "../../shared/helpers/ReducerContext";

export const FETCH_USER_ACCOUNT = "USER_ACCOUNT/FETCH_USER_ACCOUNT";
export const UPDATE_USER_ACCOUNT = "USER_ACCOUNT/UPDATE_USER_ACCOUNT";
export const SKIP_USER_VERIFICATION = "USER_ACCOUNT/SKIP_VERIFICATION";

const selector = injectReducer(
  "userAccount",
  combineReducers({
    item: createAsyncReducer(
      FETCH_USER_ACCOUNT,
      Map({ user: null, failed: false }),
      {
        pending: state => state.merge({ failed: false }),
        fulfilled: (state, action) =>
          state.merge({
            failed: false,
            user: fromJS(action.payload.data),
          }),
        rejected: state => state.merge({ user: null, failed: true }),
      },
    ),
    updating: createAsyncFlagReducer(UPDATE_USER_ACCOUNT),
    skipVerification: createActionReducer(
      SKIP_USER_VERIFICATION,
      false,
      fp.stubTrue,
    ),
  }),
);

export function getUserAccount(state) {
  return selector(state).getIn(["item", "user"]);
}

export function isUserAccountFetchFailed(state) {
  return selector(state).getIn(["item", "failed"]);
}

export function getUserAccountId(state) {
  return selector(state).getIn(["item", "user", "user_id"]);
}

export function getUserAccountAuthorities(state) {
  return fp.toArray(selector(state).getIn(["item", "user", "roles"]));
}

export function getUserAccountMarketplaceId(state) {
  return fp.toFinite(selector(state).getIn(["item", "user", "marketplace_id"]));
}

function getUserAccountFistName(state) {
  return selector(state).getIn(["item", "user", "first_name"]);
}

function getUserAccountLastName(state) {
  return selector(state).getIn(["item", "user", "last_name"]);
}

function getUserAccountEmail(state) {
  return selector(state).getIn(["item", "user", "email"]);
}

export function getUserAccountName(state) {
  const userName = cleanupStringArray([
    getUserAccountFistName(state),
    getUserAccountLastName(state),
  ]).join(" ");

  return userName || getUserAccountEmail(state);
}

export function getUserAccountPhone(state) {
  return selector(state).getIn(["item", "user", "phone"]);
}

export function getSupplierId(state) {
  return selector(state).getIn(["item", "user", "supplier", "id"]);
}

export function isUserAccountFetching(state) {
  return selector(state).getIn(["item", "fetching"]);
}

export function isUserAccountUpdating(state) {
  return selector(state).get("updating");
}

export function isUserAccountVerified(state) {
  return selector(state).getIn(["item", "user", "valid_phone"]);
}
