import { Map } from "immutable";
import { injectReducer } from "../../shared/helpers/ReducerContext";

const HIDE_POPUP = "SUBSCRIPTIONS/HIDE_POPUP";

const selector = injectReducer("subscription", (state = Map(), action) => {
  if (action.type === HIDE_POPUP) {
    return state.merge({ popup: false });
  }
  return state;
});

export const getPopupItems = state => selector(state);

export const onHidePopup = () => ({
  type: HIDE_POPUP,
  payload: false,
});

export const showFreeTrialPopup = state => selector(state).get("popup", true);
