import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const MATCHES_FN = "matches";
export const NOT_MATCHES_FN = "notMatches";
export const OVER_SOME_FN = "overSome";
export const OVER_EVERY_FN = "overEvery";

export const RULE_FNS = OrderedSet.of(
  MATCHES_FN,
  NOT_MATCHES_FN,
  OVER_SOME_FN,
  OVER_EVERY_FN,
);
export const MATCHES_FN_AND_NOT_MATCHES_FN = OrderedSet.of(
  MATCHES_FN,
  NOT_MATCHES_FN,
  OVER_SOME_FN,
  OVER_EVERY_FN,
);

const hasFn = fp.has("fn");
const hasValue = fp.has("value");
const isValidFn = x => RULE_FNS.has(x);

const assertFn = (fn, args) => {
  if (!isValidFn(fn)) {
    throw new Error(`Corrupted Rule: Unknown predicate function "${fn}"`);
  }

  if (!fp.isUndefined(args) && !fp.isArray(args)) {
    throw new Error(
      `Corrupted Rule: Invalid predicate "args" object type "${typeof args}"`,
    );
  }
};

const assertValue = value => {
  if (fp.isUndefined(value)) {
    throw new Error(`Corrupted Rule: Predicate value is "undefined"`);
  }
};

const assertRestProperties = rest => {
  const restKeys = fp.keys(rest);

  if (restKeys.length > 0) {
    throw new Error(
      `Corrupted Rule: Unknown predicate properties: "${restKeys.join(", ")}"`,
    );
  }
};

const fns = {
  [MATCHES_FN]: ([x]) => fp.matches(x),
  [NOT_MATCHES_FN]: ([x]) => fp.negate(fp.matches(x)),

  [OVER_SOME_FN]: fp.overSome,
  [OVER_EVERY_FN]: fp.overEvery,
};

const getFn = fn => (...args) => fns[fn](args);

export class RulePredicate {
  static create(predicate) {
    return new RulePredicate(predicate);
  }

  static mapPredicates = fp.map(
    fp.flow(RulePredicate.create, x => x.compile()),
  );

  constructor(predicate) {
    if (!fp.isPlainObject(predicate)) {
      throw new Error(
        `Corrupted Rule: Invalid predicate object type "${typeof predicate}"`,
      );
    }

    this.hasFn = hasFn(predicate);
    this.hasValue = hasValue(predicate);

    if (this.hasFn) {
      const { fn, args, ...rest } = predicate;

      assertFn(fn, args);
      assertRestProperties(rest);

      this.fn = fn;
      this.args = RulePredicate.mapPredicates(args);
    } else if (this.hasValue) {
      const { value, ...rest } = predicate;

      assertValue(value);
      assertRestProperties(rest);

      this.value = value;
    } else {
      throw new Error(
        `Corrupted Rule: Predicate does not have required properties`,
      );
    }
  }

  compile() {
    if (this.hasValue) {
      return this.value;
    }

    const fn = getFn(this.fn);

    return fn(...this.args);
  }
}

export class Rule {
  static create(predicate) {
    return new Rule(predicate);
  }

  constructor(rule) {
    if (!fp.isPlainObject(rule)) {
      throw new Error(
        `Corrupted Rule: Invalid rule object type "${typeof rule}"`,
      );
    }

    const predicate = new RulePredicate(rule.predicate);

    if (!predicate.hasFn) {
      throw new Error(
        `Corrupted Rule: Rule predicate has to be a function predicate`,
      );
    }

    this.fn = predicate.compile();
    this.code = fp.trim(rule.code);
    this.weight = fp.toLength(rule.weight);
  }

  process(value) {
    const comparison = this.fn(value);
    if (!comparison) {
      const toJurisdiction = fp.toArray(fp.get("to_jurisdiction", value));
      const filtered = toJurisdiction.filter(jurisdiction =>
        this.fn({ ...value, to_jurisdiction: fp.pick(["id"], jurisdiction) }),
      );

      return filtered.length > 0;
    }

    return comparison;
  }
}

const mapRules = fp.flow(
  fp.toPairs,
  fp.map(fp.flow(([code, rule]) => ({ code, ...rule }), Rule.create)),
  fp.sortBy("weight"),
);

export class RuleList {
  static create(rules) {
    return new RuleList(rules);
  }

  constructor(rules) {
    this.rules = mapRules(rules);
  }

  process(value) {
    const processRules = fp.flow(
      fp.find(x => x.process(value)),
      fp.get("code"),
    );

    return processRules(this.rules);
  }
}

export const toRulesGroupListFilter = fp.compose(
  DataListFilter.create,

  mapSchema({
    ...getListFilterSchema(),

    use_solr: types.boolean,
    marketplaceId: types.ID,
    rule_group_status: types.string,
    marketplaceIds: queryTypes.IDSeq,
  }),
);
