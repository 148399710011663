import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { connect } from "react-redux";
import { getIsRTL } from "../../reducers/LocalizationReducer";
import { danger1 } from "../../../shared/theme/main-theme";
import { withTheme } from "@material-ui/core/styles";

CheckboxComponent.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  theme: PropTypes.object,
  inputRef: PropTypes.func,
};

function CheckboxComponent({ meta, input, theme, inputRef, ...custom }) {
  const error = meta.touched && meta.error;
  return (
    <FormControlLabel
      label={error || custom.label}
      inputRef={inputRef}
      control={
        <Checkbox
          {...input}
          {...custom}
          labelStyle={error ? { color: danger1 } : {}}
          // iconStyle={{
          //   marginRight: custom.isRTL ? 0 : "16px",
          //   marginLeft: !custom.isRTL ? 0 : "16px",
          // }}
          value={Boolean(input.value)}
          checked={Boolean(input.value)}
          onBlur={() => input.onBlur()}
          onFocus={() => input.onFocus()}
          onCheck={(event, value) => {
            input.onChange(value);
          }}
        />
      }
    />
  );
}

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
  withTheme,
);

FormCheckbox.propTypes = {
  label: PropTypes.node,
  disabled: PropTypes.bool,
  normalize: PropTypes.func,
  inputRef: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  isRTL: PropTypes.bool,
};

function FormCheckbox(props) {
  return <Field {...props} component={CheckboxComponent} />;
}
export default enhancer(FormCheckbox);
