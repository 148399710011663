import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";

TextWithLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default function TextWithLink(props) {
  return props.to ? <Link {...props} /> : <span {...props} />;
}
