import React from "react";
import fp from "lodash/fp";
import Layer from "react-layer";
import { isEqualData } from "../../helpers/DataUtils";

export default function withGlobalPortal(reducePropsToComponent) {
  return WrappedComponent => {
    const reduceInstancesToComponent = fp.flow(
      fp.map("props"),
      reducePropsToComponent,
    );
    const mountedInstances = [];
    let layer;
    let content;

    const emitChange = () => {
      if (mountedInstances.length) {
        if (!layer) {
          layer = new Layer(document.body, () => content);
        }
      } else {
        if (layer) {
          layer.destroy();
        }

        layer = null;
      }

      content = reduceInstancesToComponent(mountedInstances);

      if (layer) {
        layer.render(null, mountedInstances[0]);
      }
    };

    return class extends React.Component {
      static displayName = `WithGlobalPortal(${WrappedComponent.displayName ||
        WrappedComponent.name ||
        "Component"})`;

      componentDidMount() {
        mountedInstances.push(this);
        emitChange();

        this.layer = layer;
      }

      shouldComponentUpdate(nextProps) {
        return !isEqualData(this.props, nextProps);
      }

      componentDidUpdate() {
        emitChange();
      }

      componentWillUnmount() {
        const index = mountedInstances.indexOf(this);

        mountedInstances.splice(index, 1);

        if (module.hot) {
          if (this.layer && this.layer !== layer) {
            this.layer.destroy();
          }
        }

        emitChange();
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    };
  };
}
