import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import cx from "classnames";
import PropTypes from "prop-types";
import ExtendElement from "./ExtendElement";
import { responsive } from "../../../shared/theme/jss-responsive";

export const WRAP = true;
export const NO_WRAP = false;
export const WRAP_REVERSE = "reverse";

export const ALIGN_START = "flex-start";
export const ALIGN_CENTER = "center";
export const ALIGN_END = "flex-end";
export const ALIGN_STRETCH = "stretch";

export const JUSTIFY_START = "flex-start";
export const JUSTIFY_CENTER = "center";
export const JUSTIFY_END = "flex-end";
export const JUSTIFY_SPACE_AROUND = "space-between";
export const JUSTIFY_SPACE_BETWEEN = "space-around";
export const JUSTIFY_SPACE_EVENLY = "space-evenly";

export const DIRECTION_ROW = "row";
export const DIRECTION_ROW_REVERSE = "row-reverse";
export const DIRECTION_COLUMN = "column";
export const DIRECTION_COLUMN_REVERSE = "column-reverse";

export const FLEX_NONE = "none";
export const FLEX_INITIAL = "initial";
export const FLEX_AUTO = "auto";
export const FLEX_GROW = "grow";
export const FLEX_NOGROW = "nogrow";
export const FLEX_NOSHRINK = "noshrink";

const FLEX_SIZE = [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12];

const createFlexStyles = (prefix) => {
  const flexPreset = {
    true: "1 1 0%",
    none: "0 0 auto",
    initial: "0 1 auto",
    auto: "1 1 0%",
    grow: "1 1 100%",
    nogrow: "0 1 auto",
    noshrink: "1 1 0%",
  };

  const styles = {};

  FLEX_SIZE.forEach((size) => {
    const percent = `${(size * 100) / 12}%`;

    styles[`${prefix}-${size}`] = {
      maxWidth: percent,
      maxHeight: "100%",
      flex: `1 1 ${percent}`,
    };
  });

  fp.keys(flexPreset).forEach((name) => {
    styles[`${prefix}-${name}`] = { flex: flexPreset[name] };
  });

  return styles;
};

const createDirectionStyles = (size) => {
  const prefix = size ? `${size}-` : "";

  return {
    [`${prefix}direction-row`]: {
      minWidth: 0,
      flexDirection: "row",
    },
    [`${prefix}direction-row-reverse`]: {
      minWidth: 0,
      flexDirection: "row-reverse",
    },
    [`${prefix}direction-column`]: {
      minHeight: 0,
      flexDirection: "column",
    },
    [`${prefix}direction-column-reverse`]: {
      minHeight: 0,
      flexDirection: "column-reverse",
    },
  };
};
const enhancer = useSheet(
  {
    root: { display: "flex", boxSizing: "border-box" },

    "gutter-8": { margin: "-4px", "& > $root": { padding: "4px" } },
    "gutter-16": { margin: "-8px", "& > $root": { padding: "8px" } },
    "gutter-24": { margin: "-12px", "& > $root": { padding: "12px" } },
    "gutter-40": { margin: "-20px", "& > $root": { padding: "20px" } },

    "container-8": { padding: "4px" },
    "container-16": { padding: "8px" },
    "container-24": { padding: "12px" },
    "container-40": { padding: "20px" },

    "wrap-true": { flexWrap: "wrap" },
    "wrap-false": { flexWrap: "nowrap" },
    "wrap-reverse": { flexWrap: "wrap-reverse" },

    "align-center": { alignItems: "center" },
    "align-flex-end": { alignItems: "flex-end" },
    "align-stretch": { alignItems: "stretch" },

    "justify-center": { justifyContent: "center" },
    "justify-flex-end": { justifyContent: "flex-end" },
    "justify-space-between": { justifyContent: "space-between" },
    "justify-space-around": { justifyContent: "space-around" },

    ...createFlexStyles("flex"),
    ...createDirectionStyles(),

    [responsive("$xs")]: {
      ...createFlexStyles("xs"),
      ...createDirectionStyles("xs"),
    },
    [responsive("$sm")]: {
      ...createFlexStyles("sm"),
      ...createDirectionStyles("sm"),
    },
    [responsive("$md")]: {
      ...createFlexStyles("md"),
      ...createDirectionStyles("md"),
    },
    [responsive("$lg")]: {
      ...createFlexStyles("lg"),
      ...createDirectionStyles("lg"),
    },
  },
  { important: false },
);

const GUTTER_OPTION = [0, 8, 16, 24, 40];

const flexType = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.oneOf(FLEX_SIZE),
  PropTypes.oneOf([
    FLEX_NONE,
    FLEX_INITIAL,
    FLEX_AUTO,
    FLEX_GROW,
    FLEX_NOGROW,
    FLEX_NOSHRINK,
  ]),
]);

const directionType = PropTypes.oneOf([
  DIRECTION_ROW,
  DIRECTION_ROW_REVERSE,
  DIRECTION_COLUMN,
  DIRECTION_COLUMN_REVERSE,
]);

FlexBox.propTypes = {
  sheet: PropTypes.object,
  classes: PropTypes.object,

  style: PropTypes.object,
  className: PropTypes.string,

  gutter: PropTypes.oneOf(GUTTER_OPTION),
  container: PropTypes.oneOf(GUTTER_OPTION),

  element: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),

  wrap: PropTypes.oneOf([WRAP, NO_WRAP, WRAP_REVERSE]),
  align: PropTypes.oneOf([ALIGN_START, ALIGN_CENTER, ALIGN_END, ALIGN_STRETCH]),
  justify: PropTypes.oneOf([
    JUSTIFY_START,
    JUSTIFY_CENTER,
    JUSTIFY_END,
    JUSTIFY_SPACE_AROUND,
    JUSTIFY_SPACE_BETWEEN,
    JUSTIFY_SPACE_EVENLY,
  ]),

  direction: directionType,
  xsDirection: directionType,
  smDirection: directionType,
  mdDirection: directionType,
  lgDirection: directionType,

  xs: flexType,
  sm: flexType,
  md: flexType,
  lg: flexType,
  flex: flexType,
};

FlexBox.defaultProps = {
  wrap: NO_WRAP,
  align: ALIGN_START,
  justify: JUSTIFY_START,
  direction: DIRECTION_ROW,

  gutter: 0,
  container: 0,
  element: "div",
};

function FlexBox({
  sheet,
  classes,
  xs,
  sm,
  md,
  lg,
  flex,
  wrap,
  align,
  justify,
  gutter,
  container,
  direction,
  xsDirection,
  smDirection,
  mdDirection,
  lgDirection,
  className,
  ...props
}) {
  return (
    <ExtendElement
      {...props}
      className={cx(
        classes.root,
        classes[`xs-${xs}`],
        classes[`sm-${sm}`],
        classes[`md-${md}`],
        classes[`lg-${lg}`],
        classes[`flex-${flex}`],
        classes[`wrap-${wrap}`],
        classes[`align-${align}`],
        classes[`gutter-${gutter}`],
        classes[`justify-${justify}`],
        classes[`container-${container}`],
        classes[`direction-${direction}`],
        classes[`xs-direction-${xsDirection}`],
        classes[`sm-direction-${smDirection}`],
        classes[`md-direction-${mdDirection}`],
        classes[`lg-direction-${lgDirection}`],
        className,
      )}
    />
  );
}

export default enhancer(FlexBox);
