import {
  FETCH_PROFILE,
  isUserFetching,
  isUserUpdating,
  UPDATE_PROFILE,
} from "../reducers/ProfileReducer";
import { FETCH_CUSTOMER_ACCOUNT } from "../reducers/CustomerReducer";
import { api as wingApi } from "../api/shared/BaseApi";
import { API_ROOT_URL } from "../../shared/constants/ApiConstants";

const UPDATE_URL = `${API_ROOT_URL}/customer/update`;
const ACCOUNT_URL = `${API_ROOT_URL}/customer/account`;

export function fetchUser() {
  return (dispatch, getState, api) => {
    if (isUserFetching(getState())) {
      return Promise.reject(new Error("User profile is already fetching"));
    }

    return dispatch({ type: FETCH_PROFILE, payload: api.get(ACCOUNT_URL) });
  };
}

export function updateUser(request) {
  return (dispatch, getState, api) => {
    if (isUserUpdating(getState())) {
      return Promise.reject(new Error("User profile is already fetching"));
    }

    return dispatch({
      type: UPDATE_PROFILE,
      payload: api.put(UPDATE_URL, { body: request }),
    });
  };
}

export const getCustomerAccount = () =>
  wingApi.getStream(ACCOUNT_URL, {
    headers: {
      "x-app-type": "customer-web",
    },
  });

export function fetchCustomer() {
  return (dispatch, getState, api) =>
    dispatch({
      type: FETCH_CUSTOMER_ACCOUNT,
      payload: api.get(ACCOUNT_URL).then(data => ({ data })),
    });
}
