import React, { useEffect } from "react";

import {
  makeStyles,
  Collapse,
  IconButton,
  CircularProgress,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";
import {
  ExpandLess,
  ExpandMore,
  AddCircleOutline,
  EditOutlined,
} from "@material-ui/icons";
import cn from "classnames";

const useStyles = makeStyles({
  container: {
    marginLeft: 20,
    position: "relative",
  },
  mainContent: {
    position: "relative",
    borderBottom: "1px solid #eee",
    padding: "8px 25px",
    cursor: "pointer",
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    color: "#555",
  },
  selected: {
    fontStyle: "italic",
    fontWeight: "bold",
    color: "green",
  },
  description: {
    fontSize: 14,
    color: "#aaa",
  },
  horizontalLine: {
    position: "absolute",
    width: 20,
    left: -10,
    top: 30,
    height: 2,
    backgroundColor: props => (props.selectedChildren ? "green" : "lightgray"),
  },
  verticalLine: {
    position: "absolute",
    width: 2,
    left: -10,
    top: 0,
    height: 30,
    backgroundColor: props =>
      props.selectedChildren || props.selectedThrough ? "green" : "lightgray",
  },
  expandButton: {
    position: "absolute",
    top: "calc(50% - 30px)",
    right: 20,
    fontSize: 16,
    zIndex: 20,
  },
  hasChild: {
    position: "absolute",
    left: 10,
    top: 30,
    bottom: -1,
    width: 2,
    backgroundColor: props => (props.selectedChildren ? "green" : "lightgray"),
  },
  throughLine: {
    position: "absolute",
    width: 2,
    backgroundColor: props => (props.selectedThrough ? "green" : "lightgray"),
    left: -10,
    top: 0,
    bottom: 0,
  },
  node: {
    width: 18,
    height: 18,
    borderRadius: 9,
    color: "#fff",
    fontSize: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 0,
    top: 21,
    backgroundColor: props => (props.selectedNode ? "green" : "lightgray"),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    padding: 10,
  },
  addButton: {
    fontSize: 14,
    color: "#4CAF50",
    textTransform: "capitalize",
  },
  buttonContainer: {
    padding: "10px 0 10px 0",
    borderBottom: "1px solid #eee",
    textAlign: "center",
  },
  editIcon: {
    position: "absolute",
    top: "calc(50% - 30px)",
    right: 60,
    fontSize: 14,
    zIndex: 20,
  },
});

const JurisdictionTreeViewItem = props => {
  const classes = useStyles(props);
  /* eslint-disable */
  useEffect(() => {
    if (props.withoutInput && props.currentCode === props.countryCode) {
      props.onExpand();
    }
  }, [props.currentCode, props.countryCode]);
  return (
    <div id={props.currentCode} className={classes.container}>
      {!props.isLast && <div className={classes.throughLine} />}

      <div
        className={classes.mainContent}
        onClick={props.onClick}
        role="button"
        styling="link"
      >
        {props.hasChildren && <div className={classes.hasChild} />}
        <div className={classes.horizontalLine} />
        <div className={classes.verticalLine} />
        <div
          className={cn(
            classes.title,
            props.selected ? classes.selected : null,
          )}
        >
          {props.title}
        </div>
        <div className={classes.description}>
          {props.description || "Description"}
        </div>
        {props.showExpand && (
          <IconButton
            disabled={props.isLoading}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              props.onExpand();
            }}
            className={classes.expandButton}
          >
            {props.expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
        {props.showEdit && (
          <IconButton
            disabled={props.isLoading}
            onClick={e => {
              props.onEdit && props.onEdit();
              e.stopPropagation();
              e.preventDefault();
            }}
            className={classes.editIcon}
          >
            <EditOutlined />
          </IconButton>
        )}
      </div>
      {props.node >= 0 && <div className={classes.node}>{props.node}</div>}
      {props.isLoading && (
        <div className={classes.loading}>
          <CircularProgress color="secondary" variant="indeterminate" />
        </div>
      )}
      {props.showExpand && !props.isLoading && props.content && (
        <Collapse in={props.expanded}>
          <div>{props.content}</div>
        </Collapse>
      )}
      {!props.showExpand && !props.isLoading && props.content && (
        <div>{props.content}</div>
      )}
      {props.form}
      {props.showAddButton && (
        <div className={classes.buttonContainer}>
          <Button
            variant="text"
            className={classes.addButton}
            startIcon={<AddCircleOutline />}
            onClick={props.onAddClick}
          >
            {props.addButtonText || "Добавить"}
          </Button>
        </div>
      )}
    </div>
  );
  /* eslint-enable */
};

JurisdictionTreeViewItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isLast: PropTypes.bool,
  hasChildren: PropTypes.bool,
  content: PropTypes.element,
  onClick: PropTypes.func,
  expanded: PropTypes.bool,
  onExpand: PropTypes.func,
  withoutInput: PropTypes.bool,
  node: PropTypes.number,
  isLoading: PropTypes.bool,
  showExpand: PropTypes.bool,
  form: PropTypes.element,
  showAddButton: PropTypes.bool,
  addButtonText: PropTypes.string,
  onAddClick: PropTypes.func,
  showEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  selected: PropTypes.bool,
};

export default JurisdictionTreeViewItem;
