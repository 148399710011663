import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import NotificationStack from "./NotificationStack";
import withGlobalPortal from "../portals/withGlobalPortal";

const enhancer = compose(
  withGlobalPortal(propsList => (
    <NotificationStack notifications={propsList} />
  )),
  mapPropsStream(propsStream => {
    const sideEffectsStream = propsStream
      .debounce(
        props =>
          props.autoHideDuration > 0
            ? Observable.timer(props.autoHideDuration)
            : Observable.timer(0),
      )
      .do(props => {
        if (props.open && props.autoHideDuration > 0) {
          props.onRequestClose();
        }
      })
      .startWith(null);

    return propsStream.combineLatest(sideEffectsStream, fp.identity);
  }),
);

Notification.propTypes = {
  action: PropTypes.node,
  autoHideDuration: PropTypes.number,
  onClick: PropTypes.func,
  onRequestClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  uid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.oneOf(["info", "success", "warning", "error"]),
};

function Notification() {
  return null;
}

export default enhancer(Notification);
