import sprintf from "sprintf";
import { format } from "date-fns";
import { api } from "../shared/BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const TIME_SLOT_AVAILABILITY_LIST_URL = `${
  API_ROOT_URL
}/admin/timeslot_availability`;
const TIME_SLOT_AVAILABILITY_CREATE_URL = `${
  API_ROOT_URL
}/admin/timeslot_availability`;
const TIME_SLOT_AVAILABILITY_ITEM_URL = `${
  API_ROOT_URL
}/admin/timeslot_availability/%s`;

type createTimeSlotAvailabilityRequest = {
  id: number,
  neighborhood: {
    description: string,
    id: number,
    name: string,
    phone: string,
  },
  supplier: {
    description: string,
    id: number,
    name: string,
    phone: string,
  },
  timeslot: {
    description: string,
    id: number,
    name: string,
    phone: string,
  },
  capacity: number,
  cutoff_time: string,
  courier_type: string,
};

const timeSlotAvailabilityMapper = (
  data: createTimeSlotAvailabilityRequest,
) => ({
  ...data,
  cutoff_time: format(data.cutoff_time, "HH:mm:ss"),
});

export const getTimeSlotAvailability = id =>
  api.getStream(sprintf(TIME_SLOT_AVAILABILITY_ITEM_URL, id));

export const removeTimeSlotAvailability = id =>
  api.delete(sprintf(TIME_SLOT_AVAILABILITY_ITEM_URL, id));
export const updateTimeSlotAvailability = body =>
  api.put(sprintf(TIME_SLOT_AVAILABILITY_CREATE_URL), {
    body: timeSlotAvailabilityMapper(body),
  });

export const createTimeSlotAvailability = (
  body: createTimeSlotAvailabilityRequest,
) =>
  api.post(TIME_SLOT_AVAILABILITY_CREATE_URL, {
    body: timeSlotAvailabilityMapper(body),
  });

export const getTimeSlotAvailabilityList = request =>
  api.getStream(TIME_SLOT_AVAILABILITY_LIST_URL, {
    params: request.getDefinedValues(),
  });
