import React from "react";
import _ from "lodash";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router";

const enhancer = compose(withRouter);

LinkWithNext.propTypes = {
  router: PropTypes.object,
  location: PropTypes.object,
  nextUrl: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

function LinkWithNext(props) {
  const { location } = props;
  const linkProps = _.omit(props, [
    "router",
    "routes",
    "location",
    "params",
    "nextUrl",
  ]);

  const toLocation = props.router.createLocation(props.to);

  toLocation.query.next =
    props.nextUrl || location.query.next || location.pathname + location.search;

  return <Link {...linkProps} to={toLocation} />;
}

export default enhancer(LinkWithNext);
