import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { compareLocations } from "../../../shared/helpers/UrlUtils";

const Navigate = withRouter(
  componentFromStream(propsStream =>
    propsStream
      .map(props => ({
        to: props.to,
        active: compareLocations(props.location, props.to),
        navigate: props.push ? props.router.push : props.router.replace,
      }))
      .filter(fp.isMatch({ active: false }))
      .do(props => props.navigate(props.to))
      .mapTo(null)
      .startWith(null),
  ),
);

Navigate.displayName = "Navigate";

Navigate.defaultProps = { push: true };

Navigate.propTypes = {
  push: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default Navigate;
