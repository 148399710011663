import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { TransitionUp } from "../../components/dialog/TransitionUp";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
  JUSTIFY_END,
} from "../../components/ui-core/FlexBox";
import { Check, GetApp, KeyboardArrowLeft, Print } from "@material-ui/icons";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import {
  BATCH_RECEIPT_URL,
  dots,
  ERROR,
  IS_DOWNLOADING,
  IS_PRINTING,
  LOADING,
  money,
  NOT_STARTED,
  SUCCESS,
} from "../../components/orders-core/AdminPostForm";
import { button } from "../../../shared/theme/main-theme";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  postBatchChargeItems,
  postBatchChargeItemsByDraft,
} from "../../api/admin/AdminBatchApi";
import fp from "lodash/fp";
import OrderPaymentTypes, {
  CARD,
  CASH,
  NON_PAYMENT,
} from "../../constants/OrderPaymentTypes";
import { ORDER_LIST_URL } from "../../constants/AdminPathConstants";
import {
  CUSTOMS_FEE,
  FORWARDING_FEE,
  INSURANCE_FEE,
  NOTIFICATION_FEE,
  SERVICE,
  SERVICE_ADDED,
  STAMP,
} from "../../constants/OrderChargeItemTypes";
import { getPDF } from "../../helpers/FormUtils";
import { get } from "lodash";
import { getAVVALUserId } from "../../reducers/ProfileReducer";
import PageLoading from "../../components/ui-core/PageLoading";

const enhancer = compose(
  connect(
    state => ({
      userIdAVVAL: getAVVALUserId(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

const useStyles = makeStyles({
  rightBorder: {
    paddingRight: "1rem",
    borderRight: "1px solid #EEEEEE",
    color: "rgba(38, 50, 56, 0.7)",
  },
  rightBorder2: {
    padding: "0 1rem",
    borderRight: "1px solid #EEEEEE",
    color: "rgba(38, 50, 56, 0.7)",
  },
  titleStyle: {
    color: "rgba(38, 50, 56, 0.7)",
  },
  padding: {
    padding: "0 .5rem",
    color: "rgba(38, 50, 56, 0.7)",
  },
  fullWidth: {
    flex: "0 1 auto",
    maxWidth: "68%",
  },
  price: {
    flex: "0 1 auto",
    paddingLeft: ".5rem",
  },
  formControl: {
    flex: "0 1 auto",
    marginLeft: "1rem",
    minWidth: "160px",
  },
  dots: {
    clear: "both",
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    flex: 1,
    padding: "0 .5rem",
  },
  content: {
    minWidth: "1200px",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
  },
  titleWithBorder: {
    borderBottom: "1px solid #eeeeee",
    paddingTop: 0,
  },
  titleWithBorder2: {
    borderBottom: "1px solid #eeeeee",
  },
  card: {
    padding: "1rem",
  },
  btn: {
    flex: "1 1 auto",
    margin: "0 .5rem",
  },
  btnWrapper: {
    margin: "0 -.5rem",
  },
  changeSize: {
    cursor: "pointer",
    color: button,
  },
  sizesParent: {
    marginTop: 16,
    "&>div": {
      flex: "1 1 auto",
      margin: "0 .25rem",
    },
    "&>:first-child": {
      margin: "0 .25rem 0 0",
    },
    "&>:last-child": {
      margin: "0 0 0 .25rem",
    },
  },
  h5: {
    color: "rgba(38, 50, 56, 0.7)",
    fontsize: "1rem",
    margin: ".25rem 0",
  },
  tab: {
    padding: 0,
    fontSize: "13px",
    marginLeft: "12px",
    marginRight: "12px",
    color: button,
    border: "none",
    outline: "none",
    cursor: "pointer",
    background: "none",
    fontFamily: "Blogger Sans",
    textTransform: "uppercase",
    transition: `color 250ms ease`,
    "&:hover": {
      color: button,
      textDecoration: "none",
    },
  },
  jmIcon: {
    border: "1px solid #bdbdbd",
    borderLeft: "none",
    borderRadius: 0,
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
});

const SubmitNotPaidPostsDialog = props => {
  const { getLocalisationMessage, postIds } = props;
  const classes = useStyles();
  const [isLoadingGet, setIsLoadingGet] = useState(false);
  const [isLoading, setIsLoading] = useState(NOT_STARTED);
  const [retPostIds, setRetPostIds] = useState([]);
  const [excludeBarcodes, setExcludeBarcodes] = useState([]);
  const [charges, setCharges] = useState([]);
  const [infoMessage, setInfoMessage] = useState("");
  const [batchId, setBatchId] = useState(false);
  const [stateBatchReceipt, setStateBatchReceipt] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: BATCH_RECEIPT_URL,
  });
  let payType = NON_PAYMENT;
  const totalSum = charges
    .filter(v => v.payment_type === CASH || v.payment_type === CARD)
    .reduce(
      (a, b) => (b.charge_type === STAMP ? a - b.charge : a + b.charge),
      0,
    );
  useEffect(() => {
    setIsLoadingGet(true);
    props
      .getBatchChargeItems(postIds)
      .then(res => {
        setIsLoadingGet(false);
        if (res.status === "success") {
          payType = get(res, "data.payment_type", NON_PAYMENT);
          const chargeItemsTemp = get(res, "data.charges", [])
            ? get(res, "data.charges", []).filter(
                x =>
                  x.charge_type === INSURANCE_FEE ||
                  x.charge_type === CUSTOMS_FEE ||
                  x.charge_type === FORWARDING_FEE ||
                  x.charge_type === SERVICE ||
                  x.charge_type === SERVICE_ADDED ||
                  x.charge_type === NOTIFICATION_FEE,
              )
            : [];
          const chargeItems = chargeItemsTemp
            ? chargeItemsTemp.filter(v => v.charge)
            : [];
          chargeItems.forEach(v => {
            // eslint-disable-next-line no-param-reassign
            if (!v.payment_type) v.payment_type = payType;
          });
          setCharges(chargeItems);
          setRetPostIds(get(res, "data.order_ids", []));
          setExcludeBarcodes(get(res, "data.excluded_barcodes", []));
          setInfoMessage(get(res, "data.message", ""));
        } else {
          props.showErrorMessage(res.message);
        }
      })
      .catch(error => {
        setIsLoadingGet(false);
        props.showErrorMessage(error);
      });
  }, []);

  const handleChange = (e, index) => {
    const temp = [...charges];
    temp[index].payment_type = e.target.value;
    setCharges(temp);
  };

  const saveNotPaidPosts = () => {
    setIsLoading(LOADING);
    if (props.batchId) {
      postBatchChargeItemsByDraft(props.batchId, {
        charges,
        parcel: {},
        batch_id: props.batchId,
      })
        .then(res => {
          setBatchId(fp.get("data.batch_id", res));
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_created",
              "Successfully Created",
            ),
          );
          if (props.refreshOrderList) {
            props.refreshOrderList();
          }

          setIsLoading(SUCCESS);
        })
        .catch(error => {
          setIsLoading(ERROR);
          props.showErrorMessage(error);
        });
    } else {
      postBatchChargeItems(postIds, {
        charges,
        parcel: {},
        order_ids: retPostIds,
      })
        .then(res => {
          setBatchId(fp.get("data.batch_id", res));
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_created",
              "Successfully Created",
            ),
          );
          if (props.refreshOrderList) {
            props.refreshOrderList();
          }

          setIsLoading(SUCCESS);
        })
        .catch(error => {
          setIsLoading(ERROR);
          props.showErrorMessage(error);
        });
    }
  };
  return (
    <Dialog
      TransitionComponent={TransitionUp}
      open={true}
      fullWidth={true}
      className={classes.content}
      maxWidth="xl"
    >
      {isLoadingGet && <PageLoading isLoading={true} />}
      <DialogTitle className={classes.titleWithBorder2}>
        <FlexBox direction="column" align={ALIGN_CENTER}>
          <h2 style={{ fontWeight: "bold" }}>
            {getLocalisationMessage("payment", "Payment")}
          </h2>
          <FlexBox direction="column" style={{ textAlign: "center" }}>
            <h5 className={classes.h5}>
              {getLocalisationMessage("number_of_items")} : {retPostIds.length}
            </h5>
            <h5
              className={classes.h5}
              style={{ color: !retPostIds.length > 0 && "#EB5757" }}
            >
              {infoMessage}
            </h5>
          </FlexBox>
        </FlexBox>
      </DialogTitle>
      <DialogContent>
        {retPostIds.length > 0 && (
          <React.Fragment>
            <FlexBox direction="column" flex={true}>
              {charges.map((v, index) => (
                <FlexBox
                  style={{ marginTop: "1rem" }}
                  align={ALIGN_CENTER}
                  justify={JUSTIFY_CENTER}
                  flex={true}
                  key={v.id}
                >
                  <span className={classes.fullWidth}>
                    {`${
                      v.charge_type === SERVICE_ADDED
                        ? v.name
                        : getLocalisationMessage(
                            v.charge_type === SERVICE
                              ? "package"
                              : v.charge_type === STAMP
                              ? "payment_done"
                              : v.charge_type,
                          )
                    } x ${v.count}`}
                  </span>
                  <span className={classes.dots}>{dots}</span>
                  <span
                    className={classes.price}
                    style={{ color: v.charge_type === STAMP && "#EB5757" }}
                  >
                    {v.charge_type === STAMP && "-"}{" "}
                    {money(v.charge) + getLocalisationMessage("som", "so'm")}
                  </span>
                  {isLoading !== SUCCESS && (
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>
                        {getLocalisationMessage("payment_type")}
                      </InputLabel>
                      <Select
                        disabled={props.isDisabledPaymentType && v.payment_type}
                        value={v.payment_type}
                        onChange={e => handleChange(e, index)}
                      >
                        {OrderPaymentTypes.map(i => (
                          <MenuItem key={i} value={i}>
                            {getLocalisationMessage(i)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </FlexBox>
              ))}
            </FlexBox>
            <FlexBox
              justify={JUSTIFY_END}
              style={{
                marginTop: "1rem",
                marginRight: "1rem",
              }}
            >
              <h5>
                {getLocalisationMessage("to_pay", "To pay")}: {money(totalSum)}
                {getLocalisationMessage("som", "so'm")}
              </h5>
            </FlexBox>
          </React.Fragment>
        )}

        {excludeBarcodes && excludeBarcodes.length > 0 && (
          <FlexBox
            style={{
              color: "red",
              gap: 10,
            }}
          >
            <span>
              {getLocalisationMessage(
                "please_accept_payment_for_these_orders_separately",
                "Please accept payment for these order(s) separately",
              )}{" "}
              :{" "}
            </span>{" "}
            {excludeBarcodes.map(excludeBarcode => (
              <span>{`${excludeBarcode} `} </span>
            ))}
          </FlexBox>
        )}
      </DialogContent>
      {retPostIds.length ? (
        <DialogActions>
          <FlexBox
            flex={true}
            justify={JUSTIFY_CENTER}
            style={{ paddingBottom: "1rem" }}
          >
            <FlexBox className={classes.btnWrapper}>
              {isLoading !== SUCCESS && (
                <CustomButton
                  disabled={isLoading === LOADING}
                  className={classes.btn}
                  onClick={() => props.closeDialog()}
                  startIcon={<KeyboardArrowLeft />}
                  variant={OUTLINED}
                  color={SECONDARY}
                >
                  {getLocalisationMessage("back", "Back")}
                </CustomButton>
              )}
              {isLoading === SUCCESS && (
                <FlexBox>
                  <CustomButton
                    className={classes.btn}
                    onClick={() =>
                      props.redirectToOrderList
                        ? props.setLocation(ORDER_LIST_URL)
                        : props.closeDialog()
                    }
                    startIcon={<KeyboardArrowLeft />}
                    variant={OUTLINED}
                    color={SECONDARY}
                  >
                    {getLocalisationMessage("go_to_list", "Go To List")}
                  </CustomButton>
                  <ButtonGroup
                    className={classes.btn}
                    variant={OUTLINED}
                    color={SECONDARY}
                  >
                    <CustomButton
                      onClick={() =>
                        getPDF(
                          stateBatchReceipt,
                          setStateBatchReceipt,
                          IS_PRINTING,
                          batchId,
                          props.showErrorMessage,
                        )
                      }
                      endIcon={
                        stateBatchReceipt[IS_PRINTING] ? (
                          <CircularProgress size={20} color="secondary" />
                        ) : (
                          <Print />
                        )
                      }
                    >
                      {getLocalisationMessage("receipt")}
                    </CustomButton>
                    <CustomButton
                      onClick={() =>
                        getPDF(
                          stateBatchReceipt,
                          setStateBatchReceipt,
                          IS_DOWNLOADING,
                          batchId,
                          props.showErrorMessage,
                        )
                      }
                    >
                      {stateBatchReceipt[IS_DOWNLOADING] ? (
                        <CircularProgress size={20} color="secondary" />
                      ) : (
                        <GetApp />
                      )}
                    </CustomButton>
                  </ButtonGroup>
                </FlexBox>
              )}
              {isLoading !== SUCCESS && (
                <CustomButton
                  disabled={isLoading === LOADING}
                  className={classes.btn}
                  variant={CONTAINED}
                  color={SECONDARY}
                  onClick={() => {
                    saveNotPaidPosts();
                  }}
                  endIcon={
                    isLoading === LOADING ? (
                      <CircularProgress size={20} color="secondary" />
                    ) : (
                      <Check />
                    )
                  }
                >
                  {getLocalisationMessage("accept_payment")}
                </CustomButton>
              )}
            </FlexBox>
          </FlexBox>
        </DialogActions>
      ) : (
        <DialogActions>
          <FlexBox
            flex={true}
            justify={JUSTIFY_CENTER}
            style={{ paddingBottom: "1rem" }}
          >
            <FlexBox className={classes.btnWrapper}>
              <CustomButton
                className={classes.btn}
                onClick={() => props.closeDialog()}
                startIcon={<KeyboardArrowLeft />}
                variant={OUTLINED}
                color={SECONDARY}
              >
                {getLocalisationMessage("back", "Back")}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </DialogActions>
      )}
    </Dialog>
  );
};
SubmitNotPaidPostsDialog.propTypes = {
  redirectToOrderList: PropTypes.bool,
  isDisabledPaymentType: PropTypes.bool,
  closeDialog: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  setLocation: PropTypes.func,
  postIds: PropTypes.array,
  batchId: PropTypes.number,
  userIdAVVAL: PropTypes.string,
  getBatchChargeItems: PropTypes.func,
  refreshOrderList: PropTypes.func,
};
export default enhancer(SubmitNotPaidPostsDialog);
