import {
  CONFIRM_CODE,
  REQUEST_CODE,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  isCodeConfirming,
  isCodeRequesting,
  isPasswordResetPending,
  isPasswordUpdatePending,
} from "../../shared/reducers/AuthReducer";
import { API_ROOT_URL } from "../../shared/constants/ApiConstants";

const VERIFY_URL = `${API_ROOT_URL}/user/verify`;
const PASSWORD_RESET_URL = `${API_ROOT_URL}/user/password/reset`;
const PASSWORD_REQUEST_RESET_URL = `${
  API_ROOT_URL
}/user/password/request-reset`;

export function requestVerificationCode() {
  return (dispatch, getState, api) => {
    if (isCodeRequesting(getState())) {
      return Promise.reject(
        new Error("Verification code is already requesting"),
      );
    }

    return dispatch({ type: REQUEST_CODE, payload: api.get(VERIFY_URL) });
  };
}

export function confirmVerificationCode(code) {
  return (dispatch, getState, api) => {
    if (isCodeConfirming(getState())) {
      return Promise.reject(
        new Error("Verification code is already confirming"),
      );
    }

    return dispatch({
      type: CONFIRM_CODE,
      payload: api.post(VERIFY_URL, { body: { value: code } }),
    });
  };
}

export function resetPasswordRequest(email, redirectUrl) {
  return (dispatch, getState, api) => {
    if (isPasswordResetPending(getState())) {
      return Promise.reject(new Error("Password reset is already pending"));
    }

    return dispatch({
      type: RESET_PASSWORD,
      payload: api.post(PASSWORD_REQUEST_RESET_URL, {
        body: { email, redirect_url: redirectUrl },
      }),
    });
  };
}

export function resetPassword(key, newPassword) {
  return (dispatch, getState, api) => {
    if (isPasswordUpdatePending(getState())) {
      return Promise.reject(new Error("Password is already updating"));
    }

    return dispatch({
      type: UPDATE_PASSWORD,
      payload: api.post(PASSWORD_RESET_URL, {
        body: { key, new_password: newPassword },
      }),
    });
  };
}
