import React from "react";
import _ from "lodash";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  AccountBox as ActionAccountBox,
  Lock as ActionLock,
} from "@material-ui/icons";
import AppSidebarLink from "./AppSidebarLink";
import AppSidebarBlock from "./AppSidebarBlock";
import { getSupplierName, getUserName } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  PROFILE_URL,
  SIGN_OUT_URL,
} from "../../constants/DashboardPathConstants";
import { getAppName } from "../../../shared/reducers/AppReducer";
import { SUPPLIER } from "../../../server/constants/AppTypes";

const enhancer = compose(
  useSheet({ root: { marginTop: "auto" } }),
  connect(state => {
    const appName = getAppName(state);
    const userName =
      appName && appName === SUPPLIER
        ? getSupplierName(state)
        : getUserName(state);

    return {
      userName,
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    };
  }),
);

AppSidebarProfileBlock.propTypes = {
  classes: PropTypes.object,
  userName: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

function AppSidebarProfileBlock(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <AppSidebarBlock
      title={getLocalisationMessage("profile", "Profile")}
      className={classes.root}
    >
      <AppSidebarLink to={PROFILE_URL} leftIcon={<ActionAccountBox />}>
        {_.truncate(props.userName, { length: 20 })}
      </AppSidebarLink>

      <AppSidebarLink to={SIGN_OUT_URL} leftIcon={<ActionLock />}>
        {getLocalisationMessage("sign_out", "Sign Out")}
      </AppSidebarLink>
    </AppSidebarBlock>
  );
}

export default enhancer(AppSidebarProfileBlock);
