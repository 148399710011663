import React, { useEffect, useState } from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  Card,
  Dialog,
  CardContent,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatText } from "../../helpers/FormatUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import { Save } from "@material-ui/icons";
import { getOrderListColumns } from "../../api/shared/ProfileApi";
import FormToggle from "../form/FormToggle";
import fp from "lodash/fp";

const enhancer = compose(
  withTheme,
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      minWidth: "500px",
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      minHeight: "auto",
    },
  }),
  connect(state => ({
    i18n: getMessages(state),
  })),
  reduxForm({
    form: "DynamicListColumnsForm",
    enableReinitialize: true,
  }),
  formValues({
    columns: "columns",
  }),
);

DynamicListColumnsForm.propTypes = {
  classes: PropTypes.object,
  onDismiss: PropTypes.func,
  submitting: PropTypes.bool,
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
  change: PropTypes.func,
  columns: PropTypes.array,
};

function DynamicListColumnsForm(props) {
  const { i18n, classes, columns } = props;

  const [fields, setFields] = useState([]);

  useEffect(() => {
    getOrderListColumns()
      .then(res => {
        if (res && res.data) {
          setFields(res.data);
        }
      })
      .catch(() => setFields([]));
  }, [props.open]);

  return (
    <Dialog open={props.open} onClose={props.onDismiss} maxWidth="md">
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get("dynamic_columns", " Dynamic Columns")}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <FlexBox element={<form autoComplete="off" />}>
          <PageLoading isLoading={props.submitting} />
          <Card
            style={{
              flex: "1 1 auto",
              margin: ".5rem",
              boxShadow:
                "rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px",
            }}
          >
            <CardContent expandable={true}>
              <FlexBox
                direction="column"
                justify="flex-start"
                align="flex-start"
              >
                <FormControlLabel
                  labelPlacement="start"
                  style={{
                    justifyContent: "space-between",
                  }}
                  control={
                    <Switch
                      style={{
                        paddingBottom: 15,
                      }}
                      checked={
                        fields.length ===
                        Object.keys(columns).filter(key => columns[key]).length
                      }
                      onChange={(e, x) =>
                        fields.map(field => props.change(`columns.${field}`, x))
                      }
                    />
                  }
                  label={i18n.get("all", "All")}
                />
                <hr />
                {fields &&
                  fields.map(field => (
                    <FormToggle
                      style={{
                        justifyContent: "space-between",
                      }}
                      labelPlacement="start"
                      key={field}
                      name={`columns.${field}`}
                      label={i18n.get(fp.toLower(field), formatText(field))}
                    />
                  ))}
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onDismiss} primary={true}>
          {i18n.get("dismiss", "Dismiss")}
        </Button>

        <CustomButton
          onClick={() => props.onSubmit(columns)}
          variant={CONTAINED}
          color={SECONDARY}
          startIcon={<Save />}
        >
          {i18n.get("reset", "Reset")}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(DynamicListColumnsForm);
