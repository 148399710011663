import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "./BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";
import DataListFilter from "../../helpers/DataListFilter";

const CURRENCY_ITEM_URL = `${API_ROOT_URL}/public/currency/%s`;
const CURRENCY_CHOOSE_URL = `${API_ROOT_URL}/public/currency/choose`;
const CURRENCY_CONVERT_URL = `${API_ROOT_URL}/public/currency/convert/%s/%s`;

const itemCache = new ObservableCache({
  loader: id =>
    api
      .get(sprintf(CURRENCY_ITEM_URL, id))
      .then(fp.flow(fp.get("data"), fromJS)),
});

const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(CURRENCY_CHOOSE_URL, { params: request.getDefinedValues() }),
});

export const getCachedCurrency = id => itemCache.get(id);
export const getCurrencyPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const getCurrencies = () =>
  api.get(CURRENCY_CHOOSE_URL, {
    params: {
      size: 9999,
    },
  });

export const convertCurrency = (currencyFrom, currencyTo) =>
  api.get(sprintf(CURRENCY_CONVERT_URL, currencyFrom, currencyTo));
