import React from "react";
import { Iterable } from "immutable";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { getIsRTL } from "../../reducers/LocalizationReducer";
import { makeStyles } from "@material-ui/core/styles";
import cx from "classnames";
import { ArrowDropDown } from "@material-ui/icons";

export const OUTLINED = "outlined";
export const STANDARD = "standard";
export const FILLED = "filled";
const NOT_SET_OPTION = {};
const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
  },
  formSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  disabled: {
    color: "green",
    "&>.Mui-disabled>fieldset": {
      borderStyle: "dashed",
    },
  },
}));
SelectFieldComponent.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  disabled: PropTypes.bool,
  autoWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxHeight: PropTypes.number,
  hintText: PropTypes.node,
  label: PropTypes.node,
  validate: PropTypes.func,
  formatInput: PropTypes.func,
  formatOption: PropTypes.func,
  compareOptions: PropTypes.func,
  size: PropTypes.string,
  options: PropTypes.instanceOf(Iterable).isRequired,
  isRTL: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  variant: PropTypes.oneOf([STANDARD, OUTLINED, FILLED]),
  margin: PropTypes.oneOf(["none", "normal"]),
  labelStyle: PropTypes.object,
  inputProps: PropTypes.object,
  defaultValue: PropTypes.any,
  immediatelyShowError: PropTypes.bool,
  ignoreError: PropTypes.bool,
};

SelectFieldComponent.defaultProps = {
  formatOption: fp.identity,
  compareOptions: isEqualData,

  size: "small",

  variant: OUTLINED,
  ignoreError: true,
};

export function SelectFieldComponent(props) {
  const classes = useStyles();
  const {
    input,
    meta,
    options,
    formatInput,
    formatOption,
    compareOptions,
    isRTL,
    label,
    fullWidth,
    className,
    size,
    style,
    variant,
    inputProps,
    labelStyle,
    disabled,
    margin,
    defaultValue,
    immediatelyShowError,
    ignoreError,
    ...custom
  } = props;

  let selectedOption = NOT_SET_OPTION;
  // if (input.name.startsWith("contentItems")) console.log("options", toJS(options));
  const children = options
    // eslint-disable-next-line no-shadow
    .map((option, key, options) => {
      const text = formatOption(option);
      if (!input.value && defaultValue === option) {
        selectedOption = option;
      }
      if (
        selectedOption === NOT_SET_OPTION &&
        compareOptions(option, input.value)
      ) {
        selectedOption = option;
      }
      if (!input.value && options.size === 1) {
        input.onChange(option);
        selectedOption = option;
      }
      return (
        <MenuItem key={key} value={option}>
          {" "}
          {formatInput ? formatInput(option) : text}{" "}
        </MenuItem>
      );
    });

  if (selectedOption === NOT_SET_OPTION) {
    selectedOption = input.value;
  }

  return (
    <FormControl
      disabled={disabled}
      className={cx(
        className,
        classes.formControl,
        {
          [classes.formSpacing]: margin === "normal",
        },
        disabled && classes.disabled,
      )}
      variant={variant}
      fullWidth={fullWidth}
      size={size}
    >
      <InputLabel id="select-label-id" style={labelStyle}>
        {label}
      </InputLabel>
      <Select
        {...custom}
        {...input}
        fullWidth={fullWidth}
        value={selectedOption}
        margin={margin}
        errorText={
          ignoreError
            ? immediatelyShowError
              ? meta.error
              : meta.touched && meta.error
            : ""
        }
        onBlur={fp.flow(fp.noop, input.onBlur)}
        onFocus={fp.flow(fp.noop, input.onFocus)}
        label={label}
        style={style}
        IconComponent={() =>
          custom.loading ? (
            <CircularProgress
              style={{
                position: "absolute",
                right: ".5rem",
                width: "1.75rem",
                height: "1.75rem",
              }}
              color="secondary"
            />
          ) : (
            <ArrowDropDown />
          )
        }
      >
        {children}
      </Select>
      {ignoreError ? (
        (meta.error && immediatelyShowError) || meta.touched ? (
          <FormHelperText error={Boolean(meta.error)}>
            {meta.error}
          </FormHelperText>
        ) : null
      ) : null}
    </FormControl>
  );
}

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
);

FormSelectField.propTypes = {
  name: PropTypes.string.isRequired,

  disabled: PropTypes.bool,
  autoWidth: PropTypes.bool,
  immediatelyShowError: PropTypes.bool,
  fullWidth: PropTypes.bool,
  ignoreError: PropTypes.bool,

  maxHeight: PropTypes.number,

  hintText: PropTypes.node,
  label: PropTypes.node,

  validate: PropTypes.func,

  formatInput: PropTypes.func,
  formatOption: PropTypes.func,

  compareOptions: PropTypes.func,
  className: PropTypes.string,

  onChange: PropTypes.func,

  options: PropTypes.instanceOf(Iterable).isRequired,
  isRTL: PropTypes.bool,

  style: PropTypes.object,
  inputProps: PropTypes.object,
  labelStyle: PropTypes.object,

  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  size: PropTypes.oneOf(["medium", "small"]),
  margin: PropTypes.oneOf(["none", "normal", "dense"]),
};

FormSelectField.defaultProps = {
  variant: "outlined",
  size: "small",
  ignoreError: true,
};

function FormSelectField(props) {
  return <Field {...props} component={SelectFieldComponent} />;
}

export default enhancer(FormSelectField);
