import { OrderedSet } from "immutable";

export const NO_COD = "NO_COD";
export const COD_NOT_ASSIGNED = "COD_NOT_ASSIGNED";
export const COD_TO_BE_COLLECTED = "COD_TO_BE_COLLECTED";
export const COD_COLLECTED = "COD_COLLECTED";
export const COD_TRANSFERRED = "COD_TRANSFERRED";
export const COD_WITH_WING = "COD_WITH_WING";
export const COD_WITH_WING_BANK = "COD_WITH_WING_BANK";
export const COD_WITH_SAAS_BANK = "COD_WITH_SAAS_BANK";
export const COD_TRANSFER_REJECTED = "COD_TRANSFER_REJECTED";
export const COD_WITHDRAW_REQUESTED = "COD_WITHDRAW_REQUESTED";
export const COD_PAID_TO_MERCHANT = "COD_PAID_TO_MERCHANT";
export const COD_WITHDRAW_REJECTED = "COD_WITHDRAW_REJECTED";
export const COD_PENDING = "COD_PENDING";
export const COD_WITH_DRIVER = "COD_WITH_DRIVER";
export const ALL_STATUSES_IN_FINANCE = "ALL_STATUSES_IN_FINANCE";

export default OrderedSet.of(
  NO_COD,
  COD_NOT_ASSIGNED,
  COD_TO_BE_COLLECTED,
  COD_COLLECTED,
  COD_TRANSFERRED,
  COD_WITH_WING,
  COD_WITH_WING_BANK,
  COD_TRANSFER_REJECTED,
  COD_WITHDRAW_REQUESTED,
  COD_PAID_TO_MERCHANT,
  COD_WITHDRAW_REJECTED,
  COD_PENDING,
);
