import { OrderedSet } from "immutable";

export const SAVER = "in_5_days";
export const NEXT_DAY = "next_day";
export const SAME_DAY = "same_day";
export const URGENT = "bullet";
export const FBS = "fbs";
export const STANDARD = "standard";
export const DEFAULT = "default";

export default OrderedSet.of(SAVER, NEXT_DAY, SAME_DAY, URGENT, FBS);
