import useSheet from "react-jss";
import { compose, nest } from "recompose";
import AppStatusObserver from "./AppStatusObserver";
import NavigateActionsProvider from "../router/NavigateActionsProvider";
import NotificationStackProvider from "../notifications/NotificationStackProvider";
import { primary1, primary2 } from "../../../shared/theme/main-theme";

const enhancer = compose(
  useSheet(
    {
      "@global": {
        "*, *::before, *::after": { boxSizing: "border-box" },
        "html, body, #content": {
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
          fontSize: "15px",
          lineHeight: "24px",
          backgroundColor: "#F4F6F8",
          fontFamily: '"Roboto", "sans-serif"',
          WebkitFontSmoothing: "antialiased",
          overflow: "auto !important",
        },
        a: {
          textDecoration: "none",
          color: primary1,
          "&:hover": { textDecoration: "underline", color: primary2 },
        },
        table: { "& tr, & tr, & td": { verticalAlign: "middle" } },
        "h1, h2, h3, h4, h5, h6": { margin: 0 },
        "::-ms-clear": { display: "none" },
        "input:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 1000px white inset",
        },
        ".full-height": {
          display: "flex",
          flexDirection: "column",
          "& > div": { flex: "1 1 0%" },
        },
        ".autoscrollcomponent": {
          display: "flex",
          flex: "1 1 0%",
        },
      },
    },
    { important: false },
  ),
);

const Root = nest(
  AppStatusObserver,
  NavigateActionsProvider,
  NotificationStackProvider,
);

Root.displayName = "Root";

export default enhancer(Root);
