import React from "react";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import {
  red,
  blue,
  grey,
  pink,
  teal,
  amber,
  green,
  indigo,
  orange,
  purple,
  blueGrey,
  deepOrange,
  deepPurple,
} from "@material-ui/core/colors";

const takeFirstChars = fp.flow(
  fp.trim,
  fp.words,
  fp.slice(0, 2),
  fp.map(fp.first),
  fp.join(""),
  fp.toUpper,
);
const getColor = fp.memoize(() =>
  fp.sample([
    red[500],
    pink[500],
    purple[500],
    deepPurple[500],
    indigo[500],
    blue[500],
    teal[500],
    green[500],
    amber[500],
    orange[500],
    deepOrange[500],
    grey[500],
    blueGrey[500],
  ]),
);

UserAvatar.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string,
  fallbackValue: PropTypes.string,
  style: PropTypes.object,
};
UserAvatar.defaultProps = { fallbackValue: "?" };

export default function UserAvatar({ name, fallbackValue, ...props }) {
  const hasImage = Boolean(props.src);
  const code = !hasImage && (takeFirstChars(name) || fallbackValue);

  return hasImage ? (
    <Avatar {...props} />
  ) : (
    <Avatar
      {...props}
      style={{ ...props.style, backgroundColor: getColor(code) }}
    >
      {code}
    </Avatar>
  );
}
