import sprintf from "sprintf";
import { api } from "./BaseApi";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

const UPLOAD_URL = `${API_ROOT_URL}/file/upload`;
const UPLOAD_VOICE_URL = `${API_V2_ROOT_URL}/dashboard/order/voice-sorting`;
const UPLOAD_PUBLIC_UR = `${API_ROOT_URL}/file/upload_public`;
const FILE_ITEM_URL = `${API_ROOT_URL}/file/attachment-url/%s`;
const FILE_ITEMS_URL = `${API_ROOT_URL}/file/multiple-attachment-url`;

export const uploadFile = file => api.postStream(UPLOAD_URL, { file });
export const uploadPublicFile = file =>
  api.postStream(UPLOAD_PUBLIC_UR, { file });
export const uploadVoiceFile = file =>
  api.postStream(UPLOAD_VOICE_URL, { file, params: { expireHour: 26280 } });

export const getFileUrl = id => api.getStream(sprintf(FILE_ITEM_URL, id));
export const getFileUrl2 = id => api.get(sprintf(FILE_ITEM_URL, id));

export const getAttachmentUrl = id => api.get(sprintf(FILE_ITEM_URL, id));

export const getFileUrlArray = request =>
  api.getStream(FILE_ITEMS_URL, { params: request.getDefinedValues() });

export const getFileUrlList = params => api.get(FILE_ITEMS_URL, { params });
