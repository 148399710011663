import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import spring from "react-motion/lib/spring";
import TransitionMotion from "react-motion/lib/TransitionMotion";
import { accent1 } from "../../../shared/theme/main-theme";
import { responsive } from "../../../shared/theme/jss-responsive";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet(
    {
      root: {
        zIndex: 2900,
        right: "12px",
        bottom: "12px",
        position: "fixed",
        minWidth: "320px",
        [responsive("$xs")]: { important: true, left: 0, right: 0, bottom: 0 },
      },
      item: {
        width: "100%",
        fontSize: "14px",
        position: "relative",
        boxSizing: "border-box",
        color: "#fff",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        willChange: "height, transform",
        transformOrigin: "center bottom",
        transitionProperty: "height, transform",
      },
      content: {
        flex: "1 1 0%",
        display: "flex",
        marginTop: "12px",
        padding: "14px 24px",
        alignItems: "center",
        borderRadius: "2px",
        backgroundColor: "#000000",
        [responsive("$xs")]: { important: true, borderRadius: 0 },
      },
      success: {
        backgroundColor: "rgba(51, 105, 30, 0.87)",
      },
      error: {
        backgroundColor: "rgba(183, 28, 28, 0.87)",
      },
      message: { flex: "1 1 0%" },
      action: {
        important: true,
        color: accent1,
        marginTop: "-8px",
        marginLeft: "16px",
        marginRight: "-16px",
        marginBottom: "-8px",
      },
    },
    { important: false },
  ),
);

NotificationStack.propTypes = {
  classes: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      action: PropTypes.node,
      onClick: PropTypes.func,
      children: PropTypes.node,
    }),
  ).isRequired,
};

const getOpenNotifications = fp.flow(
  fp.castArray,
  fp.filter("open"),
  fp.reverse,
);

const willEnter = () => ({ opacity: 0, translate: 120, maxHeight: 128 });
const willLeave = () => () => ({
  opacity: spring(0),
  translate: spring(120),
  maxHeight: spring(0),
});

function NotificationStack(props) {
  const items = getOpenNotifications(props.notifications);
  return (
    <TransitionMotion
      willEnter={willEnter}
      willLeave={willLeave}
      defaultStyles={items.map(item => ({
        data: item,
        key: String(item.uid),
        style: { opacity: 0, translate: 120, maxHeight: 128 },
      }))}
      styles={items.map(item => ({
        key: String(item.uid),
        data: item,
        style: {
          opacity: spring(1),
          translate: spring(0),
          maxHeight: spring(128),
        },
      }))}
    >
      {interpolatedStyles => (
        <div className={props.classes.root}>
          {interpolatedStyles.map(item => (
            <div
              role="button"
              tabIndex="-1"
              key={item.key}
              onClick={item.data.onClick}
              onKeyPress={item.data.onClick}
              className={props.classes.item}
              style={{
                maxHeight: item.style.maxHeight,
                transform: `translate3d(${item.style.translate}%, 0, 0)`,
              }}
            >
              <div
                className={cx(
                  props.classes.content,
                  props.classes[item.data.type],
                )}
              >
                <div
                  style={{ opacity: item.style.opacity }}
                  className={props.classes.message}
                >
                  {item.data.children}
                </div>

                {React.isValidElement(item.data.action) &&
                  React.cloneElement(item.data.action, {
                    style: { opacity: item.style.opacity },
                    className: cx(
                      item.data.action.props.className,
                      props.classes.action,
                    ),
                  })}
              </div>
            </div>
          ))}
        </div>
      )}
    </TransitionMotion>
  );
}

export default enhancer(NotificationStack);
