import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const CREATE_DRIVER_URL = `${API_ROOT_URL}/admin/driver`;
const DRIVER_ITEM_URL = `${API_ROOT_URL}/admin/driver/%s`;
const DRIVER_LIST_URL = `${API_ROOT_URL}/admin/drivers`;
const DRIVERS_LOCATION_URL = `${API_ROOT_URL}/admin/drivers/locations`;
const DRIVER_CHOOSE_URL = `${API_ROOT_URL}/admin/driver/choose`;
const DRIVER_CHECK_IN_NOTE_URL = `${API_ROOT_URL}/driver/check_in/note`;
const DRIVER_LIABILITY_URL = `${API_ROOT_URL}/admin/driver/%s/liability`;
const DRIVER_CHECK_IN_UPDATE_URL = `${API_ROOT_URL}/driver/check_in/update`;
const DRIVER_LOCATION_ITEM_URL = `${API_ROOT_URL}/admin/driver/%s/locations`;
const DRIVER_MARKETPLACE_URL = `${API_ROOT_URL}/admin/driver/%s/marketplaces`;
const DRIVER_ORDERS_LOCATION_URL = `${API_ROOT_URL}/admin/driver/%s/order/locations`;
const UPDATE_BOUNDARY_URL = `${API_ROOT_URL}/radar/boundary/`;
const CREATE_DRIVER_FINANCE_URL = `${API_ROOT_URL}/admin/driver/finance_settings`;
const GET_DRIVER_FINANCE_URL = `${API_ROOT_URL}/admin/driver/%s/finance_settings`;
const DRIVER_LIABILITY_TOTALS_URL = `${API_ROOT_URL}/admin/driver/%s/liability_totals`;
const CASHIER_LIABILITY_TOTALS_URL = `${API_ROOT_URL}/cashier/driver/%s/liability_totals`;
const STATUS_TOTALS_URL = `${API_ROOT_URL}/admin/driver/%s/status_totals`;
const CASHIER_LIABILITY_TOTALS_OPEN_URL = `${API_ROOT_URL}/cashier/driver/%s/open_liability_totals`;
const DRIVER_ATTENDANCE_REPORT_URL = `${API_ROOT_URL}/admin/driver/daily_attendance_report`;
const DRIVERS_BATCH_CREATE_IN_KPI_URL = `${API_ROOT_URL}/admin/drivers_batch/create_in_kpi`;
const DRIVERS_DASHBOARD_URL = `${API_ROOT_URL}/dashboard/admin/drivers_dashboard`;
const UPDATE_DRIVER_CASHIER_PSF_COD_CHARGE = `${API_ROOT_URL}/cashier/order/update/psf_cod_charge`;
const CALCULATE_LIABILITY_TOTAL_URL = `${API_ROOT_URL}/cashier/driver/total_liability_for_selected`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(DRIVER_ITEM_URL, id)),
});

const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(DRIVER_CHOOSE_URL, { params: request.getDefinedValues() }),
});

export const getDriver = id => itemCache.refresh(id);

export const saveMarketplaces = (id, marketplaceIds) =>
  api.post(sprintf(DRIVER_MARKETPLACE_URL, id), {
    body: {
      marketplace_ids: marketplaceIds,
    },
  });

export const getCachedDriver = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));

export const getDriverPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const createDriver = body => api.post(CREATE_DRIVER_URL, { body });

export const getDriverAttendance = (request: DataListFilter) =>
  api.getStream(DRIVER_ATTENDANCE_REPORT_URL, {
    params: request.getDefinedValues(),
  });

export const updateDriver = (id, body) =>
  api.put(sprintf(DRIVER_ITEM_URL, id), { body });

export const getDriverList = request =>
  api.getStream(DRIVER_LIST_URL, { params: request.getDefinedValues() });

export const getDriversDashboard = request =>
  api.getStream(DRIVERS_DASHBOARD_URL, { params: request.getDefinedValues() });

export type DriverCheckInRequest = {
  ids: number[],
  note: string,
  type: string,
};

export const getDriverLiability = (driverId, request: DataListFilter) =>
  api.getStream(sprintf(DRIVER_LIABILITY_URL, driverId), {
    params: request.getDefinedValues(),
  });

export const getDriverLiabilityTotals = driverId =>
  api.getStream(sprintf(DRIVER_LIABILITY_TOTALS_URL, driverId));

export const getCashierLiabilityTotals = (driverId, request: DataListFilter) =>
  api.getStream(sprintf(CASHIER_LIABILITY_TOTALS_URL, driverId), {
    params: request.getDefinedValues(),
  });

export const getStatusTotal = (driverId, request: DataListFilter) =>
  api.getStream(sprintf(STATUS_TOTALS_URL, driverId), {
    params: request.getDefinedValues(),
  });

export const getCashierOpenLiabilityTotals = driverId =>
  api.getStream(sprintf(CASHIER_LIABILITY_TOTALS_OPEN_URL, driverId));

// eslint-disable-next-line camelcase
export const updateCashierOrderUpdatePsfCodCharge = body =>
  api.put(UPDATE_DRIVER_CASHIER_PSF_COD_CHARGE, { body });

export const getCalculateLiabilityTotal = body =>
  api.post(CALCULATE_LIABILITY_TOTAL_URL, { body });

export const driverCheckInUpdate = (body: DriverCheckInRequest) =>
  api.put(DRIVER_CHECK_IN_UPDATE_URL, { body });

export const driverCheckInNote = (body: DriverCheckInRequest) =>
  api.put(DRIVER_CHECK_IN_NOTE_URL, { body });

export const getDriverFinanceSettings = driverId =>
  api.getStream(sprintf(GET_DRIVER_FINANCE_URL, driverId));

export type DriverFinanceSettings = {
  id: number,
  cost: number,
  driver: {
    id: number,
  },
  payment_type: string,
  vehicle_type: string,
  marketplace_id: number,
};

export const updateBoundary = body => api.put(UPDATE_BOUNDARY_URL, { body });
const UPDATE_BOUNDARY_URL_BY_ORDER = `${API_ROOT_URL}/radar/boundary/by_order/`;
export const updateBoundaryByOrder = body =>
  api.put(UPDATE_BOUNDARY_URL_BY_ORDER, { body });

export const saveDriverFinanceSettings = (body: DriverFinanceSettings) =>
  api.post(CREATE_DRIVER_FINANCE_URL, { body });

export const getDriverAttendanceReport = () =>
  api.getStream("/api/v1/operational_report/driver_attendance_excel");

export const getDriverLocationList = params =>
  api.getStream(DRIVERS_LOCATION_URL, { params, timeout: Infinity });

export const getDriverLocationItem = (driverId, request: DataListFilter) =>
  api.getStream(sprintf(DRIVER_LOCATION_ITEM_URL, driverId), {
    params: request.getDefinedValues(),
  });

export const getDriverOrdersLocation = driverId =>
  api.getStream(sprintf(DRIVER_ORDERS_LOCATION_URL, driverId));

export const createInKPI = driversIds =>
  api.post(DRIVERS_BATCH_CREATE_IN_KPI_URL, {
    params: { driversIds: driversIds.join(",") },
  });
