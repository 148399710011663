import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { withMapInstance } from "react-leflet-map-components";
import { isEqualData } from "../../helpers/DataUtils";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";

const enhancer = compose(
  withMapInstance(),
  mapPropsStream(propsStream => {
    const sideEffectsStream = mergeSideEffectStreams(
      propsStream
        .filter(props => props.map)
        .distinctUntilKeyChanged("size", isEqualData)
        .do(props => props.map.invalidateSize()),
    );

    return propsStream.merge(sideEffectsStream);
  }),
);

LeafletMapSizer.propTypes = {
  maps: PropTypes.object.isRequired,

  size: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
};

function LeafletMapSizer() {
  return null;
}

export default enhancer(LeafletMapSizer);
