import { Observable } from "rxjs";
import React, { useContext, useEffect } from "react";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import { connect } from "react-redux";
import Navigate from "../router/Navigate";
import { isEqualData } from "../../helpers/DataUtils";
import { SIGN_IN_URL } from "../../constants/DashboardPathConstants";
import { signOut } from "../../api/shared/AuthApi";
import {
  isTokenValid,
  persistToken,
  isTokenPersisting,
} from "../../../shared/reducers/AuthReducer";
import Preloader from "../../../shared/components/preloader/Preloader";
import { GlobalContext } from "../../containers/shared/ClientApp";

const enhancer = connect((state) => ({
  isTokenValid: isTokenValid(state),
  isTokenPersisting: isTokenPersisting(state),
}));

const SignOut = enhancer(
  componentFromStream((propsStream) => {
    const logOutStream = propsStream
      .filter((props) => props.isTokenValid)
      .distinctUntilChanged(fp.isEqual)
      .switchMap(() => signOut().catch(() => Observable.of({})))
      .distinctUntilChanged(isEqualData)
      .startWith({})
      .map(fp.flow(fp.update("pending", Boolean), fromJS))
      .distinctUntilChanged(isEqualData);

    const cleanCookiesStream = propsStream
      .distinctUntilChanged(isEqualData)
      .do((props) => {
        if (props.isTokenValid) {
          props.dispatch(persistToken(null));
        }
      });

    return propsStream.combineLatest(
      cleanCookiesStream,
      logOutStream,
      (props) =>
        props.isTokenValid || props.isTokenPersisting ? (
          <Preloader show={true} />
        ) : (
          <Navigate push={false} to={SIGN_IN_URL} />
        ),
    );
  }),
);
const SignOutWrapper = (props) => {
  const { setUW } = useContext(GlobalContext);
  useEffect(() => {
    localStorage.setItem("uw", null);
    setUW(null);
  }, []);
  return <SignOut {...props} />;
};
export default enhancer(SignOutWrapper);
