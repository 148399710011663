import _ from "lodash";
import { isDate, isValid as isDateValid } from "date-fns";
import fp from "lodash/fp";
import { isEmpty } from "./DataUtils";
import { parsePhone } from "./FormatUtils";
import AppAuthorities from "../../server/constants/AppAuthorities";
import {
  ROLE_ADMIN,
  ROLE_ADMIN_VIEWER,
} from "../../shared/constants/Authorities";

const emailRegExp = /\S+@\S+\.\S+/;
const textNumberRegExp = /^([a-zA-Z0-9]+)$/;
const urlRegExp = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/;
const hasNumber = /\d/;
const hasLowercase = /[a-z]/;
const hasUppercase = /[A-Z]/;

export const isBlankString = fp.flow(fp.trim, fp.isEmpty);

export const isValidCoordinates = x =>
  fp.isPlainObject(x) &&
  fp.isFinite(x.lat) &&
  (fp.isFinite(x.lon) || fp.isFinite(x.lng));

export const isPositive = fp.overEvery([fp.isFinite, fp.lte(0)]);
export const isPositiveInteger = fp.overEvery([fp.isInteger, fp.lte(0)]);

export const isValidId = fp.flow(
  fp.toNumber,
  fp.overEvery([fp.isInteger, fp.lt(0)]),
);

export const isValidIdForNeighborhood = fp.flow(
  fp.toNumber,
  fp.overEvery([fp.isInteger]),
);

export const isValidIdIncludingUnknown = fp.flow(
  fp.toNumber,
  fp.overEvery([
    fp.isInteger,
    fp.overSome([fp.lt(0), fp.flow(x => fp.isEqual(x, -1))]),
  ]),
);

export const isValidObjectId = fp.overEvery([
  fp.isPlainObject,
  fp.flow(fp.get("id"), isValidId),
]);

export const isValidObjectIdForNeighborhood = fp.overEvery([
  fp.isPlainObject,
  fp.flow(fp.get("id"), isValidIdForNeighborhood),
]);

export const isValidObjectIdIncludingUnknown = fp.overEvery([
  fp.isPlainObject,
  fp.flow(fp.get("id"), isValidIdIncludingUnknown),
]);

export const isValidObjectByCode = fp.overEvery([
  fp.isPlainObject,
  fp.flow(fp.get("id")),
]);

export const isValidDate = fp.overEvery([isDate, isDateValid]);

export function validateEmail(email) {
  if (_.isEmpty(email)) {
    return "Email is required";
  }

  if (!emailRegExp.test(email)) {
    return "Invalid email";
  }

  return null;
}

export function validateEmailLocalisation(email, getLocalisationMessage) {
  if (_.isEmpty(email)) {
    return (
      (getLocalisationMessage &&
        getLocalisationMessage("email_is_required", "Email is required")) ||
      "Email is required"
    );
  }

  if (!emailRegExp.test(email)) {
    return (
      (getLocalisationMessage &&
        getLocalisationMessage("invalid_email", "Invalid email")) ||
      "Invalid email"
    );
  }

  return null;
}

export function validateString(value, errorMessage = "This field is required") {
  return isBlankString(value) ? errorMessage : null;
}

export function validateStringForLocalisation(
  value,
  errorMessage = "This field is required",
  getLocalisationMessage,
) {
  if (!isBlankString(value)) return null;

  if (
    !isBlankString(
      getLocalisationMessage(
        "this_field_is_required",
        "This field is required",
      ),
    )
  ) {
    return getLocalisationMessage(
      "this_field_is_required",
      "This field is required",
    );
  }
  return errorMessage;
}

export function validatePhoneNumber(number) {
  const value = parsePhone(number);
  const length = _.size(value);

  if (length === 0) {
    return "Phone number is required";
  }

  if (length < 8) {
    return "Invalid phone number";
  }

  return null;
}

export function validateDetails(value) {
  if (isEmpty(value)) {
    return "Details is required";
  }

  return null;
}
export function validatePhoneNumberForLocalisation(
  number,
  getLocalisationMessage,
) {
  const value = parsePhone(number);
  const length = _.size(value);

  if (length === 0) {
    return getLocalisationMessage
      ? getLocalisationMessage(
          "phone_number_is_required",
          "Phone number is required",
        )
      : "Phone number is required";
  }

  if (length < 8) {
    return getLocalisationMessage
      ? getLocalisationMessage("invalid_phone_number", "Invalid phone number")
      : "Invalid phone number";
  }

  return null;
}
export function validatePhoneNumberFori18n(number, i18n) {
  const value = parsePhone(number);
  const length = _.size(value);

  if (length === 0) {
    return i18n.get("phone_number_is_required", "Phone number is required");
  }

  if (length < 8) {
    return i18n.get("invalid_phone_number", "Invalid phone number");
  }

  return null;
}

export function validateRoleAuthorities(values) {
  if (!values || _.isEmpty(values)) {
    return true;
  }
  return false;
}

export function validatePaymentType(value) {
  const length = _.size(value);

  if (length === 0) {
    return "Payment Type is required";
  }

  return null;
}
export function validatePaymentTypeForLocalisation(
  value,
  getLocalisationMessage,
) {
  const length = _.size(value);

  if (length === 0) {
    return "Payment Type is required";
  }

  if (length === 0) {
    return getLocalisationMessage(
      "payment_type_is_required",
      "Payment Type is required",
    );
  }
  return null;
}
export function validatePackageMenu(value) {
  const length = _.size(value);
  if (isEmpty(value)) {
    return "This field is required";
  }

  if (length === 0) {
    return "This field is required";
  }

  return null;
}
export function validatePackageMenuForLocalisation(
  value,
  getLocalisationMessage,
) {
  const length = _.size(value);
  if (isEmpty(value)) {
    return "This field is required";
  }

  if (length === 0) {
    return "This field is required";
  }

  if (length === 0) {
    return getLocalisationMessage("what_is_required", "This field is required");
  }
  return null;
}
export function validatePassword(password) {
  if (_.isEmpty(password)) {
    return "Password is required";
  }

  if (_.size(password) < 6) {
    return "Password must contain at least six characters";
  }

  if (!hasNumber.test(password)) {
    return "Password must contain at least one number";
  }

  if (!hasLowercase.test(password)) {
    return "Password must contain at least one lowercase character";
  }

  if (!hasUppercase.test(password)) {
    return "Password must contain at least one uppercase character";
  }

  return null;
}

export function validatePasswordForLocalisation(
  password,
  getLocalisationMessage,
) {
  if (_.isEmpty(password)) {
    return getLocalisationMessage(
      "password_is_required",
      "Password is required",
    );
  }

  if (_.size(password) < 6) {
    return getLocalisationMessage(
      "password_min_length_error",
      "Password must contain at least six characters",
    );
  }

  if (!hasNumber.test(password)) {
    return getLocalisationMessage(
      "password_must_contain_at_least_one_number",
      "Password must contain at least one number",
    );
  }

  if (!hasLowercase.test(password)) {
    return getLocalisationMessage(
      "password_must_contain_at_least_one_lowercase_character",
      "Password must contain at least one lowercase character",
    );
  }

  if (!hasUppercase.test(password)) {
    return getLocalisationMessage(
      "password_must_contain_at_least_one_uppercase_character",
      "Password must contain at least one uppercase character",
    );
  }

  return null;
}

export function validatePackage(packageValue) {
  if (isEmpty(packageValue)) {
    return "Package is required";
  }

  return null;
}

export function validatePasswordLocalised(
  password,
  emptyMessage = "Password is required",
  lengthMessage = "Password must contain at least six characters",
  hasNumberMessage = "Password must contain at least one number",
  hasLowercaseMessage = "Password must contain at least one lowercase character",
  hasUppercaseMessage = "Password must contain at least one uppercase character",
) {
  if (_.isEmpty(password)) {
    return emptyMessage;
  }

  if (_.size(password) < 6) {
    return lengthMessage;
  }

  if (!hasNumber.test(password)) {
    return hasNumberMessage;
  }

  if (!hasLowercase.test(password)) {
    return hasLowercaseMessage;
  }

  if (!hasUppercase.test(password)) {
    return hasUppercaseMessage;
  }

  return null;
}

export function validateURL(url) {
  if (_.isEmpty(url)) {
    return null;
  }

  if (!urlRegExp.test(url)) {
    return "Invalid URL";
  }

  return null;
}

export function isValidURL(url) {
  if (_.isEmpty(url)) {
    return false;
  }

  return urlRegExp.test(url);
}

export function validateReference(reference) {
  if (_.isEmpty(reference)) {
    return "Reference is required";
  }

  if (!textNumberRegExp.test(reference)) {
    return "Invalid Reference";
  }

  return null;
}
export function validateReferenceForLocalisation(
  reference,
  getLocalisationMessage,
) {
  if (_.isEmpty(reference)) {
    return getLocalisationMessage(
      "reference_is_required",
      "Reference is required",
    );
  }

  if (!textNumberRegExp.test(reference)) {
    return getLocalisationMessage("invalid_reference", "Invalid Reference");
  }

  return null;
}

export function validatePrefix(value) {
  if (_.isEmpty(value)) {
    return "Only alphanumeric characters are allowed here";
  }

  if (!textNumberRegExp.test(value)) {
    return "Only alphanumeric characters are allowed here";
  }

  return null;
}

export function validateRole(values) {
  if (!values || _.isEmpty(values)) {
    return "Role is required";
  }
  return null;
}
// export const excludeUserRoles = fp.flow(toPlainObject, fp.get("authorities"), toArray);

export const checkUserAuthority = (roles, app) => {
  const authority = AppAuthorities.get(app);
  switch (authority) {
    case ROLE_ADMIN:
      return Boolean(
        roles.find(
          role =>
            role.get("code") === ROLE_ADMIN ||
            role.get("code") === ROLE_ADMIN_VIEWER,
        ),
      );
    default:
      return roles.find(role => role.get("code") === authority);
  }
};
