import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { getValue } from "../../helpers/DataUtils";
import Dialog from "@material-ui/core/Dialog/Dialog";
import { getMessage } from "../../reducers/LocalizationReducer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getPushItem,
  updatePushItem,
} from "../../api/admin/AdminNotificationApi";
import FlexBox from "../ui-core/FlexBox";
import { Close, ReportProblem } from "@material-ui/icons";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import {
  ACT_MANAGEMENT_URL,
  ORDER_LIST_URL,
} from "../../constants/AdminPathConstants";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  formatActNotificationLocalisation,
  formatActReplyNotificationLocalisation,
  formatNotificationLocalisation,
} from "./LockerPostDrawer";
import {
  getPushLog,
  updatePushLog,
} from "../../reducers/PushNotificationReducer";

function NotificationDialog(props) {
  const { getLocalisationMessage, open, setOpen } = props;
  const [notification, setNotification] = useState({});

  useEffect(() => {
    if (open) {
      getPushItem(open).then(res => {
        if (res) {
          setNotification(res.data);
        }
      });
    }
  }, [open]);

  return (
    <Dialog
      modal={true}
      open={open}
      PaperProps={{
        style: { width: "550px" },
      }}
    >
      <DialogTitle style={{ padding: "16px 24px 0 24px" }}>
        <FlexBox flex={true} justify="space-between">
          <FlexBox align="center" style={{ gap: 8 }}>
            <ReportProblem style={{ color: "#FF9800" }} />
            <span>
              {getLocalisationMessage("notification", "Notification")}
            </span>
          </FlexBox>
          <FlexBox justify="flex-end">
            <IconButton
              onClick={() => {
                setOpen(null);
              }}
            >
              <Close color="error" />
            </IconButton>
          </FlexBox>
        </FlexBox>
      </DialogTitle>
      <DialogContent style={{ padding: "16px 24px 24px 24px" }}>
        <FlexBox style={{ gap: 16 }} direction="column">
          <Typography style={{ color: "#3F51B5", fontWeight: "500" }}>
            {notification.type === "ACT" || notification.type === "ACT_REPLY"
              ? getLocalisationMessage("attention", "Attention!")
              : notification.type === "NOTIFICATION"
              ? notification.subject
              : getLocalisationMessage(
                  "order_storage_limit_in_locker_post_is_reached",
                  "Order storage limit in LockerPost is reached",
                )}
          </Typography>
          <Typography variant="body2" component="p">
            {notification.type === "ACT"
              ? formatActNotificationLocalisation(
                  props.currentLanguage,
                  notification.username,
                  notification.act_number,
                  notification.dispatched_date,
                )
              : notification.type === "ACT_REPLY"
              ? formatActReplyNotificationLocalisation(
                  props.currentLanguage,
                  notification.username,
                  notification.act_number,
                  notification.dispatched_date,
                )
              : getValue(notification, "type") === "NOTIFICATION"
              ? getValue(notification, "message")
              : formatNotificationLocalisation(
                  props.currentLanguage,
                  notification.warehouse_name,
                  notification.barcode,
                  notification.dispatched_date,
                  notification.locker_post_address,
                  true,
                  props.showSuccessMessage,
                  getLocalisationMessage,
                )}
          </Typography>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        {notification.type === "ACT" || notification.type === "ACT_REPLY" ? (
          <CustomButton
            variant={OUTLINED}
            color={SECONDARY}
            onClick={() => {
              updatePushItem(getValue(notification, "id"))
                .then(res => {
                  if (res) {
                    props.showSuccessMessage(
                      getLocalisationMessage(
                        "successfully_updated",
                        "Successfully Updated",
                      ),
                    );
                    props.setLocation(ACT_MANAGEMENT_URL);
                    props.updatePushLog(!props.isRefresh);
                    setOpen(null);
                    props.onClose();
                  }
                })
                .catch(error => props.showErrorMessage(error));
            }}
          >
            {getLocalisationMessage("go_to_act", "Go to ACT")}
          </CustomButton>
        ) : (
          notification.type === "LOCKER_POST" && (
            <CustomButton
              variant={OUTLINED}
              color={SECONDARY}
              onClick={() => {
                props.setLocation(
                  updateQuery(ORDER_LIST_URL, {
                    orderBarcode: getValue(notification, "barcode"),
                  }),
                );
                setOpen(null);
              }}
            >
              {getLocalisationMessage("assign_to_driver", "Assign To Driver")}
            </CustomButton>
          )
        )}
        <CustomButton
          style={{ width: 100 }}
          variant={CONTAINED}
          color={SECONDARY}
          onClick={() => {
            updatePushItem(getValue(notification, "id"))
              .then(res => {
                if (res) {
                  props.showSuccessMessage(
                    getLocalisationMessage(
                      "successfully_updated",
                      "Successfully Updated",
                    ),
                  );
                  setTimeout(() => props.setTab("VIEWED"), 500);
                  setOpen(null);
                  props.setRefresh(prev => !prev);
                  props.updatePushLog(!props.isRefresh);
                }
              })
              .catch(error => props.showErrorMessage(error));
          }}
        >
          {" "}
          {getLocalisationMessage("ok", "OK")}{" "}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

NotificationDialog.propTypes = {
  getLocalisationMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  setLocation: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  open: PropTypes.number,
  setOpen: PropTypes.func,
  setTab: PropTypes.func,
  setRefresh: PropTypes.func,
  updatePushLog: PropTypes.func,
  onClose: PropTypes.func,
  currentLanguage: PropTypes.string,
  isRefresh: PropTypes.bool,
};

const mapStateToProps = state => ({
  isRefresh: getPushLog(state),
  getLocalisationMessage: (code, defaultMessage) =>
    getMessage(state, code, defaultMessage),
});

const mapDispatchToProps = {
  showErrorMessage,
  showSuccessMessage,
  updatePushLog,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDialog);
