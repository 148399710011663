import React from "react";

import fp from "lodash/fp";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import JurisdictionSelectTreeView from "../jurisdiction-tree/JurisdictionSelectTreeView";
import { getJurisdictionHierarchyById } from "../../api/admin/AdminJurisdictionsApi";
import { SearchOutlined } from "@material-ui/icons";
import { get, last } from "lodash";
import { UZ } from "../orders-core/AdminPostForm";
import { getMessages } from "../../reducers/LocalizationReducer";
import { connect } from "react-redux";
import { Map } from "immutable";

const useStyles = makeStyles({
  dialogContent: {
    padding: 10,
  },
  searchText: {
    marginTop: 10,
  },
});

function JurisdictionSelectField(props) {
  const {
    input,
    meta,
    parseOnBlur,
    focus,
    ignoreRtl,
    inputStyle,
    variant,
    size,
    withoutInput,
    withoutInputOpen,
    label,
    forPostcode,
    i18n,
    ...custom
  } = props;
  const countryCode = get(input, "value.country_code") || "UZ";
  const classes = useStyles();
  const [temp, setTemp] = React.useState();
  const [value, setValue] = React.useState();
  const [dialog, setDialog] = React.useState(false);

  const getJurisdictionLevel = data => {
    if (!data) return null;

    if (props.fetchAllLevels) {
      return data;
    }

    return data[data.length - 1];
  };

  const [search, setSearch] = React.useState();

  React.useEffect(() => {
    if (input.value) {
      let request = input.value;
      if (fp.isArray(request)) {
        request = fp.last(request);
      }

      if (request.id) {
        getJurisdictionHierarchyById(request.id).then(response => {
          setValue(response.data);
        });
      } else {
        setValue(undefined);
      }
    } else {
      setValue(undefined);
    }
  }, [input.value]);

  return (
    /* eslint-disable */
    <React.Fragment>
      <Dialog
        open={withoutInput ? withoutInputOpen : dialog}
        fullWidth={true}
        onClose={() => {
          setDialog(false);
          setSearch(undefined);
        }}
      >
        <DialogTitle>
          {i18n.get("select_jurisdiction", "Select Jurisdiction")}
          <div className={classes.searchText}>
            <TextField
              autoFocus={true}
              fullWidth={true}
              onChange={e => {
                setSearch(e.target.value);
              }}
              label={i18n.get("jurisdiction_search", "Jurisdiction Search")}
              variant="outlined"
              size="small"
              InputProps={{ endAdornment: <SearchOutlined /> }}
              value={search}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogContent}>
            <JurisdictionSelectTreeView
              withoutInput={withoutInput}
              countryCode={countryCode}
              onSelect={(value, hasChildren) => {
                const selectedItem = last(value);
                if (forPostcode) {
                  if (
                    Boolean(
                      selectedItem &&
                        selectedItem.country_code === UZ &&
                        selectedItem.level_id > 1,
                    ) ||
                    Boolean(
                      selectedItem &&
                        selectedItem.country_code !== UZ &&
                        selectedItem.level_name === "country",
                    )
                  ) {
                    if (hasChildren) {
                      setTemp(value && value.length ? value : null);
                    } else {
                      input.onChange(getJurisdictionLevel(value));
                      setValue([...value]);
                      setTemp(undefined);
                      if (value.length) {
                        if (withoutInput) {
                          props.closeDialog();
                        } else {
                          setDialog(false);
                        }
                      }
                    }
                  }
                }
                if (
                  Boolean(
                    selectedItem &&
                      selectedItem.country_code === UZ &&
                      selectedItem.level_id > 2,
                  ) ||
                  Boolean(
                    selectedItem &&
                      selectedItem.country_code !== UZ &&
                      selectedItem.level_name === "country",
                  )
                ) {
                  if (hasChildren) {
                    setTemp(value && value.length ? value : null);
                  } else {
                    input.onChange(getJurisdictionLevel(value));
                    setValue([...value]);
                    setTemp(undefined);
                    if (value.length) {
                      if (withoutInput) {
                        props.closeDialog();
                      } else {
                        setDialog(false);
                      }
                    }
                  }
                }
              }}
              searchText={search}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setTemp(undefined);
              setValue(undefined);
              if (withoutInput) {
                props.closeDialog();
              } else {
                setDialog(false);
              }
            }}
          >
            {i18n.get("cancel", "Cancel")}
          </Button>
          <Button
            color="secondary"
            disabled={!Boolean(temp)}
            onClick={() => {
              input.onChange(getJurisdictionLevel(temp));
              setValue([...temp]);
              setTemp(undefined);
              if (withoutInput) {
                props.closeDialog();
              } else {
                setDialog(false);
              }
            }}
          >
            {i18n.get("select", "Select")}
          </Button>
        </DialogActions>
      </Dialog>
      {!withoutInput && (
        <TextField
          {...input}
          {...custom}
          size={size || "small"}
          label={
            label ||
            `${i18n.get("jurisdiction_hierarchy", "Jurisdiction hierarchy")} *`
          }
          fullWidth={true}
          InputProps={{
            endAdornment: (
              <Button
                variant="contained"
                onClick={() => {
                  setDialog(true);
                }}
                style={{ marginRight: 10 }}
                size="small"
              >
                {" "}
                Выбрать{" "}
              </Button>
            ),
            style: {
              padding: "0",
            },
            readOnly: true,
          }}
          inputStyle={
            ignoreRtl
              ? fp.merge(inputStyle, {
                  direction: "rtl",
                  "text-align": "right",
                })
              : inputStyle
          }
          value={(value || []).map(c => c.name).join(" -> ")}
          variant={variant}
          error={Boolean(!meta.active && meta.touched && meta.error)}
          helperText={!meta.active && meta.touched && meta.error}
        />
      )}
    </React.Fragment>
    /* eslint-enable */
  );
}

JurisdictionSelectField.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  parseOnBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  focus: PropTypes.bool,
  ignoreRtl: PropTypes.bool,
  inputStyle: PropTypes.object,
  variant: PropTypes.string,
  label: PropTypes.node,
  size: PropTypes.oneOf(["medium", "small"]),
  margin: PropTypes.oneOf(["none", "dense", "normal"]),

  withoutInput: PropTypes.bool,
  withoutInputOpen: PropTypes.bool,
  fetchAllLevels: PropTypes.bool,
  forPostcode: PropTypes.bool,
  i18n: PropTypes.instanceOf(Map),
};

JurisdictionSelectField.defaultProps = {
  size: "small",
  variant: "outlined",
  fetchAllLevels: false,
};

function FormJurisdictionSelectField(props) {
  return <Field {...props} component={JurisdictionSelectField} />;
}

FormJurisdictionSelectField.propTypes = {
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  parseOnBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["medium", "small"]),
  focus: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  withoutInput: PropTypes.bool,
  fullWidth: PropTypes.bool,
  multiLine: PropTypes.bool,
  ignoreRtl: PropTypes.bool,
  floatingLabelStyle: PropTypes.object,
  hintStyle: PropTypes.object,

  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  label: PropTypes.node,
  helperText: PropTypes.node,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  forPostcode: PropTypes.bool,
  inputStyle: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
};

export default connect(state => ({ i18n: getMessages(state) }))(
  FormJurisdictionSelectField,
);
