import React, { useEffect, useState } from "react";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";
import _ from "lodash";
import { FILLED, OUTLINED, STANDARD } from "./FormTextField";

const SimpleAutocomplete = ({
  onChange,
  variant,
  label,
  initialId,
  getById,
  getOptionLabel,
  // getOptionSelected,
  renderOption,
  chooseAPI,
  filters = {},
  fullWidth,
  margin,
  hintText,
  replaceName,
  chooseData,
  isAddedServices,
  size,
  mappingData,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [filter, setFilter] = useState(filters);
  useEffect(async () => {
    if (!value && initialId) {
      const response = await getById(initialId);
      let data = await _.get(response, "data");
      if (mappingData) data = mappingData(data);
      if (data) {
        if (replaceName) setValue({ ...data, name: data[replaceName] });
        else setValue(data);
      }
    }
  }, [initialId]);
  useEffect(() => {
    let active = true;
    (async () => {
      setIsLoading(true);
      const response = await chooseAPI(filter);
      const data = await _.get(response, chooseData);
      if (active) setOptions(data);
      setIsLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [filter]);
  return (
    <Autocomplete
      size={size}
      value={value}
      fullWidth={fullWidth}
      onInputChange={(e, v) => {
        if (v) {
          setFilter({ ...filter, search: v });
        } else {
          onChange(v);
          setValue(null);
          setFilter({ ...filter, search: v });
        }
      }}
      onOpen={() => setFilter({ ...filter, search: "" })}
      onClose={() => {
        setIsLoading(false);
        if (isAddedServices) {
          setValue("");
          setFilter({ ...filter, search: "" });
        }
      }}
      onChange={(e, v) => {
        onChange(v);
        setValue(v);
      }}
      filterOptions={createFilterOptions({
        ignoreCase: true,
      })}
      // getOptionSelected={(v) => v[getOptionSelected]}
      getOptionLabel={v => fp.toString(v[getOptionLabel] || v)}
      renderOption={v =>
        renderOption ? renderOption(v) : fp.toString(v[getOptionLabel] || v)
      }
      options={options}
      loading={isLoading}
      renderInput={params => (
        <TextField
          {...params}
          autocomplete="new-password"
          variant={variant}
          margin={margin}
          label={label}
          placeholder={hintText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

SimpleAutocomplete.defaultProps = {
  filters: {
    page: 0,
    size: 20,
    status: "active",
  },
  getOptionLabel: "name",
  // renderOption: "name",
  getOptionSelected: "name",
  variant: OUTLINED,
  chooseData: "data",
  size: "medium",
};
SimpleAutocomplete.propTypes = {
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  hintText: PropTypes.node,
  label: PropTypes.node,
  variant: PropTypes.oneOf([OUTLINED, STANDARD, FILLED]),
  getById: PropTypes.func,
  chooseAPI: PropTypes.func,
  mappingData: PropTypes.func,
  initialId: PropTypes.number,
  onChange: PropTypes.func,
  filters: PropTypes.object,
  getOptionLabel: PropTypes.string,
  renderOption: PropTypes.string,
  getOptionSelected: PropTypes.string,
  replaceName: PropTypes.string,
  chooseData: PropTypes.string,
  isAddedServices: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
};
export default SimpleAutocomplete;
