import React from "react";
import {
  AccountBalance,
  AccountBalanceWallet,
  AddBox,
  Archive,
  Assessment,
  AssignmentInd,
  AssignmentReturned,
  BlurCircular,
  Bookmark,
  BusinessCenter,
  CallReceived,
  CardGiftcard,
  CloudUpload,
  Dashboard,
  DeviceHub,
  DriveEta,
  ErrorOutline,
  EventAvailable,
  FlightTakeoff,
  FolderOpen,
  LocationOn,
  Map,
  Message,
  MonetizationOn,
  People,
  PeopleOutline,
  Report,
  Search,
  Settings,
  Style,
  SwapCalls,
  SwapHoriz,
  SwapHorizontalCircle,
  Timeline,
  Unarchive,
  VerticalAlignBottom,
  Warning,
} from "@material-ui/icons";
import RadarIcon from "../components/icons/RadarIcon";
import AddressIcon from "../components/icons/AddressIcon";
import VehicleIcon from "../components/icons/VehicleIcon";
import TruckDelivery from "../components/icons/TruckDelivery";
import WarehouseIcon from "../components/icons/WarehouseIcon";
import CommunicationIcon from "../components/icons/CommunicationIcon";
import NeighborhoodsIcon from "../components/icons/NeighborhoodsIcon";
import AccountCardDetails from "../components/icons/AccountCardDetails";
import PackageVariantClosed from "../components/icons/PackageVariantClosed";
import TimeslotIcon from "../components/icons/TimeslotAvailabilityIcon";
import OrderIcon from "../components/icons/OrderIcon";
import FileIcon from "../components/icons/FileIcon";
import LocationIcon from "../components/icons/LocationIcon";

export function layoutIcon(icon, notAvailableValue = <Dashboard />) {
  switch (icon) {
    case "actionDashboard":
      return <Dashboard />;
    case "actionSearch":
      return <Search />;
    case "truckDelivery":
      return <TruckDelivery />;
    case "actionFlightTakeoff":
      return <FlightTakeoff />;
    case "fileFolderOpen":
      return <FolderOpen />;
    case "alertErrorOutline":
      return <ErrorOutline />;
    case "hardwareDeviceHub":
      return <DeviceHub />;
    case "actionTimeline":
      return <Timeline />;
    case "fileFileUpload":
      return <CloudUpload />;
    case "imageBlurCircular":
      return <BlurCircular />;
    case "communicationLocationOn":
      return <LocationOn />;
    case "packageVariantClosed":
      return <PackageVariantClosed />;
    case "actionAccountBalance":
      return <AccountBalance />;
    case "actionAccountBalanceWallet":
      return <AccountBalanceWallet />;
    case "actionAssignmentReturned":
      return <AssignmentReturned />;
    case "actionSwapHoriz":
      return <SwapHoriz />;
    case "notificationDriveEta":
      return <DriveEta />;
    case "contentReport":
      return <Report />;
    case "placesBusinessCenter":
      return <BusinessCenter />;
    case "socialPeopleOutline":
      return <PeopleOutline />;
    case "accountCardDetails":
      return <AccountCardDetails />;
    case "notificationEventAvailable":
      return <EventAvailable />;
    case "radarIcon":
      return <RadarIcon />;
    case "vehicleIcon":
      return <VehicleIcon />;
    case "warehouseIcon":
      return <WarehouseIcon />;
    case "timeslotIcon":
      return <TimeslotIcon />;
    case "neighborhoodsIcon":
      return <NeighborhoodsIcon />;
    case "socialPeople":
      return <People />;
    case "addressIcon":
      return <AddressIcon />;
    case "actionCardGiftcard":
      return <CardGiftcard />;
    case "communicationMessage":
      return <Message />;
    case "actionSettings":
      return <Settings />;
    case "actionBookmark":
      return <Bookmark />;
    case "communicationIcon":
      return <CommunicationIcon />;
    case "communicationCallReceived":
      return <CallReceived />;
    case "contentAddBox":
      return <AddBox />;
    case "mapsMap":
      return <Map />;
    case "actionAssignmentInd":
      return <AssignmentInd />;
    case "editorMonetizationOn":
      return <MonetizationOn />;
    case "alertWarning":
      return <Warning />;
    case "imageStyle":
      return <Style />;
    case "archive":
      return <Archive />;
    case "unArchive":
      return <Unarchive />;
    case "swapHorizontalCircle":
      return <SwapHorizontalCircle />;
    case "route_templates":
      return <SwapCalls />;
    case "report_warehouse":
      return <Assessment />;
    case "hybrid":
      return <VerticalAlignBottom />;

    default:
      return notAvailableValue;
  }
}

export function customerLayoutIcon(icon, notAvailableValue = <Dashboard />) {
  switch (icon) {
    case "truckDelivery":
      return <OrderIcon viewBox="0 0 18 18" />;
    case "fileFileUpload":
      return <FileIcon viewBox="0 0 18 18" />;

    case "communicationLocationOn":
      return <LocationIcon viewBox="0 0 18 18" />;

    default:
      return notAvailableValue;
  }
}
