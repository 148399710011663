import React, { useState } from "react";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
  JUSTIFY_START,
} from "../ui-core/FlexBox";
import FormTextField from "../form/FormTextField";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Close,
  DeleteSweep,
  SystemUpdateAlt,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import { FieldArray } from "redux-form";
import {
  createNotFalsyValidator,
  createObjectIdValidator,
  createOtherNameValidator,
} from "../../helpers/FormUtils";
import CustomButton, { OUTLINED, SECONDARY } from "../ui-core/CustomButton";
import { TransitionUp } from "../dialog/TransitionUp";
import FormProductCategoryAutoComplete from "../form/FormProductCategoryAutoComplete";

export const productCategory = {
  category_type: {},
  count: null,
  weight: null,
  other_name: null,
};

const ProductCategoryItem = ({
  fields,
  getLocalisationMessage,
  errorShow,
  setErrorShow,
  productCategories,
}) => (
  <FlexBox flex={true} direction="column">
    <FlexBox flex={true} direction="column" style={{ gap: 13 }}>
      {fields.map((item, index) => (
        <FlexBox
          key={index}
          flex={true}
          style={{ marginBottom: ".25rem", gap: 13 }}
        >
          <FlexBox direction="column" flex={true}>
            {index === 0 && (
              <h6>{`${getLocalisationMessage(
                "category_type",
                "Category Type",
              )} *`}</h6>
            )}
            <FlexBox direction="column">
              <FormProductCategoryAutoComplete
                immediatelyShowError={errorShow}
                name={`${item}.category_type`}
                validate={createObjectIdValidator(
                  getLocalisationMessage("this_field_is_required"),
                )}
                fullWidth={true}
              />
            </FlexBox>
          </FlexBox>
          <FlexBox direction="column" flex={true}>
            {index === 0 && (
              <h6>{`${getLocalisationMessage(
                "description",
                "Description",
              )} *`}</h6>
            )}
            <FormTextField
              immediatelyShowError={errorShow}
              fullWidth={true}
              disabled={productCategories[index].category_type.id !== 1}
              name={`${item}.other_name`}
              validate={createOtherNameValidator(
                productCategories[index].category_type.id === 1,
                getLocalisationMessage("this_field_is_required"),
              )}
            />
          </FlexBox>
          <FlexBox direction="column" flex={true}>
            {index === 0 && (
              <h6>{`${getLocalisationMessage("count", "Count")} *`}</h6>
            )}
            <FormTextField
              immediatelyShowError={errorShow}
              fullWidth={true}
              type="number"
              onFocus={event => {
                event.target.select();
              }}
              name={`${item}.count`}
              validate={createNotFalsyValidator(
                getLocalisationMessage("this_field_is_required"),
              )}
            />
          </FlexBox>
          <FlexBox direction="column" flex={true}>
            {index === 0 && (
              <h6>{`${getLocalisationMessage(
                "weight_g",
                "Weight (g)",
              )}  *`}</h6>
            )}
            <FormTextField
              immediatelyShowError={errorShow}
              fullWidth={true}
              type="number"
              onFocus={event => {
                event.target.select();
              }}
              name={`${item}.weight`}
              validate={createNotFalsyValidator(
                getLocalisationMessage("this_field_is_required"),
              )}
            />
          </FlexBox>

          <FlexBox direction="column" align="center" justify="center">
            {index === 0 && <FlexBox style={{ height: 30 }} />}
            <FlexBox direction="column" align="center">
              <IconButton size="small" onClick={() => fields.remove(index)}>
                <Close color="error" />
              </IconButton>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      ))}
    </FlexBox>

    <FlexBox
      style={{ marginTop: ".5rem" }}
      justify={JUSTIFY_START}
      align={ALIGN_CENTER}
    >
      <CustomButton
        variant={OUTLINED}
        onClick={() => {
          fields.push(productCategory);
          setErrorShow(false);
        }}
        startIcon={<AddCircleOutline />}
      >
        {getLocalisationMessage("add_more", "Add More")}
      </CustomButton>
    </FlexBox>
  </FlexBox>
);

ProductCategoryItem.propTypes = {
  fields: PropTypes.object,
  productCategories: PropTypes.array,
  getLocalisationMessage: PropTypes.func,
  setErrorShow: PropTypes.func,
  errorShow: PropTypes.bool,
};

const ProductCategoryDialog = props => {
  const {
    classes,
    isOpen,
    getLocalisationMessage,
    change,
    setIsOpen,
    isValidatedProductCategories,
    productCategories,
  } = props;

  const [errorShow, setErrorShow] = useState(false);
  return (
    <Dialog
      TransitionComponent={TransitionUp}
      open={isOpen}
      fullWidth={true}
      className={classes.content}
      maxWidth="xl"
    >
      <DialogTitle className={classes.titleWithBorder2}>
        <FlexBox flex={true}>
          <span>
            {getLocalisationMessage("product_categories", "Product categories")}
          </span>
        </FlexBox>
      </DialogTitle>
      <DialogContent>
        <FlexBox direction="column" flex={true}>
          <FieldArray
            name="productCategories"
            component={ProductCategoryItem}
            getLocalisationMessage={props.getLocalisationMessage}
            errorShow={errorShow}
            setErrorShow={setErrorShow}
            productCategories={productCategories}
          />
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <FlexBox
          flex={true}
          justify={JUSTIFY_CENTER}
          style={{ paddingBottom: "1rem" }}
        >
          <FlexBox className={classes.btnWrapper}>
            <CustomButton
              className={classes.btn}
              onClick={() => {
                change("productCategories", []);
                setIsOpen(false);
                setErrorShow(false);
              }}
              startIcon={<DeleteSweep />}
              variant={OUTLINED}
              color={SECONDARY}
            >
              {getLocalisationMessage("clear_and_close")}
            </CustomButton>
            <CustomButton
              className={classes.btn}
              endIcon={<SystemUpdateAlt />}
              variant={OUTLINED}
              color={SECONDARY}
              onClick={() => {
                if (isValidatedProductCategories) {
                  setIsOpen(false);
                } else {
                  setErrorShow(true);
                }
              }}
            >
              {getLocalisationMessage("save")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </DialogActions>
    </Dialog>
  );
};

ProductCategoryDialog.propTypes = {
  classes: PropTypes.object,
  isOpen: PropTypes.object,
  getLocalisationMessage: PropTypes.object,
  change: PropTypes.object,
  productCategories: PropTypes.object,
  setIsOpen: PropTypes.func,
  showErrorMessage: PropTypes.func,
  isValidatedProductCategories: PropTypes.bool,
};

export default ProductCategoryDialog;
