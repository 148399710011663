import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

const BATCH_ORDER_UPDATE_LIST = `${API_ROOT_URL}/admin/batch_updates`;
const HYBRID_BATCH_ORDER_UPDATE_LIST = `${API_ROOT_URL}/admin/hybrid_batch_updates`;
const HYBRID_BATCH_ORDERS_UPDATE = `${API_ROOT_URL}/admin/orders/hybrid_batch_update`;
const BATCH_ORDER_UPDATE_ITEM = `${API_ROOT_URL}/admin/batch_update/%s`;
const HYBRID_BATCH_ORDER_UPDATE_ITEM = `${API_ROOT_URL}/admin/hybrid_batch_update/%s`;
const GET_BATCH_ORDER_ITEMS = `${API_ROOT_URL}/admin/batch_update/multi/%s`;
const GET_BATCH_CHARGE_ITEMS = `${API_V2_ROOT_URL}/admin/order/batch_payment/%s`;
const SINGLE_PAYMENT_URL = `${API_V2_ROOT_URL}/admin/order/payment/%s`;
const GET_BATCH_CHARGE_ITEMS_BY_DRAFT = `${API_V2_ROOT_URL}/admin/order_import/batch_payment/%s`;
const POST_BATCH_CHARGE_ITEMS = `${API_V2_ROOT_URL}/admin/order/batch_payment`;
const POST_BATCH_CHARGE_ITEMS_BY_DRAFT = `${API_V2_ROOT_URL}/admin/order_import/batch_payment`;
const BATCH_UPDATE_TOTALS = `${API_ROOT_URL}/admin/batch_updates_totals`;

const SUGGEST_SUPPLIER_URL = `${API_ROOT_URL}/admin/suggest_supplier/%s`;
export const MANIFEST_URL = `${API_V2_ROOT_URL}/manifest`;
export const DRIVER_MANIFEST_URL = `${API_V2_ROOT_URL}/driver_manifest`;
export const COURIER_MANIFEST_URL = `${API_V2_ROOT_URL}/courier_manifest`;
export const FORM10_URL = `${API_V2_ROOT_URL}/form10`;
export const FORM16_URL = `${API_V2_ROOT_URL}/form16`;
export const FORM24_URL = `${API_V2_ROOT_URL}/form24`;
export const BARCODES_PDF = `${API_V2_ROOT_URL}/barcode_printable`;
export const INVALIDATE_URL = `${API_ROOT_URL}/admin/orders/batch_update/invalidate`;
export const CATEGORIES_CHOOSE_URL = `${API_ROOT_URL}/admin/batch/categories/choose`;
export const COMPLETE_ROUTE = `${API_ROOT_URL}/admin/batch_update/complete`;
export const HOLD_ON_CUSTOMS_URL = `${API_V2_ROOT_URL}/hold_on_customs`;
export const CUSTOMS_INSPECTION_URL = `${API_V2_ROOT_URL}/customs_inspection`;
export const HYBRID_SIMPLE_URL = `${API_V2_ROOT_URL}/hybrid_manifest`;
export const RETURNED_MANIFEST_URL = `${API_V2_ROOT_URL}/returning_manifest`;

export const getCategories = () =>
  api.get(CATEGORIES_CHOOSE_URL, {
    params: {
      custom: true,
      size: 200,
    },
  });

export const getBatchOrderUpdateList = (request: DataListFilter) =>
  api.getStream(BATCH_ORDER_UPDATE_LIST, {
    params: request.getDefinedValues(),
  });
export const getBatchOrderUpdateList2 = params =>
  api.get(BATCH_ORDER_UPDATE_LIST, {
    params,
  });

export const getHybridBatchOrderUpdateList = params =>
  api.get(HYBRID_BATCH_ORDER_UPDATE_LIST, {
    params,
  });

export const updateOrderReason = body =>
  api.post(HYBRID_BATCH_ORDERS_UPDATE, {
    body,
  });

export const getBatchOrderUpdateItem = id =>
  api.getStream(sprintf(BATCH_ORDER_UPDATE_ITEM, id));

export const getHybridBatchOrderUpdateItem = id =>
  api.getStream(sprintf(HYBRID_BATCH_ORDER_UPDATE_ITEM, id));
export const getBatchOrderUpdateItem2 = id =>
  api.get(sprintf(BATCH_ORDER_UPDATE_ITEM, id));

export const getSuggestSupplier = id =>
  api.getStream(sprintf(SUGGEST_SUPPLIER_URL, id));

export const getBatchOrdersItems = ids =>
  api.getStream(sprintf(GET_BATCH_ORDER_ITEMS, ids));

export const getBatchChargeItems = postIds =>
  api.get(sprintf(GET_BATCH_CHARGE_ITEMS, postIds));

export const getBatchChargeItemsByDraft = batchId =>
  api.get(sprintf(GET_BATCH_CHARGE_ITEMS_BY_DRAFT, batchId));

export const updateSinglePayment = id =>
  api.put(sprintf(SINGLE_PAYMENT_URL, id));

export const postBatchChargeItems = (ids, body) =>
  api.post(sprintf(POST_BATCH_CHARGE_ITEMS, ids), { body });

export const postBatchChargeItemsByDraft = (ids, body) =>
  api.post(sprintf(POST_BATCH_CHARGE_ITEMS_BY_DRAFT, ids), { body });
export const completeRoute = id =>
  api.post(COMPLETE_ROUTE, {
    body: {
      batch_ids: [id],
    },
  });

export const getBatchTotals = () => api.get(BATCH_UPDATE_TOTALS);
