import React from "react";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resetPassword } from "../../actions/AuthActions";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import ChangePasswordForm from "../../components/auth/ChangePasswordForm";

const enhancer = connect(
  state => ({
    i18n: getMessages(state),
  }),
  {
    resetPassword,
    showErrorMessage,
    showSuccessMessage,
  },
);

ChangePasswordContainer.propTypes = {
  router: PropTypes.object,
  params: PropTypes.object,
  resetPassword: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function ChangePasswordContainer(props) {
  return (
    <ChangePasswordForm
      initialValues={{ key: props.params.key }}
      onSubmit={values =>
        props
          .resetPassword(values.key, values.password)
          .catch(ResponseError.throw)
      }
      onSubmitSuccess={() => {
        props.router.replace("/");
        props.showSuccessMessage(
          props.i18n.get(
            "your_new_password_set_up_and_you_are_ready_to_sign_in",
            "Your new password set up and you are ready to sign in.",
          ),
        );
      }}
      onSubmitFail={props.showErrorMessage}
    />
  );
}

export default enhancer(ChangePasswordContainer);
