import fp from "lodash/fp";

function optionsToQuery(options) {
  const { minWidth, maxWidth } = options;

  const query = [];

  if (!fp.isUndefined(minWidth)) {
    query.push(`(min-width: ${minWidth}px)`);
  }

  if (!fp.isUndefined(maxWidth)) {
    query.push(`(max-width: ${maxWidth}px)`);
  }

  return query.join(" and ");
}

const breakpoints = { xs: 480, sm: 960, md: 1280, lg: 1920 };

const mediaQueries = {
  $xs: optionsToQuery({ maxWidth: breakpoints.xs }),
  $sm: optionsToQuery({
    minWidth: breakpoints.xs + 1,
    maxWidth: breakpoints.sm,
  }),
  $md: optionsToQuery({
    minWidth: breakpoints.sm + 1,
    maxWidth: breakpoints.md,
  }),
  $lg: optionsToQuery({
    minWidth: breakpoints.md + 1,
    maxWidth: breakpoints.lg,
  }),
  $xl: optionsToQuery({ minWidth: breakpoints.lg + 1 }),
};

export const responsive = fp.flow(
  fp.trim,
  fp.split("or"),
  fp.map(
    fp.flow(
      fp.split("and"),
      fp.map(fp.flow(fp.trim, q => mediaQueries[q])),
      fp.compact,
      fp.join(" and "),
    ),
  ),
  fp.compact,
  fp.join(", "),
  q => `@media ${q}`,
);
