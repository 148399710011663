import { login as facebookLogin } from "../helpers/FacebookHelper";
import {
  SIGN_IN,
  SIGN_UP,
  isSigningIn,
  isSigningUp,
  FACEBOOK_SIGN_IN,
  isFacebookSigningIn,
} from "../../shared/reducers/AuthReducer";
import { API_ROOT_URL } from "../../shared/constants/ApiConstants";
import { api as baseAPI } from "../api/shared/BaseApi";

const SIGN_UP_URL = `${API_ROOT_URL}/customer/register`;
const SIGN_IN_URL = `${API_ROOT_URL}/customer/authenticate`;
const SIGN_IN_URL_CUSTOMER = `${API_ROOT_URL}/customer/authenticate?context=customer`;
const FACEBOOK_PROFILE_URL = `${API_ROOT_URL}/customer/facebook/profile`;
const FACEBOOK_SIGN_UP_URL = `${API_ROOT_URL}/customer/facebook/register`;
const FACEBOOK_SIGN_IN_URL = `${API_ROOT_URL}/customer/facebook/authenticate`;
const SEND_CODE = `${API_ROOT_URL}/user/verify_confirmation_code`;
const SEND_CONFIRMATION_CODE = `${API_ROOT_URL}/send_confirmation_code`;
const VERIFY_CONFIRMATION_CODE = `${API_ROOT_URL}/verify_confirmation_code`;

export function signUp(request, nextUrl) {
  return (dispatch, getState, api) => {
    if (isSigningUp(getState())) {
      return Promise.reject(new Error("Already signing up"));
    }

    return dispatch({
      meta: { next: nextUrl, username: request },
      type: SIGN_UP,
      payload: api.post(SIGN_UP_URL, {
        body: request,
        headers: { "X-app-type": "Web" },
      }),
    });
  };
}

export function signIn({ username, password, isCustomer }, nextUrl) {
  return (dispatch, getState, api) => {
    if (isSigningIn(getState())) {
      return Promise.reject(new Error("Already signing in"));
    }
    return dispatch({
      meta: { next: nextUrl },
      type: SIGN_IN,
      payload: api.post(isCustomer ? SIGN_IN_URL_CUSTOMER : SIGN_IN_URL, {
        body: { username, password, remember_me: true },
      }),
    });
  };
}

export function facebookSignIn() {
  return (dispatch, getState, api) => {
    if (isFacebookSigningIn(getState())) {
      return Promise.reject(new Error("Already signing in to Facebook"));
    }

    return dispatch({
      meta: { notify: true },
      type: FACEBOOK_SIGN_IN,
      payload: facebookLogin().then(({ accessToken }) =>
        api.post(FACEBOOK_SIGN_IN_URL, { body: { access_token: accessToken } }),
      ),
    });
  };
}

export function facebookAccountFetch(nextUrl) {
  return (dispatch, getState, api) => {
    if (isFacebookSigningIn(getState())) {
      return Promise.reject(new Error("Already fetching Facebook account"));
    }

    return dispatch({
      meta: { notify: true, next: nextUrl },
      type: FACEBOOK_SIGN_IN,
      payload: facebookLogin().then(({ accessToken }) =>
        api.post(FACEBOOK_PROFILE_URL, { body: { access_token: accessToken } }),
      ),
    });
  };
}

export function facebookSignUp(customer, nextUrl) {
  return (dispatch, getState, api) => {
    if (isFacebookSigningIn(getState())) {
      return Promise.reject(new Error("Already Signing Up to Facebook"));
    }

    return dispatch({
      meta: { url: nextUrl, notify: true },
      type: FACEBOOK_SIGN_IN,
      payload: facebookLogin().then(({ accessToken }) =>
        api.post(FACEBOOK_SIGN_UP_URL, {
          body: { customer, access_token: accessToken },
        }),
      ),
    });
  };
}

export const sendCode = (code, phone) =>
  baseAPI.post(SEND_CODE, {
    body: {
      code,
      phone,
    },
  });
export const verifyRegisterCode = (code, phone) =>
  baseAPI.post(VERIFY_CONFIRMATION_CODE, {
    body: {
      code,
      phone,
    },
  });

export const getCodeRegister = (username) =>
  baseAPI.post(`${SEND_CONFIRMATION_CODE}?username=${username}`);
