import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Drawer } from "@material-ui/core";

const enhancer = compose(
  useSheet({
    root: {
      inset: "revert",
      "& > div": { top: "64px", bottom: 0, height: "auto" },
    },
  }),
);

AppLayoutDrawer.propTypes = {
  sheet: PropTypes.object,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onRequestChange: PropTypes.func.isRequired,
};

function AppLayoutDrawer(props) {
  const {
    sheet: { classes },
  } = props;

  return (
    <Drawer
      className={cx(classes.root, props.className)}
      open={props.open}
      onClose={() => props.onRequestChange(false)}
    >
      {props.children}
    </Drawer>
  );
}

export default enhancer(AppLayoutDrawer);
