import _ from "lodash";
import { fromJS, List } from "immutable";
import fp from "lodash/fp";
import { isEmpty } from "./DataUtils";
import { formatText } from "./FormatUtils";
import { getInitialStateForHelper } from "./InitialState";
import {
  COD_COLLECTED,
  COD_PAID_TO_MERCHANT,
  COD_PENDING,
  COD_TO_BE_COLLECTED,
  COD_TRANSFER_REJECTED,
  COD_TRANSFERRED,
  COD_WITH_SAAS_BANK,
  COD_WITH_WING,
  COD_WITH_WING_BANK,
  COD_WITHDRAW_REJECTED,
  COD_WITHDRAW_REQUESTED,
  NO_COD,
} from "../constants/CODStatus";
import { CASH_CLIENT, CLIENT_MERCHANT } from "../constants/ClientType";
import { COD_INQUIRY, HR_RELATED_INQUIRY } from "../constants/ReasonTypes";
import {
  FBS,
  NEXT_DAY,
  SAME_DAY,
  SAVER,
  STANDARD,
  URGENT,
} from "../constants/CourierTypes";
import { ALL_SHIPPING, REGULAR, REVERSE } from "../constants/LogisticTypes";
import { ALL, CASH_CLIENTS, MERCHANTS } from "../constants/MerchantTypes";
import {
  CHAT_CALL_FROM_CLIENT,
  SOCIAL_MEDIA_GOOGLE_APP_GOOGLE_PLAY,
  SOUQ_CUSTOMER_SUPPORT_EMAIL,
} from "../constants/InquiriesTypes";
import {
  ARRIVED,
  ASSIGNED,
  CLOSED,
  FAILED,
  ON_THE_WAY,
  PARTIALLY_COMPLETED,
  UNASSIGNED,
} from "../constants/JobStatusCodes";
import { RECIPIENT } from "../constants/OrderPayerTypes";
import {
  DELIVERY_ATTEMPT_FAILED,
  PICKUP_ATTEMPT_FAILED,
} from "../constants/NoteTypeCategory";
import {
  ACCEPTED,
  ARRIVED_TO_DELIVERY_ADDRESS,
  ASSIGNED_TO_COURIER,
  BAD_RECIPIENT_ADDRESS,
  BAD_WEATHER_DURING_DELIVERY,
  CANCELLED,
  COD_NOT_READY,
  COLLECTION_ARRANGED_OR_REQUESTED,
  COMPLETED,
  DELIVERY_ATTEMPT,
  DELIVERY_DELAY_DUE_TO_LATE_BOOKING,
  DELIVERY_FAILED,
  DELIVERY_REJECTED,
  DELIVERY_SCHEDULED,
  DESTROYED_ON_CUSTOMER_REQUEST,
  DISPATCHED,
  DRIVER_REJECTED,
  FIRST_DELIVERY_ATTEMPT,
  FIRST_PICKUP_ATTEMPT,
  FUTURE_DELIVERY_REQUESTED,
  ID_OR_DOCUMENT_REQUIRED_MISSING,
  IN_SORTING_FACILITY,
  IN_TRANSIT,
  IN_TRANSIT_TO_SUPPLIER,
  INCOMPLETE_PARCEL,
  LOST_OR_DAMAGED,
  NO_CAPACITY_FOR_DELIVERY,
  NO_TIME_FOR_DELIVERY,
  ON_HIS_WAY,
  ON_HOLD,
  OTHER_DELIVERY_ATTEMPT,
  OTHER_PICKUP_ATTEMPT,
  OUT_FOR_DELIVERY,
  OUT_FOR_RETURN,
  OUT_OF_DELIVERY_AREA,
  PENDING_ASSIGN_TO_COURIER,
  PICKED_UP,
  PICKED_UP_AUTO,
  PREPARED_FOR_TRANSIT,
  RECIPIENT_ADDRESS_CHANGE_REQUESTED,
  RECIPIENT_MOBILE_NO_RESPONSE,
  RECIPIENT_MOBILE_SWITCHED_OFF,
  RECIPIENT_MOBILE_WRONG,
  RECIPIENT_NAME_MISSING,
  RECIPIENT_NOT_AVAILABLE,
  REJECTED,
  RETURNED_TO_ORIGIN,
  RETURNING_TO_ORIGIN,
  SECOND_DELIVERY_ATTEMPT,
  SECOND_PICKUP_ATTEMPT,
  THIRD_DELIVERY_ATTEMPT,
  THIRD_PICKUP_ATTEMPT,
  TO_BE_RETURNED,
  TRANSITED_TO_SUPPLIER,
  UNABLE_TO_ACCESS_RECIPIENT_PREMISES,
  WRONG_SHIPMENT,
} from "../constants/OrderStatusCodes";
import {
  ADVANCE_BOOK,
  CARD,
  CASH,
  CREDIT_BALANCE,
  NON_CASH,
  NON_PAYMENT,
} from "../constants/OrderPaymentTypes";
import { phoneCountyCodeList } from "../constants/PhoneCountryCodes";
import { statusColors } from "../../shared/theme/main-theme";
import { getAppMarketplace } from "../../shared/reducers/AppReducer";
import {
  DELIVERYUAE_ID,
  SUBWAY_ID,
  SWISH_NOW_ID,
  UPLIFT_ID,
  WING_TEST_APP,
} from "../../server/constants/MarketplaceId";
import { BIZ, GEO } from "../constants/LocationAddresstypes";
// import {
//   BANDEROL,
//   BANDEROL_VALUED,
//   EXPRESS_DOCS,
//   EXPRESS_PARCEL,
//   LETTER,
//   LETTER_VALUED,
//   PARCEL,
//   POSTCARD,
//   SMALL_PACKETS,
// } from "./OrderOutboundSortingHelper";

const state = getInitialStateForHelper();
const marketplaceId = state ? getAppMarketplace(state) : 1;

const NA = "N/A";

export function formatInquiriesTypes(
  inquiries,
  notAvailableValue = NA,
  // eslint-disable-next-line no-unused-vars
  getLocalisationMessage = null,
) {
  switch (inquiries) {
    case SOUQ_CUSTOMER_SUPPORT_EMAIL:
      return "Souq.com Customer Support Email";
    case CHAT_CALL_FROM_CLIENT:
      return "Chat/Call from Client (Non Souq)";
    case SOCIAL_MEDIA_GOOGLE_APP_GOOGLE_PLAY:
      return "Social Media/Google APP/Google Play";

    default:
      return inquiries || notAvailableValue;
  }
}

export function formatReasonTypes(reason, notAvailableValue = NA) {
  switch (reason) {
    case COD_INQUIRY:
      return "COD Inquiry";
    case HR_RELATED_INQUIRY:
      return "HR Related Inquiry";

    default:
      return formatText(reason) || notAvailableValue;
  }
}

export function formatReasonTypesLocalised(
  reason,
  getLocalisationMessage,
  notAvailableValue = NA,
) {
  switch (reason) {
    case COD_INQUIRY:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("cod_inquiry", "HR Related Inquiry")) ||
        "COD Inquiry"
      );
    case HR_RELATED_INQUIRY:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("hr_related_inquiry")) ||
        "HR Related Inquiry"
      );

    default:
      return (
        (getLocalisationMessage && getLocalisationMessage(reason)) ||
        formatText(reason) ||
        notAvailableValue
      );
  }
}

export function formatOrderStatusCode(statusCode, notAvailableValue = NA) {
  switch (statusCode) {
    case ACCEPTED:
      return "Driver Assigned";
    case IN_SORTING_FACILITY:
      return "In Sorting Warehouse";
    case ON_HOLD:
      return "Delivery On Hold";
    case ON_HIS_WAY:
      return "Driver On His Way";
    case COMPLETED:
      return "Delivered";
    default:
      return formatText(statusCode) || notAvailableValue;
  }
}

export function formatOrderStatusCodeLocalization(statusCode, messages) {
  switch (statusCode) {
    case ACCEPTED:
      return (messages && messages.get("driver_assigned")) || "Driver Assigned";
    case ON_HOLD:
      return (
        (messages && messages.get("delivery_on_hold")) || "Delivery On Hold"
      );
    case ON_HIS_WAY:
      return (
        (messages && messages.get("driver_on_his_way")) || "Driver On His Way"
      );
    case COMPLETED:
      return (messages && messages.get("delivered")) || "Delivered";
    default:
      return (messages && messages.get(statusCode)) || formatText(statusCode);
  }
}

export const formatOrderStatusCodeForLocalisation = (
  statusCode,
  getLocalisationMessage,
) => {
  let localizationKey = statusCode;
  switch (statusCode) {
    case ACCEPTED:
      localizationKey = "driver_assigned";
      break;
    case ON_HOLD:
      localizationKey = "delivery_on_hold";
      break;
    case ON_HIS_WAY:
      localizationKey = "driver_on_his_way";
      break;
    case COMPLETED:
      localizationKey = "delivered";
      break;
    default:
  }

  return (
    (getLocalisationMessage && getLocalisationMessage(localizationKey)) ||
    formatText(localizationKey)
  );
};
export const formatOrderStatusCodeForLocalisation2 = (
  statusCode,
  getLocalisationMessage,
) => {
  let localizationKey = statusCode;
  switch (statusCode) {
    case ACCEPTED:
      localizationKey = "driver_assigned";
      break;
    case ON_HOLD:
      localizationKey = "delivery_on_hold";
      break;
    case ON_HIS_WAY:
      localizationKey = "driver_on_his_way";
      break;
    case COMPLETED:
      localizationKey = "delivered";
      break;
    case PREPARED_FOR_TRANSIT:
      localizationKey = "prepared_for_transit_public";
      break;
    case IN_SORTING_FACILITY:
      localizationKey = "in_sorting_facility_public";
      break;
    case IN_TRANSIT:
      localizationKey = "in_transit_public";
      break;

    default:
  }

  return (
    (getLocalisationMessage && getLocalisationMessage(localizationKey)) ||
    formatText(localizationKey)
  );
};

export function orderStatusColor(statusCode) {
  switch (statusCode) {
    case ASSIGNED_TO_COURIER:
    case PENDING_ASSIGN_TO_COURIER:
      return statusColors.status2;
    case PICKED_UP:
    case PICKED_UP_AUTO:
      return statusColors.status4;
    case BAD_RECIPIENT_ADDRESS:
      return statusColors.status5;
    case IN_TRANSIT:
      return statusColors.status7;
    case IN_SORTING_FACILITY:
      return statusColors.status8;
    case ON_HOLD:
      return statusColors.status10;
    case CANCELLED:
      return statusColors.status11;
    case DISPATCHED:
    case OUT_FOR_DELIVERY:
    case ARRIVED_TO_DELIVERY_ADDRESS:
      return statusColors.status12;
    case COMPLETED:
      return statusColors.status13;
    case RECIPIENT_NOT_AVAILABLE:
      return statusColors.status14;
    case DELIVERY_SCHEDULED:
      return statusColors.status15;
    case TO_BE_RETURNED:
    case RETURNED_TO_ORIGIN:
    case DESTROYED_ON_CUSTOMER_REQUEST:
      return statusColors.status16;
    case LOST_OR_DAMAGED:
      return statusColors.status17;
    default:
      return statusColors.status1;
  }
}

export function jobStatusColor(statusCode) {
  switch (statusCode) {
    case UNASSIGNED:
      return statusColors.jobStatus1;
    case ASSIGNED:
    case ASSIGNED_TO_COURIER:
    case ON_THE_WAY:
      return statusColors.jobStatus2;
    case ARRIVED:
      return statusColors.jobStatus3;
    case COMPLETED:
      return statusColors.jobStatus4;
    case PARTIALLY_COMPLETED:
      return statusColors.jobStatus5;
    case FAILED:
      return statusColors.jobStatus6;
    case CLOSED:
      return statusColors.jobStatus7;
    default:
      return orderStatusColor(statusCode);
  }
}

export function formatCODStatusCode(status, notAvailableValue = NA) {
  switch (status) {
    case NO_COD:
      return "No COD";
    case COD_TO_BE_COLLECTED:
      return "To Be Collected";
    case COD_COLLECTED:
      return "Collected";
    case COD_TRANSFERRED:
      return "COD Transferred";
    case COD_WITH_WING:
      return "With Cashier";
    case COD_WITH_WING_BANK:
      return "With Wing Bank";
    case COD_WITH_SAAS_BANK:
      return "With Bank";
    case COD_TRANSFER_REJECTED:
      return "COD Transfer Rejected";
    case COD_WITHDRAW_REQUESTED:
      return "COD Withdraw Requested";
    case COD_PAID_TO_MERCHANT:
      return "Paid to Customer";
    case COD_WITHDRAW_REJECTED:
      return "COD Withdraw Rejected";
    case COD_PENDING:
      return "Pending";
    default:
      return notAvailableValue;
  }
}

export function formatCODStatusCodeLocalised(
  status,
  getLocalisationMessage,
  notAvailableValue = NA,
) {
  switch (status) {
    case NO_COD:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("no_cod", "No COD")) ||
        "No COD"
      );
    case COD_TO_BE_COLLECTED:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("to_be_collected", "To Be Collected")) ||
        "To Be Collected"
      );
    case COD_COLLECTED:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("collected", "Collected")) ||
        "Collected"
      );
    case COD_TRANSFERRED:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("cod_transferred", "COD Transferred")) ||
        "COD Transferred"
      );
    case COD_WITH_WING:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("with_cashier", "With Cashier")) ||
        "With Cashier"
      );
    case COD_WITH_WING_BANK:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("with_wing_bank", "With Wing Bank")) ||
        "With Wing Bank"
      );
    case COD_WITH_SAAS_BANK:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("with_bank", "With Bank")) ||
        "With Bank"
      );
    case COD_TRANSFER_REJECTED:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage(
            "cod_transfer_rejected",
            "COD Transfer Rejected",
          )) ||
        "COD Transfer Rejected"
      );
    case COD_WITHDRAW_REQUESTED:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage(
            "cod_withdraw_requested",
            "COD Withdraw Requested",
          )) ||
        "COD Withdraw Requested"
      );
    case COD_PAID_TO_MERCHANT:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("paid_to_customer", "Paid to Customer")) ||
        "Paid to Customer"
      );
    case COD_WITHDRAW_REJECTED:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage(
            "cod_withdraw_rejected",
            "COD Withdraw Rejected",
          )) ||
        "COD Withdraw Rejected"
      );
    case COD_PENDING:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("pending", "Pending")) ||
        "Pending"
      );
    default:
      return (
        (getLocalisationMessage && getLocalisationMessage("na")) ||
        notAvailableValue
      );
  }
}

export function formatCODStatusCodeLocalisedi18n(
  status,
  i18n,
  notAvailableValue = NA,
) {
  switch (status) {
    case NO_COD:
      return (i18n && i18n.get("no_cod", "No COD")) || "No COD";
    case COD_TO_BE_COLLECTED:
      return (
        (i18n && i18n.get("to_be_collected", "To Be Collected")) ||
        "To Be Collected"
      );
    case COD_COLLECTED:
      return (i18n && i18n.get("collected", "Collected")) || "Collected";
    case COD_TRANSFERRED:
      return (
        (i18n && i18n.get("cod_transferred", "COD Transferred")) ||
        "COD Transferred"
      );
    case COD_WITH_WING:
      return (
        (i18n && i18n.get("with_cashier", "With Cashier")) || "With Cashier"
      );
    case COD_WITH_WING_BANK:
      return (
        (i18n && i18n.get("with_wing_bank", "With Wing Bank")) ||
        "With Wing Bank"
      );
    case COD_WITH_SAAS_BANK:
      return (i18n && i18n.get("with_bank", "With Bank")) || "With Bank";
    case COD_TRANSFER_REJECTED:
      return (
        (i18n && i18n.get("cod_transfer_rejected", "COD Transfer Rejected")) ||
        "COD Transfer Rejected"
      );
    case COD_WITHDRAW_REQUESTED:
      return (
        (i18n &&
          i18n.get("cod_withdraw_requested", "COD Withdraw Requested")) ||
        "COD Withdraw Requested"
      );
    case COD_PAID_TO_MERCHANT:
      return (
        (i18n && i18n.get("paid_to_customer", "Paid to Customer")) ||
        "Paid to Customer"
      );
    case COD_WITHDRAW_REJECTED:
      return (
        (i18n && i18n.get("cod_withdraw_rejected", "COD Withdraw Rejected")) ||
        "COD Withdraw Rejected"
      );
    case COD_PENDING:
      return (i18n && i18n.get("pending", "Pending")) || "Pending";
    default:
      return (i18n && i18n.get("na")) || notAvailableValue;
  }
}

export function formatLocalisedCODStatusCode(status, getLocalisationMessage) {
  switch (status) {
    case NO_COD:
      return getLocalisationMessage(NO_COD.toLowerCase(), "No COD");
    case COD_TO_BE_COLLECTED:
      return getLocalisationMessage(
        COD_TO_BE_COLLECTED.toLowerCase(),
        "To Be Collected",
      );
    case COD_COLLECTED:
      return getLocalisationMessage(
        COD_COLLECTED.toLowerCase(),
        "COD Collected",
      );
    case COD_TRANSFERRED:
      return getLocalisationMessage(
        COD_TRANSFERRED.toLowerCase(),
        "COD Transferred",
      );
    case COD_WITH_WING:
      return getLocalisationMessage(
        COD_WITH_WING.toLowerCase(),
        "With Cashier",
      );
    case COD_WITH_WING_BANK:
      return getLocalisationMessage(
        COD_WITH_WING_BANK.toLowerCase(),
        "With Wing Bank",
      );
    case COD_WITH_SAAS_BANK:
      return getLocalisationMessage(
        COD_WITH_SAAS_BANK.toLowerCase(),
        "With Bank",
      );
    case COD_TRANSFER_REJECTED:
      return getLocalisationMessage(
        COD_TRANSFER_REJECTED.toLowerCase(),
        "COD Transfer Rejected",
      );
    case COD_WITHDRAW_REQUESTED:
      return getLocalisationMessage(
        COD_WITHDRAW_REQUESTED.toLowerCase(),
        "COD Withdraw Requested",
      );
    case COD_PAID_TO_MERCHANT:
      return getLocalisationMessage(
        COD_PAID_TO_MERCHANT.toLowerCase(),
        "Paid to Customer",
      );
    case COD_WITHDRAW_REJECTED:
      return getLocalisationMessage(
        COD_WITHDRAW_REJECTED.toLowerCase(),
        "COD Withdraw Rejected",
      );
    case COD_PENDING:
      return getLocalisationMessage(COD_PENDING.toLowerCase(), "Pending");
    default:
      return getLocalisationMessage("all", "All");
  }
}

export function formatSupplierCODStatusCode(status, notAvailableValue) {
  switch (status) {
    case COD_COLLECTED:
      return "COD pending";
    case COD_TRANSFERRED:
      return "Sent to Company";
    case COD_WITH_WING:
      return "With Wing Cashier";
    case COD_WITH_WING_BANK:
      return "With Wing Bank";
    case COD_PAID_TO_MERCHANT:
      return "COD Paid To Merchant";
    default:
      return formatCODStatusCode(status, notAvailableValue);
  }
}

export function formatSupplierCODStatusCodeLocalised(
  status,
  getLocalisationMessage,
  notAvailableValue,
) {
  switch (status) {
    case COD_COLLECTED:
      return getLocalisationMessage("cod_pending", "COD pending");
    case COD_TRANSFERRED:
      return getLocalisationMessage("send_to_company", "Sent to Company");
    case COD_WITH_WING:
      return getLocalisationMessage("cod_with_wing", "With Cashier");
    case COD_WITH_WING_BANK:
      return getLocalisationMessage("with_wing_bank", "With Company Bank");
    case COD_PAID_TO_MERCHANT:
      return getLocalisationMessage("cod_paid_to_merchant", "Paid To Merchant");
    default:
      return formatCODStatusCodeLocalised(
        status,
        getLocalisationMessage,
        notAvailableValue,
      );
  }
}

export function formatCustomerCODStatusCode(status, notAvailableValue) {
  switch (status) {
    case COD_WITH_WING_BANK:
      return "Withdrawable";
    case COD_PAID_TO_MERCHANT:
      return "Withdrawn";
    default:
      return formatCODStatusCode(status, notAvailableValue);
  }
}

export function formatCustomerCODStatusCodeLocalised(
  status,
  getLocalisationMessage,
  notAvailableValue,
) {
  switch (status) {
    case COD_WITH_WING_BANK:
      return getLocalisationMessage("withdrawable", "Withdrawable");
    case COD_PAID_TO_MERCHANT:
      return getLocalisationMessage("withdrawn", "Withdrawn");
    default:
      return formatCODStatusCodeLocalised(
        status,
        getLocalisationMessage,
        notAvailableValue,
      );
  }
}

export function formatNoteCategory(category, notAvailableValue = NA) {
  switch (category) {
    case DELIVERY_ATTEMPT_FAILED:
      return "Delivery Attempt Failed";
    case PICKUP_ATTEMPT_FAILED:
      return "Pick Up Attempt Failed";
    default:
      return formatText(category) || notAvailableValue;
  }
}

export function formatCourierType(type) {
  switch (_.toLower(type)) {
    case NEXT_DAY:
      return "Next Day";
    case SAME_DAY:
      switch (marketplaceId) {
        case 21:
        case 33:
        case DELIVERYUAE_ID:
        case WING_TEST_APP:
          return "Express Delivery";
        case UPLIFT_ID:
        case SWISH_NOW_ID:
          return "Scheduled Delivery";
        default:
          return "Same Day";
      }
    case SAVER:
      return "Saver";
    case STANDARD:
      return "Standard";
    case URGENT:
      if (
        marketplaceId === UPLIFT_ID ||
        marketplaceId === SUBWAY_ID ||
        marketplaceId === SWISH_NOW_ID
      ) {
        return "Express Delivery";
      }
      return "Urgent";
    case FBS:
      return "FBS";
    default:
      return "N/A";
  }
}

export function formatCourierTypeLocalization(
  type,
  getLocalisationMessage = null,
) {
  if (getLocalisationMessage)
    return getLocalisationMessage(type, formatText(type));

  return type;

  // switch (type) {
  //   case LETTER:
  //     return "Письма";
  //   case LETTER_VALUED:
  //     return "Ценное письмо";
  //   case PARCEL:
  //     return "Посылки";
  //   case BANDEROL:
  //     return "Бандероли";
  //   case BANDEROL_VALUED:
  //     return "Ценное Бандероли";
  //   case SMALL_PACKETS:
  //     return "Мелкие Пакеты";
  //   case POSTCARD:
  //     return "Открытка";
  //   case EXPRESS_DOCS:
  //     return "Экспресс Документы";
  //   case EXPRESS_PARCEL:
  //     return "Экспресс посылка";
  //   default:
  //     return type;
  // }
}

export function formatPaymentType(type) {
  switch (type) {
    case CASH:
      return "Cash";
    case NON_PAYMENT:
      return "Non payment";
    case CARD:
    case NON_CASH:
      return "Card";
    case CREDIT_BALANCE:
      return "Credit Balance";
    case ADVANCE_BOOK:
      return "Advance book";
    default:
      return "N/A";
  }
}

export function formatLocalisedPaymentType(type, getLocalisationMessage) {
  switch (type) {
    case CASH:
      return (
        (getLocalisationMessage && getLocalisationMessage("cash")) || "Cash"
      );
    case NON_PAYMENT:
      return (
        (getLocalisationMessage && getLocalisationMessage("non_payment")) ||
        "Non payment"
      );
    case CARD:
      return (
        (getLocalisationMessage && getLocalisationMessage("card")) || "Card"
      );
    case NON_CASH:
      return (
        (getLocalisationMessage && getLocalisationMessage("non_cash")) ||
        "Non cash"
      );
    case CREDIT_BALANCE:
      return (
        (getLocalisationMessage && getLocalisationMessage("credit_balance")) ||
        "Credit Balance"
      );
    case ADVANCE_BOOK:
      return (
        (getLocalisationMessage && getLocalisationMessage("advance_book")) ||
        "Advance book"
      );
    default:
      return (getLocalisationMessage && getLocalisationMessage("na")) || "N/A";
  }
}

export function formatPaymentTypeI18n(type, i18n) {
  switch (type) {
    case CASH:
      return (i18n && i18n.get("cash")) || "Cash";
    case NON_PAYMENT:
      return (i18n && i18n.get("non_payment")) || "Non payment";
    case CARD:
      return (i18n && i18n.get("card")) || "Card";
    case NON_CASH:
      return (i18n && i18n.get("non_cash")) || "Non cash";
    case CREDIT_BALANCE:
      return (i18n && i18n.get("credit_balance")) || "Credit Balance";
    case ADVANCE_BOOK:
      return (i18n && i18n.get("advance_book")) || "Advance book";

    default:
      return (i18n && i18n.get("na")) || "N/A";
  }
}

export function formatShippingTypeLocalisationI18n(type, i18n) {
  switch (type) {
    case REGULAR:
      return (i18n && i18n.get("regular_shipping")) || "Regular Shipping";
    case REVERSE:
      return (i18n && i18n.get("reverse_shipping")) || "Reverse Shipping";
    case ALL_SHIPPING:
      return (i18n && i18n.get("all_shipping")) || "All Shipping";
    default:
      return (i18n && i18n.get("na")) || "N/A";
  }
}

export function formatShippingTypeLocalisation(type, getLocalisationMessage) {
  switch (type) {
    case REGULAR:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("regular_shipping")) ||
        "Regular Shipping"
      );
    case REVERSE:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("reverse_shipping")) ||
        "Reverse Shipping"
      );
    case ALL_SHIPPING:
      return (
        (getLocalisationMessage && getLocalisationMessage("all_shipping")) ||
        "All Shipping"
      );
    default:
      return (getLocalisationMessage && getLocalisationMessage("na")) || "N/A";
  }
}

export function formatAddressTypeLocalisation(type, getLocalisationMessage) {
  switch (type) {
    case GEO:
      return (
        (getLocalisationMessage && getLocalisationMessage("physical_person")) ||
        "Physical Person"
      );
    case BIZ:
      return (
        (getLocalisationMessage && getLocalisationMessage("organization")) ||
        "Organization"
      );
    default:
      return (getLocalisationMessage && getLocalisationMessage("na")) || "N/A";
  }
}

export function formatMerchantTypeI18n(type, i18n) {
  switch (type) {
    case MERCHANTS:
      return (i18n && i18n.get("merchant")) || "Merchant";
    case CASH_CLIENTS:
      return (i18n && i18n.get("cash_clients")) || "Cash Clients";
    case ALL:
      return (i18n && i18n.get("all")) || "All";
    default:
      return (i18n && i18n.get("na")) || "N/A";
  }
}

export function formatMerchantType(type, getLocalisationMessage) {
  switch (type) {
    case MERCHANTS:
      return (
        (getLocalisationMessage && getLocalisationMessage("merchant")) ||
        "Merchant"
      );
    case CASH_CLIENTS:
      return (
        (getLocalisationMessage && getLocalisationMessage("cash_clients")) ||
        "Cash Clients"
      );
    case ALL:
      return (getLocalisationMessage && getLocalisationMessage("all")) || "All";
    default:
      return (getLocalisationMessage && getLocalisationMessage("na")) || "N/A";
  }
}

export function formatClientTypeI18n(type, i18n) {
  switch (type) {
    case CLIENT_MERCHANT:
      return (i18n && i18n.get("merchant")) || "Merchant";
    case CASH_CLIENT:
      return (i18n && i18n.get("cash_client")) || "Cash Client";
    case ALL:
      return (i18n && i18n.get("all")) || "All";
    default:
      return (i18n && i18n.get("all")) || "All";
  }
}

export function shouldDisablePayer(paymentType) {
  return paymentType !== CASH;
}

export function shouldDisableRecipientNotAvailable(payer, paymentType) {
  return payer === RECIPIENT && paymentType === CASH;
}

const mapNotes = fp.flow(
  fp.get("data.list"),
  fp.map(item => ({
    id: item.id,
    date: new Date(item.created_date),
    user: item.noted_by,
    note: {
      content: item.content,
      privacy: item.privacy,
      category: item.category,
      reason: item.cancellation_reason,
    },
  })),
);

const mapHistory = fp.flow(
  fp.get("data.list"),
  fp.map(item => ({
    ...item,
    id: item.id,
    date: new Date(item.date),
    user: item.created_by_user,
    driver: item.driver,
    supplier: item.supplier,
    warehouse: item.warehouse,
    lat: item.lat,
    lon: item.lon,
    status: { code: item.status, text: item.status_desc },
    batch: item.batch,
  })),
);

const mapCODHistory = fp.flow(
  fp.get("data.list"),
  fp.map(item => ({
    date: new Date(item.date),
    cod_status: {
      code: item.cod_status,
      text: item.cod_status_description,
    },
    psf_status: {
      code: item.psf_status,
      text: item.psf_status_description,
    },
    user: item.created_by_user,
  })),
);

export const mapOrderCODHistoryToActivitias = fp.flow(
  fp.get("data.list"),
  fp.map(item => ({
    date: item.date,
    status: item.status_desc,
    user: item.created_by_user,
  })),
  v =>
    v.sort((a, b) => {
      const dateA = a.date.valueOf();
      const dateB = b.date.valueOf();

      return dateB > dateA ? 1 : -1;
    }),
  fromJS,
);

export const mapOrderCODHistoryToActivitiasV2 = fp.flow(
  fp.toPlainObject,
  response => [mapNotes(response.notes), mapCODHistory(response.history)],
  fp.flatten,
  v =>
    v.sort((a, b) => {
      const dateA = a.date.valueOf();
      const dateB = b.date.valueOf();

      if (dateA === dateB) {
        const aType = a.note ? "note" : a.cod_status ? "cod_status" : "call";
        const bType = b.note ? "note" : b.cod_status ? "cod_status" : "call";

        // Sort by id if types are same.
        if (aType === bType) {
          return b.id - a.id;
        }

        // First - show notes.
        if (aType === "note") {
          return -1;
        } else if (bType === "note") {
          return 1;
        }

        // Second - show calls.
        if (aType === "call") {
          return -1;
        } else if (bType === "call") {
          return 1;
        }

        // Finally - show status update.
        return aType === "cod_status" ? 1 : -1;
      }

      return dateB - dateA;
    }),
  fromJS,
);

export const mapOrderNotesAndHistoryToActivities = fp.flow(
  fp.toPlainObject,
  response => [mapNotes(response.notes), mapHistory(response.history)],
  fp.flatten,
  v =>
    v.sort((a, b) => {
      const dateA = a.date.valueOf();
      const dateB = b.date.valueOf();

      if (dateA === dateB) {
        const aType = a.note ? "note" : a.status ? "status" : "call";
        const bType = b.note ? "note" : b.status ? "status" : "call";

        // Sort by id if types are same.
        if (aType === bType) {
          return b.id - a.id;
        }

        // First - show notes.
        if (aType === "note") {
          return -1;
        } else if (bType === "note") {
          return 1;
        }

        // Second - show calls.
        if (aType === "call") {
          return -1;
        } else if (bType === "call") {
          return 1;
        }

        // Finally - show status update.
        return aType === "status" ? 1 : -1;
      }

      return dateB - dateA;
    }),
  fromJS,
);

// eslint-disable-next-line no-unused-vars
export function isNewVersion(marketplace, isMerchant = false) {
  // if (marketplace.get("marketplace_id") === WING_AE_ID) return !isMerchant;
  return marketplace.getIn(["setting", "settings", "newModelEnabled"], false);
}

export function getChargeableWeight(weight, volumetricWeight) {
  return weight && volumetricWeight
    ? weight > volumetricWeight
      ? weight
      : volumetricWeight
    : volumetricWeight;
}

export function isPickUpAddress(order) {
  if (order.getIn(["location_history", 0, "pickup"])) {
    if (
      order.getIn(["location_history", 0, "lat"]) !==
        order.getIn(["locations", 0, "lat"]) &&
      order.getIn(["location_history", 0, "lon"]) !==
        order.getIn(["locations", 0, "lon"])
    )
      return 0;

    if (
      order.getIn(["location_history", 1, "lat"]) !==
        order.getIn(["locations", 1, "lat"]) &&
      order.getIn(["location_history", 1, "lon"]) !==
        order.getIn(["locations", 1, "lon"])
    )
      return 1;
  }
  return -1;
}

export function getAddressByOrderStatus(status) {
  switch (status) {
    case ASSIGNED_TO_COURIER:
    case OTHER_PICKUP_ATTEMPT:
    case UNASSIGNED:
    case ACCEPTED:
    case ON_HIS_WAY:
    case ARRIVED:
    case FIRST_PICKUP_ATTEMPT:
    case SECOND_PICKUP_ATTEMPT:
    case THIRD_PICKUP_ATTEMPT:
    case DRIVER_REJECTED:
      return 0;

    case OTHER_DELIVERY_ATTEMPT:
    case PICKED_UP_AUTO:
    case PICKED_UP:
    case IN_SORTING_FACILITY:
    case IN_TRANSIT:
    case OUT_FOR_DELIVERY:
    case ARRIVED_TO_DELIVERY_ADDRESS:
    case ON_HOLD:
    case FIRST_DELIVERY_ATTEMPT:
    case SECOND_DELIVERY_ATTEMPT:
    case THIRD_DELIVERY_ATTEMPT:
    case BAD_RECIPIENT_ADDRESS:
    case RECIPIENT_NOT_AVAILABLE:
    case RECIPIENT_MOBILE_SWITCHED_OFF:
    case RECIPIENT_MOBILE_WRONG:
    case RECIPIENT_MOBILE_NO_RESPONSE:
    case RECIPIENT_ADDRESS_CHANGE_REQUESTED:
    case COD_NOT_READY:
    case FUTURE_DELIVERY_REQUESTED:
    case OUT_OF_DELIVERY_AREA:
    case UNABLE_TO_ACCESS_RECIPIENT_PREMISES:
    case NO_CAPACITY_FOR_DELIVERY:
    case ID_OR_DOCUMENT_REQUIRED_MISSING:
    case BAD_WEATHER_DURING_DELIVERY:
    case RECIPIENT_NAME_MISSING:
    case COLLECTION_ARRANGED_OR_REQUESTED:
    case WRONG_SHIPMENT:
    case INCOMPLETE_PARCEL:
    case DELIVERY_DELAY_DUE_TO_LATE_BOOKING:
    case NO_TIME_FOR_DELIVERY:
    case DELIVERY_REJECTED:
    case DELIVERY_SCHEDULED:
    case DELIVERY_FAILED:
    case DISPATCHED:
    case COMPLETED:
    case IN_TRANSIT_TO_SUPPLIER:
      return 1;

    default:
      return 0;
  }
}

const isUrgentOrSameDayCourierType = fp.flow(
  fp.toLower,
  fp.over([fp.eq(URGENT), fp.eq(SAME_DAY)]),
  fp.some(Boolean),
);

export const getCourierType = order =>
  order.getIn(["package", "courier_type"]) ||
  order.getIn(["package_type", "courier_type", "type"]);
export const isEnabledAssignDriver = (
  settingsEnabledServiceTypes,
  courierType,
) =>
  settingsEnabledServiceTypes.includes(courierType) ||
  isUrgentOrSameDayCourierType(courierType);
export const mapChargeItems = fp.flow(
  chargeItems => (List.isList(chargeItems) ? chargeItems : List()),
  chargeItems =>
    chargeItems
      .toMap()
      .mapEntries(([, item]) => [item.get("charge_type"), item]),
);

export function getChargeItem(order) {
  return mapChargeItems(order.get("charge_items"));
}

export const isDocument = fp.startsWith("Document");
export const isIOS = fp.includes("x-app-type:[customer_ios]");
export const isAndroid = fp.includes("x-app-type:[customer_android]");
export const isExcelImport =
  fp.includes("EXCEL_IMPORT") || fp.includes("Excel import");
export const isOpencart = fp.includes("opencart");
export const isMagentoV2 = fp.includes("x-app-type:[magento-extension-v2]");
export const isMagentoV1 = fp.includes("x-app-type:[magento-v1-api]");
export const isShopify = fp.includes("x-app-type:[Shopify-app]");
export const isWordpress = fp.includes("x-app-type:[wordpress_plugin]");
export const isWeb =
  fp.includes("Mozilla") || fp.includes("Chrome") || fp.includes("Safari");
export const isTelegram = fp.includes("TELEGRAM_BOT");
export const isIPS = fp.includes("IPS");

export function getPhoneCode(locationDetails) {
  const countryName = locationDetails.getIn(["country", "name"]);
  if (countryName) {
    const phoneItem = phoneCountyCodeList.find(
      countryItem => countryItem.country === countryName,
    );
    if (!isEmpty(phoneItem)) return { code: phoneItem.code };
  }
  return null;
}

export const shouldOrderBeAssigned = order =>
  order.get("status") === UNASSIGNED ||
  order.get("status") === ASSIGNED_TO_COURIER;

export function isOrderCapableForCancel(statusCode) {
  return (
    statusCode !== ARRIVED &&
    statusCode !== IN_TRANSIT &&
    statusCode !== OUT_FOR_DELIVERY &&
    statusCode !== DELIVERY_FAILED &&
    statusCode !== TO_BE_RETURNED &&
    statusCode !== REJECTED &&
    statusCode !== DELIVERY_ATTEMPT &&
    statusCode !== OUT_FOR_RETURN &&
    statusCode !== RETURNING_TO_ORIGIN &&
    statusCode !== FIRST_DELIVERY_ATTEMPT &&
    statusCode !== BAD_RECIPIENT_ADDRESS &&
    statusCode !== DELIVERY_SCHEDULED &&
    statusCode !== RECIPIENT_NOT_AVAILABLE &&
    statusCode !== RECIPIENT_MOBILE_SWITCHED_OFF &&
    statusCode !== RECIPIENT_MOBILE_WRONG &&
    statusCode !== RECIPIENT_ADDRESS_CHANGE_REQUESTED &&
    statusCode !== COD_NOT_READY &&
    statusCode !== DELIVERY_REJECTED &&
    statusCode !== RECIPIENT_MOBILE_NO_RESPONSE &&
    statusCode !== FUTURE_DELIVERY_REQUESTED &&
    statusCode !== OUT_OF_DELIVERY_AREA &&
    statusCode !== UNABLE_TO_ACCESS_RECIPIENT_PREMISES &&
    statusCode !== DELIVERY_DELAY_DUE_TO_LATE_BOOKING &&
    statusCode !== NO_TIME_FOR_DELIVERY &&
    statusCode !== NO_CAPACITY_FOR_DELIVERY &&
    statusCode !== BAD_WEATHER_DURING_DELIVERY &&
    statusCode !== RECIPIENT_NAME_MISSING &&
    statusCode !== LOST_OR_DAMAGED &&
    statusCode !== IN_TRANSIT_TO_SUPPLIER &&
    statusCode !== TRANSITED_TO_SUPPLIER &&
    statusCode !== PICKED_UP_AUTO &&
    statusCode !== ARRIVED_TO_DELIVERY_ADDRESS &&
    statusCode !== DISPATCHED &&
    statusCode !== CANCELLED &&
    statusCode !== COMPLETED
  );
}
