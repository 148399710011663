import { OrderedSet } from "immutable";

export const SHIPMENT = "orders";
export const ORDER = "ORDER";
export const SIMPLE = "SIMPLE";
export const CONSOLIDATED = "CONSOLIDATED";
export const CONTAINER = "CONTAINER";
export const ROUTE = "ROUTE";
export const RETURN = "RETURN";
export const ISSUED = "ISSUED";

export const batchTabs = OrderedSet.of(SIMPLE, CONSOLIDATED, CONTAINER, ROUTE);
export default OrderedSet.of(SHIPMENT, SIMPLE, CONSOLIDATED, CONTAINER, ROUTE);
