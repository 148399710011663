export const INTERNATIONAL_INVOICE_URL =
  "https://www.wing.ae/wp-content/uploads/2018/09/INTERNATIONAL_INVOICE.xlsx";

export const CREATE_ORDER_CSV_URL = "/file/order/csv";
export const CREATE_DYNAMIC_ORDER_CSV_URL = "/file/order/dynamic/csv";
export const CREATE_ORDER_POST_CSV_URL = "/file/order/csv_v2";
export const CREATE_FINANCE_ORDER_CSV_URL = "/file/finance/order/csv";
export const CREATE_ORDER_MANIFEST_URL = "/file/order/manifest";
export const CREATE_ORDER_JOB_MANIFEST_URL = "/file/order/job_manifest";
export const CREATE_ORDER_AWBZ_HTML_URL = "/file/order/awbz/html";
export const CREATE_ORDER_AIRWAYBILL_URL = "/file/order/airwaybill";
export const CREATE_ORDER_AIRWAYBILL_URL_V2 = "/file/order/airwaybill_v2";
export const CREATE_ORDER_AIRWAYBILL_MINI_URL = "/file/order/airwaybill_mini";
export const CREATE_ORDER_AIRWAYBILL_ZEBRA_URL = "/file/order/airwaybill_zebra";
export const CREATE_ORDER_TAX_INVOICE_URL = "/file/order/tax_invoice";
export const CREATE_ORDER_AIRWAYBILL_MINI_URL_V2 =
  "/file/order/airwaybill_mini_v2";
export const CREATE_ORDER_BATCH_LABEL_URL = "/file/order/batch_label";
export const CREATE_WITHDRAWABLE_REFERENCE_TEMPLATE_URL =
  "/file/withdrawable/reference_template/csv";
export const DOWNLOAD_ORDER_CSV_TEMPLATE_URL = "/file/order/template/csv";
export const DOWNLOAD_ORDER_CSV_V2_TEMPLATE_URL = "/file/order/template/csv_v2";
export const DOWLAND_ORDER_CSV_V2_INTERNATIONAL_TEMPLATE_URL =
  "/file/order/international/template/csv_v2";
export const DOWLOAD_ORDER_WEIGHT_CSV_TEMPLATE_URL =
  "/file/overweight/template/csv";
export const DOWLOAD_ORDER_WEIGHT_V2_CSV_TEMPLATE_URL =
  "/file/overweight/v2/template/csv";

export const CREATE_ORDER_REVENUE_REPORT_URL = "/file/order/revenue_report/csv";
export const CREATE_ORDER_COD_AGING_RECEIVABLE_REPORT_URL =
  "/file/order/cod_aging_receivable/csv";
export const CREATE_USER_XLS_URL = "/file/user/xls";

export const CREATE_COD_STATEMENT_HISTORY_URL =
  "/file/cod/statement/history/csv";
export const CREATE_COD_TRANSFER_STATEMENT_URL =
  "/file/cod-transfer/statement/csv";
export const ADMIN_COD_WITHDRAW_DETAILED_STATEMENT_URL =
  "/file/cod-transfer/detailed-statement/csv";
export const CREATE_SETTLEABLE_CUSTOMERS_URL =
  "/file/cod-withdraw/settleable-customers/csv";
export const CREATE_CUSTOMER_PENDING_BALANCE_URL =
  "/file/customer/pending-balance/csv";
export const CREATE_FINANCE_PENDING_WITHDRAW_URL = "/file/withdraw/pending/csv";
export const CREATE_COD_WITHDRAW_STATEMENT_URL =
  "/file/cod-withdraw/statement/csv";
export const CREATE_COD_WITHDRAW_RECONCILE_STATEMENT_URL =
  "/file/cod-withdraw/reconcile-statement/csv";
export const CREATE_COD_WITHDRAW_RECONCILE_STATEMENT_POST_URL =
  "/file/cod-withdraw/reconcile-statement/post/csv";
export const CREATE_COD_WITHDRAW_CASH_COLLECTION_REPORT_URL =
  "/file/cod-withdraw/cash-collection-report/csv";
export const CREATE_ORDER_DAILY_DEDICATED_PICKUP_URL =
  "/file/order/daily_dedicated_pickup/csv";
export const CREATE_SUPPLIER_BALANCE_URL = "/file/supplier/balance/csv";

export const CREATE_SUPPLIER_CSV_URL = "/file/supplier/csv";
export const CREATE_SUPPLIER_USER_CSV_URL = "/file/supplier/user/csv";
export const CREATE_SUPPLIER_CONTACTS_CSV_URL = "/file/supplier/contacts/csv";

export const CREATE_DRIVER_CSV_URL = "/file/driver/csv";
export const CREATE_DRIVER_REPORT_URL = "/file/driver/report";
export const CREATE_DRIVER_RUNSHEET_URL = "/file/driver/runsheet";
export const CREATE_DRIVER_TEMPLATE_URL = "/file/driver/template";
export const CREATE_DRIVER_DYNAMIC_RUNSHEET_URL =
  "/file/driver/dynamic_runsheet";

export const CREATE_USERS_TEMPLATE_URL = "/file/user/template";

export const CREATE_DAILY_CALL_REPORT_URL = "/file/daily-call/report";
export const CREATE_RECEIVABNLE_AGING_REPORT_URL =
  "/file/receivable-aging/report";
export const CREATE_DRIVER_LIABILITY_REPORT_URL =
  "/file/driver-liability/report";
export const CREATE_DRIVERS_DASHBOARD_CSV_URL = "/file/drivers-dashboard/csv";

export const CREATE_VEHICLE_CSV_URL = "/file/vehicle/csv";
export const CREATE_VEHICLE_TEMPLATE_URL = "/file/vehicle/temlate";

export const CREATE_CUSTOMER_CSV_URL = "/file/customer/csv";
export const CREATE_CUSTOMER_FEEDBACK_CSV_URL = "/file/customer/feedback/csv";

export const CREATE_CUSTOMER_DPS_CSV_URL = "/file/customer_dps/export_csv";
export const CREATE_INTERNATIONAL_INVOICE_URL =
  "/file/customer/order/international_invoice";
export const CREATE_CASHIERS_CSV_URL = "/file/cashiers/file";

// Package rule
export const CREATE_PACKAGE_RULE_URL = "/file/package_rules/template";
export const CREATE_PACKAGE_CSV_URL = "/file/package_rules/export";
