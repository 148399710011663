import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
  withState,
} from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import AppLayoutAppBar from "./AppLayoutAppBar";
import AppLayoutDrawer from "./AppLayoutDrawer";
import { isEqualData } from "../../helpers/DataUtils";
import { cleanupStringArray } from "../../helpers/FormatUtils";
import { trackPageView } from "../../helpers/EventTracker";
import { getUser } from "../../reducers/ProfileReducer";
import {
  getMarketplaceFavicon,
  getMarketplaceId,
  getMarketplaceName,
} from "../../reducers/MarketplaceReducer";
import {
  onHidePopup,
  showFreeTrialPopup,
} from "../../reducers/SubscriptionReducer";
import { responsive } from "../../../shared/theme/jss-responsive";
import { getAppName } from "../../../shared/reducers/AppReducer";
import { ROLE_MARKETPLACE_ADMIN } from "../../../shared/constants/Authorities";
import favicon from "../../assets/favicon.png";
import { hasRole } from "../../helpers/RoleUtils";

const getTitle = fp.flow(
  fp.over([fp.get("marketplaceName"), fp.get("title")]),
  cleanupStringArray,
  fp.join(" | "),
);
const enhancer = compose(
  withRouter,
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        marketplaceName: getMarketplaceName(state),
        isMarketplaceAdmin: hasRole(userRoles, ROLE_MARKETPLACE_ADMIN),
        marketplaceFavicon: getMarketplaceFavicon(state),
        marketplaceId: getMarketplaceId(state),
        appName: getAppName(state),
        showPopup: showFreeTrialPopup(state),
      };
    },
    { onHidePopup },
  ),
  withState("state", "setState", {
    isOpenTrialPopup: true,
  }),
  getContext({
    setLocation: PropTypes.func,
  }),
  useSheet({
    root: { height: "100%" },
    content: { important: false, height: "100%", padding: "64px 0 0 0" },
    trialContainer: {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      backgroundColor: "red",
      color: "white",
      zIndex: "9999",
      textAlign: "center",
      lineHeight: "2",
    },
    container: {
      height: "100%",
      padding: "4px",
      display: "flex",
      flexDirection: "column",
      [responsive("$xs or $sm")]: { padding: 0 },
    },
    drawer: {
      "& > div": {
        top: "64px",
        bottom: 0,
        height: "auto",
        backgroundColor: "red",
      },
    },
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestChange,
      stream: onRequestChangeStream,
    } = createEventHandler();

    const openStream = propsStream
      .pluck("location", "key")
      .distinctUntilChanged()
      .mapTo(false)
      .merge(onRequestChangeStream);

    const locationStream = propsStream
      .filter(
        fp.flow(
          fp.pick(["location", "marketplaceName", "app", "title"]),
          fp.every(Boolean),
        ),
      )
      .distinctUntilChanged(
        (x, y) =>
          x.location.pathname === y.location.pathname && x.title === y.title,
      )
      .do(props => {
        trackPageView(props.location, getTitle(props), fp.startCase(props.app));
      });

    return propsStream
      .combineLatest(openStream, locationStream, (props, open) => ({
        ...props,
        open,
        onRequestChange,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AppLayout.propTypes = {
  sheet: PropTypes.object,
  location: PropTypes.object,
  open: PropTypes.bool,
  onRequestChange: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
  sidebar: PropTypes.node.isRequired,
  appBarRightAction: PropTypes.node,
  className: PropTypes.string,
  marketplaceFavicon: PropTypes.string,
  appBarClassName: PropTypes.string,
};

AppLayout.defaultProps = {
  isTrial: false,
  trialCount: 0,
};

function AppLayout(props) {
  const {
    sheet: { classes },
  } = props;

  const mFavicon = props.marketplaceFavicon || favicon;

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{getTitle(props)}</title>
        <link rel="icon" type="image/png" href={mFavicon} sizes="16x16" />
      </Helmet>

      <AppLayoutAppBar
        title={props.title}
        rightAction={props.appBarRightAction}
        open={props.open}
        className={props.appBarClassName}
        onToggle={() => props.onRequestChange(!props.open)}
        location={props.location}
      />
      <AppLayoutDrawer
        open={props.open}
        onRequestChange={props.onRequestChange}
      >
        {props.sidebar}
      </AppLayoutDrawer>
      <div className={classes.content}>
        <div className={cx(classes.container, props.className)}>
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default enhancer(AppLayout);
