import { FETCH_PROFILE, SKIP_VERIFICATION } from "../reducers/ProfileReducer";
import { SUPPLIER } from "../../server/constants/AppTypes";
import { API_ROOT_URL } from "../../shared/constants/ApiConstants";

const ACCOUNT_URL = `${API_ROOT_URL}/account`;
const SUPPLIER_ACCOUNT_URL = `${API_ROOT_URL}/supplier/account`;

export function fetchUser(appType) {
  return (dispatch, getState, api) =>
    dispatch({
      type: FETCH_PROFILE,
      payload: api
        .get(appType === SUPPLIER ? SUPPLIER_ACCOUNT_URL : ACCOUNT_URL)
        .then((data) => ({ data })),
    });
}

export const updateUserDispatch = (payload) => (dispatch) =>
  dispatch({
    type: FETCH_PROFILE,
    payload,
  });

export function skipVerification() {
  return { type: SKIP_VERIFICATION };
}
