import { OrderedSet } from "immutable";

export const WING_AE_ID = 1;
export const WATANIA_ID = 2;
export const WING_SA_ID = 3;
export const TAWSEEL_ID = 4;
export const JAYEEK_ID = 5;
export const LEOPARD_ID = 6;
export const DEMO_ID = 7;
export const TFM_ID = 8;
export const ROYAL_ID = 9;
export const AL_RIYADA_ID = 10;
export const AIRBORNE_ID = 11;
export const EXPRESS_ID = 12;
export const RIVERS_ID = 13;
export const GALAXY_GATE_ID = 14;
export const ONAK_ID = 15;
export const FMH_ID = 16;
export const BLUE_PAGES_ID = 17;
export const WING_UZ_ID = 18;
export const MELLTOO_ID = 19;
export const EMIRATES_AUCTION_ID = 20;
export const FOOD_KART_ID = 21;
export const ALLOCATION_ID = 22;
export const ODIGGO_ID = 23;
export const OMAN_DEMO_ID = 24;
export const EGYPT_DEMO_ID = 25;
export const AZARBAIJAN_DEMO_ID = 26;
export const HAWKDXB_ID = 27;
export const APSITEX_ID = 28;
export const UAE_DEMO_ID = 29;
export const KSA_DEMO_ID = 30;
export const NPL_DEMO_ID = 31;
export const CITY_MALL_ID = 32;
export const FOODKART_UAE_ID = 33;
export const PAKISTAN_ID = 34;
export const PRO_CONNECT_ID = 35;
export const SASTODEAL_ID = 36;
export const JORDAN_DEMO_ID = 37;
export const UPLIFT_ID = 263306629;
export const DELIVERYUAE_ID = 277522643;
export const SUBWAY_ID = 305686267;
export const SWISH_NOW_ID = 320753914;
export const WING_TEST_APP = 261705766;
export const JOLIBEE_ID = 509233557;
export const GOODBASKET_ID = 563337336;
export const TIGER_EXPRESS_ID = 562369785;
export const AVON_ID = 615567581;
export const LIFE_PHARMACY_ID = 658118773;
export const WINSTON = 696547483;
export const UMID_QA = 495250946;

export default OrderedSet.of(
  WING_AE_ID,
  WATANIA_ID,
  WING_SA_ID,
  TAWSEEL_ID,
  FOOD_KART_ID,
  ALLOCATION_ID,
  WING_TEST_APP,
  AVON_ID,
  UMID_QA,
);
