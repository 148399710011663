import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { List, ListSubheader } from "@material-ui/core";
import { connect } from "react-redux";
import { getIsRTL } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
);

AppSidebarBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isRTL: PropTypes.bool,
};

function AppSidebarBlock({ className, isRTL, title, children }) {
  return (
    <List
      className={className}
      subheader={
        <ListSubheader
          style={{
            paddingLeft: !isRTL ? "16px" : 0,
            paddingRight: isRTL ? "16px" : 0,
            background: "#fff",
          }}
        >
          {title}
        </ListSubheader>
      }
    >
      {children}
    </List>
  );
}

export default enhancer(AppSidebarBlock);
