import { Map } from "immutable";
import {
  ADMIN,
  TOOLS,
  CUSTOMER,
  SUPPLIER,
  ALLOCATION,
  QALLOCATION,
} from "./AppTypes";
import {
  ROLE_ADMIN,
  ROLE_CUSTOMER,
  ROLE_TOOLS_VIEWER,
  ROLE_SUPPLIER_ADMIN,
} from "../../shared/constants/Authorities";

export default Map({
  [ADMIN]: ROLE_ADMIN,
  [CUSTOMER]: ROLE_CUSTOMER,
  [SUPPLIER]: ROLE_SUPPLIER_ADMIN,
  [TOOLS]: ROLE_TOOLS_VIEWER,
  [ALLOCATION]: ROLE_ADMIN,
  [QALLOCATION]: ROLE_ADMIN,
});
