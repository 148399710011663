import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector, formValues } from "redux-form";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormChipInput from "../form/FormChipInput";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getObjectId } from "../../helpers/FormUtils";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import { isValidObjectId } from "../../helpers/ValidateUtils";

const valueSelector = formValueSelector("BatchOrdersAssignToDriverDialog");

const enhancer = compose(
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  connect(
    state => ({
      orderBarcodes: valueSelector(state, "orderBarcodes"),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,

      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,

      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,

      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,

      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
  withHandlers({
    onSubmit: props => ({ driver, supplier, warehouse, ...restValues }) => {
      if (!props.onSubmit) {
        return null;
      }
      const values = { ...restValues };

      if (isValidObjectId(driver)) {
        values.driver = driver;
      }

      if (isValidObjectId(warehouse)) {
        values.warehouse = warehouse;
      }

      if (isValidObjectId(supplier)) {
        values.supplier = supplier;
      }

      return props.onSubmit(values);
    },
  }),
  reduxForm({
    form: "BatchOrdersAssignToDriverDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      orderBarcodes:
        fp.size(values.orderBarcodes) === 0 &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_orders")) ||
          "Select Orders"),
      driver:
        !isValidObjectId(values.driver) &&
        props.getLocalisationMessage("select_driver", "Select Driver"),
    }),
  }),
  formValues({
    supplier: "supplier",
  }),
);

BatchOrdersAssignToDriverDialog.propTypes = {
  submitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  disableBarcodes: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  classes: PropTypes.object,
  supplier: PropTypes.object,
  theme: PropTypes.object,
};

BatchOrdersAssignToDriverDialog.defaultProps = {
  disableBarcodes: false,
};

function BatchOrdersAssignToDriverDialog(props) {
  const { getLocalisationMessage, classes } = props;

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          minWidth: 600,
          maxWidth: 800,
          minHeight: 200,
        },
      }}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        {getLocalisationMessage("dispatch")}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <form>
          {props.isLoading ? (
            <FlexBox justify="center" align="center">
              <CircularProgress size={60} color="secondary" />
            </FlexBox>
          ) : (
            <FlexBox container={8} direction="column">
              <FlexBox flex={true}>
                <FormChipInput
                  name="orderBarcodes"
                  focus={true}
                  margin="normal"
                  disabled={props.disableBarcodes}
                  fullWidth={true}
                  label={getLocalisationMessage("orders_barcodes")}
                />
              </FlexBox>

              <FlexBox>
                <FormDriverAutoComplete
                  name="driver"
                  fullWidth={true}
                  margin="normal"
                  label={getLocalisationMessage("driver", "Driver")}
                  supplierId={getObjectId(props.supplier)}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type To Search...",
                  )}
                />
              </FlexBox>
            </FlexBox>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose} primary={true}>
          {getLocalisationMessage("dismiss")}
        </Button>

        <Button onClick={props.handleSubmit} primary={true}>
          {getLocalisationMessage("submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchOrdersAssignToDriverDialog);
