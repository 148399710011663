import React from "react";
import PropTypes from "prop-types";
import Navigate from "../router/Navigate";
import { captureException } from "../../helpers/ErrorTracker";
import { DASHBOARD_URL } from "../../constants/DashboardPathConstants";

NotFound.propTypes = { location: PropTypes.object };

export default function NotFound(props) {
  captureException(`Unknown Route: ${props.location.pathname}`, {
    extra: { location: props.location },
  });

  return <Navigate push={false} to={DASHBOARD_URL} />;
}
