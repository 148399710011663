import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import FacebookIcon from "../icons/FacebookIcon";
import { pureComponent } from "../../helpers/HOCUtils";
import { load } from "../../helpers/FacebookHelper";

const enhancer = compose(
  useSheet({
    button: {
      color: "#fff",
      marginTop: "6px",
      paddingLeft: "32px",
      backgroundColor: "#3b5998",
      "&:hover": { backgroundColor: "#6d84b4" },
      "&[disabled]": { backgroundColor: "#6d84b4", cursor: "wait" },
    },
    icon: {
      top: "6px",
      left: "0",
      position: "absolute",
      fill: "#fff",
      marginLeft: "8px",
    },
  }),
  mapPropsStream(propsStream => {
    const FBStream = Observable.defer(() => load()).startWith(null);

    return propsStream.combineLatest(FBStream, (props, FB) => ({
      ...props,
      disabled: props.disabled || !FB,
    }));
  }),
  pureComponent(fp.pick(["disabled", "className"])),
);

FacebookButton.propTypes = {
  FB: PropTypes.object,
  sheet: PropTypes.object,
  classes: PropTypes.object,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
};

FacebookButton.defaultProps = { type: "button" };

function FacebookButton({ sheet, classes, ...props }) {
  return (
    <Button
      {...props}
      className={cx(classes.button, props.className)}
      icon={<FacebookIcon className={classes.icon} />}
    >
      {props.label}
    </Button>
  );
}

export default enhancer(FacebookButton);
