import { Observable } from "rxjs";
import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";

const baseFilter = new DataListFilter({
  size: 20,
  status: ACTIVE,
  show_all: true,
});

const parseInput = name => ({ name });
const formatOption = fp.get("name");

const WarehouseAutoComplete = componentFromStream(propsStream => {
  const valueStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value"))
    .switchMap(({ input: { value } }) =>
      value && value.id && !value.name
        ? getCachedWarehouse(value.id)
            .map(item => ({ id: item.get("id"), name: item.get("name") }))
            .catch(() => Observable.of(value))
            .startWith(value)
        : Observable.of(value),
    );

  const optionsStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value.name"))
    .switchMap(
      ({
        marketplaceId,
        isDisableShowAll,
        postcodeIndexes,
        jurisdictionIds,
        warehouseIds,
        supplierId,
        includePostcode,
        input: { value },
      }) =>
        getWarehousePredictions(
          baseFilter
            .setSearch(value && value.name)
            .setValue(
              "marketplaceId",
              marketplaceId === 0 ? null : marketplaceId,
            )
            .setValue("supplier_ids", supplierId === 0 ? null : supplierId)
            .setValue("multi_marketplace", marketplaceId !== 0)
            .setValue("show_all", !isDisableShowAll)
            .setValue("postcode_indexes", postcodeIndexes)
            .setValue("jurisdiction_ids", jurisdictionIds)
            .setValue("include_postcode", includePostcode)
            .setValue("warehouse_ids", warehouseIds),
        )
          .map(fp.flow(fp.get("payload.data"), fp.toArray))
          .catch(() => Observable.of(List())),
    )
    .distinctUntilChanged(isEqualData)
    .withLatestFrom(propsStream)
    .map(([v, props]) =>
      props.disableP7 ? v.filter(x => x.id !== 19938417) : v,
    );

  return propsStream
    .map(
      fp.omit([
        "supplierId",
        "marketplaceId",
        "getCachedWarehouse",
        "getWarehousePredictions",
      ]),
    )
    .combineLatest(valueStream, optionsStream, (props, value, options) => (
      <AutoCompleteComponent
        {...props}
        options={options}
        filter={fp.stubTrue}
        parseInput={parseInput}
        formatOption={formatOption}
        input={{ ...props.input, value }}
      />
    ));
});

FormWarehouseAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,
  supplierId: PropTypes.number,
  validate: PropTypes.func,
  disableP7: PropTypes.bool,
  includePostcode: PropTypes.bool,
  hintText: PropTypes.node,
  label: PropTypes.node,

  marketplaceId: PropTypes.number,
};

FormWarehouseAutoComplete.defaultProps = {
  maxSearchResults: 10,
  marketplaceId: 0,
  supplierId: 0,
};

export default function FormWarehouseAutoComplete(props) {
  return <Field {...props} component={WarehouseAutoComplete} />;
}
