import { OrderedSet } from "immutable";

export const GENERIC = "generic";
export const PICKUP_ATTEMPT_FAILED = "pick_up_failed";
export const DELIVERY_ATTEMPT_FAILED = "delivery_failed";

export default OrderedSet.of(
  GENERIC,
  DELIVERY_ATTEMPT_FAILED,
  PICKUP_ATTEMPT_FAILED,
);
