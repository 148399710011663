import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import spring from "react-motion/lib/spring";
import Motion from "react-motion/lib/Motion";

/* eslint-disable-next-line react/prefer-stateless-function */
export default class HamburgerButton extends React.Component {
  static propTypes = {
    size: PropTypes.number,
    active: PropTypes.bool.isRequired,
    isRTL: PropTypes.bool,
    color: PropTypes.string,
  };

  static defaultProps = { size: 24, isRTL: false, color: "#fff" };

  render() {
    const { size, active, isRTL, color } = this.props;

    const height = size / 10;
    const top = size / 2 - height / 2;
    const offset = size / 8;
    const width = size * 0.75;

    const styles = {
      root: {
        display: "inline-block",
        position: "relative",
        width: size,
        height: size,
      },
      bar: {
        height,
        width,
        top,
        left: offset,
        right: offset,
        display: "inline-block",
        position: "absolute",
        backgroundColor: color,
      },
    };

    const motionStyle = {
      rootSize: spring(size),
      sideBarTop: spring(active ? 3.1 : height * 2),
      sideBarLeft: spring(active ? (isRTL ? -3 : 1) : 0),
      sideBarWidth: spring(active ? size / 1.5 / Math.sqrt(2) : width),
      sideBarRotate: spring(active ? (isRTL ? -45 : 45) : 0),
      centerBarWidth: spring(active ? size / 1.5 : width),
      centerBarOffset: spring(active ? size / 6 : offset),
    };

    return (
      <Motion style={motionStyle}>
        {({
          rootSize,
          sideBarTop,
          sideBarLeft,
          sideBarWidth,
          sideBarRotate,
          centerBarWidth,
          centerBarOffset,
        }) => (
          <span
            style={_.assign({}, styles.root, {
              width: rootSize,
              height: rootSize,
            })}
          >
            <span
              style={_.assign({}, styles.bar, {
                width: sideBarWidth,
                transform: [
                  `translate3d(${-sideBarLeft}px, ${-sideBarTop}px, 0)`,
                  `rotate3d(0, 0, 1, ${-sideBarRotate}deg)`,
                ].join(" "),
              })}
            />
            <span
              style={_.assign({}, styles.bar, {
                width: centerBarWidth,
                left: centerBarOffset,
                right: centerBarOffset,
              })}
            />
            <span
              style={_.assign({}, styles.bar, {
                width: sideBarWidth,
                transform: [
                  `translate3d(${-sideBarLeft}px, ${sideBarTop}px, 0)`,
                  `rotate3d(0, 0, 1, ${sideBarRotate}deg)`,
                ].join(" "),
              })}
            />
          </span>
        )}
      </Motion>
    );
  }
}
