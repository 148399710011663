import { Observable } from "rxjs";
import fp from "lodash/fp";
import loadScript from "load-script";
import { ObservableCache } from "./loading-cache";
import { getHash, getValue } from "./DataUtils";
import { getInitialStateForHelper } from "./InitialState";
import { updateQuery } from "../../shared/helpers/UrlUtils";
import { getMapPlaceApiKey, getMapProvider, getMapProviderKey } from "../../shared/reducers/AppReducer";

const SCRIPT_LOAD_CALLBACK = "init_ymap_callback";

const state = getInitialStateForHelper();
export const yandexMapKey =
  getMapProvider(state) === "yandex_map" ? getMapProviderKey(state) : "";

export const yandexPlaceApiKey =
  getMapProvider(state) === "yandex_map" ? getMapPlaceApiKey(state) : "";

const MAP_URL = updateQuery("https://api-maps.yandex.ru/2.1/", {
  lang: "en_US",
  onload: SCRIPT_LOAD_CALLBACK,
  apikey: yandexMapKey,
});

const maxCacheAge = 5 * 60 * 1000;
const getMaps = fp.get("ymaps");
const toPlainObject = value => JSON.parse(JSON.stringify(value));

const getYandexObjectResult = fp.flow(
  fp.get("GeoObjectCollection"),
  fp.get("metaDataProperty"),
  fp.get("GeocoderResponseMetaData"),
);

function loadMaps() {
  if (!loadMaps.promise) {
    loadMaps.promise = new Promise(resolve => {
      const ymaps = getMaps(window);

      if (ymaps) {
        resolve(ymaps);
      } else {
        loadScript(MAP_URL);

        window[SCRIPT_LOAD_CALLBACK] = ymaps2 => {
          delete window[SCRIPT_LOAD_CALLBACK];

          resolve(ymaps2);
        };
      }
    });
  }

  return loadMaps.promise;
}

export const getYandexMapsStream = () => Observable.defer(loadMaps);

const geocodeCache = new ObservableCache({
  maxAge: maxCacheAge,
  keyNormalizer: getHash,
  loader: query =>
    getYandexMapsStream()
      .switchMap(ymaps =>
        Observable.create(observer => {
          const service = ymaps.geocode(query, { json: true });
          service.then(
            res => {
              observer.next(getYandexObjectResult(res));
            },
            err => {
              observer.error(err);
            },
          );
        }),
      )
      .retryWhen(() => Observable.timer(5000))
      .map(toPlainObject),
});

export const getYandexGeoCode = request => geocodeCache.get(request);

const autocompleteCache = new ObservableCache({
  maxAge: maxCacheAge,
  keyNormalizer: getHash,
  loader: query =>
    getYandexMapsStream()
      .switchMap(ymaps =>
        Observable.create(observer => {
          ymaps.suggest(query).then(items => {
            observer.next(items);
          });
        }),
      )
      .retryWhen(() => Observable.timer(5000))
      .map(toPlainObject),
});

export const getPlacePredictions = request => autocompleteCache.get(request);

const SUGGEST_API = "https://search-maps.yandex.ru/v1/";
const GEOCODE_API = "https://geocode-maps.yandex.ru/1.x";

export const getPlacePredictions2 = request =>
  fetch(
    request && request.lat && request.lng
      ? `${SUGGEST_API}?apikey=6c06f9ca-044c-4518-8bab-0f7f83855b87&type=${request.type}&text=${request.text}&lang=${request.lang}&ll=${request.lng},${request.lat}&spn=${Math.abs(request.lng-50.824828)},${Math.abs(request.lat-41.721618)}&rspn=1`
      : `${SUGGEST_API}?apikey=6c06f9ca-044c-4518-8bab-0f7f83855b87&type=${request.type}&text=${request.text}&lang=${request.lang}`,
  )
    .then(items => items.json())
    .then(items => items.features);

export const getGeoCode = request =>
  fetch(
    `${GEOCODE_API}?apikey=${yandexMapKey}&format=json&lang=${request.lang}&geocode=${request.lng},${request.lat}`
  )
    .then(res => res.json())
    .then(res => res.response);

export const getGeoCodeByText = request =>
  fetch(
    request && request.lat && request.lng
      ? `${GEOCODE_API}?apikey=${yandexMapKey}&format=json&lang=${request.lang}&geocode=${request.geocode}&ll=${request.lng},${request.lat}&spn=${Math.abs(request.lng - 50.824828)},${Math.abs(request.lat - 41.721618)}&rspn=1`
      : `${GEOCODE_API}?apikey=${yandexMapKey}&format=json&lang=${request.lang}&geocode=${request.geocode}`,
  )
    .then(res => res.json())
    .then(res => getValue(res, "response.GeoObjectCollection.featureMember", []));
