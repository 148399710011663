import React from "react";
import LinkWithNext from "../router/LinkWithNext";
import { SIGN_IN_URL } from "../../constants/DashboardPathConstants";

SignInLink.defaultProps = { children: "Sign In" };

function SignInLink(props) {
  return <LinkWithNext {...props} to={SIGN_IN_URL} />;
}

export default SignInLink;
