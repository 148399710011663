import React from "react";
import PropTypes from "prop-types";
import Layer from "react-layer";

export default class LayerProvider extends React.Component {
  static propTypes = {
    layer: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
  };

  componentDidMount() {
    this.renderOverlay();
  }

  componentDidUpdate() {
    this.renderOverlay();
  }

  componentWillUnmount() {
    if (this.layer) {
      this.layer.destroy();
      this.layer = null;
    }
  }

  renderOverlay() {
    if (!this.layer) {
      this.layer = new Layer(document.body, () => this.props.layer);
    }

    this.layer.render(null, this);
  }

  render() {
    return React.Children.only(this.props.children);
  }
}
