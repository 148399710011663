import React, { useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox, { JUSTIFY_CENTER } from "../../components/ui-core/FlexBox";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { getCCPDF } from "../../helpers/FormUtils";
import { ButtonGroup, CircularProgress } from "@material-ui/core";
import { GetApp, Print } from "@material-ui/icons";
import {
  IS_DOWNLOADING,
  IS_PRINTING,
} from "../../components/orders-core/AdminPostForm";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";

export const IS_PRINTING2 = "isPrinting2";
export const IS_DOWNLOADING2 = "isDownloading2";
const enhancer = compose(
  connect(
    (state) => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);
      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

AdminCashCertificateContainer.propTypes = {
  getLocalisationMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
};

function AdminCashCertificateContainer(props) {
  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());

  const [stateCC, setStateCC] = useState({
    [IS_PRINTING]: false,
    [IS_PRINTING2]: false,
    [IS_DOWNLOADING]: false,
    [IS_DOWNLOADING2]: false,
    url: `${API_V2_ROOT_URL}/cash_report`,
  });
  const { getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      slug="cash_certificate"
      title={getLocalisationMessage("cash_certificate", "Cash Certificate")}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <FlexBox direction="column" style={{ margin: "2rem" }}>
          <FlexBox flex={true} direction="column">
            <FlexBox gutter={16} flex={true}>
              <FlexBox flex={true} direction="column">
                <DatePicker
                  format="dd/MM/yyyy"
                  autoOk={true}
                  animateYearScrolling={true}
                  disableFuture={true}
                  label={getLocalisationMessage("from_date")}
                  value={fromDate}
                  onChange={(v) => setFromDate(v)}
                />
              </FlexBox>

              <FlexBox flex={true} direction="column">
                <DatePicker
                  format="dd/MM/yyyy"
                  autoOk={true}
                  animateYearScrolling={true}
                  disableFuture={true}
                  value={toDate}
                  onChange={(v) => setToDate(v)}
                  label={getLocalisationMessage("to_date")}
                />
              </FlexBox>
            </FlexBox>
            <FlexBox justify={JUSTIFY_CENTER} style={{ marginTop: "1rem" }}>
              <ButtonGroup
                variant={CONTAINED}
                color={SECONDARY}
                style={{ marginRight: "1rem" }}
              >
                <CustomButton
                  onClick={() =>
                    getCCPDF(
                      stateCC,
                      setStateCC,
                      IS_PRINTING,
                      props.showErrorMessage,
                      fromDate,
                      toDate,
                      "ALL",
                    )
                  }
                  endIcon={
                    stateCC[IS_PRINTING] ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Print />
                    )
                  }
                >
                  {getLocalisationMessage(
                    "cash_certificate",
                    "Cash Certificate",
                  )}
                </CustomButton>
                <CustomButton
                  onClick={() =>
                    getCCPDF(
                      stateCC,
                      setStateCC,
                      IS_DOWNLOADING,
                      props.showErrorMessage,
                      fromDate,
                      toDate,
                      "ALL",
                    )
                  }
                >
                  {stateCC[IS_DOWNLOADING] ? (
                    <CircularProgress size={20} />
                  ) : (
                    <GetApp />
                  )}
                </CustomButton>
              </ButtonGroup>
              <ButtonGroup variant={CONTAINED} color={SECONDARY}>
                <CustomButton
                  onClick={() =>
                    getCCPDF(
                      stateCC,
                      setStateCC,
                      IS_PRINTING2,
                      props.showErrorMessage,
                      fromDate,
                      toDate,
                      "UZB,SNG,GLOBAL",
                    )
                  }
                  endIcon={
                    stateCC[IS_PRINTING2] ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Print />
                    )
                  }
                >
                  {getLocalisationMessage(
                    "кассовая справка по направлениям ",
                    "кассовая справка по направлениям ",
                  )}
                </CustomButton>
                <CustomButton
                  onClick={() =>
                    getCCPDF(
                      stateCC,
                      setStateCC,
                      IS_DOWNLOADING2,
                      props.showErrorMessage,
                      fromDate,
                      toDate,
                      "UZB,SNG,GLOBAL",
                    )
                  }
                >
                  {stateCC[IS_DOWNLOADING2] ? (
                    <CircularProgress size={20} />
                  ) : (
                    <GetApp />
                  )}
                </CustomButton>
              </ButtonGroup>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </MuiPickersUtilsProvider>
    </AdminAppLayout>
  );
}

export default enhancer(AdminCashCertificateContainer);
