import React from "react";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Notification from "./Notification";
import LayerProvider from "../portals/LayerProvider";
import { isEqualData } from "../../helpers/DataUtils";
import {
  getNotifications,
  hideNotification,
} from "../../reducers/NotificationsReducer";

const enhancer = compose(
  connect(state => ({ notifications: getNotifications(state) }), {
    hideNotification,
  }),
  mapPropsStream(propsStream =>
    propsStream
      .distinctUntilKeyChanged("notifications", isEqualData)
      .map(props => ({
        children: props.notifications
          .map(notification => (
            <Notification
              open={true}
              key={notification.hashCode()}
              uid={notification.hashCode()}
              type={notification.get("messageType")}
              onClick={() => props.hideNotification(notification)}
              onRequestClose={() => props.hideNotification(notification)}
              autoHideDuration={notification.get("autoHideDuration") || 4000}
            >
              {notification.get("message")}
            </Notification>
          ))
          .toArray(),
      })),
  ),
);

const Notifications = enhancer(props => <div>{props.children}</div>);

NotificationStackProvider.propTypes = { children: PropTypes.node.isRequired };

export default function NotificationStackProvider(props) {
  return (
    <LayerProvider layer={<Notifications />}>{props.children}</LayerProvider>
  );
}
