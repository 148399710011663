import { cloneDeep } from "lodash";
import { Map, fromJS } from "immutable";
import { combineReducers } from "redux-immutable";
import { getCurrentLanguage } from "../../reducers/LocalizationReducer";
import {
  UZBEK_LANGUAGE,
  ARABIC_LANGUAGE,
  FRENCH_LANGUAGE,
  ENGLISH_LANGUAGE,
  RUSSIAN_LANGUAGE,
  SERBIAN_LANGUAGE,
  SPANISH_LANGUAGE,
  TURKISH_LANGUAGE,
  HUNGARIAN_LANGUAGE,
  MONGOLIAN_LANGUAGE,
} from "../../constants/LocaleTypes";
import { injectReducer } from "../../../shared/helpers/ReducerContext";
import globalLocales from "./../../messages/global_locales.json";

const jsonObject = fromJS(cloneDeep(globalLocales));

const selector = injectReducer(
  "order_status_localisation",
  combineReducers({
    [ENGLISH_LANGUAGE]: () => jsonObject.get(ENGLISH_LANGUAGE) || {},
    [RUSSIAN_LANGUAGE]: () => jsonObject.get(RUSSIAN_LANGUAGE) || {},
    [UZBEK_LANGUAGE]: () => jsonObject.get(UZBEK_LANGUAGE) || {},
    [TURKISH_LANGUAGE]: () => jsonObject.get(TURKISH_LANGUAGE) || {},
    [FRENCH_LANGUAGE]: () => jsonObject.get(FRENCH_LANGUAGE) || {},
    [SPANISH_LANGUAGE]: () => jsonObject.get(SPANISH_LANGUAGE) || {},
    [MONGOLIAN_LANGUAGE]: () => jsonObject.get(MONGOLIAN_LANGUAGE) || {},
    [SERBIAN_LANGUAGE]: () => jsonObject.get(SERBIAN_LANGUAGE) || {},
    [ARABIC_LANGUAGE]: () => jsonObject.get(ARABIC_LANGUAGE) || {},
    [HUNGARIAN_LANGUAGE]: () => jsonObject.get(HUNGARIAN_LANGUAGE) || {},
  }),
);

export function getStatusLocalisation(state) {
  const langCode = getCurrentLanguage(state);

  return selector(state).get(langCode) || new Map();
}
