import { OrderedSet } from "immutable";

export const CASH = "cash";
export const NON_PAYMENT = "non_payment";
export const CARD = "card";
export const NON_CASH = "non_cash";
export const CREDIT_BALANCE = "credit_balance";
export const ADVANCE_BOOK = "advance_book";
export const COMBINED = "combined";
export const CORPORATE_INTERNAL = "corporate_internal";

export default OrderedSet.of(
  CASH,
  CARD,
  NON_PAYMENT,
  ADVANCE_BOOK,
  CREDIT_BALANCE,
);
export const filterFormPayTypes = OrderedSet.of(
  CASH,
  CARD,
  NON_PAYMENT,
  CREDIT_BALANCE,
  ADVANCE_BOOK,
);
export const CASH_CARD_NON_PAYMENT = OrderedSet.of(CASH, CARD, NON_PAYMENT);
export const CUSTOMER_PAYMENT_TYPES = OrderedSet.of(CASH, CARD);
export const freePayTypes = [
  NON_CASH,
  CREDIT_BALANCE,
  ADVANCE_BOOK,
  CORPORATE_INTERNAL,
];
