import WingApi from "../helpers/WingApi";
import { FETCH_MARKETPLACE } from "../reducers/MarketplaceReducer";
import { API_ROOT_URL } from "../../shared/constants/ApiConstants";

const MARKETPLACE_URL = `${API_ROOT_URL}/marketplace`;

export function loadMarketplace(filter) {
  return (getState, dispatch, api: WingApi) =>
    api.getStream(MARKETPLACE_URL, {
      params: filter.toRequestParams(),
    });
}

export function fetchMarketplace() {
  return (dispatch, getState, api) =>
    dispatch({
      type: FETCH_MARKETPLACE,
      payload: api.get(MARKETPLACE_URL).then(data => ({ data })),
    });
}
