import React from "react";
import useSheet from "react-jss";
import PropTypes from "prop-types";
import { CardContent } from "@material-ui/core";
import { Link } from "react-router";
import CenteredCard from "../deprecated/CenteredCard";
import { SIGN_OUT_URL } from "../../constants/DashboardPathConstants";

const enhancer = useSheet({ textRight: { textAlign: "right" } });

AreYouLost.propTypes = { sheet: PropTypes.object };

function AreYouLost({ sheet: { classes } }) {
  return (
    <CenteredCard>
      <CardContent>
        <h5>
          <strong>Are you lost?</strong>
        </h5>
        <br />
        <p>You are not allowed to view this page.</p>
        <div className={classes.textRight}>
          <Link to={SIGN_OUT_URL}>Sign Out</Link>
        </div>
      </CardContent>
    </CenteredCard>
  );
}

export default enhancer(AreYouLost);
