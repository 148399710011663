export const ROLE_ADMIN = "ROLE_ADMIN";

export const ROLE_FINANCE_ADMIN = "ROLE_FINANCE_ADMIN";
export const ROLE_FINANCE_USER = "ROLE_FINANCE_USER";
export const ROLE_FINANCE_APPROVER = "ROLE_FINANCE_APPROVER";
export const ROLE_FINANCE_VIEWER = "ROLE_FINANCE_VIEWER";
export const ROLE_RADAR_VIEWER = "ROLE_RADAR_VIEWER";
export const ROLE_OPERATOR = "ROLE_OPERATOR";
export const ROLE_SORTER = "ROLE_SORTER";

// Customer Role Types
export const ROLE_CUSTOMER = "ROLE_CUSTOMER";

// USER Role Types
export const ROLE_USER = "ROLE_USER";
