import React, { useEffect, useState } from "react";
import { Map } from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import {
  AppBar,
  Grid,
  IconButton,
  Tabs,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Close, DoneAll } from "@material-ui/icons";
import {
  getCurrentLanguage,
  getIsRTL,
  getMessages,
} from "../../reducers/LocalizationReducer";
import { withTheme } from "@material-ui/core/styles";
import useSheet from "react-jss";
import FlexBox from "../ui-core/FlexBox";
import Card from "@material-ui/core/Card";
import Tab from "@material-ui/core/Tab";
import CardContent from "@material-ui/core/CardContent";
import {
  formatDate,
  formatDateTimeToUrl,
  formatDateToUrl,
} from "../../helpers/FormatUtils";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {
  getPushLogs,
  updateAllPushLogs,
} from "../../api/admin/AdminNotificationApi";
import NotificationDialog from "./NotificationDialog";
import fp from "lodash/fp";
import { RUSSIAN_LANGUAGE, UZBEK_LANGUAGE } from "../../constants/LocaleTypes";
import ContentCopy from "../icons/ContentCopy";

export const formatNotificationLocalisation = (
  currentLanguage,
  postomat,
  orderBarcode,
  bookedTime,
  postomatAddress,
  copy,
  showSuccessMessage1,
  getLocalisationMessage,
) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return (
        <span
          style={{
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "170%",
            letterSpacing: "0.15px",
            textAlign: "justify",
          }}
        >
          Срок хранения отправления{" "}
          <span
            style={{
              textDecoration: "underline",
              fontWeight: "900",
              marginLeft: 5,
              marginRight: 5,
            }}
          >
            <span> {orderBarcode}</span>
            {copy && (
              <ContentCopy
                style={{ marginLeft: 5 }}
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  navigator.clipboard.writeText(orderBarcode);
                  showSuccessMessage1(
                    getLocalisationMessage("barcode_copied", "Barcode Copied"),
                  );
                }}
              />
            )}
            ,
          </span>
          размещенного в почтомате{" "}
          <span style={{ fontWeight: "900" }}>{postomat}</span> по адресу{" "}
          <span style={{ fontWeight: "900" }}>{postomatAddress}</span>{" "}
          <span style={{ fontWeight: "900", marginLeft: 5 }}>
            {formatDateTimeToUrl(new Date(bookedTime))}
          </span>
          , истек. Клиент не пришел забрать отправление. Пожалуйста, срочно
          направьте почтальона для забора этого отправления.
        </span>
      );
    case UZBEK_LANGUAGE:
      return (
        <span
          style={{
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "170%",
            letterSpacing: "0.15px",
            textAlign: "justify",
          }}
        >
          <span style={{ fontWeight: "900" }}>
            {formatDateTimeToUrl(new Date(bookedTime))}
          </span>{" "}
          da <span style={{ fontWeight: "900" }}>{postomatAddress}</span>{" "}
          manzilidagi <span style={{ fontWeight: "900" }}>{postomat}</span>{" "}
          pochtomatiga joylashtirilgan
          <span
            style={{
              fontWeight: "900",
              textDecoration: "underline",
              marginRight: 5,
              marginLeft: 5,
            }}
          >
            <span> {orderBarcode}</span>
            {copy && (
              <ContentCopy
                style={{ marginLeft: 5 }}
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  navigator.clipboard.writeText(orderBarcode);
                  showSuccessMessage1(
                    getLocalisationMessage("barcode_copied", "Barcode Copied"),
                  );
                }}
              />
            )}
          </span>
          jo'natmaning saqlash muddati tugadi. Mijoz jo'natmani olib ketish
          uchun kelmadi. Iltimos, ushbu buyurtmani zudlik bilan olib ketish
          uchun pochtachini yuboring.
        </span>
      );
    default:
      return (
        <span
          style={{
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "170%",
            letterSpacing: "0.15px",
            textAlign: "justify",
          }}
        >
          The storage period for the order{" "}
          <span
            style={{
              fontWeight: "900",
              textDecoration: "underline",
              marginRight: 5,
              marginLeft: 5,
            }}
          >
            <span> {orderBarcode}</span>
            {copy && (
              <ContentCopy
                style={{ marginLeft: 5 }}
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  navigator.clipboard.writeText(orderBarcode);
                  showSuccessMessage1(
                    getLocalisationMessage("barcode_copied", "Barcode Copied"),
                  );
                }}
              />
            )}
          </span>
          , placed in lockerpost{" "}
          <span style={{ fontWeight: "900" }}>{postomat}</span> at the address{" "}
          <span style={{ fontWeight: "900" }}>{postomatAddress}</span> in{" "}
          <span style={{ fontWeight: "900" }}>
            {formatDateTimeToUrl(new Date(bookedTime))}
          </span>{" "}
          has expired. The customer has not come to pick up the order. Please
          send available driver to pickup this order immediately.
        </span>
      );
  }
};

export const formatActNotificationLocalisation = (
  currentLanguage,
  createdBy,
  actNumber,
  sendTime,
) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return (
        <span
          style={{
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "170%",
            letterSpacing: "0.15px",
            textAlign: "justify",
          }}
        >
          {" "}
          <span style={{ fontWeight: "900" }}>
            {formatDateToUrl(new Date(sendTime))} в{" "}
            {formatDate(new Date(sendTime), "HH:mm")}
          </span>{" "}
          {"  "}
          <span style={{ fontWeight: "900" }}>{createdBy}</span> составил акт №
          <span
            style={{
              fontWeight: "900",
              marginRight: 5,
              marginLeft: 5,
            }}
          >
            <span> {actNumber}</span>
          </span>
          , направленный вам.
        </span>
      );
    case UZBEK_LANGUAGE:
      return (
        <span
          style={{
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "170%",
            letterSpacing: "0.15px",
            textAlign: "justify",
          }}
        >
          {" "}
          <span style={{ fontWeight: "900" }}>
            {formatDateTimeToUrl(new Date(sendTime))}
          </span>{" "}
          da {"  "}
          <span style={{ fontWeight: "900" }}>{createdBy}</span> tomonidan sizga
          yo’naltirilgan
          <span
            style={{
              fontWeight: "900",
              marginRight: 5,
              marginLeft: 5,
            }}
          >
            <span> {actNumber}</span>
          </span>
          raqamli akt dalolatnomasi yaratildi.
        </span>
      );
    default:
      return (
        <span
          style={{
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "170%",
            letterSpacing: "0.15px",
            textAlign: "justify",
          }}
        >
          Act
          <span
            style={{
              fontWeight: "900",
              marginRight: 5,
              marginLeft: 5,
            }}
          >
            <span> {actNumber}</span>
          </span>{" "}
          has been created for you by{" "}
          <span style={{ fontWeight: "900" }}>{createdBy}</span> on{" "}
          <span style={{ fontWeight: "900" }}>
            {formatDateTimeToUrl(new Date(sendTime))}
          </span>
        </span>
      );
  }
};

export const formatActReplyNotificationLocalisation = (
  currentLanguage,
  createdBy,
  actNumber,
  sendTime,
) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return (
        <span
          style={{
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "170%",
            letterSpacing: "0.15px",
            textAlign: "justify",
          }}
        >
          {" "}
          <span style={{ fontWeight: "900" }}>
            {formatDateToUrl(new Date(sendTime))} в{" "}
            {formatDate(new Date(sendTime), "HH:mm")}
          </span>{" "}
          {"  "}
          <span style={{ fontWeight: "900" }}>{createdBy}</span> ответил на
          направленный вами акт №
          <span
            style={{
              fontWeight: "900",
              marginRight: 5,
              marginLeft: 5,
            }}
          >
            <span> {actNumber}</span>
          </span>
        </span>
      );
    case UZBEK_LANGUAGE:
      return (
        <span
          style={{
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "170%",
            letterSpacing: "0.15px",
            textAlign: "justify",
          }}
        >
          Sizning{" "}
          <span
            style={{
              fontWeight: "900",
              marginRight: 5,
              marginLeft: 5,
            }}
          >
            <span> {actNumber}</span>
          </span>{" "}
          raqamli akt dalolatnomangizga{" "}
          <span style={{ fontWeight: "900" }}>
            {formatDateTimeToUrl(new Date(sendTime))}
          </span>{" "}
          da {"  "}
          <span style={{ fontWeight: "900" }}>{createdBy}</span> tomonidan javob
          qaytarildi.
        </span>
      );
    default:
      return (
        <span
          style={{
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "170%",
            letterSpacing: "0.15px",
            textAlign: "justify",
          }}
        >
          A reply is sent to your ACT
          <span
            style={{
              fontWeight: "900",
              marginRight: 5,
              marginLeft: 5,
            }}
          >
            <span> {actNumber}</span>,
          </span>{" "}
          by <span style={{ fontWeight: "900" }}>{createdBy}</span> on{" "}
          <span style={{ fontWeight: "900" }}>
            {formatDateTimeToUrl(new Date(sendTime))}
          </span>
        </span>
      );
  }
};

const enhancer = compose(
  withTheme,
  useSheet({
    title: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
  connect(state => ({
    i18n: getMessages(state),
    currentLanguage: getCurrentLanguage(state),
    isRTL: getIsRTL(state),
  })),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
);

LockerPostDrawer.propTypes = {
  open: PropTypes.bool,
  tabValue: PropTypes.string,
  currentLanguage: PropTypes.string,
  onRequestChange: PropTypes.func.isRequired,
  setLocationQuery: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
};

function LockerPostDrawer(props) {
  const { i18n, tabValue } = props;
  const [tab, setTab] = useState(tabValue || "SENT");
  const [openDialog, setOpenDialog] = useState(null);

  const tabs = [
    {
      title: i18n.get("new"),
      value: "SENT",
    },
    {
      title: i18n.get("seen", "Seen"),
      value: "VIEWED",
    },
  ];

  const [pushLogs, setPushLogs] = useState([]);

  useEffect(() => {
    getPushLogs(tab).then(res => {
      if (res && res.data) {
        setPushLogs(res.data);
      }
    });
  }, [tab]);

  return (
    <SwipeableDrawer
      docked={false}
      openSecondary={true}
      open={props.open}
      onClose={() => props.onRequestChange(false)}
      anchor="right"
      variant="temporary"
    >
      {openDialog && (
        <NotificationDialog
          setRefresh={props.setRefresh}
          currentLanguage={props.currentLanguage}
          setLocation={props.setLocation}
          onClose={() => props.onRequestChange(false)}
          setTab={setTab}
          open={openDialog}
          setOpen={setOpenDialog}
        />
      )}
      <AppBar position="static">
        <Toolbar>
          <FlexBox
            align="center"
            justify="space-between"
            style={{ width: "100%" }}
          >
            <FlexBox align="center">
              <IconButton
                onClick={() => {
                  props.onRequestChange(false);
                  props.setLocationQuery(
                    fp.flow(fp.unset("push_log_id"), fp.unset("tabValue")),
                  );
                }}
              >
                <Close />
              </IconButton>
              <Typography variant="h6" className={props.classes.title}>
                {i18n.get("notification", "Notification")}
              </Typography>
            </FlexBox>
            <FlexBox justify="flex-end">
              <IconButton
                onClick={() => {
                  updateAllPushLogs().then(() => {
                    setTab("VIEWED");
                  });
                }}
              >
                <DoneAll />
              </IconButton>
            </FlexBox>
          </FlexBox>
        </Toolbar>
      </AppBar>
      <Grid container={true} fullWidth={true} style={{ width: 450 }}>
        <Grid item={true} md={12} style={{ padding: 5 }}>
          <Tabs
            variant="fullWidth"
            value={tab}
            onChange={(event, value) => {
              setPushLogs([]);
              setTab(value);
            }}
            aria-label="disabled tabs example"
          >
            {tabs.map(t => (
              <Tab key={t.value} value={t.value} label={t.title} />
            ))}
          </Tabs>
        </Grid>

        <Grid item={true} md={12}>
          {pushLogs && pushLogs.length > 0 ? (
            <FlexBox direction="column" flex={true} style={{ gap: 8 }}>
              {pushLogs.map(item => (
                <Card
                  style={{
                    height: "100%",
                    cursor: tab === "SENT" ? "pointer" : "auto",
                  }}
                >
                  <CardContent>
                    <FlexBox
                      flex={true}
                      style={{ gap: 8 }}
                      direction="column"
                      onClick={() => {
                        if (tab === "SENT") {
                          setOpenDialog(item.id);
                        }
                      }}
                    >
                      <Typography
                        style={{ color: "#3F51B5", fontWeight: "500" }}
                      >
                        {item.type === "ACT" || item.type === "ACT_REPLY"
                          ? i18n.get("attention", "Attention!")
                          : item.type === "NOTIFICATION"
                          ? item.subject
                          : i18n.get(
                              "order_storage_limit_in_locker_post_is_reached",
                              "Order storage limit in LockerPost is reached",
                            )}
                      </Typography>
                      {item.type === "LOCKER_POST" && (
                        <Typography color="textSecondary">
                          {formatDateTimeToUrl(new Date(item.sent_time))}
                        </Typography>
                      )}
                      <Typography variant="body2" component="p">
                        {item.type === "ACT"
                          ? formatActNotificationLocalisation(
                              props.currentLanguage,
                              item.username,
                              item.act_number,
                              item.sent_time,
                            )
                          : item.type === "ACT_REPLY"
                          ? formatActReplyNotificationLocalisation(
                              props.currentLanguage,
                              item.username,
                              item.act_number,
                              item.sent_time,
                            )
                          : item.type === "NOTIFICATION"
                          ? item.message
                          : formatNotificationLocalisation(
                              props.currentLanguage,
                              item.warehouse_name,
                              item.barcode,
                              item.dispatched_date,
                              item.locker_post_address,
                            )}
                      </Typography>
                    </FlexBox>
                  </CardContent>
                </Card>
              ))}
            </FlexBox>
          ) : (
            <span />
          )}
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
}

export default enhancer(LockerPostDrawer);
