import React, { useEffect } from "react";
import { Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import AreYouLost from "./AreYouLost";
import NavigateToSignIn from "../auth/NavigateToSignIn";
import Notification from "../notifications/Notification";
import { fetchUser } from "../../actions/ProfileActions";
import { fetchMarketplace } from "../../actions/MarketplaceActions";
import { fetchUserAccount } from "../../actions/UserAccountActions";
import {
  getUserAccount,
  getUserAccountAuthorities,
  getUserAccountPhone,
  isUserAccountFetchFailed,
  isUserAccountVerified,
} from "../../reducers/UserReducer";
import {
  getUser,
  getUserAuthorities,
  getUserPhone,
  isUserFetchFailed,
  isUserVerified,
} from "../../reducers/ProfileReducer";
import { getMarketplace } from "../../reducers/MarketplaceReducer";
import {
  getAppMarketplace,
  getAppName,
  shouldSkipVerification,
} from "../../../shared/reducers/AppReducer";
import { isTokenValid } from "../../../shared/reducers/AuthReducer";
import { CUSTOMER, SUPPLIER } from "../../../server/constants/AppTypes";
import Preloader from "../../../shared/components/preloader/Preloader";
import { getMessage } from "../../reducers/LocalizationReducer";
import { fetchCustomer } from "../../actions/CustomerProfileActions";
import { getCustomer } from "../../reducers/CustomerReducer";

const enhancer = compose(
  connect(
    state => ({
      user: getUser(state),
      customer: getCustomer(state),
      marketplace: getMarketplace(state),
      appMarketplace: getAppMarketplace(state),
      userAccount: getUserAccount(state),
      app: getAppName(state),
      isTokenValid: isTokenValid(state),
      userFetchFailed:
        getAppName(state) === SUPPLIER
          ? isUserAccountFetchFailed(state)
          : isUserFetchFailed(state),
      phoneNumber:
        getAppName(state) === SUPPLIER
          ? getUserAccountPhone(state)
          : getUserPhone(state),
      userAuthorities:
        getAppName(state) === SUPPLIER
          ? getUserAccountAuthorities(state)
          : getUserAuthorities(state),
      isUserVerified:
        getAppName(state) === SUPPLIER
          ? isUserAccountVerified(state) || shouldSkipVerification(state)
          : isUserVerified(state) || shouldSkipVerification(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { fetchUser, fetchUserAccount, fetchMarketplace, fetchCustomer },
  ),
);

DashboardRoot.propTypes = {
  children: PropTypes.node,
  fetchUser: PropTypes.func,
  fetchCustomer: PropTypes.func,
  fetchMarketplace: PropTypes.func,
  fetchUserAccount: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  user: PropTypes.instanceOf(Map),
  customer: PropTypes.instanceOf(Map),
  marketplace: PropTypes.instanceOf(Map),
  appMarketplace: PropTypes.number,
  userAccount: PropTypes.instanceOf(Map),
  userFetchFailed: PropTypes.bool,
  phoneNumber: PropTypes.string,
  isUserVerified: PropTypes.bool,
  isTokenValid: PropTypes.bool,
  userAuthorities: PropTypes.array,
  app: PropTypes.string,
};

function DashboardRoot(props) {
  const isLoading =
    props.app === CUSTOMER
      ? !props.user || !props.marketplace || !props.customer
      : props.app === SUPPLIER
      ? !props.user || !props.marketplace || !props.userAccount
      : !props.user || !props.marketplace;

  useEffect(() => {
    if (props.isTokenValid && !props.user) {
      const delayDebounceFn = setTimeout(() => {
        props.fetchUser(props.app);
      }, 1);
      return () => clearTimeout(delayDebounceFn);
    }
    return null;
  }, [props.isTokenValid, props.user]);
  useEffect(() => {
    if (props.isTokenValid && !props.marketplace) {
      const delayDebounceFn = setTimeout(() => {
        props.fetchMarketplace();
      }, 1);
      return () => clearTimeout(delayDebounceFn);
    }
    return null;
  }, [props.isTokenValid, props.marketplace]);
  useEffect(
    () =>
      props.isTokenValid &&
      !props.userAccount &&
      props.app === SUPPLIER &&
      props.fetchUserAccount(),
    [props.isTokenValid, props.userAccount, props.app],
  );

  useEffect(
    () =>
      props.isTokenValid &&
      !props.customer &&
      props.app === CUSTOMER &&
      props.fetchCustomer(),
    [props.isTokenValid, props.customer, props.app],
  );

  if (!props.isTokenValid) {
    return <NavigateToSignIn />;
  }

  if (props.userFetchFailed) {
    return (
      <Notification
        open={true}
        type="error"
        uid="userFetchFailed"
        action={<Button onClick={props.fetchUser}>Повторить</Button>}
      >
        Не удалось загрузить данные учетной записи
      </Notification>
    );
  }

  if (isLoading) {
    return <Preloader show={true} />;
  }

  if (props.appMarketplace !== props.marketplace.get("marketplace_id")) {
    return <AreYouLost />;
  }
  return props.children || null;
}

export default enhancer(DashboardRoot);
