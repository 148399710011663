import React, { useRef } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import { Button, Fab, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { MyLocation as MapsMyLocation } from "@material-ui/icons";
import { FitBounds, Marker as LMarker, PanTo as LPanTo } from "react-leflet-map-components";
import Geolocation from "../maps/Geolocation";
import FlexBox, { FLEX_NOSHRINK } from "../ui-core/FlexBox";
import ModalPaper from "../ui-core/ModalPaper";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import { renderIf } from "../../helpers/HOCUtils";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { primary1 } from "../../../shared/theme/main-theme";
import { getMapProvider, isMapKeyAvailable } from "../../../shared/reducers/AppReducer";
import FormYandexGeoAutoComplete from "../form/FormYandexGeoAutoComplete";
import PageLoading from "../ui-core/PageLoading";
import YandexMapWrapper from "../maps/YandexMapWrapper";
import { Placemark } from "react-yandex-maps";
import { YANDEX_MAP_PROVIDER } from "../../../shared/constants/MapsControllerConstants";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      mapProvider: getMapProvider(state),
      isMapKeyAvailable: isMapKeyAvailable(state),
      getLocalisationMessage,
    };
  }),
  renderIf("open"),
  useSheet({
    body: { padding: 0, position: "relative" },
    map: { height: "550px" },
    yandexMap: { flex: "1 1 0%", zIndex: 1, position: "relative" },
    modal: {
      minWidth: "1200px",
      maxWidth: "1200px",
      minHeight: 550
    },
    actions: {
      zIndex: 1001,
      top: "12px",
      left: "12px",
      right: "12px",
      padding: "0 8px",
      marginLeft: "45px",
      position: "absolute",
    },
    geoField: {
      // [responsive("$xs")]: { display: "none" },
    },
    geoFieldWrapper: {
      padding: 10,
    },
    point: {
      position: "absolute",
      padding: 10,
      right: 0,
      bottom: 0,
      zIndex: 1000,
    },
  }),
  reduxForm({ form: "FormMapPostPinDialog", enableReinitialize: true }),
  formValues({ location: "location" }),
);

FormMapPostPinDialog.propTypes = {
  classes: PropTypes.object,
  change: PropTypes.func,
  children: PropTypes.node,
  handleSubmit: PropTypes.func,

  location: PropTypes.object,
  mapCenter: PropTypes.object,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,

  language: PropTypes.string,
  latLon: PropTypes.object,

  type: PropTypes.string,
  mapProvider: PropTypes.string,
  setStateLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func,
  setZoom: PropTypes.func,
  isLoading: PropTypes.bool,
  zoom: PropTypes.number,
};

FormMapPostPinDialog.defaultProps = {
  onRequestClose: null,
  zoom: 18,
};

function FormMapPostPinDialog(props) {
  const { classes, getLocalisationMessage, isLoading } = props;
  const isYandexMap = props.mapProvider === YANDEX_MAP_PROVIDER;
  const mapRef = useRef(null);

  return (
    <ModalPaper
      open={props.open}
      onRequestClose={props.onRequestClose}
      paperClassName={classes.modal}
    >
      <PageLoading isLoading={isLoading} />
      <Paper className={classes.actions}>
        <FlexBox wrap={true}>
          <FlexBox flex={FLEX_NOSHRINK} className={classes.geoFieldWrapper}>
            <FormYandexGeoAutoComplete
              fullWidth={true}
              language={props.language}
              location={props.latLon}
              type={props.type}
              name="location"
              data-cy="search_geo"
              underlineShow={false}
              className={classes.geoField}
              placeholder={getLocalisationMessage(
                "type_to_search_3_letters",
                "Type to Search (3 letters minimum)",
              )}
              onChange={() => {
                props.setZoom(18);
                props.setIsLoading(true);
              }}
            />
          </FlexBox>

          <FlexBox align="center" style={{ marginLeft: "auto" }}>
            {props.onRequestClose && (
              <Button onClick={props.onRequestClose}>
                {" "}
                {getLocalisationMessage("dismiss", "Dismiss")}{" "}
              </Button>
            )}
            <Button onClick={props.handleSubmit}>
              {" "}
              {getLocalisationMessage("submit", "Submit")}{" "}
            </Button>
          </FlexBox>
        </FlexBox>
      </Paper>

      <div className={classes.closeButton} />
      {isYandexMap ? (
        <YandexMapWrapper
          className={classes.map}
          height={550}
          zoom={props.zoom}
          instanceRef={mapRef}
          modules={["util.bounds"]}
          mapCenter={props.location}
          onClick={event => {
            const x = event.get("coords");
            props.change("location", {
              lat: x[0],
              lng: x[1],
            });

            props.setIsLoading(true);
            props.setZoom(18);

            props.setStateLocation(
              {
                lat: x[0],
                lng: x[1],
              },
            );
          }}
        >
          {isValidCoordinates(props.location) && (
            <Placemark geometry={[props.location.lat, props.location.lng]}/>
          )}
        </YandexMapWrapper>
      ) : (
        <LeafletMapWrapper
          data-cy="search_geo"
          className={classes.map}
          mapCenter={props.mapCenter}
          hasZoomControl={true}
          position="topleft"
          onClick={x => {
            props.setStateLocation(
              {
                lat: x.latlng.lat,
                lng: x.latlng.lng,
              },
            );
            props.setZoom(18);
            props.change("location", {
              lat: x.latlng.lat,
              lng: x.latlng.lng,
            });
            props.setIsLoading(true);
          }}
        >
          {isValidCoordinates(props.location) && (
            <div>
              <LPanTo latLng={{ lat: props.location.lat, lng: props.location.lng}} />
              <LMarker position={{ lat: props.location.lat, lng: props.location.lng}} />
              <FitBounds latLngBounds={[{ lat: props.location.lat, lng: props.location.lng}]} />
            </div>
          )}
        </LeafletMapWrapper>
      )}


      <div className={classes.point}>
        <Geolocation
          onSuccess={({ coords: { latitude, longitude } }) => {
            props.change("location", { lat: latitude, lng: longitude });
            props.setStateLocation(
              { lat: latitude, lng: longitude },
            );
            props.setZoom(18);
            props.setIsLoading(true);
          }}
          render={({ getCurrentPosition }) => (
            <div>
              <Fab
                data-cy="geo_find_me"
                size="small"
                onClick={getCurrentPosition}
                style={{ backgroundColor: "#fff" }}
              >
                <MapsMyLocation style={{ fill: primary1 }} />
              </Fab>
            </div>
          )}
        />
      </div>
      {props.children}
    </ModalPaper>
  );
}

export default enhancer(FormMapPostPinDialog);
