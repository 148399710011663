import fp from "lodash/fp";

export const parseString = fp.flow(fp.trim, fp.split(","), fp.compact, fp.uniq);
export const parseStringNotUnique = fp.flow(fp.trim, fp.split(","), fp.compact);
export const parseIntString = fp.flow(parseString, fp.map(fp.toInteger));
export const stringifyArray = fp.flow(
  fp.castArray,
  fp.compact,
  fp.map(fp.trim),
  fp.compact,
  fp.uniq,
  fp.join(","),
);
