import { OrderedSet } from "immutable";

export const UNASSIGNED = "unassigned";
export const ASSIGNED_TO_COURIER = "assigned_to_courier";
export const ACCEPTED = "accepted";
export const ON_HIS_WAY = "on_his_way";
export const ARRIVED = "arrived";
export const PICKED_UP = "picked_up";
export const PICKED_UP_AUTO = "picked_up_auto";
export const IN_SORTING_FACILITY = "in_sorting_facility";
export const IN_TRANSIT = "in_transit";
export const OUT_FOR_DELIVERY = "out_for_delivery";
export const ARRIVED_TO_DELIVERY_ADDRESS = "arrived_to_delivery_address";
export const DELIVERY_FAILED = "delivery_failed";
export const TO_BE_RETURNED = "to_be_returned";
export const RETURNED_TO_ORIGIN = "returned_to_origin";
export const CANCELLED = "cancelled";
export const DRIVER_REJECTED = "driver_rejected";
export const COMPLETED = "completed";
export const COD_TRANSFERRED = "cod_transferred";
export const ON_HOLD = "on_hold";
export const PICK_UP_ATTEMPT = "pick_up_attempt";
export const DELIVERY_ATTEMPT = "delivery_attempt";
export const OUT_FOR_RETURN = "out_for_return";
export const RETURNING_TO_ORIGIN = "returning_to_origin";

export const OTHER_PICKUP_ATTEMPT = "other_pickup_attempt";
export const OTHER_DELIVERY_ATTEMPT = "other_delivery_attempt";

export const FIRST_PICKUP_ATTEMPT = "first_pickup_attempt";
export const SECOND_PICKUP_ATTEMPT = "second_pickup_attempt";
export const THIRD_PICKUP_ATTEMPT = "third_pickup_attempt";

export const FIRST_DELIVERY_ATTEMPT = "first_delivery_attempt";
export const SECOND_DELIVERY_ATTEMPT = "second_delivery_attempt";
export const THIRD_DELIVERY_ATTEMPT = "third_delivery_attempt";

export const BAD_RECIPIENT_ADDRESS = "bad_recipient_address";
export const DELIVERY_SCHEDULED = "delivery_scheduled";
export const RECIPIENT_NOT_AVAILABLE = "recipient_not_available";
export const RECIPIENT_MOBILE_SWITCHED_OFF = "recipient_mobile_switched_off";
export const RECIPIENT_MOBILE_WRONG = "recipient_mobile_wrong";
export const RECIPIENT_ADDRESS_CHANGE_REQUESTED =
  "recipient_address_change_requested";
export const COD_NOT_READY = "cod_not_ready";
export const DELIVERY_REJECTED = "delivery_rejected";
export const RECIPIENT_MOBILE_NO_RESPONSE = "recipient_mobile_no_response";
export const FUTURE_DELIVERY_REQUESTED = "future_delivery_requested";
export const OUT_OF_DELIVERY_AREA = "out_of_delivery_area";
export const UNABLE_TO_ACCESS_RECIPIENT_PREMISES =
  "unable_to_access_recipient_premises";
export const WRONG_SHIPMENT = "wrong_shipment";
export const INCOMPLETE_PARCEL = "incomplete_parcel";
export const DELIVERY_DELAY_DUE_TO_LATE_BOOKING =
  "delivery_delay_due_to_late_booking";
export const NO_TIME_FOR_DELIVERY = "no_time_for_delivery";
export const NO_CAPACITY_FOR_DELIVERY = "no_capacity_for_delivery";
export const ID_OR_DOCUMENT_REQUIRED_MISSING =
  "id_or_document_required_missing";
export const BAD_WEATHER_DURING_DELIVERY = "bad_weather_during_delivery";
export const RECIPIENT_NAME_MISSING = "recipient_name_missing";
export const COLLECTION_ARRANGED_OR_REQUESTED =
  "collection_arranged_or_requested";
export const DESTROYED_ON_CUSTOMER_REQUEST = "destroyed_on_customer_request";
export const LOST_OR_DAMAGED = "lost_or_damaged";
export const IN_TRANSIT_TO_SUPPLIER = "in_transit_to_supplier";
export const TRANSITED_TO_SUPPLIER = "transited_to_supplier";
export const PREPARED_FOR_TRANSIT = "prepared_for_transit";

export const DISPATCHED = "dispatched";
export const PROCESSING_AT_WAREHOUSE = "processing_at_warehouse";
export const MISROUTED = "misrouted";

export const PARTIAL_COMPLETE = "partial_complete";
export const PENDING_ASSIGN_TO_COURIER = "pending_assign_to_courier";
export const ALL_FAILED = "all_failed";

export const PARTIALLY_IN_SORTING_FACILITY = "partially_in_sorting_facility";
export const IN_DIFFERENT_WAREHOUSE = "in_different_warehouse";

export const OFFICE_CLOSED = "office_closed";
export const LOST_OR_DAMAGED_CLOSED = "lost_or_damaged_closed";

export const RECIPIENT_WANTS_INSPECT_ITEM = "recipient_wants_inspect_item";
export const OTHER_REASON = "other_reason";
export const LOST = "lost";
export const DAMAGED = "damaged";
export const REJECTED = "rejected";
export const CANCELLED_WITH_REFUND = "cancelled_with_refund";
export const ISSUED_TO_RECIPIENT = "issued_to_recipient";
export const READY_FOR_DELIVERY = "ready_for_delivery";

export const SENT_TO_CUSTOMS = "sent_to_customs";
export const HOLD_ON_AT_CUSTOMS = "hold_on_at_customs";
export const RETURNED_FROM_CUSTOMS = "returned_from_customs";
export const NEED_TO_PICKED_UP_FROM_LOCKER_POST =
  "need_to_pick_up_from_locker_post";

export const RECEIVER_DEAD = "receiver_dead";
export const RECEIVER_NOT_LIVES_THERE = "receiver_not_lives_there";
export const INCOMPLETE_ADDRESS = "incomplete_address";
export const RECEIVER_REFUSE = "receiver_refuse";
export const NOT_AT_HOME = "not_at_home";
export const DIDNT_APPEAR_ON_NOTICE = "didnt_appear_on_notice";
export const DEFECT = "defect";
export const TRY_PERFORM = "try_perform";
export const READY_FOR_ISSUE = "ready_for_issue";
export const RETENTION_PERIOD_HAS_EXPIRED = "retention_period_has_expired";
export const ORGANIZATION_WITH_GIVEN_ADDRESS_NOT_FOUND =
  "organization_with_given_address_not_found";
export const RETURNED_FROM_LOCKER_POST = "returned_from_locker_post";
export const IN_LOCKER_POST = "in_locker_post";

export const RTOStatuses = OrderedSet.of(
  RECEIVER_DEAD,
  RECEIVER_NOT_LIVES_THERE,
  INCOMPLETE_ADDRESS,
  RECEIVER_REFUSE,
  NOT_AT_HOME,
  DIDNT_APPEAR_ON_NOTICE,
  DEFECT,
  ORGANIZATION_WITH_GIVEN_ADDRESS_NOT_FOUND,
  RETENTION_PERIOD_HAS_EXPIRED,
  RETURNED_TO_ORIGIN,
  RETURNING_TO_ORIGIN,
);

export const HybridStatuses = OrderedSet.of(
  RECEIVER_DEAD,
  RECEIVER_NOT_LIVES_THERE,
  INCOMPLETE_ADDRESS,
  RECEIVER_REFUSE,
  NOT_AT_HOME,
  DIDNT_APPEAR_ON_NOTICE,
  DEFECT,
  ORGANIZATION_WITH_GIVEN_ADDRESS_NOT_FOUND,
);
export const FailedStatusCodes = OrderedSet.of(
  LOST,
  DAMAGED,
  CANCELLED_WITH_REFUND,
  DESTROYED_ON_CUSTOMER_REQUEST,
  WRONG_SHIPMENT,
  UNABLE_TO_ACCESS_RECIPIENT_PREMISES,
  OUT_OF_DELIVERY_AREA,
  RECIPIENT_NOT_AVAILABLE,
  OTHER_REASON,
);
export const finalStatuses = [
  COMPLETED,
  CANCELLED,
  CANCELLED_WITH_REFUND,
  RETURNED_TO_ORIGIN,
  ISSUED_TO_RECIPIENT,
];
export const isShowOrderButton = [
  PREPARED_FOR_TRANSIT,
  IN_SORTING_FACILITY,
  RETURNED_FROM_CUSTOMS,
  READY_FOR_DELIVERY,
  READY_FOR_ISSUE,
];

export const showedOrderButton = status =>
  isShowOrderButton.some(v => v === status);
export const isNotFinalStatus = status =>
  !finalStatuses.some(v => v === status);
export const isShowWarehouse = status =>
  [IN_TRANSIT, PREPARED_FOR_TRANSIT, TO_BE_RETURNED, RETURNING_TO_ORIGIN].find(
    v => v === status,
  );

export const AllStatuses = OrderedSet.of(
  UNASSIGNED,
  ASSIGNED_TO_COURIER,
  ACCEPTED,
  ON_HIS_WAY,
  ARRIVED,
  PICKED_UP,
  PICKED_UP_AUTO,
  IN_SORTING_FACILITY,
  IN_TRANSIT,
  OUT_FOR_DELIVERY,
  ARRIVED_TO_DELIVERY_ADDRESS,
  DELIVERY_FAILED,
  TO_BE_RETURNED,
  RETURNED_TO_ORIGIN,
  CANCELLED,
  DRIVER_REJECTED,
  COMPLETED,
  COD_TRANSFERRED,
  ON_HOLD,
  PICK_UP_ATTEMPT,
  DELIVERY_ATTEMPT,
  OUT_FOR_RETURN,
  RETURNING_TO_ORIGIN,
  OTHER_PICKUP_ATTEMPT,
  OTHER_DELIVERY_ATTEMPT,
  FIRST_PICKUP_ATTEMPT,
  SECOND_PICKUP_ATTEMPT,
  THIRD_PICKUP_ATTEMPT,
  FIRST_DELIVERY_ATTEMPT,
  SECOND_DELIVERY_ATTEMPT,
  THIRD_DELIVERY_ATTEMPT,
  BAD_RECIPIENT_ADDRESS,
  DELIVERY_SCHEDULED,
  RECIPIENT_NOT_AVAILABLE,
  RECIPIENT_MOBILE_SWITCHED_OFF,
  RECIPIENT_MOBILE_WRONG,
  RECIPIENT_ADDRESS_CHANGE_REQUESTED,
  COD_NOT_READY,
  DELIVERY_REJECTED,
  RECIPIENT_MOBILE_NO_RESPONSE,
  FUTURE_DELIVERY_REQUESTED,
  OUT_OF_DELIVERY_AREA,
  UNABLE_TO_ACCESS_RECIPIENT_PREMISES,
  WRONG_SHIPMENT,
  INCOMPLETE_PARCEL,
  DELIVERY_DELAY_DUE_TO_LATE_BOOKING,
  NO_TIME_FOR_DELIVERY,
  NO_CAPACITY_FOR_DELIVERY,
  ID_OR_DOCUMENT_REQUIRED_MISSING,
  BAD_WEATHER_DURING_DELIVERY,
  RECIPIENT_NAME_MISSING,
  COLLECTION_ARRANGED_OR_REQUESTED,
  DESTROYED_ON_CUSTOMER_REQUEST,
  LOST_OR_DAMAGED,
  IN_TRANSIT_TO_SUPPLIER,
  TRANSITED_TO_SUPPLIER,
  PREPARED_FOR_TRANSIT,
  DISPATCHED,
  PROCESSING_AT_WAREHOUSE,
  MISROUTED,
  PARTIAL_COMPLETE,
  PENDING_ASSIGN_TO_COURIER,
  ALL_FAILED,
  PARTIALLY_IN_SORTING_FACILITY,
  IN_DIFFERENT_WAREHOUSE,
  OFFICE_CLOSED,
  LOST_OR_DAMAGED_CLOSED,
  RECIPIENT_WANTS_INSPECT_ITEM,
  OTHER_REASON,
  LOST,
  DAMAGED,
  REJECTED,
  CANCELLED_WITH_REFUND,
  ISSUED_TO_RECIPIENT,
  READY_FOR_DELIVERY,
);
export default OrderedSet.of(
  PREPARED_FOR_TRANSIT,
  IN_TRANSIT,
  IN_SORTING_FACILITY,
  ACCEPTED,
  RECIPIENT_MOBILE_NO_RESPONSE,
  COD_NOT_READY,
  DISPATCHED,
  RETURNING_TO_ORIGIN,
  COMPLETED,
  RETURNED_TO_ORIGIN,
  SENT_TO_CUSTOMS,
  HOLD_ON_AT_CUSTOMS,
  RETURNED_FROM_CUSTOMS,
);

export const orderStatusSorting = OrderedSet.of(
  ISSUED_TO_RECIPIENT,
  PREPARED_FOR_TRANSIT,
  IN_TRANSIT,
  IN_SORTING_FACILITY,
  ACCEPTED,
  CANCELLED,
  RECIPIENT_MOBILE_NO_RESPONSE,
  CANCELLED_WITH_REFUND,
  COD_NOT_READY,
  DISPATCHED,
  RETURNING_TO_ORIGIN,
  COMPLETED,
  RETURNED_TO_ORIGIN,
  SENT_TO_CUSTOMS,
  HOLD_ON_AT_CUSTOMS,
  RETURNED_FROM_CUSTOMS,
  READY_FOR_ISSUE,
  RETURNED_FROM_LOCKER_POST,
  IN_LOCKER_POST,
  READY_FOR_DELIVERY,
);

export const orderStatusForFilter = OrderedSet.of(
  UNASSIGNED,
  ISSUED_TO_RECIPIENT,
  PREPARED_FOR_TRANSIT,
  IN_TRANSIT,
  IN_SORTING_FACILITY,
  ACCEPTED,
  CANCELLED,
  RECIPIENT_MOBILE_NO_RESPONSE,
  CANCELLED_WITH_REFUND,
  COD_NOT_READY,
  DISPATCHED,
  RETURNING_TO_ORIGIN,
  COMPLETED,
  RETURNED_TO_ORIGIN,
  SENT_TO_CUSTOMS,
  HOLD_ON_AT_CUSTOMS,
  RETURNED_FROM_CUSTOMS,
  READY_FOR_ISSUE,
  RETURNED_FROM_LOCKER_POST,
  IN_LOCKER_POST,
);
