import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { FormControlLabel, Switch } from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { getIsRTL } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  withTheme,
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
);

ToggleComponent.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  trueValue: PropTypes.any,
  falseValue: PropTypes.any,
  valueComparator: PropTypes.func,
  isRTL: PropTypes.bool,
  theme: PropTypes.object,
  name: PropTypes.string.isRequired,
  withLabel: PropTypes.bool,
};

function ToggleComponent({
  input,
  meta,
  trueValue,
  falseValue,
  valueComparator,
  isRTL,
  theme,
  name,
  withLabel,
  ...custom
}) {
  return withLabel ? (
    <FormControlLabel
      {...custom}
      control={
        <Switch
          name={name}
          checked={valueComparator(input.value, trueValue)}
          onChange={(e, v) => input.onChange(v ? trueValue : falseValue)}
        />
      }
    />
  ) : (
    <Switch
      name={name}
      checked={valueComparator(input.value, trueValue)}
      onChange={(e, v) => input.onChange(v ? trueValue : falseValue)}
    />
  );
}

FormToggle.propTypes = {
  label: PropTypes.node,
  disabled: PropTypes.bool,
  labelPlacement: PropTypes.string,
  name: PropTypes.string.isRequired,
  trueValue: PropTypes.any,
  falseValue: PropTypes.any,
  valueComparator: PropTypes.func,
  labelStyle: PropTypes.object,
  withLabel: PropTypes.bool,
};

FormToggle.defaultProps = {
  trueValue: true,
  falseValue: false,
  withLabel: true,
  valueComparator: fp.eq,
};

function FormToggle(props) {
  return <Field {...props} component={ToggleComponent} />;
}

export default enhancer(FormToggle);
