import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { PinDrop, Place } from "@material-ui/icons";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { getValue, isEqualData } from "../../helpers/DataUtils";
import {
  getGeoCode,
  getGeoCodeByText,
  getPlacePredictions2,
} from "../../helpers/YandexMapsHelper";
import {
  ENGLISH_LANGUAGE,
  RUSSIAN_LANGUAGE,
  UZBEK_LANGUAGE,
} from "../../constants/LocaleTypes";
import { BIZ } from "../../constants/LocationAddresstypes";

// const getLocation = fp.flow(fp.get("Point"), fp.get("coordinates"));

export function formatLocalType(type) {
  switch (type) {
    case UZBEK_LANGUAGE:
      return "uz_UZ";
    case ENGLISH_LANGUAGE:
      return "en_US";
    case RUSSIAN_LANGUAGE:
      return "ru_RU";

    default:
      return "ru_RU";
  }
}

export const GeoAutoCompleteComponent = componentFromStream(propsStream => {
  const valueStream = propsStream
    .map(fp.get("input.value"))
    .filter(Boolean)
    .distinctUntilChanged(fp.isEqual);

  const dataSourceStream = valueStream
    /**
     * Load By { address }
     */
    .filter(({ address, lat, lng }) => Boolean(address && !lat && !lng))
    .combineLatest(propsStream, (value, props) => ({
      input: value.address,
      language: props.language,
      type: props.type,
      location: {
        lat: props.location.lat,
        lng: props.location.lng,
      },
    }))
    .distinctUntilChanged(fp.isEqual)
    .debounceTime(300)
    .filter(props => {
      if (
        props.input &&
        (props.input.length === 5 ||
          props.input.length === 7 ||
          props.input.length === 9 ||
          props.input.length === 11 ||
          props.input.length === 13)
      ) {
        return true;
      }
      return false;
    })
    .switchMap(query =>
      Observable.defer(() =>
        query.type === BIZ
          ? getPlacePredictions2({
              text: query.input,
              lat: query.location.lat,
              lng: query.location.lng,
              lang: formatLocalType(query.language),
              type: query.type,
            })
          : getGeoCodeByText({
              geocode: query.input,
              lat: query.location.lat,
              lng: query.location.lng,
              lang: formatLocalType(query.language),
            }),
      ).catch(() => Observable.of([])),
    )
    .startWith([])
    .distinctUntilChanged(fp.isEqual)
    .map(
      fp.map(item => {
        if (
          item &&
          item.properties &&
          Object.keys(item.properties).includes("CompanyMetaData")
        ) {
          return {
            address: `${getValue(item, "properties.name")}-${getValue(
              item,
              "properties.CompanyMetaData.address",
            )}`,
            value: getValue(item, "properties.CompanyMetaData.address"),
            location: {
              lat: getValue(item, "geometry.coordinates[1]"),
              lng: getValue(item, "geometry.coordinates[0]"),
            },
          };
        }
        return {
          address: getValue(
            item,
            "GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted",
            "",
          ),
          value: getValue(
            item,
            "GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted",
            "",
          ),
          location: {
            lat: getValue(item, "GeoObject.Point.pos").split(" ")[1],
            lng: getValue(item, "GeoObject.Point.pos").split(" ")[0],
          },
        };
      }),
    );

  const sideEffectsStream = Observable.merge(
    Observable.combineLatest(
      propsStream.pluck("countryCode").distinctUntilChanged(),
      Observable.merge(
        /**
         *  Load by { address }
         */
        valueStream
          .filter(({ address, lat, lng }) => Boolean(address && !lat && !lng))
          .map(fp.pick(["value", "address", "location"])),
        /**
         *  Load by { lat, lng }
         */
        valueStream
          .filter(({ address, lat, lng }) => Boolean(!address && lat && lng))
          .map(({ lat, lng }) => ({ location: { lat, lng } })),
      ),
      (region, query) => ({ ...query, region }),
    )
      .distinctUntilKeyChanged("value")
      .filter(query => Boolean(query.value))
      .withLatestFrom(propsStream)
      .switchMap(([{ location }, props]) =>
        Observable.defer(() =>
          getGeoCode({
            lang: formatLocalType(props.language),
            lat: location.lat,
            lng: location.lng,
          }),
        )
          .catch(() => Observable.of(null))
          .map(
            fp.flow(result =>
              fp.isEmpty(result)
                ? null
                : {
                    lat: location.lat,
                    lng: location.lng,
                    address: getValue(
                      result,
                      "GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted",
                      "",
                    ),
                  },
            ),
          ),
      )
      .filter(Boolean)
      .withLatestFrom(propsStream)
      .do(([value, props]) => props.input.onChange(value)),
  )
    .mapTo(null)
    .startWith(null)
    .distinctUntilChanged();

  return propsStream
    .map(fp.omit(["language", "countryCode"]))
    .distinctUntilChanged(isEqualData)
    .combineLatest(
      dataSourceStream,
      sideEffectsStream,
      ({ onOpenMap, ...props }, options) => (
        <AutoCompleteComponent
          {...props}
          options={
            (props.input.value &&
              props.input.value.address &&
              props.input.value.address.length < 5) ||
            !props.input.value.address
              ? []
              : options
          }
          input={{
            ...props.input,
            onChange: item =>
              props.input.onChange(
                item && item.pinDrop ? { ...props.input.value } : item,
              ),
          }}
          filter={fp.stubTrue}
          formatOption={fp.get("address")}
          parseInput={address => ({ address })}
          renderOptionLeftIcon={item =>
            item.pinDrop ? <PinDrop /> : <Place />
          }
        />
      ),
    );
});

GeoAutoCompleteComponent.propTypes = {
  language: PropTypes.string,
  countryCode: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,

  onOpenMap: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

GeoAutoCompleteComponent.defaultProps = {
  language: "en",
  hintText: "Type to Search (5 letters minimum)",
};

FormYandexGeoAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  underlineShow: PropTypes.bool,

  onOpenMap: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,
  location: PropTypes.object,
  type: PropTypes.string,
};

export default function FormYandexGeoAutoComplete(props) {
  return <Field {...props} component={GeoAutoCompleteComponent} />;
}
