import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

const CUSTOMER_CREATE_URL = `${API_ROOT_URL}/admin/customer`;
const CUSTOMER_ITEM_URL = `${API_ROOT_URL}/admin/customer/%s`;
const CUSTOMER_CHOOSE_URL = `${API_ROOT_URL}/admin/customer/choose`;
const UPDATE_CUSTOMER_SALES_PERSON_URL = `${API_ROOT_URL}/customer/sales_representative`;
const CUSTOMER_SALES_PERSON_URL = `${API_ROOT_URL}/customer/%s/sales_representative`;
const CUSTOMER_PAYMENT_METHODS_URL = `${API_ROOT_URL}/admin/customer/%s/payment_methods`;

const GET_CUSTOMER_CONTRACT_OWNER = `${API_ROOT_URL}/customer/%s/contract_owner`;
const UPDATE_CUSTOMER_CONTRACT_OWNER = `${API_ROOT_URL}/customer/contract_owner`;

const ADMIN_CUSTOMER_TYPES = `${API_ROOT_URL}/admin/customer/customer_types`;

const GET_DEFAULT_CUSTOMER_ADDRESS = `${API_V2_ROOT_URL}/default-address`;
const COMPANY_INN_CHECK_URL = `${API_ROOT_URL}/customer/check/inn/%s`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(CUSTOMER_ITEM_URL, id)),
});

export const getCustomer = id => itemCache.refresh(id);
export const getCachedCustomer = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));

const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(CUSTOMER_CHOOSE_URL, { params: request.getDefinedValues() }),
});

const customerTypePredictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: () => api.getStream(ADMIN_CUSTOMER_TYPES),
});

export const getCustomerPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export type PaymentMethodsRequest = {
  order_id: number,
  package_id: number,
  promo_code: string,
  locations: [
    {
      lat: number,
      lon: number,
      city: string,
      pickup: boolean,
      details: string,
      address: string,
      contact_name: string,
      email: string,
      phone: string,
      company_name: string,
      confirmed_by_recipient: boolean,
      address_type: string,
      postcode: string,
      address_city: string,
      address_street: string,
    },
  ],
};

export const getPaymentMethods = (id, body: PaymentMethodsRequest) =>
  api.post(sprintf(CUSTOMER_PAYMENT_METHODS_URL, id), { body });

export const getContractOwner = id =>
  api.getStream(sprintf(GET_CUSTOMER_CONTRACT_OWNER, id));

export const updateCustomer = (id, values) =>
  api.put(sprintf(CUSTOMER_ITEM_URL, id), { body: values });

export const updateContractOwner = values =>
  api.post(UPDATE_CUSTOMER_CONTRACT_OWNER, { body: values });

export const getSalesPerson = id =>
  api.getStream(sprintf(CUSTOMER_SALES_PERSON_URL, id));

export const updateSalesPerson = body =>
  api.post(UPDATE_CUSTOMER_SALES_PERSON_URL, { body });

export const getCustomerTypes = () => customerTypePredictionsCache.get();

export type DefaultAddressBook = {
  address: string,
  address_type: string,
  apartment: string,
  building: string,
  city: {
    code: string,
    id: number,
    name: string,
  },
  country: {
    code: string,
    id: number,
    name: string,
  },
  floor: string,
  landmark: string,
  name: string,
  neighborhood: {
    code: string,
    id: number,
    name: string,
  },
  phone: string,
  phone_code: string,
  phone_without_code: string,
  street: string,
  lat: number,
  lon: number,
  pickup: boolean,
};

export const getCustomerDefaultAddressforCustomer = () =>
  api.getStream(GET_DEFAULT_CUSTOMER_ADDRESS);
export const createCustomer = body => api.post(CUSTOMER_CREATE_URL, { body });
export const getCustomerById = id => api.get(sprintf(CUSTOMER_ITEM_URL, id));
export const getCustomersChoose = params =>
  api.get(CUSTOMER_CHOOSE_URL, { params });
export const getCustomersChooseHybrid = params =>
  api.get(CUSTOMER_CHOOSE_URL, { params: { ...params, source: "HYBRID" } });

export const checkCompanyInn = inn =>
  api.get(sprintf(COMPANY_INN_CHECK_URL, inn));
