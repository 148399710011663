import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";

const enhancer = compose(
  useSheet({
    root: { height: "100%", display: "flex", flexDirection: "column" },
  }),
);

AppSidebar.propTypes = {
  sheet: PropTypes.object,
  children: PropTypes.node.isRequired,
};

function AppSidebar(props) {
  const {
    sheet: { classes },
  } = props;

  return <div className={classes.root}>{props.children}</div>;
}

export default enhancer(AppSidebar);
