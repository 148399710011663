export const MAPS_DISTANCE_MATRIX_URL = "/maps/distance-matrix";

export const WING_GOOGLE_MAP_KEY_LIVE =
  "AIzaSyBVrVlXD5jNphjJAW3KQdEh9zJ3cyH9EK4";
export const WING_GOOGLE_MAP_KEY_DEV =
  "AIzaSyAKQ1ZYGZqiqc0Eug_CQGyoZqnYUJmZ95c";
export const WING_GOOGLE_MAP_KEY_JAYEEK_LIVE =
  "AIzaSyCMaLUVZiffwnGd6eq2bhdrPZh-H0VCnIs";

export const GOOGLE_MAP_PROVIDER = "google_map";
export const YANDEX_MAP_PROVIDER = "yandex_map";
export const TWO_GIS_MAP_PROVIDER = "two_gis_map";
export const OPEN_STREET_MAP_PROVIDER = "openstreet_map";

export const MAP_PROVIDERS = [
  GOOGLE_MAP_PROVIDER,
  YANDEX_MAP_PROVIDER,
  TWO_GIS_MAP_PROVIDER,
  OPEN_STREET_MAP_PROVIDER,
];
