import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import {
  getCachedUser,
  getUserPredictions,
} from "../../api/admin/AdminUserApi";
import {
  getProductCategoryId,
  getProductCategoryPredictions,
} from "../../api/shared/ProductCategoryApi";

const baseFilter = new DataListFilter({ page: 0, size: 20 });

const parseInput = name => ({ name });
const formatOption = fp.get("name");

const ProductCategoryAutoComplete = componentFromStream(propsStream => {
  const valueStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value"))
    .switchMap(({ input: { value } }) =>
      value && value.id && !value.name
        ? getProductCategoryId(value.id)
            .map(item => ({ id: value.id, name: item.get("full_name") }))
            .catch(() => Observable.of(value))
            .startWith(value)
        : Observable.of(value),
    );

  const optionsStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value.name"))
    .switchMap(({ input: { value } }) =>
      getProductCategoryPredictions(baseFilter.setSearch(value && value.name))
        .map(fp.flow(fp.get("payload.data.list"), fp.toArray, fromJS))
        .catch(() => Observable.of(List())),
    )
    .distinctUntilChanged(isEqualData)
    .map(v => v.toJS());

  return propsStream.combineLatest(
    valueStream,
    optionsStream,
    ({ ...props }, value, options) => (
      <AutoCompleteComponent
        {...props}
        options={options}
        filter={fp.stubTrue}
        parseInput={parseInput}
        formatOption={formatOption}
        input={{ ...props.input, value }}
      />
    ),
  );
});

FormProductCategoryAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  getCachedUser: PropTypes.func,
  getUserPredictions: PropTypes.func,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormProductCategoryAutoComplete.defaultProps = {
  maxSearchResults: 10,

  getCachedUser,
  getUserPredictions,
};

export default function FormProductCategoryAutoComplete(props) {
  return <Field {...props} component={ProductCategoryAutoComplete} />;
}
