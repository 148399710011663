import { Observable } from "rxjs";
import React from "react";
import { fromJS, List } from "immutable";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = (name) => ({ name });
const formatOption = fp.get("name");

const DriverAutoComplete = componentFromStream((propsStream) => {
  const optionsStream = propsStream
    .distinctUntilChanged(isEqualData)
    .switchMap(({ dutyStatus, supplierId, input: { value } }) =>
      getDriverPredictions(
        baseFilter
          .setSearch(value && value.name)
          .setValue("supplier_ids", supplierId)
          .setValue("duty_status", dutyStatus),
      )
        .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
        .catch(() => Observable.of(List())),
    )
    .distinctUntilChanged(isEqualData)
    .map((v) => v.toJS());

  const valueStream = propsStream
    .distinctUntilChanged(isEqualData)
    .switchMap(({ input: { value } }) =>
      value && value.id && !value.name
        ? getCachedDriver(value.id)
            .map((item) => ({ id: value.id, name: item.get("full_name") }))
            .catch(() => Observable.of(value))
            .startWith(value)
        : Observable.of(value),
    );

  return propsStream
    .map(fp.omit(["supplierId", "getCachedDriver", "getDriverPredictions"]))
    .combineLatest(valueStream, optionsStream, (props, value, options) => (
      <AutoCompleteComponent
        {...props}
        options={options}
        filter={fp.stubTrue}
        parseInput={parseInput}
        formatOption={formatOption}
        input={{ ...props.input, value }}
      />
    ));
});

FormDriverAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  supplierId: PropTypes.number,

  hintText: PropTypes.node,
  label: PropTypes.node,

  dutyStatus: PropTypes.string,
};

FormDriverAutoComplete.defaultProps = {
  maxSearchResults: 10,
};

export default function FormDriverAutoComplete(props) {
  return <Field {...props} component={DriverAutoComplete} />;
}
