import fp from "lodash/fp";
import { overEach } from "../../shared/helpers/FnUtils";

export const preventDefault = fp.method("preventDefault");
export const withPreventingDefault = fp.rest(
  fp.flow(fp.concat(preventDefault), overEach),
);

export const stopPropagation = fp.method("stopPropagation");
export const withStoppingPropagation = fp.rest(
  fp.flow(fp.concat(stopPropagation), overEach),
);
