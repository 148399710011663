import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import cx from "classnames";
import PropTypes from "prop-types";
import ExtendElement from "./ExtendElement";
import {
  muted1,
  accent1,
  danger1,
  primary1,
  success1,
} from "../../../shared/theme/main-theme";

export const MUTED = "muted";
export const PRIMARY = "primary";
export const SUCCESS = "success";
export const INFO = "info";
export const ACTUAL = "actual";
export const INVERT = "invert";
export const WARNING = "warning";
export const DANGER = "danger";
export const DELETED = "deleted";
export const INSERTED = "inserted";
export const SMALL = "small";
export const BOLD = "bold";
export const UPPER = "uppercase";
export const LOWER = "lowercase";
export const CAPITAL = "capitalize";

export const ALIGN_RIGHT = "align-right";
export const ALIGN_CENTER = "align-center";
export const ALIGN_LEFT = "align-left";

const BASE_TYPE = PropTypes.oneOf([
  MUTED,
  PRIMARY,
  SUCCESS,
  INFO,
  INVERT,
  ACTUAL,
  WARNING,
  DANGER,
  DELETED,
  INSERTED,
  SMALL,
  BOLD,
  UPPER,
  LOWER,
  CAPITAL,
  ALIGN_RIGHT,
  ALIGN_CENTER,
  ALIGN_LEFT,
]);

export const TEXT_TYPE = PropTypes.oneOfType([
  BASE_TYPE,
  PropTypes.arrayOf(BASE_TYPE),
]);

const enhancer = useSheet({
  [MUTED]: { color: muted1 },
  [PRIMARY]: { color: primary1, fontWeight: 500 },
  [SUCCESS]: { color: success1 },
  [INFO]: { color: accent1 },
  [INVERT]: { color: "#fff" },
  [ACTUAL]: { color: "#2196f3" },
  [WARNING]: {},
  [DANGER]: { color: danger1 },
  [DELETED]: { textDecoration: "line-through" },
  [INSERTED]: { textDecoration: "underline" },
  [SMALL]: { fontSize: "85%" },
  [BOLD]: { fontWeight: 500 },
  [ALIGN_RIGHT]: { textAlign: "right" },
  [ALIGN_CENTER]: { textAlign: "center" },
  [ALIGN_LEFT]: { textAlign: "left" },
  [UPPER]: { textTransform: "uppercase" },
  [LOWER]: { textTransform: "lowercase" },
  [CAPITAL]: { textTransform: "capitalize" },
});

Text.propTypes = {
  sheet: PropTypes.object,
  classes: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  fallbackValue: PropTypes.node,
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  type: TEXT_TYPE,
  fallbackType: TEXT_TYPE,
};

Text.defaultProps = { element: "span" };

function Text({
  sheet,
  classes,
  type,
  children,
  fallbackType,
  fallbackValue,
  ...props
}) {
  const isEmpty =
    fp.isNil(children) || (fp.isString(children) && fp.isEmpty(children));
  const resolveClasses = fp.flow(
    fp.pick(fp.castArray(isEmpty ? fallbackType : type)),
    fp.values,
  );

  return (
    <ExtendElement
      {...props}
      className={cx(resolveClasses(classes), props.className)}
    >
      {isEmpty ? fallbackValue : children}
    </ExtendElement>
  );
}

export default enhancer(Text);
