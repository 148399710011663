import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Card } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { getAppLogo } from "../../../shared/reducers/AppReducer";
import Logo from "../../assets/logo_uzpost.png";
import { CircularProgressWithGradient } from "../ui-core/PageLoading";
import Box from "@material-ui/core/Box";

const cleanupProps = fp.omit([
  "sheet",
  "classes",
  "children",
  "className",
  "cardClassName",
  "withLogo",
  "isLoading",
  "zDepth",
  "loadingZDepth",
]);

const enhancer = compose(
  connect(state => ({
    appLogo: getAppLogo(state),
  })),
  useSheet({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      display: "block",
      maxWidth: "200px",
      marginTop: "30px",
      marginLeft: "auto",
      maxHeight: "200px",
      marginRight: "auto",
      marginBottom: "30px",
      "@media (max-width: 991px)": {
        maxWidth: "100px",
      },
    },
    card: {
      important: false,
      position: "relative",
      minWidth: `${96 * 5}px`,
      maxWidth: `${96 * 5}px`,
    },
    cardLoading: {
      overflow: "hidden",
      minWidth: `${96 * 2}px`,
      maxWidth: `${96 * 2}px`,
      minHeight: `${96 * 2}px`,
      maxHeight: `${96 * 2}px`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    hidden: { display: "none" },
    box: {
      "& .MuiSvgIcon-root": {
        width: "11em",
        height: "1.5em",
        fontSize: "1.8em",
      },
    },
  }),
  mapPropsStream(propsStream => {
    const marketplaceStream = propsStream.map(() => window.location.host);

    return propsStream
      .combineLatest(marketplaceStream, (props, marketplaceId) => ({
        ...props,
        marketplaceId,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

CenteredCard.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  zDepth: PropTypes.number,
  loadingZDepth: PropTypes.number,
  className: PropTypes.string,
  cardClassName: PropTypes.string,
  withLogo: PropTypes.bool,
  appLogo: PropTypes.string,
  marketplaceId: PropTypes.string,
  dispatch: PropTypes.func,
};

CenteredCard.defaultProps = {
  zDepth: 1,
  isLoading: false,
  loadingZDepth: 5,
  withLogo: false,
};

function CenteredCard({ marketplaceId, appLogo, dispatch, ...props }) {
  const rootProps = cleanupProps(props);
  const logo = appLogo || Logo;

  return (
    <div className={cx(props.classes.root, props.className)}>
      {props.isLoading && (
        <Box position="relative" display="flex" justifyContent="center">
          <CircularProgressWithGradient
            size={200}
            strokeWidth={25}
            percentage={100}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            className={props.classes.box}
          >
            <svg
              width="200"
              height="120"
              viewBox="0 0 50 50"
              fill="none"
              style={{
                marginTop: "65px",
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="ZNAK">
                <path
                  id="Vector"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.8987 0L28.7975 16.774L26.1716 19.7304L8.64657 0H13.8987ZM21.7769 0L32.7365 12.3389L30.1106 15.2954L16.5247 0H21.7769ZM6.02054 0L24.3995 20.6921H22.6039C20.3063 20.6921 18.3282 19.7697 16.7034 17.9405L0.768372 0H6.02054Z"
                  fill="#F07824"
                />
                <path
                  id="Vector_2"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M28.7128 4.84399C32.5026 4.84399 37.1901 4.84399 41.1774 4.84399C44.4715 4.84399 46.3228 6.84703 46.8802 7.47363L49.1394 10.0166C47.8629 10.0166 46.5866 10.017 45.3106 10.017L38.273 17.9406C36.6488 19.7695 34.6699 20.6922 32.3726 20.6922H27.9507C31.1111 17.1337 34.2717 13.5756 37.432 10.0168L35.603 10.0166C34.3739 10.0166 32.9719 9.6416 31.4713 7.95132L28.7128 4.84399Z"
                  fill="#183E98"
                />
              </g>
            </svg>
          </Box>
        </Box>
      )}

      {!props.isLoading && (
        <Card
          {...rootProps}
          zDepth={props.isLoading ? props.loadingZDepth : props.zDepth}
          className={cx(
            props.classes.card,
            { [props.classes.cardLoading]: props.isLoading },
            props.cardClassName,
          )}
          style={{ minWidth: "300px", width: "450px" }}
        >
          <div className={cx({ [props.classes.hidden]: props.isLoading })}>
            {props.withLogo && (
              <img alt="DMS LOGO" src={logo} className={props.classes.logo} />
            )}

            {props.children}
          </div>
        </Card>
      )}
    </div>
  );
}

export default enhancer(CenteredCard);
