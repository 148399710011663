import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function UzpostLogo() {
  return (
    <SvgIcon viewBox="0 0 16 16">
      <svg
        width="150"
        height="80"
        viewBox="0 0 120 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="ZNAK">
          <path
            id="Vector"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.8987 0L28.7975 16.774L26.1716 19.7304L8.64657 0H13.8987ZM21.7769 0L32.7365 12.3389L30.1106 15.2954L16.5247 0H21.7769ZM6.02054 0L24.3995 20.6921H22.6039C20.3063 20.6921 18.3282 19.7697 16.7034 17.9405L0.768372 0H6.02054Z"
            fill="#F07824"
          />
          <path
            id="Vector_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.7128 4.84399C32.5026 4.84399 37.1901 4.84399 41.1774 4.84399C44.4715 4.84399 46.3228 6.84703 46.8802 7.47363L49.1394 10.0166C47.8629 10.0166 46.5866 10.017 45.3106 10.017L38.273 17.9406C36.6488 19.7695 34.6699 20.6922 32.3726 20.6922H27.9507C31.1111 17.1337 34.2717 13.5756 37.432 10.0168L35.603 10.0166C34.3739 10.0166 32.9719 9.6416 31.4713 7.95132L28.7128 4.84399Z"
            fill="#183E98"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
