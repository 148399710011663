import { fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import { formatErrorMessage } from "../helpers/FormatUtils";
import { injectReducer } from "../../shared/helpers/ReducerContext";

const SHOW_MESSAGE = "NOTIFICATIONS/SHOW_MESSAGE";
const HIDE_MESSAGE = "NOTIFICATIONS/HIDE_MESSAGE";

export const ERROR_MESSAGE = "error";
export const SUCCESS_MESSAGE = "success";
export const WARNING_MESSAGE = "warning";
export const INFO_MESSAGE = "info";

const hasStatus = fp.has("status");
const shouldNotify = fp.flow(fp.get("meta.notify"), fp.eq(true));

const selector = injectReducer(
  "notifications",
  (state = OrderedSet(), action) => {
    switch (action.type) {
      case SHOW_MESSAGE: {
        const message = formatErrorMessage(action.payload);

        if (message) {
          return state.add(
            fromJS({
              message,
              httpError: false,
              messageType: action.payload.type,
              autoHideDuration: action.payload.autoHideDuration,
            }),
          );
        }

        return state;
      }

      case HIDE_MESSAGE:
        return state.remove(action.payload);

      default: {
        if (action.error && action.payload) {
          const httpError = hasStatus(action.payload);
          const httpRequestError =
            httpError &&
            (action.payload.status === 403 || action.payload.status === 500);
          const httpConnectionError = httpError && !action.payload.status;

          if (httpRequestError || httpConnectionError || shouldNotify(action)) {
            const message = formatErrorMessage(action.payload);

            if (message) {
              return state.add(
                fromJS({
                  message,
                  httpError,
                  action: null,
                  messageType: ERROR_MESSAGE,
                }),
              );
            }
          }
        }

        return state;
      }
    }
  },
);

export const getNotifications = state => selector(state);

export const hideNotification = notification => ({
  type: HIDE_MESSAGE,
  payload: notification,
});

export const showSnackbar = (payload: {
  message: string,
  autoHideDuration: number,
  type: INFO_MESSAGE | SUCCESS_MESSAGE | WARNING_MESSAGE | ERROR_MESSAGE,
}) => ({ type: SHOW_MESSAGE, payload });

export const showErrorMessage = (message: string, autoHideDuration: number) =>
  showSnackbar({ message, autoHideDuration, type: ERROR_MESSAGE });

export const showSuccessMessage = (message: string, autoHideDuration: number) =>
  showSnackbar({ message, autoHideDuration, type: SUCCESS_MESSAGE });

export const showWarningMessage = (message: string, autoHideDuration: number) =>
  showSnackbar({ message, autoHideDuration, type: WARNING_MESSAGE });
