import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import { componentFromStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { CircularProgress, makeStyles, TextField } from "@material-ui/core";
import FlexBox from "../ui-core/FlexBox";
import { uploadFile } from "../../api/shared/FileApi";

const FileUploadButtonComponent = componentFromStream((propsStream) => {
  const { handler: onChange, stream: onChangeStream } = createEventHandler();

  const uploadStream = onChangeStream
    .map(fp.get(["target", "files", 0]))
    .withLatestFrom(propsStream)
    .do(([, props]) => props.input.onChange(null))
    .switchMap(
      ([file]) =>
        uploadFile(file)
          .catch((error) => Observable.of({ error }))
          .map(fp.update("payload", fp.get("data.id"))),
      ([, props], response) => [response, props],
    )
    .do(([response, props]) => {
      if (response.payload) {
        props.input.onChange(response.payload);
      }
    })
    .map(fp.nth(0) /* response */)
    .startWith(null);

  return propsStream.combineLatest(
    uploadStream,
    ({ meta, label, input, ...custom }, upload) =>
      upload && upload.pending ? (
        <FlexBox justify="center">
          <CircularProgress
            size={24}
            thickness={2}
            mode="determinate"
            value={upload.progress}
          />
        </FlexBox>
      ) : (
        <TextField
          {...input}
          {...custom}
          label={label}
          floatingLabelFixed={true}
          type="file"
          errorText={!meta.active && meta.touched && meta.error}
          value=""
          onChange={onChange}
          onBlur={fp.flow(fp.noop, input.onBlur)}
          onFocus={fp.flow(fp.noop, input.onFocus)}
        />
      ),
  );
});

FormFileUploadFieldCustom.propTypes = {
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  fullWidth: PropTypes.bool,
  hintText: PropTypes.node,
  label: PropTypes.node,
  classes: PropTypes.object,
};
const useStyles = makeStyles({
  img: {
    "& > div": {
      opacity: "0",
      position: "absolute",
      cursor: "pointer",
      "&:before": {
        cursor: "pointer",
      },
      "&>input": {
        cursor: "pointer",
        width: "90px",
        height: "23px",
        position: "absolute",
        top: "-20px",
        padding: "0 15px",
        left: "-14px",
      },
    },
    "& > label": {
      color: "white",
      cursor: "pointer",
      position: "unset",
      transform: "unset",
    },
  },
});
function FormFileUploadFieldCustom(props) {
  const classes = useStyles();
  return (
    <Field
      className={classes.img}
      style={{
        display: "flex",
        color: "white",
        cursor: "pointer",
        verticalAlign: "middle",
        maxWidth: "100px",
        padding: ".25rem 1rem",
        textAlign: "center",
        borderRadius: "2px",
        justifyContent: "center",
        minWidth: "8rem",
        marginTop: "1rem",
        position: "relative",
        backgroundColor: "rgba(38, 50, 56, 0.32)",
      }}
      {...props}
      component={FileUploadButtonComponent}
    />
  );
}

export default FormFileUploadFieldCustom;
