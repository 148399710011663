import { Observable } from "rxjs";
import fp from "lodash/fp";
import loadScript from "load-script";
import { updateQuery } from "../../shared/helpers/UrlUtils";

const SCRIPT_LOAD_CALLBACK = "__shipox_leaflet_maps_callback__";

const MAP_URL = updateQuery("https://unpkg.com/leaflet@1.7.1/dist/leaflet.js");

const getMaps = fp.get("L");

function loadMaps() {
  if (!loadMaps.promise) {
    loadMaps.promise = new Promise(resolve => {
      const maps = getMaps(window);

      if (maps) {
        resolve(maps);
      } else {
        loadScript(MAP_URL);

        window[SCRIPT_LOAD_CALLBACK] = () => {
          delete window[SCRIPT_LOAD_CALLBACK];

          resolve(getMaps(window));
        };
      }
    });
  }
  return loadMaps.promise;
}

export const getLeafletMapsStream = () => Observable.defer(loadMaps);
