import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

export const getCurrentLocation = (onSuccess, onError, options = {}) => {
  const {
    enableHighAccuracy = false,
    timeout = Infinity,
    maximumAge = 0,
  } = options;

  if (!("geolocation" in window.navigator)) {
    return;
  }

  window.navigator.geolocation.getCurrentPosition(onSuccess, onError, {
    enableHighAccuracy,
    timeout,
    maximumAge,
  });
};

export default class Geolocation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchingPosition: false,
      position: undefined,
      error: undefined,
    };
  }

  componentDidMount() {
    if (_.isObject(window)) {
      return;
    }

    if (!("geolocation" in window.navigator)) {
      return;
    }

    if (this.props.lazy) {
      return;
    }

    this.getCurrentPosition();
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  onSuccess = position => {
    if (this.willUnmount) return;

    this.setState({ position, fetchingPosition: false }, () =>
      this.props.onSuccess(position),
    );
  };

  onError = error => {
    if (this.willUnmount) return;

    this.setState({ error, fetchingPosition: false }, () =>
      this.props.onError(error),
    );
  };

  getCurrentPosition = () => {
    const { enableHighAccuracy, timeout, maximumAge } = this.props;

    this.setState({ fetchingPosition: true });

    return getCurrentLocation(this.onSuccess, this.onError, {
      enableHighAccuracy,
      timeout,
      maximumAge,
    });
  };

  render() {
    if (!this.props.render) {
      return null;
    }
    return (
      this.props.render({
        getCurrentPosition: this.getCurrentPosition,
        fetchingPosition: this.state.fetchingPosition,
        position: this.state.position,
        error: this.state.error,
      }) || null
    );
  }
}

Geolocation.propTypes = {
  // https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions
  enableHighAccuracy: PropTypes.bool,
  timeout: PropTypes.number,
  maximumAge: PropTypes.number,
  // https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  // Do not call getCurrentPosition on mount
  lazy: PropTypes.bool,
  render: PropTypes.any,
};

Geolocation.defaultProps = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
  onSuccess: () => {},
  // eslint-disable-next-line handle-callback-err
  onError: () => {},
  lazy: false,
};
