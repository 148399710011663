import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResponseError from "../../helpers/ResponseError";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { RETURNING_TO_ORIGIN } from "../../constants/OrderStatusCodes";
import BatchRTOOrdersDialog from "../../components/orders-core/BatchRTOOrdersDialog";
import fp from "lodash/fp";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import { INACTIVE } from "../../constants/OverallStatus";
import { getMessages } from "../../reducers/LocalizationReducer";
import { Map } from "immutable";
import { renderIf } from "../../helpers/HOCUtils";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

BatchRTOOrdersFormDialogWrapper.propTypes = {
  open: PropTypes.bool,
  isSorting: PropTypes.bool,
  isHybrid: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  showErrorMessage: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
  showSuccessMessage: PropTypes.func,
};

function BatchRTOOrdersFormDialogWrapper({
  initialValues,
  isHybrid,
  isSorting,
  ...props
}) {
  return (
    <BatchRTOOrdersDialog
      open={props.open}
      hideOrderNumber={true}
      isSorting={isSorting}
      isHybrid={isHybrid}
      initialValues={{
        ...initialValues,
        batch_type: INACTIVE,
        privacy: PUBLIC,
        order_status: RETURNING_TO_ORIGIN,
      }}
      onRequestClose={props.onRequestClose}
      onSubmit={fp.flow(toSnakeCase, values =>
        batchAsyncUpdateOrder(values).catch(ResponseError.throw),
      )}
      onSubmitFail={response => {
        props.showErrorMessage(response);
      }}
      onSubmitSuccess={response => {
        props.showSuccessMessage(
          props.i18n.get("successfully_updated", "Successfully Updated"),
        );
        props.onSubmitSuccess(response);
      }}
    />
  );
}

export default enhancer(BatchRTOOrdersFormDialogWrapper);
