import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "./BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";
import { JURISDICTION_UZBEKISTAN } from "../../components/orders-core/AdminPostForm";

const COUNTRY_ITEM_URL = `${API_V2_ROOT_URL}/country/%s`;
const COUNTRY_LIST_URL = `${API_V2_ROOT_URL}/countries`;

const CITY_ITEM_URL = `${API_V2_ROOT_URL}/city/%s`;
const CITY_LIST_URL = `${API_V2_ROOT_URL}/cities`;

const JM_ITEM_URL = `${API_V2_ROOT_URL}/jurisdiction/%s`;
const JM_LIST_URL = `${API_V2_ROOT_URL}/jurisdiction/choose/list`;

const POSTCODES_LIST_URL = `${API_V2_ROOT_URL}/neighborhoods`;

const PUBLIC_CITY_ITEM_URL = `${API_V2_ROOT_URL}/public/city/%s`;
const PUBLIC_CITY_LIST_URL = `${API_V2_ROOT_URL}/public/cities`;
const PUBLIC_POSTCODES_LIST_URL = `${API_V2_ROOT_URL}/public/neighborhoods`;

const parseArrayResponse = fp.flow(fp.get("data"), fp.toArray, fromJS);

// Country
const countryCache = new ObservableCache({
  loader: (id) =>
    api
      .get(sprintf(COUNTRY_ITEM_URL, id))
      .then(fp.flow(fp.get("data"), fromJS)),
});

export const getCachedCountry = (id) => countryCache.get(id);
// export const getCountryPredictions = (request: DataListFilter) =>
//   api.getStream(COUNTRY_LIST_URL, { params: request.getDefinedValues() });

// const countryPredictionsCache = new ObservableCache({
//   loader: (request) => api.get(COUNTRY_LIST_URL, { params: request.getDefinedValues() }),
// });
//
// export const getCountryPredictions = (request: DataListFilter) => countryPredictionsCache.get(request);

export const getCountryPredictions = (request: DataListFilter) =>
  api.getStream(COUNTRY_LIST_URL, { params: request.getDefinedValues() });

// JM
const JMCache = new ObservableCache({
  loader: (id) =>
    api.get(sprintf(JM_ITEM_URL, id)).then(fp.flow(fp.get("data"), fromJS)),
});
export const getCachedJM = (id) => JMCache.get(id);
export const getJMPredictions = (request: DataListFilter) =>
  api.getStream(JM_LIST_URL, { params: request.getDefinedValues() });

export const getJMList = (request: DataListFilter) =>
  api.getStream(JM_LIST_URL, { params: request.getDefinedValues() });
export const getJMById = (id) => api.get(sprintf(JM_ITEM_URL, id));
export const getJMList2 = (params) => api.get(JM_LIST_URL, { params });
export const getJMListOnlyUZ = (params) =>
  api.get(JM_LIST_URL, {
    params: {
      ...params,
      parentId: JURISDICTION_UZBEKISTAN.id,
      levelIds: [1, 2],
    },
  });

// City
const cityCache = new ObservableCache({
  loader: (id) =>
    api.get(sprintf(CITY_ITEM_URL, id)).then(fp.flow(fp.get("data"), fromJS)),
});

export const getCachedCity = (id) => cityCache.get(id);
export const getCityPredictions = (request: DataListFilter) =>
  api.getStream(CITY_LIST_URL, { params: request.getDefinedValues() });

export const getCityList = (request: DataListFilter) =>
  api.getStream(CITY_LIST_URL, { params: request.getDefinedValues() });

const neighborhoodCache = new ObservableCache({
  loader: () =>
    api.get(POSTCODES_LIST_URL).then(fp.flow(fp.get("data"), fromJS)),
});

export const getCachedPostcode = (id) => neighborhoodCache.get(id);
export const getPostcodePredictions = (request: DataListFilter) =>
  api.getStream(POSTCODES_LIST_URL, { params: request.getDefinedValues() });

// Public URLS:
const publicCityCache = new ObservableCache({
  loader: (id) =>
    api
      .get(sprintf(PUBLIC_CITY_ITEM_URL, id))
      .then(fp.flow(fp.get("data"), fromJS)),
});

export const getPublicCachedCity = (id) => publicCityCache.get(id);
export const getPublicCityPredictions = (request: DataListFilter) =>
  api.getStream(PUBLIC_CITY_LIST_URL, { params: request.getDefinedValues() });

// Neighborhoods

const publicNeighborhoodCache = new ObservableCache({
  loader: () =>
    api.get(PUBLIC_POSTCODES_LIST_URL).then(fp.flow(fp.get("data"), fromJS)),
});

export const getPublicCachedNeighborhood = (id) =>
  publicNeighborhoodCache.get(id);
export const getPublicNeighborhoodPredictions = (request: DataListFilter) =>
  api.getStream(PUBLIC_POSTCODES_LIST_URL, {
    params: request.getDefinedValues(),
  });

const cityCacheOrdered = new ObservableCache({
  loader: () => api.get(CITY_LIST_URL).then(parseArrayResponse),
});

const countryCacheOrdered = new ObservableCache({
  loader: () => api.get(COUNTRY_LIST_URL).then(parseArrayResponse),
});

export const getOrderedCities = () => cityCacheOrdered.get();
export const getOrderedCountries = () => countryCacheOrdered.get();
