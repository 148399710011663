import { api } from "../shared/BaseApi";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";
import DataListFilter from "../../helpers/DataListFilter";
import { ObservableCache } from "../../helpers/loading-cache";
import sprintf from "sprintf";
import fp from "lodash/fp";
import { fromJS } from "immutable";

const JURISDICTIONS_URL = `${API_V2_ROOT_URL}/jurisdiction`;
const JURISDICTIONS_ITEM_URL = `${API_V2_ROOT_URL}/jurisdiction/%s`;
const JURISDICTIONS_LEVEL_CHOOSE_URL = `${API_V2_ROOT_URL}/jurisdiction/level/choose`;
const JURISDICTIONS_LEVEL_ITEM_URL = `${API_V2_ROOT_URL}/jurisdiction/level/%s`;
const JURISDICTIONS_COUNTRY_LIST_URL = `${API_V2_ROOT_URL}/country/list`;
const JURISDICTIONS_HIERARCHY_URL = `${API_V2_ROOT_URL}/jurisdiction/hierarchy/%s`;
const JURISDICTION_GET_BY_LOCATION_URL = `${API_V2_ROOT_URL}/jurisdiction/get_by_location`;

export const getJurisdictionCountryList = (request: DataListFilter) =>
  api.get(JURISDICTIONS_COUNTRY_LIST_URL, {
    params: request.getDefinedValues(),
  });

export const getJurisdictionHierarchyById = (id: number) =>
  api.get(sprintf(JURISDICTIONS_HIERARCHY_URL, id));

export const getJurisdictions = (request: DataListFilter) =>
  api.get(JURISDICTIONS_URL, { params: request.getDefinedValues() });
export const getJurisdictionByLocation = ({ lat, lng }) =>
  api.get(JURISDICTION_GET_BY_LOCATION_URL, { params: { lat, lng } });

export const getJurisdictionItem = id =>
  api.getStream(sprintf(JURISDICTIONS_ITEM_URL, id));

export const getJurisdictionItemV2 = id =>
  api.get(sprintf(JURISDICTIONS_ITEM_URL, id));

export const addJurisdiction = body => api.post(JURISDICTIONS_URL, { body });
export const editJurisdiction = body => api.put(JURISDICTIONS_URL, { body });

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(JURISDICTIONS_LEVEL_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(JURISDICTIONS_LEVEL_CHOOSE_URL, {
      params: request.getDefinedValues(),
    }),
});

export const getJurisdictionLevel = id => itemCache.refresh(id);
export const getCachedJurisdictionLevel = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getJurisdictionLevelPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const getJurisdictionsChoose = params =>
  api.get(JURISDICTIONS_URL, { params });
