import {
  ROLE_RULE_ADMIN,
  ROLE_SUPER_ADMIN,
  ROLE_FINANCE_ADMIN,
  ROLE_PACKAGE_ADMIN,
  ROLE_MARKETPLACE_ADMIN,
  ROLE_MARKETPLACE_OWNER,
} from "../../shared/constants/Authorities";

const isMainAdmin = userRoles =>
  userRoles.some(
    role =>
      role === ROLE_SUPER_ADMIN ||
      role === ROLE_FINANCE_ADMIN ||
      role === ROLE_MARKETPLACE_OWNER ||
      role === ROLE_MARKETPLACE_ADMIN ||
      role === ROLE_PACKAGE_ADMIN ||
      role === ROLE_RULE_ADMIN,
  );
export default isMainAdmin;
export const isMainAdminForProofOfPayment = userRoles =>
  userRoles.some(
    role =>
      role === ROLE_SUPER_ADMIN ||
      role === ROLE_FINANCE_ADMIN ||
      role === ROLE_MARKETPLACE_OWNER ||
      role === ROLE_MARKETPLACE_ADMIN,
  );
