import { Iterable } from "immutable";
import fp from "lodash/fp";

function createMapper(
  changeCase: Function,
): (value: Array | Object | Iterable) => Array | Object {
  const mapArray = fp.map(mapKeysDeep);
  const mapObject = fp.flow(fp.mapKeys(changeCase), fp.mapValues(mapKeysDeep));

  return mapKeysDeep;

  function mapKeysDeep(value: Array | Object | Iterable): Array | Object {
    if (Iterable.isIterable(value)) {
      return mapKeysDeep(value.toJS());
    }

    if (fp.isArray(value)) {
      return mapArray(value);
    }

    if (fp.isPlainObject(value)) {
      return mapObject(value);
    }

    return value;
  }
}

export const toCamelCase: (
  value: Array | Object | Iterable,
) => Array | Object = createMapper(fp.camelCase);
export const toSnakeCase: (
  value: Array | Object | Iterable,
) => Array | Object = createMapper(fp.snakeCase);
