import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Navigate from "../router/Navigate";
import { SIGN_IN_URL } from "../../constants/DashboardPathConstants";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import Preloader from "../../../shared/components/preloader/Preloader";

const enhancer = compose(withRouter);

NavigateToSignIn.propTypes = { location: PropTypes.object };

function NavigateToSignIn(props) {
  const { location } = props;

  const toLocation = updateQuery(SIGN_IN_URL, {
    next: location.pathname + location.search,
  });

  return (
    <Preloader show={true}>
      <Navigate to={toLocation} />
    </Preloader>
  );
}

export default enhancer(NavigateToSignIn);
