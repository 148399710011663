import { OrderedSet } from "immutable";

export const OTHER = "OTHER";
export const COD_INQUIRY = "COD_INQUIRY";
export const NEW_CUSTOMER = "NEW_CUSTOMER";
export const PICK_UP_INQUIRY = "PICK_UP_INQUIRY";
export const DELIVERY_INQUIRY = "DELIVERY_INQUIRY";
export const ACCOUNTS_INQUIRY = "ACCOUNTS_INQUIRY";
export const SERVICE_COMPLAINTS = "SERVICE_COMPLAINTS";
export const PRODUCT_COMPLAINTS = "PRODUCT_COMPLAINTS";
export const HR_RELATED_INQUIRY = "HR_RELATED_INQUIRY";

export default OrderedSet.of(
  DELIVERY_INQUIRY,
  PICK_UP_INQUIRY,
  PRODUCT_COMPLAINTS,
  SERVICE_COMPLAINTS,
  NEW_CUSTOMER,
  COD_INQUIRY,
  ACCOUNTS_INQUIRY,
  HR_RELATED_INQUIRY,
  OTHER,
);
