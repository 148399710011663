import React, { useEffect, useState } from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import { connect } from "react-redux";
import { Help, Notifications } from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import HamburgerButton from "../ui-core/HamburgerButton";
import { getMarketplaceLogo } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import HelpCenterDrawer from "../../components/help-center/CitiesHelpCenterDrawer";
import { responsive } from "../../../shared/theme/jss-responsive";
import {
  getAppMarketplace,
  getAppName,
} from "../../../shared/reducers/AppReducer";
import { ADMIN } from "../../../server/constants/AppTypes";
import uzpostNewLogo from "../../assets/logo_uzpost.png";
import {
  getUserMixedWarehouses,
  hasUserPermission,
} from "../../reducers/ProfileReducer";
import LockerPostDrawer from "../help-center/LockerPostDrawer";
import { getValue } from "../../helpers/DataUtils";
import Badge from "@material-ui/core/Badge";
import { getPushLogs } from "../../api/admin/AdminNotificationApi";
import { getPushLog } from "../../reducers/PushNotificationReducer";

const enhancer = compose(
  connect(state => ({
    marketplaceLogo: getMarketplaceLogo(state),
    marketplaceId: getAppMarketplace(state),
    app: getAppName(state),
    isRefresh: getPushLog(state),
    getPushLogs: hasUserPermission(state, "ADMIN_GET_PUSH_LOGS"),
    getUserMixedWarehouses: getUserMixedWarehouses(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withTheme,
  useSheet({
    root: { top: 0, left: 0, right: 0, position: "fixed", zIndex: 1100 },
    title: {
      minHeight: "100%",
      position: "relative",
      display: "flex",
      flexFlow: "row",
      color: props => props.theme.palette.alternateTextColor,
      fontSize: "18px",
      alignItems: "center",
      " & img": {
        objectFit: "contain",
        maxWidth: "150px",
        marginRight: props => (props.isRTL ? "0" : "10px"),
        marginLeft: props => (!props.isRTL ? "0" : "10px"),
        marginTop: "-8px",
      },
      "@media (max-width: 991px)": {
        "& span": {
          display: "none",
        },
      },
      "@media (max-width: 400px)": {
        "& img": {
          maxWidth: "80px",
        },
      },
      [responsive("$xs")]: { paddingLeft: 0, backgroundImage: "none" },
    },
    imageToggleButton: {
      marginRight: props => (props.isRTL ? "-16px" : "8px"),
      marginLeft: props => (!props.isRTL ? "-16px" : "8px"),
    },
  }),
  withState("state", "setState", {}),
);

AppLayoutAppBar.propTypes = {
  sheet: PropTypes.object,
  location: PropTypes.object,
  theme: PropTypes.object,
  open: PropTypes.bool.isRequired,
  isRefresh: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  title: PropTypes.string,
  rightAction: PropTypes.node,
  marketplaceLogo: PropTypes.string,
  app: PropTypes.string,
  state: PropTypes.object,
  setState: PropTypes.func,
  classes: PropTypes.object,
  getPushLogs: PropTypes.bool,
};

function AppLayoutAppBar(props) {
  const {
    sheet: { classes },
    state,
  } = props;
  const logo = props.marketplaceLogo || uzpostNewLogo;
  const pushLogID = Boolean(getValue(props.location, "query.push_log_id"));
  const tabValue = getValue(props.location, "query.tabValue", "SENT");

  const [showNotification, setNotification] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [pushLogs, setPushLogs] = useState([]);

  useEffect(() => {
    if (props.app === ADMIN && props.getPushLogs) {
      getPushLogs("SENT").then(res => {
        if (res && res.data) {
          setPushLogs(res.data);
        }
      });
    }
  }, [tabValue, refresh, props.isRefresh]);

  const rightActions = (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        justifyContent: "flex-end",
        alignItems: "flex-start",
      }}
    >
      {props.rightAction}
      <div
        style={{
          display: "flex",
          flexGrow: 0,
        }}
      >
        {props.app === ADMIN && (
          <div>
            {props.getPushLogs && (
              <IconButton
                onClick={event => {
                  event.preventDefault();
                  setNotification(true);
                }}
              >
                <Badge
                  color="error"
                  badgeContent={
                    pushLogs && pushLogs.length > 0 ? pushLogs.length : 0
                  }
                >
                  <Notifications />
                </Badge>
              </IconButton>
            )}

            {(pushLogID || showNotification) && (
              <LockerPostDrawer
                tabValue={tabValue}
                setRefresh={setRefresh}
                open={showNotification || pushLogID}
                onRequestChange={open => setNotification(open)}
              />
            )}
          </div>
        )}

        {props.app === ADMIN && (
          <div>
            <IconButton
              onClick={event => {
                event.preventDefault();
                props.setState(fp.flow(fp.update("openSort", value => !value)));
              }}
            >
              <Help />
            </IconButton>

            <HelpCenterDrawer
              open={state.openSort}
              onRequestChange={open =>
                props.setState(fp.flow(fp.update("openSort", () => open)))
              }
            />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <IconButton
          onClick={() => {
            props.onToggle();
          }}
          style={{ zIndex: 1400 }}
        >
          <HamburgerButton
            color={props.theme.palette.appBarTextColor}
            active={props.open}
          />
        </IconButton>
        <div className={classes.title}>
          <img src={logo} title={props.title} alt={props.title} />
          <span>{props.title}</span>
        </div>
        {rightActions}
      </Toolbar>
    </AppBar>
  );
}

export default enhancer(AppLayoutAppBar);
