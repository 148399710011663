import sprintf from "sprintf";
import { api } from "../../shared/BaseApi";
import { API_V2_ROOT_URL } from "../../../../shared/constants/ApiConstants";

const CUSTOMER_PAYMENT_METHODS_URL = `${API_V2_ROOT_URL}/payment_methods`;
const BY_CUSTOMER_PAYMENT_METHODS_URL = `${API_V2_ROOT_URL}/customer/payment_methods`;

export const getPaymentMethods = body =>
  api.getStream(sprintf(CUSTOMER_PAYMENT_METHODS_URL), {
    params: body.getDefinedValues(),
  });
export const getPaymentMethods2 = params =>
  api.get(sprintf(CUSTOMER_PAYMENT_METHODS_URL), { params });

export const getCustomerPaymentMethods2 = params =>
  api.get(sprintf(BY_CUSTOMER_PAYMENT_METHODS_URL), { params });
