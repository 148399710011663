import { getJurisdictions } from "../../api/admin/AdminJurisdictionsApi";
import DataListFilter from "../../helpers/DataListFilter";
import fp from "lodash/fp";

/* eslint-disable */
class JurisdictionTreeModel {
  model = {
    countryList: [],
    children: {},
    fetchingId: null,
    isCountryLoading: false,
    errorCallback: null,
    selected: [],
    error: null,
    expanded: [],
    restChildren: [],
  };

  _onModelChange = null;

  _onSelect = null;

  clearModel() {
    this.model = {
      countryList: [],
      children: {},
      fetchingId: -1,
      isCountryLoading: false,
      errorCallback: null,
      selected: [],
      error: null,
      expanded: [],
      restChildren: [],
    };
  }

  _triggerModelChanged = () => {
    if (this._onModelChange) {
      this._onModelChange({ ...this.model });
    }
  };

  _updateModel = fieldName => value => {
    this.model[fieldName] = value;
  };

  // fetchCountryList = () => {
  //   this._updateModel("isCountryLoading")(true);
  //   this._triggerModelChanged();
  //   this._fetchCountryList()
  //     .then(
  //       fp.flow(
  //         fp.get("data"),
  //         this._updateModel("countryList"),
  //         () => this._updateModel("isCountryLoading")(false),
  //         this._triggerModelChanged,
  //       ),
  //     )
  //     .catch(
  //       fp.flow(
  //         fp.set(this.model, "error"),
  //         () => this._updateModel("isCountryLoading")(false),
  //         this._triggerModelChanged,
  //       ),
  //     );
  // };

  fetchJurisdictionsList(parentId = null, onFinish) {
    this._updateModel("fetchingId")(parentId);
    this._triggerModelChanged();
    this._fetchJurisdctionList(parentId)
      .then(
        fp.flow(
          fp.get("data"),
          data => {
            this.model.children[parentId] = data;
            if (this.model.selected.length > 0) {
              const lastItem = this.model.selected[
                this.model.selected.length - 1
              ];
              if (
                lastItem.id === parentId &&
                this.model.children[lastItem.id]
              ) {
                this._selectAllChildren(this.model.children[lastItem.id]);
              }
            }
          },
          () => this._updateModel("fetchingId")(-1),
          this._triggerModelChanged,
          () => {
            if (onFinish) onFinish();
          },
        ),
      )
      .catch(error => {
        console.log("error", error);
      });
  }

  _fetchJurisdctionList(parentId) {
    return getJurisdictions(new DataListFilter({ parentId }));
  }

  setOnModelChange = onModelChange => {
    this._onModelChange = onModelChange;
  };

  setOnSelect = onSelect => {
    this._onSelect = onSelect;
  };

  getChildren = (parentId = null) => this.model.children[parentId];

  hasChildren = child => child.children_count > 0;

  isThrough = child => {
    if (!this.model.selected.length) {
      return false;
    }
    if (child.parent_id >= 0) {
      const selectedSameParentId = this.model.selected.find(
        s => s.parent_id === child.parent_id,
      );
      if (selectedSameParentId) {
        const children = this.model.children[child.parent_id] || [];
        const selectedIndex = children.indexOf(
          children.find(ch => ch.id === selectedSameParentId.id),
        );
        const currentIndex = children.indexOf(
          children.find(ch => ch.id === child.id),
        );
        return currentIndex < selectedIndex;
      }
      return false;
    } else {
      const selectedParent = this.model.selected.indexOf(
        this.model.selected[0],
      );
      let parentList = this.model.children[null] || [];
      let selectedIndex = parentList.indexOf(
        parentList.find(s => s.id === selectedParent.id),
      );
      let currentIndex = parentList.indexOf(
        parentList.find(s => s.id === child.id),
      );
      return currentIndex < selectedIndex;
    }
  };

  hasSelected = child =>
    this.model.selected.length > 0 &&
    this.model.selected[this.model.selected.length - 1].id === child.id;

  hasInSelection = child =>
    Boolean(this.model.selected.find(s => s.id === child.id));

  _findInChildrenInCountryId = id => {
    for (const key of Object.keys(this.model.children)) {
      const children = this.model.children[key] || [];
      const found = children.find(child =>
        id === null ? child.parent_id === id : child.id === id,
      );
      if (found) {
        return found;
      }
    }
  };

  _selectAllChildren = (list = []) => {
    list.forEach(ch => {
      this.model.restChildren.push(ch);
      if (this.model.children[ch.id] && this.hasChildren(ch)) {
        this._selectAllChildren(this.model.children[ch.id]);
      }
    });
  };

  select = child => {
    if (child.parent_id >= 0) {
      this.model.selected = [];
      this.model.restChildren = [];
      this.model.selected.unshift(child);

      let parent_id = child.parent_id;

      while (parent_id != null) {
        const found = this._findInChildrenInCountryId(parent_id);
        this.model.selected.unshift(found);
        parent_id = found.parent_id;
      }
      if (this.hasChildren(child) && this.model.children[child.id]) {
        this._selectAllChildren(this.model.children[child.id]);
      }
    } else {
      this.model.selected = [child];
      this.model.restChildren = [];
      if (this.model.children[child.id]) {
        this._selectAllChildren(this.model.children[child.id]);
      }
    }
    if (this._onSelect) {
      this._onSelect(this.model.selected);
    }
    this._triggerModelChanged();
  };

  unselect = child => {
    if (this.hasSelected(child)) {
      this.model.selected.pop();
      this.model.restChildren = [];
      if (child.parent_id >= 0 && this.model.selected.length) {
        const lastItem = this.model.selected[this.model.selected.length - 1];
        if (lastItem.parent_id >= 0) {
          if (this.hasChildren(lastItem) && this.model.children[lastItem.id]) {
            this._selectAllChildren(this.model.children[lastItem.id]);
          }
        } else if (this.model.children[lastItem.id]) {
          this._selectAllChildren(this.model.children[lastItem.id]);
        }
      }
      if (this._onSelect) {
        this._onSelect(this.model.selected);
      }
      this._triggerModelChanged();
    }
  };

  toggleExpansion = child => {
    if (this.model.expanded.find(e => e.id === child.id)) {
      this.model.expanded.splice(
        this.model.expanded.indexOf(
          this.model.expanded.find(e => e.id === child.id),
        ),
        1,
      );
    } else {
      this.model.expanded.push(child);
    }
    this._triggerModelChanged();
  };

  isExpanded = child =>
    Boolean(this.model.expanded.find(e => e.id === child.id));

  isInRestChildren = child =>
    Boolean(this.model.restChildren.find(ch => ch.id === child.id));
}
/* eslint-enable */

export default JurisdictionTreeModel;
