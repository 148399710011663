import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import sizeMe from "react-sizeme";
import { LeafletMap, ZoomControl } from "react-leflet-map-components";
import LeafletMapSizer from "./LeafletMapSizer";
import { getLeafletMapsStream } from "../../helpers/LeafletMapsHelper";
import {
  getMarketplaceCountry,
  getMarketplaceMapCenter,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getMapProvider,
  getMapProviderKey,
} from "../../../shared/reducers/AppReducer";
import Polygon from "./assets/polygon.svg";
import Drag from "./assets/drag.svg";
import Edit from "./assets/edit.svg";
import Rotate from "./assets/rotate.svg";
import Delete from "./assets/delete.svg";

const hasLocation = fp.flow(
  fp.over([fp.get("lat"), fp.get("lng")]),
  fp.every(Boolean),
);

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      country: getMarketplaceCountry(state),
      baseCountryMapCenter: getMarketplaceMapCenter(state),
      mapType: getMapProvider(state),
      mapKey: getMapProviderKey(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  sizeMe({ monitorWidth: true, monitorHeight: true }),
  useSheet({
    map: {
      height: "100%",
      width: "100%",
      "& .leaflet-pm-icon-polygon": {
        backgroundImage: `url(${Polygon})`,
      },
      "& .leaflet-pm-icon-edit": {
        backgroundImage: `url(${Edit})`,
      },
      "& .leaflet-pm-icon-drag": {
        backgroundImage: `url(${Drag})`,
      },
      "& .leaflet-pm-icon-delete": {
        backgroundImage: `url(${Delete})`,
      },
      "& .leaflet-pm-icon-rotate": {
        backgroundImage: `url(${Rotate})`,
      },
    },
  }),
  mapPropsStream(propsStream =>
    propsStream.combineLatest(getLeafletMapsStream(), (props, maps) => ({
      ...props,
      maps,
    })),
  ),
);

LeafletMapWrapper.propTypes = {
  size: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object,
  style: PropTypes.object,

  onClick: PropTypes.func,
  onDragEnd: PropTypes.func,
  onZoomChanged: PropTypes.func,
  onCenterChanged: PropTypes.func,
  onBoundsChanged: PropTypes.func,
  baseCountryMapCenter: PropTypes.object,
  mapType: PropTypes.string,
  mapKey: PropTypes.string,
  mapCenter: PropTypes.object,
  maps: PropTypes.object,
  zoom: PropTypes.number,
  hasZoomControl: PropTypes.bool,
  position: PropTypes.oneOf([
    "topleft",
    "topright",
    "bottomleft",
    "bottomright",
  ]),
  children: PropTypes.node,
};

LeafletMapWrapper.defaultProps = {
  mapType: "google_map",
  zoom: 12,
  hasZoomControl: true,
  position: "bottomright",
};
function LeafletMapWrapper(props) {
  return (
    <LeafletMap
      onDragEnd={props.onDragEnd}
      onZoomEnd={props.onZoomChanged}
      onResize={props.onBoundsChanged}
      onMoveEnd={props.onCenterChanged}
      style={props.style}
      className={cx(props.classes.map, props.className)}
      // mapTypeId={props.mapType}
      zoom={props.zoom}
      onClick={props.onClick}
      center={
        hasLocation(props.mapCenter)
          ? props.mapCenter
          : props.baseCountryMapCenter
      }
      maps={props.maps}
      // apiKey={props.mapKey}
    >
      {props.hasZoomControl && <ZoomControl position={props.position} />}

      <LeafletMapSizer maps={props.maps} size={props.size} />

      {props.children}
    </LeafletMap>
  );
}

export default enhancer(LeafletMapWrapper);
