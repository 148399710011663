import { Observable } from "rxjs";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { FirebaseSDK } from "./FirebaseSDK";
import { formatDateToUrl, formatDateTimeToUrl } from "../helpers/FormatUtils";
import { getInitialState } from "../helpers/InitialState";
import { MERGE_OURS, MERGE_THEIRS } from "../constants/MergeStrategies";
import { getTokenUserId } from "../../shared/reducers/AuthReducer";

const basePath = "order_sorting";
const jobPath = `${basePath}/job`;
const logsPath = `${basePath}/logs`;

const uid = getTokenUserId(getInitialState());

const mapResponse = fp.flow(fp.method("val"), fp.toPlainObject, fromJS);
const validateOrder = (x) => {
  const value = x.val();

  return Boolean(value && value.number === x.key);
};

export class OrderSortingDB {
  constructor(warehouseId = null) {
    this.db = new FirebaseSDK();
    this.warehouseId = warehouseId;
  }

  getPath(...args) {
    return [jobPath, this.warehouseId, ...args].join("/");
  }

  //
  // History
  //

  trackAction(type, payload = {}) {
    this.db
      .push(
        [
          logsPath,
          formatDateToUrl(new Date()),
          this.warehouseId,
          type,
          uid,
        ].join("/"),
        {
          ...payload,
          time: formatDateTimeToUrl(new Date()),
        },
      )
      .toPromise();
  }

  //
  // Bin Rules
  //

  getBinRulesPath(...args) {
    return this.getPath("bin_rules", ...args);
  }

  getBinRules() {
    return this.db.get(this.getBinRulesPath()).map(mapResponse);
  }

  setBinRules(rules) {
    return this.db.set(this.getBinRulesPath(), rules);
  }

  //
  // Registry Bin Rules
  //

  getRegistryBinRulesPath(...args) {
    return this.getPath("registry_bin_rules", ...args);
  }

  getRegistryBinRules() {
    return this.db.get(this.getRegistryBinRulesPath()).map(mapResponse);
  }

  setRegistryBinRules(rules) {
    return this.db.set(this.getRegistryBinRulesPath(), rules);
  }

  //
  // Orders
  //

  getOrdersPath(...args) {
    return this.getPath("orders", ...args);
  }

  clearOrders() {
    return this.db.update(this.getPath(), { tasks: null, orders: null });
  }

  reloadOrder(orderNumber = null) {
    return this.batchReloadOrders([orderNumber]);
  }

  batchReloadOrders(orderNumbers) {
    const values = orderNumbers.reduce((acc, x) => {
      acc[`${x}/hash`] = null;
      acc[`${x}/failed`] = null;

      return acc;
    }, {});

    return this.db.update(this.getOrdersPath(), values);
  }

  removeOrder(orderNumber) {
    return this.batchRemoveOrders([orderNumber]);
  }

  batchUpdateOrders(values) {
    return this.db.update(this.getOrdersPath(), values);
  }

  updateOrder(orderNumber, updater) {
    return this.db.transaction(this.getOrdersPath(orderNumber), updater);
  }

  mergeOrders(orders, mergeStrategy) {
    switch (mergeStrategy) {
      case MERGE_OURS:
      case MERGE_THEIRS:
        return Observable.from(orders).mergeMap(
          (theirs) =>
            this.updateOrder(
              theirs.number,
              fp.flow(fp.toPlainObject, (ours) =>
                mergeStrategy === MERGE_OURS
                  ? { ...theirs, ...ours }
                  : { ...ours, ...theirs },
              ),
            ),
          5,
        );

      default:
        return this.batchUpdateOrders(
          orders.reduce((acc, x) => {
            acc[x.number] = x;

            return acc;
          }, {}),
        );
    }
  }

  batchRemoveOrders(orderNumbers) {
    const values = orderNumbers.reduce((acc, number) => {
      acc[number] = null;

      return acc;
    }, {});

    return this.batchUpdateOrders(values).switchMapTo(
      this.batchUpdateCounter(values),
    );
  }

  batchUpdateOrderBins(orderNumbers, value) {
    return this.batchUpdateOrders(
      orderNumbers.reduce((acc, number) => {
        acc[`${number}/bin`] = value;

        return acc;
      }, {}),
    );
  }

  getOrders() {
    return this.db
      .get(this.getOrdersPath())
      .map(
        fp.flow(mapResponse, (response) =>
          response.filter((x, key) => Map.isMap(x) && x.get("number") === key),
        ),
      );
  }

  getOrder(orderNumber = null) {
    return this.db.get(this.getOrdersPath(orderNumber)).map(mapResponse);
  }

  getOrderAddStream() {
    return this.db
      .onChildAdd(this.getOrdersPath())
      .filter(validateOrder)
      .map(mapResponse);
  }

  getOrderChangeStream() {
    return this.db
      .onChildChanged(this.getOrdersPath())
      .filter(validateOrder)
      .map(mapResponse);
  }

  getOrderRemoveStream() {
    return this.db
      .onChildRemoved(this.getOrdersPath())
      .filter(validateOrder)
      .map(mapResponse);
  }

  getCorruptedOrderStream() {
    const path = this.getOrdersPath();

    return this.db
      .onChildAdd(path)
      .merge(this.db.onChildChanged(path))
      .filter((x) => {
        const value = x.val();

        return Boolean(!value || value.number !== x.key);
      });
  }

  //
  // Counter
  //

  getCounterPath(...args) {
    return this.getPath("counter", ...args);
  }

  getCounter() {
    return this.db.get(this.getCounterPath()).map(mapResponse);
  }

  batchUpdateCounter(values) {
    return this.db.update(this.getCounterPath(), values);
  }

  clearCounter() {
    return this.db.set(this.getCounterPath(), null);
  }

  //
  // Box Counter
  //

  getBoxCounterPath(...args) {
    return this.getPath("box-counter", ...args);
  }

  getBoxCounter() {
    return this.db.get(this.getBoxCounterPath()).map(mapResponse);
  }

  batchUpdateBoxCounter(values) {
    return this.db.update(this.getBoxCounterPath(), values);
  }

  clearBoxCounter() {
    return this.db.set(this.getBoxCounterPath(), null);
  }

  //
  // Tasks
  //

  getTasksPath(...args) {
    return this.getPath("tasks", uid, ...args);
  }

  getTask(taskId = null) {
    return this.db.get(this.getTasksPath(taskId));
  }

  getTasks() {
    return this.db.get(this.getTasksPath()).map(mapResponse);
  }

  getTaskAddStream() {
    return this.db.onChildAdd(this.getTasksPath());
  }

  addTask(orderNumber, task) {
    return this.db.push(this.getTasksPath(), { task, number: orderNumber });
  }

  updateTask(taskId = null, values = null) {
    return this.db.update(this.getTasksPath(taskId), values);
  }

  removeTask(taskId = null) {
    return this.db.set(this.getTasksPath(taskId), null);
  }

  retryTask(taskId = null) {
    return this.getTask(taskId)
      .take(1)
      .takeWhile((snapshot) => snapshot.exists())
      .switchMap((response) => {
        const payload = response.val();

        return this.removeTask(taskId).concat(
          this.addTask(payload.number, payload.task),
        );
      });
  }

  updateSize(orderNumber, size) {
    return this.addTask(orderNumber, { size });
  }

  updatePieces(orderNumber, pieceCount) {
    return this.addTask(orderNumber, { piece_count: pieceCount });
  }

  //
  // Batch Validation functions
  //

  getBatchPath(...args) {
    return this.getPath("batch", ...args);
  }

  getBatches() {
    return this.db.get(this.getBatchPath()).map(mapResponse);
  }

  getBatchOrdersPath(...args) {
    return this.getBatchPath("batch_orders", ...args);
  }

  getBatchOrders() {
    return this.db
      .get(this.getBatchOrdersPath())
      .map(
        fp.flow(mapResponse, (response) =>
          response.filter((x, key) => Map.isMap(x) && x.get("number") === key),
        ),
      );
  }

  updateBatchOrders(orders) {
    return this.db.update(this.getBatchOrdersPath(), orders);
  }

  //
  // Current Root Order/Batch
  //

  getRootOrderPath(...args) {
    return this.getBatchPath("root_order", ...args);
  }

  getRootOrder() {
    return this.db.get(this.getRootOrderPath());
  }

  updateRootOrder(orderNumber = null) {
    return this.getRootOrder()
      .take(1)
      .switchMap((response) => {
        const payload = response.val();

        return fp.isEmpty(payload)
          ? this.db.update(this.getBatchPath(), { root_order: orderNumber })
          : Observable.of(payload);
      });
  }

  //
  // Batch Counter
  //

  getBatchOrdersCounterPath(...args) {
    return this.getPath("batch_counter", ...args);
  }

  updateBatchCounter() {
    return this.db.get(this.getBatchOrdersCounterPath()).map(mapResponse);
  }

  // Registries

  getRegistriesPath(...args) {
    return this.getPath("registries", ...args);
  }

  getRegistries() {
    return this.db
      .get(this.getRegistriesPath())
      .map(
        fp.flow(mapResponse, (response) =>
          response.filter((x, key) => Map.isMap(x) && x.get("number") === key),
        ),
      );
  }

  getRegistry(orderNumber = null) {
    return this.db.get(this.getRegistriesPath(orderNumber)).map(mapResponse);
  }

  batchUpdateRegistries(orders) {
    return this.db.update(this.getRegistriesPath(), orders);
  }

  batchMoveRegistriesFromOrdersToShipmentsPanel(orders) {
    return this.batchUpdateRegistries(
      orders.reduce((acc, number) => {
        acc[`${number}/number`] = number;

        return acc;
      }, {}),
    ).switchMapTo(this.batchRemoveOrders(orders));
  }

  clearRegistries() {
    return this.db.update(this.getPath(), { registries: null });
  }

  batchRemoveRegistries(orderNumbers) {
    const values = orderNumbers.reduce((acc, number) => {
      acc[number] = null;

      return acc;
    }, {});

    return this.batchUpdateRegistries(values);
  }

  batchUpdateRegistryBins(orderNumbers, value) {
    return this.batchUpdateRegistries(
      orderNumbers.reduce((acc, number) => {
        acc[`${number}/bin`] = value;

        return acc;
      }, {}),
    );
  }

  batchReloadRegistries(orderNumbers) {
    const values = orderNumbers.reduce((acc, x) => {
      acc[`${x}/hash`] = null;
      acc[`${x}/failed`] = null;

      return acc;
    }, {});

    return this.db.update(this.getRegistriesPath(), values);
  }

  getRegistryAddStream() {
    return this.db
      .onChildAdd(this.getRegistriesPath())
      .filter(validateOrder)
      .map(mapResponse);
  }

  getRegistryChangeStream() {
    return this.db
      .onChildChanged(this.getRegistriesPath())
      .filter(validateOrder)
      .map(mapResponse);
  }

  getRegistryRemoveStream() {
    return this.db
      .onChildRemoved(this.getRegistriesPath())
      .filter(validateOrder)
      .map(mapResponse);
  }

  getCorruptedRegistryStream() {
    const path = this.getRegistriesPath();

    return this.db
      .onChildAdd(path)
      .merge(this.db.onChildChanged(path))
      .filter((x) => {
        const value = x.val();

        return Boolean(!value || value.number !== x.key);
      });
  }

  //
  // Tasks
  //

  getRegistryTasksPath(...args) {
    return this.getPath("registry_tasks", uid, ...args);
  }

  getRegistryTask(taskId = null) {
    return this.db.get(this.getRegistryTasksPath(taskId));
  }

  getRegistryTasks() {
    return this.db.get(this.getRegistryTasksPath()).map(mapResponse);
  }

  getRegistryTaskAddStream() {
    return this.db.onChildAdd(this.getRegistryTasksPath());
  }

  addRegistryTask(orderNumber, task) {
    return this.db.push(this.getRegistryTasksPath(), {
      task,
      number: orderNumber,
    });
  }

  updateRegistryTask(taskId = null, values = null) {
    return this.db.update(this.getRegistryTasksPath(taskId), values);
  }

  removeRegistryTask(taskId = null) {
    return this.db.set(this.getRegistryTasksPath(taskId), null);
  }

  retryRegistryTask(taskId = null) {
    return this.getRegistryTask(taskId)
      .take(1)
      .takeWhile((snapshot) => snapshot.exists())
      .switchMap((response) => {
        const payload = response.val();

        return this.removeRegistryTask(taskId).concat(
          this.addRegistryTask(payload.number, payload.task),
        );
      });
  }

  cancelRegistryTask(orderNumber) {
    return this.addRegistryTask(orderNumber, { cancel_registry: true });
  }

  updateShipmentStatus(barcode, params) {
    return this.addRegistryTask(barcode, {
      shipment_status_update: params,
    });
  }
}
