import {
  FETCH_USER_ACCOUNT,
  SKIP_USER_VERIFICATION,
} from "../reducers/UserReducer";
import { API_ROOT_URL } from "../../shared/constants/ApiConstants";

const ACCOUNT_URL = `${API_ROOT_URL}/account`;

export function fetchUserAccount() {
  return (dispatch, getState, api) =>
    dispatch({
      type: FETCH_USER_ACCOUNT,
      payload: api.get(ACCOUNT_URL).then(data => ({ data })),
    });
}

export function skipVerification() {
  return { type: SKIP_USER_VERIFICATION };
}
