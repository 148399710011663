import "firebase/auth";
import "firebase/database";
import { Observable } from "rxjs";
import fp from "lodash/fp";
import firebase from "firebase/app";
import { ObservableCache } from "../helpers/loading-cache";
import { api } from "../api/shared/BaseApi";
import { FIREBASE_TOKEN_URL } from "../../shared/constants/FirebaseControllerConstants";

firebase.initializeApp({
  apiKey: "AIzaSyBYBahPkYbCgoz_ZRSjgoyEmRxM7cSCyCs",
  authDomain: "uzpost-74201.firebaseapp.com",
  databaseURL: "https://uzpost-74201.firebaseio.com",
  projectId: "shipox-74201",
  storageBucket: "shipox-74201.appspot.com",
  messagingSenderId: "130590812485",
});

if (module.hot) {
  module.hot.dispose(() => {
    firebase.app().delete();
  });
}

const rootDbCache = new ObservableCache({
  loader: () =>
    api
      .get(FIREBASE_TOKEN_URL)
      .then((response) => firebase.auth().signInWithCustomToken(response.token))
      .then(() => firebase.database()),
});

const envPath = (x) => `${process.env.WING_ENV}/${x}`;

const createEventStream = (ref, event) =>
  new Observable((observer) => {
    function eventHandler(value) {
      if (!observer.closed) {
        observer.next(value);
      }
    }

    ref.on(event, eventHandler, (error) => {
      if (!observer.closed) {
        observer.error(error);
        observer.complete();
      }
    });

    return () => ref.off(event, eventHandler);
  });

export const FIREBASE_TIMESTAMP = firebase.database.ServerValue.TIMESTAMP;

export class FirebaseSDK {
  constructor(dbCache = rootDbCache) {
    this.dbCache = dbCache;
  }

  getRef(path) {
    return this.dbCache.get().map((db) => db.ref(envPath(path)));
  }

  addEventListener(path, eventType, refMapper = fp.identity) {
    return this.getRef(path)
      .map(refMapper)
      .switchMap((ref) => createEventStream(ref, eventType));
  }

  get(path, refMapper) {
    return this.addEventListener(path, "value", refMapper);
  }

  set(path, value) {
    return this.getRef(path).switchMap((ref) => ref.set(value));
  }

  push(path, value) {
    return this.getRef(path).switchMap((ref) => ref.push().set(value));
  }

  update(path, updates) {
    return this.getRef(path).switchMap((ref) => ref.update(updates));
  }

  transaction(path, updater) {
    return this.getRef(path).switchMap((ref) => ref.transaction(updater));
  }

  onChildAdd(path) {
    return this.addEventListener(path, "child_added");
  }

  onChildChanged(path) {
    return this.addEventListener(path, "child_changed");
  }

  onChildRemoved(path) {
    return this.addEventListener(path, "child_removed");
  }

  isOnline() {
    return this.dbCache
      .get()
      .switchMap((db) =>
        createEventStream(db.ref(".info/connected"), "value").map(
          fp.method("val"),
        ),
      );
  }
}
