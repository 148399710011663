import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";

import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import { updateMultiplePushLog } from "../../api/admin/AdminNotificationApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getPushLog,
  updatePushLog,
} from "../../reducers/PushNotificationReducer";

const enhancer = compose(
  connect(
    state => ({
      isRTL: getIsRTL(state),
      isRefresh: getPushLog(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage, updatePushLog },
  ),
  useSheet({
    active: {
      text: "#555",
      // backgroundColor: "#000"
    },
    link: {
      color: "#555",
    },
  }),
);

AppSidebarLink.propTypes = {
  classes: PropTypes.object,
  leftIcon: PropTypes.node,
  children: PropTypes.node.isRequired,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  isRTL: PropTypes.bool,
  style: PropTypes.object,
  pushLogs: PropTypes.array,
  slug: PropTypes.string,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  updatePushLog: PropTypes.func,
  isRefresh: PropTypes.bool,
};

function AppSidebarLink(props) {
  const { classes, style, leftIcon, slug, pushLogs } = props;

  return slug === "act_management" ? (
    <Link to={props.to}>
      <ListItem
        className={classes.active}
        style={style}
        button={true}
        onClick={() => {
          if (pushLogs && pushLogs.length > 0) {
            const ids = pushLogs.map(push => push.id);
            updateMultiplePushLog(ids)
              .then(() => {
                props.updatePushLog(!props.isRefresh);
              })
              .catch(error => props.showErrorMessage(error));
          }
        }}
        // leftIcon={!props.isRTL ? props.leftIcon : undefined}
        // rightIcon={props.isRTL ? props.leftIcon : undefined}
      >
        {!props.isRTL && leftIcon ? (
          <ListItemIcon style={{ justifyContent: "center" }}>
            {" "}
            {leftIcon}{" "}
          </ListItemIcon>
        ) : (
          undefined
        )}
        <ListItemText className={classes.link}> {props.children} </ListItemText>
        {props.isRTL && leftIcon ? (
          <ListItemIcon style={{ justifyContent: "center" }}>
            {" "}
            {leftIcon}{" "}
          </ListItemIcon>
        ) : (
          undefined
        )}
      </ListItem>
    </Link>
  ) : (
    <Link to={props.to}>
      <ListItem
        className={classes.active}
        style={style}
        button={true}
        // leftIcon={!props.isRTL ? props.leftIcon : undefined}
        // rightIcon={props.isRTL ? props.leftIcon : undefined}
      >
        {!props.isRTL && leftIcon ? (
          <ListItemIcon style={{ justifyContent: "center" }}>
            {" "}
            {leftIcon}{" "}
          </ListItemIcon>
        ) : (
          undefined
        )}
        <ListItemText className={classes.link}> {props.children} </ListItemText>
        {props.isRTL && leftIcon ? (
          <ListItemIcon style={{ justifyContent: "center" }}>
            {" "}
            {leftIcon}{" "}
          </ListItemIcon>
        ) : (
          undefined
        )}
      </ListItem>
    </Link>
  );
}

export default enhancer(AppSidebarLink);
