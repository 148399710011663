import { Observable } from "rxjs";
import fp from "lodash/fp";
import WingApi from "./WingApi";
import canUseDom from "../../shared/helpers/canUseDom";
import { HOST } from "../../shared/constants/ApiConstants";

const hasNavigatorOnLine = fp.flow(fp.get("navigator.onLine"), fp.isBoolean);

const pollStream = Observable.defer(() => {
  const api = new WingApi();

  return Observable.interval(5000)
    .startWith(null)
    .switchMap(() =>
      api
        .fetchStream(HOST, { method: "HEAD" })
        .mapTo(true)
        .catch(() => Observable.of(false)),
    );
}).share();

const connectionStream = Observable.defer(() => {
  if (!canUseDom || !hasNavigatorOnLine(window)) {
    return pollStream;
  }

  return Observable.of(window.navigator.onLine)
    .merge(
      Observable.fromEvent(window, "online").mapTo(true),
      Observable.fromEvent(window, "offline").mapTo(false),
    )
    .share();
});

export const getConnectionStream = () =>
  connectionStream.distinctUntilChanged();
