import sprintf from "sprintf";
import { api } from "../../shared/BaseApi";
import { API_V2_ROOT_URL } from "../../../../shared/constants/ApiConstants";

const ORDER_URL = `${API_V2_ROOT_URL}/admin/order`;
const ORDER_ITEM_URL = `${API_V2_ROOT_URL}/admin/order/%s`;
const ASMPRO_ORDER_ITEM_URL = `${API_V2_ROOT_URL}/admin/order/track/%s`;
const LOCATION_DETAILS_URL = `${API_V2_ROOT_URL}/location/details`;
const SET_RECEIPT = `${API_V2_ROOT_URL}/admin/order/%s/avval_payment`;

export const createOrder = (body) => api.post(ORDER_URL, { body });
export const getOrder = (id) => api.getStream(sprintf(ORDER_ITEM_URL, id));
export const getOrder2 = (id) => api.get(sprintf(ORDER_ITEM_URL, id));
export const getASMPROOrder = (barcode) =>
  api.get(sprintf(ASMPRO_ORDER_ITEM_URL, barcode));
export const updateOrder = (id, body) =>
  api.put(sprintf(ORDER_ITEM_URL, id), { body });
const FOOD_DELETE_ORDER_URL = `${API_V2_ROOT_URL}/food/order/cancel/%s`;
export const deleteFoodOrder = (id) =>
  api.delete(sprintf(FOOD_DELETE_ORDER_URL, id));

const PACKAGES_PRICES_URL = `${API_V2_ROOT_URL}/admin/packages/prices/`;
const ADMIN_SERVICES_URL = `${API_V2_ROOT_URL}/admin/packages/services`;
const GENERATE_ID = `${API_V2_ROOT_URL}/admin//order/generate_id`;

export const getLocationDetails = (request) =>
  api.getStream(LOCATION_DETAILS_URL, {
    params: request.getDefinedValues(),
  });

export const getCourierPrices = (request) =>
  api.getStream(PACKAGES_PRICES_URL, {
    params: request.getDefinedValues(),
  });
export const getCourierPrices2 = (params) =>
  api.get(PACKAGES_PRICES_URL, { params });

export const getAdminServiceTypes = (params) =>
  api.get(ADMIN_SERVICES_URL, { params });
export const getCheckId = () => api.get(GENERATE_ID);
export const createOrUpdateOrder = (values, orderId, isEdit) =>
  isEdit ? updateOrder(orderId, values) : createOrder(values);
export const setReceipt = (id, body) =>
  api.post(sprintf(SET_RECEIPT, id), { body });
