import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const CREATE_POSTCODE_INDEX_URL = `${API_ROOT_URL}/admin/postcode`;
const GET_POSTCODES_LIST_URL = `${API_ROOT_URL}/admin/postcodes`;
const POSTCODE_INDEX_ITEM_URL = `${API_ROOT_URL}/admin/postcode/%s`;
const POSTCODE_INDEX_CUSTOM_ITEM_URL = `${API_ROOT_URL}/admin/postcode/custom/%s`;
const POSTCODE_INDEX_CHOOSE_URL = `${API_ROOT_URL}/admin/postcode/choose`;
const ORDER_POSTCODE_INDEX_CHOOSE_URL = `${API_ROOT_URL}/admin/order/postcode/choose`;
const UPLOAD_NEIGHBORHOOD_KML_URL = `${API_ROOT_URL}/admin/postcode/%s/kml`;
const GET_SUPPLIER_NEIGHBORHOODS_URL = `${API_ROOT_URL}/admin/supplier/%s/postcodes`;
const UPDATE_SUPPLIER_NEIGHBORHOOD_URL = `${API_ROOT_URL}/admin/supplier/%s/postcode`;
const GET_NEIGHBORHOOD_POLYGONS_URL = `${API_ROOT_URL}/admin/postcode/%s`;
const GET_ALL_NEIGHBORHOOD_POLYGONS_URL = `${API_ROOT_URL}/order/count_by_neighborhood`;
const GET_CITY_BY_ID = `${API_ROOT_URL}/city/%s`;
const COORDINATES_OF_NEIGHBORHOODS_URL = `${API_ROOT_URL}/admin/postcode/search`;
const GET_POSTCODES_BY_LOCATION = `${API_ROOT_URL}/admin/postcode/get_by_location`;
const POSTCODES_MAP_VIEW = `${API_ROOT_URL}/admin/postcodes_map_view`;

const GET_DENSITY_FILTERS = `${API_ROOT_URL}/density/filters`;
const GET_DENSITY = `${API_ROOT_URL}/density/`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(POSTCODE_INDEX_ITEM_URL, id)),
});

const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(POSTCODE_INDEX_CHOOSE_URL, {
      params: request.getDefinedValues(),
    }),
});

const predictionsCacheOnlyForOrder = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(ORDER_POSTCODE_INDEX_CHOOSE_URL, {
      params: request.getDefinedValues(),
    }),
});

export const getCoordinatesOfNeighborhoods = (request: DataListFilter) =>
  api.getStream(COORDINATES_OF_NEIGHBORHOODS_URL, {
    params: request.getDefinedValues(),
  });

export const getPostcode = id => itemCache.refresh(id);
export const getCachedPostcode = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getPostcodePredictions = (request: DataListFilter) =>
  predictionsCache.get(request);
export const getPostcodePredictionsForOrder = (request: DataListFilter) =>
  predictionsCacheOnlyForOrder.get(request);

export const getSenderPostcodePredictions = (request: DataListFilter) =>
  api.get(POSTCODE_INDEX_CHOOSE_URL, {
    params: request.getDefinedValues(),
  });
export const getPostcodeById = id =>
  api.get(sprintf(POSTCODE_INDEX_ITEM_URL, id));
export const postcodeChoose = params =>
  api.get(POSTCODE_INDEX_CHOOSE_URL, { params });

export type CreateNeighborhoodRequest = {
  city: {
    description: string,
    id: number,
    name: string,
    phone: string,
  },
  suppliers: string[],
  warehouse: {
    description: string,
    id: number,
    name: string,
    phone: string,
  },
  id: number,
  marketplace_id: number,
  marketplace_ids: number[],
  name: string,
  address: string,
  wkt: string,
};

export const getCityById = cityId =>
  api.getStream(sprintf(GET_CITY_BY_ID, cityId));

export const getNeighborhoodPolygons = cityId =>
  api.getStream(sprintf(GET_NEIGHBORHOOD_POLYGONS_URL, cityId));

export const getAllNeighborhoodPolygons = request =>
  api.getStream(GET_ALL_NEIGHBORHOOD_POLYGONS_URL, {
    params: request.getDefinedValues(),
  });

export const createNeighborhood = (body: CreateNeighborhoodRequest) =>
  api.post(CREATE_POSTCODE_INDEX_URL, { body });

export const updateNeighborhood = (id, body: CreateNeighborhoodRequest) =>
  api.put(sprintf(POSTCODE_INDEX_ITEM_URL, id), { body });

export const deletePostCode = (id) =>
  api.delete(sprintf(POSTCODE_INDEX_ITEM_URL, id));

export const deleteNeighborhood = id =>
  api.delete(sprintf(POSTCODE_INDEX_CUSTOM_ITEM_URL, id));

export const uploadNeighborhoodKML = (id, file) =>
  api.postStream(sprintf(UPLOAD_NEIGHBORHOOD_KML_URL, id), { file });

export const getPostcodeIndexList = request =>
  api.getStream(GET_POSTCODES_LIST_URL, {
    params: request.getDefinedValues(),
  });
export const getPostcodeIndexList2 = data => {
  const params = {};
  Object.keys(data).forEach(v => {
    if (data[v] || data[v] === 0) params[v] = data[v];
  });
  return api.get(GET_POSTCODES_LIST_URL, {
    params: { ...params, search: params.search || "" },
  });
};

export const getPostcodeIndexMapView = data => {
  const params = {};
  Object.keys(data).forEach(v => {
    if (data[v] || data[v] === 0) params[v] = data[v];
  });
  return api.get(POSTCODES_MAP_VIEW, {
    params: { ...params, search: params.search || "" },
  });
};

export const getSupplierNeighborhoods = id =>
  api.getStream(sprintf(GET_SUPPLIER_NEIGHBORHOODS_URL, id));

export const updateSupplierNeighborhood = (id, body) =>
  api.put(sprintf(UPDATE_SUPPLIER_NEIGHBORHOOD_URL, id), { body });

export const getDensityList = (request: DataListFilter) =>
  api.getStream(GET_DENSITY, {
    params: request.getDefinedValues(),
  });

export const getDensityCountryList = body =>
  api.post(GET_DENSITY_FILTERS, { body });

export const getPostcodesByLocation = location =>
  api.get(GET_POSTCODES_BY_LOCATION, {
    params: {
      lat: location.lat,
      lng: location.lng,
      distance: 1000,
    },
  });

export const getPostcodesByJM = jurisdictionId =>
  api.get(ORDER_POSTCODE_INDEX_CHOOSE_URL, {
    params: {
      size: 3000,
      page: 0,
      status: "active",
      jurisdiction_id: jurisdictionId,
    },
  });
