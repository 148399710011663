import { combineReducers } from "redux-immutable";
import { injectReducer } from "../../shared/helpers/ReducerContext";

const SET_PUSH_REFRESH = "PUSH_REFRESH/SET_PUSH_REFRESH";

const selector = injectReducer(
  "pushRefresh",
  combineReducers({
    pushRefresh: (state = false, { type, payload }) => {
      switch (type) {
        case SET_PUSH_REFRESH:
          return payload;

        default:
          return state;
      }
    },
  }),
);

export function getPushLog(state) {
  return selector(state).get("pushRefresh");
}

export const updatePushLog = bool => ({
  payload: bool,
  type: SET_PUSH_REFRESH,
});
