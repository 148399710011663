import { OrderedSet } from "immutable";

export const OTHERS = "OTHERS";
export const FOLLOW_UP = "FOLLOW_UP";
export const ESCALATION = "ESCALATION";
export const ONLINE_CHAT = "ONLINE_CHAT";
export const INBOUND_CALL = "INBOUND_CALL";
export const OUTBOUND_CALL = "OUTBOUND_CALL";
export const WING_SUPPORT_EMAIL = "WING_SUPPORT_EMAIL";
export const CHAT_CALL_FROM_CLIENT = "CHAT_CALL_FROM_CLIENT";
export const SOUQ_CUSTOMER_SUPPORT_EMAIL = "SOUQ_CUSTOMER_SUPPORT_EMAIL";
export const SOCIAL_MEDIA_GOOGLE_APP_GOOGLE_PLAY =
  "SOCIAL_MEDIA_GOOGLE_APP_GOOGLE_PLAY";

export default OrderedSet.of(
  INBOUND_CALL,
  OUTBOUND_CALL,
  ONLINE_CHAT,
  CHAT_CALL_FROM_CLIENT,
  WING_SUPPORT_EMAIL,
  SOCIAL_MEDIA_GOOGLE_APP_GOOGLE_PLAY,
  ESCALATION,
  FOLLOW_UP,
  OTHERS,
);
