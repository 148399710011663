import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

ExtendElement.propTypes = {
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
};

export default function ExtendElement({ element: elem, ...props }) {
  if (React.isValidElement(elem)) {
    const nextProps = { ...props };

    if (elem.props.style) {
      nextProps.style = { ...elem.props.style, ...nextProps.style };
    }

    if (elem.props.className) {
      nextProps.className = cx(elem.props.className, nextProps.className);
    }

    return React.cloneElement(elem, nextProps);
  }

  return React.createElement(elem, props);
}
