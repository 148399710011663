import { Map, OrderedSet } from "immutable";

export const TERRESTRIAL = "terrestrial";
export const AIR = "air";
export const COURIER_SERVICE = "courier_service";
export const HAND_TO_HAND = "hand_to_hand";
export const ONE_STEP = "one_step";
export const CORPORATE_INTERNAL = "corporate_internal";
export const UNREGISTERED = "unregistered";
export const SIMPLE = "simple";
export const LOCKER_POST = "locker_post";

export const transportationTypesName = Map({
  TERRESTRIAL: "Наземный",
  AIR: "Воздушный",
  COURIER_SERVICE: "Курьерская служба",
  HAND_TO_HAND: "Доставка из рук в руки",
  CORPORATE_INTERNAL: "Служебный",
});

export default OrderedSet.of(
  TERRESTRIAL,
  AIR,
  COURIER_SERVICE,
  HAND_TO_HAND,
  CORPORATE_INTERNAL,
  LOCKER_POST,
);

export const transportationTypeForPackageRule = OrderedSet.of(
  TERRESTRIAL,
  AIR,
  COURIER_SERVICE,
  ONE_STEP,
  CORPORATE_INTERNAL,
  UNREGISTERED,
  SIMPLE,
  LOCKER_POST,
);
export const lockerPostType = OrderedSet.of("LOCKER_POST", "UZPOST");
