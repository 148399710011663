import React, { useState } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FlexBox, { ALIGN_END } from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { validatePasswordLocalised } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    cardHeader: { paddingBottom: 0 },
  }),
  reduxForm({
    form: "ProfilePasswordForm",
    validate: (values, props) => ({
      currentPassword: !values.currentPassword
        ? // ? "Enter Current Password"
          props.getLocalisationMessage(
            "enter_current_password",
            "Enter Current Password",
          )
        : null,
      password: validatePasswordLocalised(
        values.password,
        props.getLocalisationMessage(
          "password_is_required",
          "Password is required",
        ),
        props.getLocalisationMessage(
          "password_min_length_error",
          "Password must contain at least six characters",
        ),
      ),
      passwordConfirm:
        values.passwordConfirm !== values.password
          ? // ? "Passwords are not equal"
            props.getLocalisationMessage(
              "passwords_are_not_equal",
              "Passwords are not equal",
            )
          : validatePasswordLocalised(
              values.passwordConfirm,
              props.getLocalisationMessage(
                "password_is_required",
                "Password is required",
              ),
              props.getLocalisationMessage(
                "password_min_length_error",
                "Password must contain at least six characters",
              ),
            ),
    }),
  }),
);

ProfilePasswordForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function ProfilePasswordForm(props) {
  const { classes, getLocalisationMessage } = props;

  const [state, setState] = useState({
    showPassword1: false,
    showPassword2: false,
    showPassword3: false,
    showPassword: false,
  });

  return (
    <FlexBox gutter={8} flex={true}>
      <FlexBox flex={true} direction="column">
        <Card>
          <PageLoading isLoading={props.submitting} />
          <CardHeader
            title={getLocalisationMessage("password", "Password")}
            className={classes.cardHeader}
          />
          <form autoComplete="off" onSubmit={props.handleSubmit}>
            <CardContent>
              <FlexBox style={{ gap: 16 }} direction="column">
                <FlexBox flex={true} align={ALIGN_END}>
                  <FormTextField
                    name="currentPassword"
                    fullWidth={true}
                    type={state.showPassword1 ? "text" : "password"}
                    label={getLocalisationMessage(
                      "current_password",
                      "Current Password",
                    )}
                    password={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setState({
                                ...state,
                                showPassword1: !state.showPassword1,
                              })
                            }
                          >
                            {state.showPassword1 ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FlexBox>
                <FlexBox flex={true} align={ALIGN_END}>
                  <FormTextField
                    name="password"
                    fullWidth={true}
                    type={state.showPassword2 ? "text" : "password"}
                    label={getLocalisationMessage(
                      "new_password",
                      "New Password",
                    )}
                    password={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setState({
                                ...state,
                                showPassword2: !state.showPassword2,
                              })
                            }
                          >
                            {state.showPassword2 ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FlexBox>
                <FlexBox flex={true} align={ALIGN_END}>
                  <FormTextField
                    name="passwordConfirm"
                    fullWidth={true}
                    type={state.showPassword3 ? "text" : "password"}
                    label={getLocalisationMessage(
                      "confirm_password",
                      "Confirm Password",
                    )}
                    password={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setState({
                                ...state,
                                showPassword3: !state.showPassword3,
                              })
                            }
                          >
                            {state.showPassword3 ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>

            <FlexBox flex={true} justify="flex-end">
              <CardActions>
                <Button type="submit">
                  {getLocalisationMessage("change_password", "Change Password")}
                </Button>
              </CardActions>
            </FlexBox>
          </form>
        </Card>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(ProfilePasswordForm);
