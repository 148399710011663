import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const CREATE_WAREHOUSE_URL = `${API_ROOT_URL}/admin/warehouse`;
const WAREHOUSE_ITEM_URL = `${API_ROOT_URL}/admin/warehouse/%s`;
const WAREHOUSE_CHOOSE_URL = `${API_ROOT_URL}/admin/warehouse/choose`;
const WAREHOUSE_DRIVERS_URL = `${API_ROOT_URL}/admin/warehouse/%s/drivers`;
const WAREHOUSE_POLYGONS_URL = `${API_ROOT_URL}/admin/warehouse/list`;
const WAREHOUSE_MARKETPLACE_URL = `${API_ROOT_URL}/admin/warehouse/%s/marketplaces`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: (id) => api.getStream(sprintf(WAREHOUSE_ITEM_URL, id)),
});

const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: (request) =>
    api.getStream(WAREHOUSE_CHOOSE_URL, { params: request.getDefinedValues() }),
});
export const getWarehouseById = (id) =>
  api.get(sprintf(WAREHOUSE_ITEM_URL, id));
export const warehouseChoose = (params) =>
  api.get(WAREHOUSE_CHOOSE_URL, { params });

export const getWarehouse = (id) => itemCache.refresh(id);

export const getCachedWarehouse = (id) =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));

export const getWarehousePredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export type WarehouseItemRequest = {
  neighborhoods: [],
  suppliers: [],
  id: number,
  marketplace_id: number,
  marketplace_ids: number[],
  name: string,
  address: string,
  status: string,
  lat: number,
  lon: number,
  wkt: string,
};

export type WarehouseDriversRequest = {
  marketplace_id: number,
  warehouse: {
    description: string,
    id: number,
    name: string,
    phone: string,
  },
  drivers: [],
  marketplace_ids: number[],
};

export const getWarehousePolygons = () => api.getStream(WAREHOUSE_POLYGONS_URL);

export const createWarehouseItem = (body: WarehouseItemRequest) =>
  api.post(CREATE_WAREHOUSE_URL, { body });

export const updateWarehouseItem = (id, body: WarehouseItemRequest) =>
  api.put(sprintf(WAREHOUSE_ITEM_URL, id), { body });

export const updateWarehouseDrivers = (id, body: WarehouseDriversRequest) =>
  api.put(sprintf(WAREHOUSE_DRIVERS_URL, id), { body });

export const saveMarketplaces = (id, marketplaceIds) =>
  api.post(sprintf(WAREHOUSE_MARKETPLACE_URL, id), {
    body: {
      marketplace_ids: marketplaceIds,
    },
  });
export const getWarehouse2 = (id) => api.get(sprintf(WAREHOUSE_ITEM_URL, id));
