import React from "react";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { safeParseDate } from "../../helpers/DateUtils";
import { DATE_SHORT_FORMAT, formatDate } from "../../helpers/FormatUtils";
// import { TextField } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { getCurrentLanguage } from "../../reducers/LocalizationReducer";
import { ENGLISH_LANGUAGE } from "../../constants/LocaleTypes";
import CustomButton, { SECONDARY } from "../ui-core/CustomButton";

// const mergeTime = (date, time) =>
//   new Date(
//     date.getFullYear(),
//     date.getMonth(),
//     date.getDate(),
//     time.getHours(),
//     time.getMinutes(),
//     0,
//   );

DateFieldComponent.propTypes = {
  meta: PropTypes.object,
  inputRef: PropTypes.func,
  onKeyDown: PropTypes.func,
  handleSelectChange: PropTypes.func,
  input: PropTypes.object,
  immediatelyShowError: PropTypes.bool,
  ignoreError: PropTypes.bool,
  initialTime: PropTypes.instanceOf(Date),
};

export function DateFieldComponent({
  initialTime,
  input,
  meta,
  ignoreError,
  immediatelyShowError,
  inputRef,
  onKeyDown,
  handleSelectChange,
  ...custom
}) {
  const value = safeParseDate(input.value);
  const language = getCurrentLanguage() || ENGLISH_LANGUAGE;
  return (
    <DatePicker
      {...input}
      {...custom}
      value={value}
      lang={language}
      error={
        ignoreError
          ? immediatelyShowError
            ? meta.error
            : Boolean(!meta.active && meta.touched && meta.error)
          : ""
      }
      inputRef={inputRef}
      helperText={
        ignoreError
          ? immediatelyShowError
            ? meta.error
            : !meta.active && meta.touched && meta.error
          : ""
      }
      onChange={date => {
        input.onChange(date || new Date());
        if (handleSelectChange) handleSelectChange();
      }}
      inputProps={{
        onKeyDown,
      }}
      format={custom.formatValue}
      onBlur={fp.noop}
      placeholder={custom.hintText}
      label={custom.hintText}
      inputVariant={custom.variant}
      size={custom.size}
      cancelLabel={<CustomButton color={SECONDARY}>Cancel</CustomButton>}
      okLabel={<CustomButton color={SECONDARY}>OK</CustomButton>}
    />
    // <KeyboardDatePicker
    //   {...input}
    //   {...custom}
    //   value={value}
    //   lang={language}
    //   // format={custom.formatDate}
    //   error={meta.touched && meta.error}
    //   onChange={(date) => {
    //     input.onChange(date)
    //   }}
    // />
  );

  // return (
  //   <TextField
  //     {...input}
  //     {...custom}
  //     type="date"
  //     value={value}
  //     locale={language}
  //     DateTimeFormat={Intl.DateTimeFormat}
  //     onBlur={fp.noop}
  //     onFocus={fp.noop}
  //     format={custom.formatDate}
  //     errorText={meta.touched && meta.error}
  //     onShow={() => input.onFocus()}
  //     onDismiss={() => input.onBlur()}
  //     onChange={(event, nextValue) => {
  //       const time = value || safeParseDate(initialTime) || new Date();
  //       input.onChange(mergeTime(nextValue, time));
  //     }}
  //   />
  // );
}

FormDateField.propTypes = {
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  ignoreError: PropTypes.bool,
  immediatelyShowError: PropTypes.bool,
  hintText: PropTypes.node,
  formatDate: PropTypes.func,
  inputRef: PropTypes.func,
  onKeyDown: PropTypes.func,
  handleSelectChange: PropTypes.func,
  label: PropTypes.node,
  initialTime: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
  formatValue: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
};

FormDateField.defaultProps = {
  formatDate: date => formatDate(date, DATE_SHORT_FORMAT),
  formatValue: DATE_SHORT_FORMAT,
  variant: "outlined",
  size: "small",
  ignoreError: true,
};

export default function FormDateField(props) {
  return <Field {...props} component={DateFieldComponent} />;
}
