import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormChipInput from "../form/FormChipInput";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import FormAutoComplete from "../form/FormAutoComplete";
import { formatOrderStatus } from "./BatchUpdateOrderDialog2";
import { HybridStatuses, RTOStatuses } from "../../constants/OrderStatusCodes";

const valueSelector = formValueSelector("BatchRTOOrdersDialog");

const enhancer = compose(
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  connect(
    state => ({
      orderBarcodes: valueSelector(state, "orderBarcodes"),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withHandlers({
    onSubmit: props => ({ warehouse, ...values }) => {
      if (!props.onSubmit) {
        return null;
      }

      return props.onSubmit({
        ...values,
        warehouse: fp.pick(["id"], warehouse),
      });
    },
  }),
  reduxForm({
    form: "BatchRTOOrdersDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      orderBarcodes:
        fp.size(values.orderBarcodes) === 0 &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_orders")) ||
          "Select Orders"),
      note:
        !values.note &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("enter_reason_note")) ||
          "Enter Reason/Note"),
    }),
  }),
);

BatchRTOOrdersDialog.propTypes = {
  submitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  hideOrderNumber: PropTypes.bool,
  isHybrid: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  classes: PropTypes.object,
  theme: PropTypes.object,
};

function BatchRTOOrdersDialog(props) {
  const { getLocalisationMessage, classes } = props;

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          minWidth: 600,
          maxWidth: 800,
          minHeight: 200,
        },
      }}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        {getLocalisationMessage("batch_rto_orders")}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <form>
          {props.isLoading ? (
            <FlexBox justify="center" align="center">
              <CircularProgress size={60} color="secondary" />
            </FlexBox>
          ) : (
            <FlexBox container={8} direction="column">
              <FlexBox flex={true}>
                <FormChipInput
                  name="orderBarcodes"
                  focus={true}
                  fullWidth={true}
                  disabled={props.hideOrderNumber}
                  label={getLocalisationMessage("orders_barcodes")}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormAutoComplete
                  margin="normal"
                  name="orderStatus"
                  fullWidth={true}
                  label={getLocalisationMessage("status", "Status")}
                  options={props.isHybrid ? HybridStatuses : RTOStatuses}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type To Search...",
                  )}
                  // formatOption={formatOrderStatus}
                  formatOption={x =>
                    formatOrderStatus(x, getLocalisationMessage)
                  }
                />
              </FlexBox>
            </FlexBox>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose} primary={true}>
          {getLocalisationMessage("dismiss")}
        </Button>

        <Button onClick={props.handleSubmit} primary={true}>
          {getLocalisationMessage("submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchRTOOrdersDialog);
