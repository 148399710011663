import React, { useEffect, useState } from "react";
import useSheet from "react-jss";
import { compose, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
  JUSTIFY_SPACE_BETWEEN,
} from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import FormAutoComplete from "../form/FormAutoComplete";
import { formatOrderStatus } from "./BatchUpdateOrderDialog2";
import { RTOStatuses } from "../../constants/OrderStatusCodes";
import FormTextField from "../form/FormTextField";
import { CARD, CASH } from "../../constants/OrderPaymentTypes";
import { cardType, serviceId, urlAVVAL } from "./AdminPostForm";
import { getAVVALUserId } from "../../reducers/ProfileReducer";
import { green, orange, red } from "@material-ui/core/colors";
import { Check, CheckCircle, Warning } from "@material-ui/icons";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import FormSelectField from "../form/FormSelectField";
import { createNotFalsyValidator } from "../../helpers/FormUtils";

const enhancer = compose(
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  connect(
    state => ({
      userIdAVVAL: getAVVALUserId(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }

      return props.onSubmit({
        ...values,
      });
    },
  }),
  reduxForm({
    form: "BatchRTOPPriceOrdersDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      price:
        !values.price && props.getLocalisationMessage("this_field_is_required"),
    }),
  }),
  formValues({
    paymentType: "paymentType",
  }),
);

BatchRTOPPriceOrdersDialog.propTypes = {
  submitting: PropTypes.bool,
  hideOrderNumber: PropTypes.bool,
  isHybrid: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  change: PropTypes.func,
  classes: PropTypes.object,
  theme: PropTypes.object,
  userIdAVVAL: PropTypes.string,
  paymentType: PropTypes.string,
  isLoadingRTOPrice: PropTypes.bool,
};

function BatchRTOPPriceOrdersDialog(props) {
  const {
    getLocalisationMessage,
    classes,
    change,
    userIdAVVAL,
    isLoadingRTOPrice,
    paymentType,
  } = props;
  const [isLoadingConnectingAVVAL, setIsLoadingConnectingAVVAL] = useState(
    false,
  );
  const [isConnectedAVVAL, setIsConnectedAVVAL] = useState(false);
  const changePaymentType = event => {
    change("paymentType", event.target.value);
    change("cardType", null);
    change("pptId", null);
  };

  useEffect(() => {
    if (userIdAVVAL) {
      setIsLoadingConnectingAVVAL(true);
      fetch(urlAVVAL, {
        method: "POST",
        body: JSON.stringify({
          method: "get-info",
          userId: userIdAVVAL,
          serviceId,
          request: {
            login: "123",
          },
        }),
      })
        .then(() => {
          setIsConnectedAVVAL(true);
        })
        .catch(() => setIsConnectedAVVAL(false))
        .finally(() => setIsLoadingConnectingAVVAL(false));
    }
  }, [userIdAVVAL]);

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      PaperProps={{
        style: {
          minWidth: 600,
          maxWidth: 800,
        },
      }}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        {getLocalisationMessage("payment_for_rto")}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <form>
          <FlexBox container={8} direction="column">
            <FlexBox flex={true}>
              <FormTextField
                name="barcode"
                focus={true}
                fullWidth={true}
                disabled={true}
                label={getLocalisationMessage("barcode")}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormAutoComplete
                margin="normal"
                name="status"
                fullWidth={true}
                disabled={true}
                label={getLocalisationMessage("status", "Status")}
                options={RTOStatuses}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
                formatOption={x => formatOrderStatus(x, getLocalisationMessage)}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormTextField
                name="price"
                type="number"
                focus={true}
                fullWidth={true}
                label={`${getLocalisationMessage("price")} *`}
                onFocus={event => {
                  event.target.select();
                }}
                parse={value => {
                  if (value <= 0) {
                    return 0;
                  }

                  return String(Math.round(parseFloat(String(value))));
                }}
              />
            </FlexBox>

            <FormControl style={{ marginTop: "1rem" }} component="fieldset">
              <span>{getLocalisationMessage("choose_payment_type")}</span>
              <RadioGroup
                style={{ flexDirection: "row" }}
                aria-label="gender"
                name="paymentType"
                value={paymentType}
                onChange={changePaymentType}
              >
                <FormControlLabel
                  value={CASH}
                  control={<Radio />}
                  label={getLocalisationMessage(CASH)}
                />
                <FormControlLabel
                  value={CARD}
                  control={<Radio />}
                  label={getLocalisationMessage(CARD)}
                />
              </RadioGroup>
            </FormControl>

            {paymentType === CARD && (
              <FlexBox flex={true} style={{ gap: 16 }}>
                <FormSelectField
                  autoWidth={true}
                  fullWidth={true}
                  name="cardType"
                  label={`${getLocalisationMessage("card_type")} *`}
                  options={cardType}
                  formatOption={x =>
                    x === "PRIVATE"
                      ? getLocalisationMessage("personal")
                      : getLocalisationMessage("corporate")
                  }
                  validate={createNotFalsyValidator(
                    getLocalisationMessage("this_field_is_required"),
                  )}
                />
                <FormTextField
                  fullWidth={true}
                  name="pptId"
                  type="number"
                  onFocus={event => {
                    event.target.select();
                  }}
                  onInput={e => {
                    // eslint-disable-next-line radix
                    e.target.value = e.target.value.toString().slice(0, 20);
                  }}
                  label={`${getLocalisationMessage("ppt_id", "PPT ID")} *`}
                  variant={OUTLINED}
                  validate={createNotFalsyValidator(
                    getLocalisationMessage("this_field_is_required"),
                  )}
                />
              </FlexBox>
            )}

            <FlexBox
              style={{ marginBottom: "0.5rem" }}
              justify={JUSTIFY_CENTER}
              align={ALIGN_CENTER}
              flex={true}
              direction="column"
            >
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                    color: isLoadingConnectingAVVAL
                      ? orange[500]
                      : isConnectedAVVAL
                      ? green[500]
                      : red[500],
                  }}
                >
                  {getLocalisationMessage(
                    !userIdAVVAL
                      ? "user_profile_is_not_connected_with_avval_profile"
                      : isLoadingConnectingAVVAL
                      ? "connecting_to_avval"
                      : isConnectedAVVAL
                      ? "user_is_connected_to_avval"
                      : "user_is_not_connected_to_avval",
                  )}
                </span>
                {isLoadingConnectingAVVAL ? (
                  <CircularProgress size={20} style={{ color: orange[500] }} />
                ) : isConnectedAVVAL ? (
                  <CheckCircle style={{ color: green[500] }} />
                ) : (
                  <Warning style={{ color: red[500] }} />
                )}
              </h5>
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>

      <DialogActions style={{ margin: "1rem 0" }}>
        <FlexBox flex={true} justify={JUSTIFY_SPACE_BETWEEN}>
          <CustomButton
            color={SECONDARY}
            variant={OUTLINED}
            onClick={props.close}
            primary={true}
          >
            {getLocalisationMessage("dismiss")}
          </CustomButton>

          <CustomButton
            disabled={isLoadingRTOPrice}
            size="small"
            style={{ padding: "10px 6rem" }}
            variant={CONTAINED}
            color={SECONDARY}
            endIcon={
              isLoadingRTOPrice ? (
                <CircularProgress size={20} color="white" />
              ) : (
                <Check />
              )
            }
            onClick={props.handleSubmit}
          >
            {getLocalisationMessage("submit")}
          </CustomButton>
        </FlexBox>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchRTOPPriceOrdersDialog);
